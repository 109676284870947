<p>resource-selection-popup works!</p>
<div 
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="config-modal" 
    tabindex="-1" 
    aria-labelledby="config-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Technicians</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3">
                <div class="row">
                    <div class="col">
                        <ejs-grid #resourceGrid
                            allowPaging = "true"
                            [query]="query"
                            [pageSettings]="gridPageSettings"
                            [dataSource]="resourceList" 
                            [allowFiltering]="true"
                            [allowReordering]="true"
                            [allowSorting]="true" 
                            [toolbar]="gridToolbar"
                            [filterSettings]="{ type: 'Menu' }"
                            [allowSelection]='true'
                            (toolbarClick)="toolbarClick($event, resourceGrid)"
                            (commandClick)="onCommandClick($event)"
                            (created)="onGridCreated($event)"
                            (actionBegin)="onGridActionBegin($event)"
                            (actionComplete)="onGridActionComplete($event, resourceGrid)"
                            >
                            <e-columns>
                                <e-column 
                                    *ngFor="let column of gridColumns;" 
                                    [field]="column.field" 
                                    [headerText]="column.headerText" 
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [format]="column.format ? column.format : null"
                                    [filterType]="'contains'"
                                    [textAlign]="column.textAlign" 
                                    [visible]="column.visible"
                                    [commands]="column?.commands ? column.commands : null"
                                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                    [allowEditing]="column.allowEditing" 
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>