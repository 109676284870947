<app-loader *ngIf="showLoader"></app-loader>
<div class="w-100">
  <ejs-grid
    #msGrid
    *ngIf="gridProps"
    [id]="gridProps.elementId || 'ms-syncfusion-grid'"
    [dataSource]="gridData"
    [height]="gridHeight"
    [query]="gridQuery"
    [gridLines]="gridProps.gridLines"
    [allowFiltering]="gridProps.allowFiltering"
    [allowSorting]="gridProps.allowSorting"
    [allowPaging]="gridProps.allowPaging"
    [pageSettings]="gridProps.pageSettings"
    [allowResizing]="gridProps.allowResizing"
    [allowExcelExport]="gridProps.allowExcelExport"
    [allowPdfExport]="gridProps.allowPdfExport"
    [allowSelection]="gridProps.allowSelection"
    [enablePersistence]="false"
    [toolbar]="gridProps.toolbar"
    [filterSettings]="gridProps.filterSettings"
    [editSettings]="gridProps.editSettings"
    [selectionSettings]="gridProps.selectionSettings"
    [columns]="gridProps.columns"
    (actionBegin)="onGridActionBegin($event)"
    (actionComplete)="onGridActionComplete($event, msGrid)"
    (excelQueryCellInfo)="onExcelQueryCellInfo($event)"
    (toolbarClick)="onToolbarClick($event)"
    (created)="onGridCreated($event)"
    (commandClick)="onCommandClick($event)"
    (rowDataBound)="onRowDataBound($event)"
></ejs-grid>
</div>
<ng-template #columnChooser>
    <app-column-chooser
        [columns]="gridProps.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<ng-template #clickableId let-data>
    <a href="javascript:" (click)="onClickableClick(data)">{{data[data.column.field]}}</a>
</ng-template>

<ng-template #statusColumn let-data>
    <span class="grid-status-column {{commonService.getStatusClass(data[data.column.field])}}">{{data[data.column.field]}}</span>
</ng-template>

<app-ms-dynamic-popup
    *ngIf="showAdjustmentOrderPopup"
    [tabName]="tab"
    pageId="PG0000149"
    [pageType]='"Popup"'
    [orderId]="popupData.adjustmentOrderNumber"
    [data]="popupData"
    [showComments]="showComments"
    (onClose)="showAdjustmentOrderPopup = false;"
    (onSave)="getPageData(gridProps)"
></app-ms-dynamic-popup>

<app-ms-dynamic-modal
    *ngIf="showAdjustmentOrderListPopup"
    pageId="PG0000161"
    modalTitle="Adjustment Orders"
    modalTitleLabel="The Following adjustment order(s) were created"
    [data]="adjustmentOrdersList"
    (onClose)="adjustmentOrderListPopupClosed($event)"
></app-ms-dynamic-modal>


<app-linked-popup-opener
    *ngIf="linkedPopupData"
    [data]="linkedPopupData"
    (onPopupClose)="linkedPopupData = null;"
></app-linked-popup-opener>

<ng-template #resourceDropdown>
    <ejs-dropdownlist
        #resourceDropdownElement
        id='inventory-resources-list'
        width='200px'
        placeholder='Select Technician...'
        [dataSource]='resourceList'
        [fields]="{ text: 'employeeName', value: 'employeeId' }"
        [value]="selectedResource"
        [showDropDownIcon]='true'
        [allowFiltering]='true'
        [filterBarPlaceholder]="'Search Technician...'"
        [popupWidth]="'300px'"
        (created)="onResourceDropdownCreated()"
        (change)="onResourceChanged($event, msGrid)">
    </ejs-dropdownlist>
</ng-template>
