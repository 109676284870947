
<app-loader *ngIf="showLoader"></app-loader>
<div class="row mt-3">
    <div class="col">
        <div class="field mb-3 position-relative">
            <label>Tracking ID:</label>
            <ejs-textbox #trackingId
                placeholder="Tracking ID"
                [value]="details?.trackingId"
                [enabled]="status === statuses.open && !isManager && !isCSA"
                (change)="details.trackingId = $event.value; tabUpdated.emit(details);"
            ></ejs-textbox>
            <!-- <div class="field-error" *ngIf="validationApplied && !details.trackingId">Tracking Id is required</div> -->
        </div>        
    </div>
    <div class="col">
        <div class="field mb-3 position-relative">
            <label>Shipment Method:</label><span class="required-asterik">*</span>
            <ejs-dropdownlist #shipmentMethod
                [placeholder]="'Shipment Method'"
                [dataSource]="shipmentMethods"
                [allowFiltering]="true"
                [filterType]="'contains'"
                [fields]="{text: 'text', value: 'value'}"
                [value]="details?.shipmentMethodCode"
                [enabled]="status === statuses.open && !isManager && !isCSA"
                (change)="details.shipmentMethodCode = $event.itemData.value; details.shipmentMethodDescription = $event.itemData.description; tabUpdated.emit(details);"
            ></ejs-dropdownlist>
            <div class="field-error" *ngIf="validationApplied && !details.shipmentMethodCode">Shipment method is required</div>
        </div>
    </div>
    <div class="col">
        <div class="field mb-3">
            <label>Estimated delivery:</label>
            <ejs-datepicker
                format="d MMM, y"
                [strictMode]='true'
                [min]="minEstimatedDate"
                placeholder='Estimated delivery'
                [value]="details?.estimatedDeliveryDate"
                [enabled]="status === statuses.open && !isManager && !isCSA"
                (change)="details.estimatedDeliveryDate = $event.value; tabUpdated.emit(details);"
            ></ejs-datepicker>
        </div>
    </div> 
</div>
