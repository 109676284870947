import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { PageElement } from 'src/app/modules/service-order/models/popup.model';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { parentStatuses, partsOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { ApiResponse, Companies, LinksEnum, UserRoles } from 'src/app/shared/models/app.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-parts-orders-popup',
  templateUrl: './parts-orders-popup.component.html',
  styleUrls: ['./parts-orders-popup.component.scss', '../../../../../../../../assets/scss/popup.scss']
})
export class PartsOrdersPopupComponent implements OnInit, OnDestroy {

  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = 'details';
  @Input() id: string = null;
  @Input() showComments: boolean = false;
  @Input() itemsData = [];
  @Input() popupSource;
  @Input() serviceOrderId: string = '';
  @Input() data = null;
  @Input() serviceOrderTechnician = null;

  @Input() originId = null;
  @Input() originModule = null;

  showPartsOrderPopup: boolean = false;
  isManager: boolean = false;
  isCSA: boolean = false;
  partsOrderData: object;

  USER;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  statuses = partsOrderStatuses;

  validateDetails: boolean = false;
  validateItems: boolean = false;
  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;

  commentCount = 0;
  attachmentCount = 0;
  detailsData: Object;
  links = [];
  attachmentsData = [];

  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('itemsTab') items;
  @ViewChild('attachmentsTab') attachments;

  priorities = [];
  itemsDDL = []
  shipmentMethods = [];
  technicianAddresses = [];
  onsiteAddress: any;
  placeOrderBtnDisabled: boolean = true;
  isKingslynn: boolean = false;
  isReadOnly: boolean = false;


  constructor(
    private commonService: CommonService,
    private serviceOrderService: ServiceOrderService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
  ) {
    this.USER = this.commonService.USER;
    this.isManager = this.USER.role === UserRoles.conwayServiceManager;
    this.isCSA = this.USER.role === UserRoles.csa;
    this.isReadOnly = this.USER.role === UserRoles.readOnly;
    this.isKingslynn = this.commonService.USER.company === Companies.kingslynn;
  }

  ngOnInit(): void {
    // this.data.linkColorCode = '#ffffff'
    this.inventoryService.popupLoader.pipe(
      takeUntil(this.destroy$),
    ).subscribe((showLoader: any) => {
      this.showLoader = showLoader;
    });
    this.route.paramMap.pipe(
      takeUntil(this.destroy$)).subscribe(params => {
      if (params && params.get('tab') && params.get('id') && params.get('showComments')) {
        this.id = params.get('id')
        this.showComments = params.get('showComments') == 'showComments' ? true : false 
      }
    });
    this.loadPage();
  }

  loadPage() {
    this.setPageData();
    this.setItemsTabData();
    this.setAttachmentsTabData();
    this.loadAPIs();
    this.toggleButtonDisable()
  }

  toggleButtonDisable() {
    if (!this.itemsData.filter(item => !item.isDeleted).length) {
      this.placeOrderBtnDisabled = true;
    } else {
      this.placeOrderBtnDisabled = this.data.status !== partsOrderStatuses.open;
    }
  }

  setPageData() {
    if (!this.data) {
      this.data = {
        partsOrderId: '',
        status: partsOrderStatuses.open,
        parentStatus: parentStatuses.open,
        company: this.USER.company,
        technicianId: this.serviceOrderTechnician?.technicianId || this.USER.userId,
        technicianName: this.serviceOrderTechnician?.technicianName || this.USER.userName,
        warehouse: this.serviceOrderTechnician?.warehouse || this.USER.warehouse,
        orderCurrency: this.USER.orderCurrency,
        createdBy: this.USER.userId,
        createdByName: this.USER.userName,
        lastUpdatedBy: this.USER.userName,
        lastUpdatedByName: this.USER.userName,
        serviceOrderId: this.serviceOrderId,
        itemList: [],
        comments: [],
        attachments: []
      };
    } else {
      this.serviceOrderId = this.data.serviceOrderId;
      this.commentCount = this.data.comments.length;
      this.attachmentCount = this.data.attachments.length;
    }
  }

  setDetailsTabData() {
    const address = this.getAddress();
    this.detailsData = {
      priority: this.data?.priority || null,
      serviceOrderId: this.data?.serviceOrderId,
      createdDate: this.data?.createdDate && new Date(this.data.createdDate) || new Date(),
      shipmentMethodCode: this.data?.shipmentMethodCode || this.getDefaultShipmentMethod('value'),
      shipmentMethodDescription: this.data?.shipmentMethodDescription || this.getDefaultShipmentMethod('description'),
      estimatedDeliveryDate: this.data?.estimatedDeliveryDate && new Date(this.data.estimatedDeliveryDate) || null,
      warehouseOrderLN: this.data?.warehouseOrderLN || null,
      addressType: this.data?.addressType || 'technician',
      phoneNumber: this.data?.phoneNumber || '',
      notes: this.data?.notes || '',
      salesOrderId: this.data?.salesOrderId || '',
      ...address
    }
  }

  getAddress() {
    if (this.data.addressType === 'onSite') {
      return {
        addressCode: this.data?.addressCode || this.onsiteAddress?.addressCode || '',
        addressName: this.data?.addressName || this.onsiteAddress?.name || this.onsiteAddress?.addressName || '',
        address: this.data?.address || this.onsiteAddress?.address || '',
        city: this.data?.city || this.onsiteAddress?.city || '',
        state: this.data?.state || this.onsiteAddress?.state || '',
        zipCode: this.data?.zipCode || this.onsiteAddress?.zipCode || '',
        country: this.data?.country || this.onsiteAddress?.country || ''
      }
    } else {
      return {
        addressCode: this.data?.addressCode || this.technicianAddresses[0]?.addressCode || '',
        addressName: this.data?.addressName || this.technicianAddresses[0]?.name || this.technicianAddresses[0]?.addressName || '',
        address: this.data?.address || this.technicianAddresses[0]?.address || '',
        city: this.data?.city || this.technicianAddresses[0]?.city || '',
        state: this.data?.state || this.technicianAddresses[0]?.stateProvince || '',
        zipCode: this.data?.zipCode || this.technicianAddresses[0]?.zipCode || '',
        country: this.data?.country || this.technicianAddresses[0]?.country || ''
      }
    }
  }

  getTechnicianAddress(key) {
    if (this.id) {
      var address = this.technicianAddresses?.find(x => x.addressCode === this.data.addressCode);
    } else {
      var address = this.technicianAddresses[0];
    }
    // const address = this.technicianAddresses[0];
    return address[key];
  }

  getOnsiteAddress(key) {
    return this.onsiteAddress[key];
  }

  getDefaultShipmentMethod(key) {
    if (this.isKingslynn) {
      const shipmentMethod = this.shipmentMethods.find(method => method.text === 'UPS WORLD EXP 1-2 DAY 1030AM') || this.shipmentMethods[0];
      return shipmentMethod[key];
    } else {
      return null;
    }
  }

  setItemsTabData() {
    this.itemsData = this.data?.itemList?.length && this.data?.itemList || this.itemsData || [];
  }

  setAttachmentsTabData() {
    this.attachmentsData = this.data?.attachments?.length && this.data?.attachments || [];
  }

  loadAPIs() {
    this.showLoader = true;
    const warehouse = this.data.partsOrderId ? this.data.warehouse : this.serviceOrderTechnician?.warehouse || this.USER.warehouse;
    const apiList = [
      this.commonService.getShippingMethodsForDDL(this.USER.company),
      this.commonService.getTechnicianAddresses(warehouse),
      this.serviceOrderService.getPriorities(this.USER.company)
    ];
    if (this.id) {
      apiList.push(this.commonService.getLinkedRecords(LinksEnum.partsOrder, this.id));
    }
    if (this.serviceOrderId) {
      apiList.push(this.inventoryService.getOnsiteAddress(this.serviceOrderId));
    }

    forkJoin(apiList).subscribe((res: any) => {
      this.shipmentMethods = res[0].result;
      this.technicianAddresses = res[1].result ? res[1].result.map(address => {
        if (address) {
          address['addressName'] = address?.name || '';
          return address;
        }
      }) : [];
      this.priorities = res[2]?.result;
      this.links = this.id ? res[3]?.result : [];
      this.onsiteAddress = this.id && this.serviceOrderId ? res[4]?.result : this.serviceOrderId && !this.id ? res[3]?.result : '';
      this.showLoader = false;
      this.setDetailsTabData();
      this.setData();
      this.loadedData = JSON.stringify(this.data);
    }, (error: HttpErrorResponse) => {
      this.showLoader = false;
      throw error;
    });

  }

  expandCollapse(value: boolean) {
    this.isExpanded = value;
  }

  setData(placeOrder?: boolean) {
    const status = !placeOrder ? this.data.status : partsOrderStatuses.submitted;
    const orderSubmissionDate = this.data.orderSubmissionDate ? this.data.orderSubmissionDate : placeOrder ? new Date() : null;
    const serviceOrderId = this.data.serviceOrderId || '';
    const originId = this.originId || null;
    const origin = this.originModule || null;

    this.data = {
      ...this.data,
      ...this.details?.getData() || this.detailsData,
      ...this.items?.getData(),
      status, serviceOrderId, orderSubmissionDate, originId, origin
    };
  }

  onCommentUpdate(data: any) {
    this.commentCount = data.filter(comment => !comment.isDeleted).length;
    this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
    this.data.attachments = data;
    this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  detailsTabUpdated(args) {
    this.data = {
      ...this.data,
      ...args
    }
  }

  isChanged() {
    return this.loadedData !== JSON.stringify(this.data);
  }

  validate() {
    this.validateDetails = this.details.validate();
    this.validateItems = this.items.validate()
    return this.validateDetails && this.validateItems;
  }

  save(close?: boolean, placeOrder?: boolean) {
    this.validationApplied = true;
    if (this.validate()) {
      this.commonService.showConfirmation('Empty row(s) will not be saved ', 'Okay', 'Cancel').then(result => {
        if (result.isConfirmed) {
          this.setData(placeOrder);
          this.inventoryService.popupLoader.next(true);
          if (this.data.itemList && this.data.itemList.length) {
            this.inventoryService.savePartsOrder(this.data)
              .subscribe((res: ApiResponse) => {
                if (res.isSuccessful) {
                  if (placeOrder) {
                    this.commonService.showNotification('success', 'Parts Order Placed!');
                  } else {
                    if (this.data.partsOrderId) {
                      this.commonService.showNotification('success', 'Parts Order Updated!');
                    } else {
                      this.commonService.showNotification('success', 'Parts Order saved as draft!');
                    }
                  }
                  res.result.createdDate = new Date(res.result.createdDate);
                  this.data = res.result;
                  this.setAttachmentsTabData();
                  this.itemsData = res.result.itemList;
                  this.id = res.result.partsOrderId;
                  this.loadedData = JSON.stringify(this.data);
                  this.onSave.emit(res.result);
                  if (close) {
                    this.close(true);
                  }
                  this.inventoryService.popupLoader.next(false);
                }
              }, (error: HttpErrorResponse) => {
                this.inventoryService.popupLoader.next(false);
                throw error;
              });
          } else {
            this.commonService.showNotification('error', 'Atleast one item needed in an Order!');
            this.inventoryService.popupLoader.next(false);
          }
        }
        else {
          return
        }
      })
    }
  }

  onShowPartOrderPopup(args) {
    this.partsOrderData = args.Data;
    this.showLoader = false;
    this.showPartsOrderPopup = true;
  }

  close(forceClose?) {
    if (!this.isChanged() || forceClose) {
      this.onClose.emit();
    } else {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
        .then(result => {
          if (result.isConfirmed) {
            this.onClose.emit();
          }
        })
    }
  }

  reset() {
    if (this.isChanged()) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
        .then(result => {
          if (result.isConfirmed) {
            this.data = JSON.parse(this.loadedData);
            this.itemsData = this.data.itemList;
            this.setDetailsTabData();
            this.loadPage();
          }
        })
    } else {
      this.commonService.showNotification('info', 'Nothing to reset!');
    }
  }

  ngOnDestroy() {
    this.data = null;
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
