import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value) {
      if(value < 1) {
        return 'Less than a minute';
      } else if (value <= 60) {
        return (value % 60) +' Mins';
      } else  if (value > 60) {
        return Math.floor(value / 60) +' Hrs ' +(value % 60) +' Mins';
      }
    }
  }
}
