import { ElementTypes, PageElement } from '../models/popup.model';

export const GeneralSectionElements: PageElement[] = [{
    id: 1,
    label: 'Configuration',
    placeholder: 'Select Configuration',
    key: 'configuration',
    readonly: false,
    type: ElementTypes.dropdown,
    value: 'some value',
    required: true,
    row: 0,
    col: 0,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
},{
    id: 2,
    label: 'Adress',
    key: 'adress',
    placeholder: 'Customer address...',
    type: ElementTypes.text,
    rows: 2,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 3,
    label: 'Customer Email Adress',
    key: 'customerEmail',
    placeholder: 'Enter email...',
    type: ElementTypes.text,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 4,
    label: 'Service Department',
    key: 'serviceDepartment',
    placeholder: 'Select Service Department',
    type: ElementTypes.dropdown,
    dataSource: [],
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 5,
    label: 'Priority',
    key: 'priority',
    placeholder: 'Priority',
    type: ElementTypes.number,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
},{
    id: 6,
    label: 'Item',
    key: 'serialNumber',
    placeholder: 'Select Serial Number',
    type: ElementTypes.dropdown,
    dataSource: [
        { text: 'One', value: 1 },
        { text: 'Two', value: 2 }
    ],
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 7,
    label: 'Serial Number',
    key: 'serialNumber',
    placeholder: 'Select Serial Number',
    type: ElementTypes.dropdown,
    dataSource: [
        { text: 'One', value: 1 },
        { text: 'Two', value: 2 }
    ],
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 4,
    label: 'Installation Date',
    key: 'installationDate',
    placeholder: 'Select Date',
    type: ElementTypes.datetime,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 5,
    label: 'Alternate Serial Number',
    key: 'alternateSerialNumber',
    placeholder: 'Enter serial number...',
    type: ElementTypes.text,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 6,
    label: 'Warranty Terms',
    key: 'warrantyTerms',
    placeholder: '',
    value: 'P/L/T',
    type: ElementTypes.text,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 6,
    label: 'Contract Terms',
    key: 'contractTerms',
    placeholder: '',
    type: ElementTypes.text,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 7,
    label: 'Start Time',
    key: 'startTime',
    placeholder: 'Select Time',
    type: ElementTypes.datetime,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 8,
    label: 'End Time',
    key: 'endTime',
    placeholder: 'Select Time',
    type: ElementTypes.datetime,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 9,
    label: 'Priority',
    key: 'priority',
    placeholder: 'Priority',
    type: ElementTypes.number,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}, {
    id: 10,
    label: 'Call Text',
    key: 'callText',
    placeholder: 'Call text',
    type: ElementTypes.text,
    rows: 2,
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 6,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
}];
