import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderTypes } from '../../models/app.model';

@Component({
  selector: 'app-ms-dynamic-modal',
  templateUrl: './ms-dynamic-modal.component.html',
  styleUrls: ['./ms-dynamic-modal.component.scss']
})
export class MsDynamicModalComponent implements OnInit {
  
  isExpanded: boolean = false;
  showLoader: boolean = false;

  @Input() pageId: string;
  @Input() modalTitle: string = 'List';
  @Input() modalTitleLabel: string = null;
  @Input() data: any = {};

  @Output() onClose = new EventEmitter();
  columns: any;
  dataSource: any = [];
  linkedPopupData: { id: any; type: any; };
  

  constructor() { }

  ngOnChanges(){
    this.dataSource = this.data;
  }

  ngOnInit(): void {
    this.columns = this.getGridColumns();
  }

  getGridColumns(){
     return [
      { field: 'id', headerText: 'Sr. No.', type: 'string', textAlign: 'Left', width: 100, isPrimaryKey: true },
      { field: 'adjustmentOrderNumber', headerText: 'Adjustment Order ID', type: 'string', textAlign: 'Left', width: 100 },
      { field: 'technicianName', headerText: 'Technician', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, visible: true, width: 160 },
      { field: 'createdDate', headerText: 'Created Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 160 },
      { field: 'createdBy', headerText: 'Created By', type: 'string', textAlign: 'Left', width: 160}
    ];
  }

  close(){
    this.onClose.emit();
  }

  openLinkedPopup(data) {
    this.linkedPopupData = {
        id: data.adjustmentOrderNumber,
        type: OrderTypes.adjustmentorder
    };
  }
}
