import { ElementTypes, PageSection, PageElement } from '../models/popup.model';
import { GeneralSectionElements } from './general-section';
import { EquipmentInformationSectionElements } from './equipment-info-section';
import { NotesCommentsSectionElements } from './notes-comments-section';

export const TechnicianDropdown: PageElement = {
    id: 1,
    label: 'Technician',
    key: 'techician',
    placeholder: 'Select Technician',
    required: true,
    type: ElementTypes.dropdown,
    dataSource: [],
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
};

export const StatusDropdown: PageElement = {
    id: 1,
    label: 'Status',
    key: 'status',
    placeholder: 'Select Status',
    required: true,
    type: ElementTypes.dropdown,
    dataSource: [],
    readonly: false,
    row: 0,
    col: 3,
    sizeX: 3,
    sizeY: 1,
    minSizeX: 3,
    minSizeY: 1
};

export const ServiceOrderPopupSections: PageSection[] = [{
        id: 1,
        name: 'General',
        icon: 'fa-tasks',
        type: 'section',
        isActive: true,
        structure: GeneralSectionElements
    }, {
        id: 2,
        name: 'Equipment Information',
        icon: 'fa-user',
        type: 'section',
        isActive: false,
        structure: EquipmentInformationSectionElements
    }, {
        id: 3,
        name: 'Notes/Comments',
        icon: 'fa-user',
        type: 'section',
        isActive: false,
        structure: NotesCommentsSectionElements
    }
];