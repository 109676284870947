import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pageTypes } from '../../models/page-config.model';
import { AppPage, PageElement, PageSection, Tab } from '../../models/page.model';
import { PageService } from '../../services/page.service';

@Component({
    selector: 'app-mobile-service-popup',
    templateUrl: './mobile-service-popup.component.html',
    styleUrls: [
        './mobile-service-popup.component.scss',
        '../../../../assets/scss/service-order.scss',
        '../../../../assets/scss/popup.scss'
    ]
})
export class MobileServicePopupComponent implements OnInit {
    pageStructure: any = [];
    pageSections: any = [];
    sidebarCollapsed: boolean = false;
    pageTypes = pageTypes;
    
    @Input()
    popupId: string = '606d80c392636c92b39e2a4e';

    page: AppPage = {
        _id: '',
        pageName: 'Untitled',
        pageType: pageTypes[0].id,
        elements: null,
        isPopup: false
    };
    selectedSection: PageSection;
    selectedItem: any;

    @Output()
    onClose = new EventEmitter();

    constructor(private pageService: PageService) { }

    ngOnInit(): void {
        this.getPage(this.popupId);
    }    

    getPage(id: string) {
        this.pageService.getPageById(id)
        .subscribe( (res: any) => {
            this.page = res;
            this.pageSections = this.page.elements;
            this.selectedSection = this.pageSections[0];
            this.pageStructure = this.selectedSection.tabs && this.selectedSection.tabs.length ? this.selectedSection.tabs[0].structure.slice() : [];
        }, (error: HttpErrorResponse) => {

        });
    }
    
    selectSection(id: number) {
        if ( this.pageSections.length === 1 || this.selectedSection.id !== id ) {
            let currentSection;
            this.pageSections.map( section => {
                if ( section.isActive ) {
                    section.isActive = false;
                }
                if ( section.id === id ) {
                    currentSection = section;
                    this.selectedSection = section;
                    section.isActive = true;
                    const selectedTab = section.tabs.find(tab => tab.isActive);
                    this.pageStructure = JSON.parse(JSON.stringify(selectedTab.structure));
                }
            });
            this.selectedItem = currentSection;
        }
    }

    selectTab(section: PageSection, tab: Tab) {
        if ( section.tabs.length ) {
            section.tabs.map( tab => {
                if ( tab.isActive ) {
                    tab.isActive = false;
                    tab.structure = this.pageStructure.slice();
                }
            });
            tab.isActive = true;
            this.pageStructure = tab.structure.slice();
        }
        this.selectedItem = tab;
    }

    close() {
        this.onClose.emit();
    }


}
