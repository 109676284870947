<app-loader *ngIf="showLoader"></app-loader>
    <ejs-grid
        #cycleCountOpenExceptionGrid
        id="{{gridName}}"
        [dataSource]="openExceptionList"
        [selectionSettings]='selectionOptions'
        [query]="query"
        [height]="gridHeight"
        [pageSettings]="pageSettings"
        [gridLines]="false"
        [allowPaging]="false"
        [enablePersistence]="false"
        [allowFiltering]="true"
        [allowSorting]="true"
        [allowResizing]="true"
        [allowReordering]="true"
        [showColumnChooser]="true"
        [allowExcelExport]="true"
        [filterSettings]="{ type: 'Menu' }"
        [editSettings]="{ allowEditing: false, allowAdding: false, allowDeleting: false , newRowPosition: 'Top' }"
        [toolbar]="grid?.toolbar"
        (created)="onGridCreated()"
        (commandClick)="onCommandClick($event)"
        (rowSelected)="rowSelected()"
        (rowDeselected)="rowDeselected()"
        (toolbarClick)="onToolbarClick($event, cycleCountOpenExceptionGrid)"
        (actionBegin)="onGridActionBegin($event)"
        (actionComplete)="onGridActionComplete($event, cycleCountOpenExceptionGrid)">
        <e-columns>
            <e-column
                *ngFor="let column of mainGrid?.columns;"
                [rowHeight]="30"
                [field]="column.field"
                [headerText]="column.headerText"
                [clipMode]="'EllipsisWithTooltip'"
                [type]="column.type"
                [width]="column.width"
                [isPrimaryKey]="column.isPrimaryKey || false"
                [validationRules]="column?.validationRules || null"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null"
                [textAlign]="column.textAlign"
                [editType]="column.editType"
                [showInColumnChooser]="column.showInColumnChooser ?? true"
                [visible]="column.visible"
                [commands]="column?.commands ? column.commands : null"
                [allowEditing]="column.allowEditing"
                [allowReordering]="column.allowReordering ?? true"
                [freeze]="column.freeze ? column.freeze: null"
                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">

                <ng-template *ngIf="column.field === 'shipmentOrderId'" #template let-data>
                    <a href="javascript:" (click)="openShipmentOrderPopup(data.shipmentOrderId)">{{data.shipmentOrderId}}</a>
                </ng-template>

                <ng-template *ngIf="column.field === 'status'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.status)}}">
                        {{data.status}}
                    </span>
                </ng-template>

                <ng-template *ngIf="column.field === 'exceptionStatus'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.exceptionStatus)}}">
                        {{data.exceptionStatus}}
                    </span>
                </ng-template>

                <ng-template *ngIf="column.field === 'reasonDescription'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.reasonDescription)}}">
                        {{data.reasonDescription}}
                    </span>
                </ng-template>

                <ng-template *ngIf="column.field === 'checkbox'" #template let-data>
                    <span (click)="countSelectedRow()">a
                    </span>
                </ng-template>

            </e-column>
        </e-columns>
    </ejs-grid>

<ng-template #columnChooser>
    <app-column-chooser
        [columns]="mainGrid?.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-ms-dynamic-modal
    *ngIf="showAdjustmentOrderListPopup"
    pageId="PG0000161"
    modalTitle="Adjustment Orders"
    modalTitleLabel="The Following adjustment order(s) were created"
    [data]="adjustmentOrdersList"
    (onClose)="adjustmentOrderListPopupClosed($event)"
></app-ms-dynamic-modal>

<app-linked-popup-opener
    *ngIf="linkedPopupData"
    [data]="linkedPopupData"
    (onPopupClose)="linkedPopupData = null;"
></app-linked-popup-opener>
