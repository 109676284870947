import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeEventArgs, NumericBlurEventArgs, NumericFocusEventArgs, NumericTextBox } from '@syncfusion/ej2-angular-inputs';

@Component({
    selector: 'sf-numerictextbox',
    templateUrl: './sf-numeric-textbox.component.html',
    styleUrls: ['./sf-numeric-textbox.component.scss']
})

export class SfNumericTextboxComponent implements OnInit{
    
    constructor() {}
    @Input() properties: NumericTextBox;
    // @Input() cssClass: string;
    // @Input() currency: string;
    // @Input() decimals: number;
    // @Input() enablePersistence: boolean;
    // @Input() enabled: boolean;
    // @Input() format: string;
    // @Input() htmlAttributes: Object;
    // @Input() max: number;
    // @Input() min: number;
    // @Input() placeholder: string;
    // @Input() readonly: boolean;
    // @Input() showClearButton: boolean;
    // @Input() showSpinButton: boolean;
    // @Input() validateDecimalOnType: boolean;
    // @Input() value: number;

    @Output() blur: EventEmitter<NumericBlurEventArgs> = new EventEmitter();
    @Output() change: EventEmitter<ChangeEventArgs> = new EventEmitter<ChangeEventArgs>();
    @Output() created: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() destroyed: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() focus: EventEmitter<NumericFocusEventArgs> = new EventEmitter<NumericFocusEventArgs>();
    ngOnInit() {

    }
    onBlur(args) { this.blur.emit(args); }
    onChange(args) { this.change.emit(args); }
    onCreated(args) { this.created.emit(args); }
    onDestroyed(args) { this.destroyed.emit(args); }
    onFocus(args) { this.focus.emit(args); }

    decrement(step?: number) {}
    destroy() {}
    focusIn() {}
    focusOut() {}
    getPersistData() {}
    getText() {}
    increment(step?: number) {}
}