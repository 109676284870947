
<div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="field mb-2">
            <label>Shipment Order LN: </label>
            <p class="mt-2">{{ details.shipmentOrderLN }}</p>
          </div>
        </div>
        
        <div class="col">
          <div class="field mb-2">
            <label>Tracking ID: </label>
            <ejs-textbox
                placeholder="Tracking ID"
                [value]="details?.trackingId"
                [enabled]="false"
            ></ejs-textbox>
          </div>
        </div>

        <div class="col">
          <ejs-checkbox #deliveryAck 
            label="Delivery Acknowledged" 
            [disabled]="!['Shipped'].includes(status) || deliveryAcknowBtnDisabled"
            [checked]="['Delivery Acknowledged', 'Parts Received', 'Exceptions', 'Closed'].includes(status)" 
            (change)="changeStatus($event)"
          ></ejs-checkbox>
        </div>
        <div class="col"></div>

        <div class="w-100"></div>

        <div class="col">
          <div class="field mb-2">
            <label>Shipment method: </label>
            <ejs-textbox
                placeholder="Shipment Order"
                [value]="details?.shipmentMethodDescription"
                [enabled]="false"
            ></ejs-textbox>
          </div>
        </div>

        <div class="col">
          <div class="field mb-2">
            <label>Estimated delivery:</label>
            <ejs-datepicker
                [min]=""
                [placeholder]="'Estimated Date'"
                [value]="details?.estimatedDeliveryDate"
                [readonly]="true"
            ></ejs-datepicker>
          </div>
        </div>

        <div class="col"></div>
        <div class="col"></div>

      </div>
    </div>
</div>

<ejs-grid #shipmentOrderItemsGrid
    id="itemsGrid"
    [dataSource]="items"
    [height]="gridHeight"
    [width]="gridWidth"
    [allowPaging]="false"
    [allowFiltering]="true"
    [allowSorting]="true"
    [allowResizing]="true"
    [allowReordering]="true"
    [showColumnChooser]="true"
    [enablePersistence]="false"
    [allowExcelExport]="true"
    [query]="itemsQuery"
    [editSettings]="{ allowEditing: false, allowAdding: false, allowDeleting: false }"
    [filterSettings]="{ type: 'Menu' }"
    (toolbarClick)="toolbarClick($event)"
    (created)="onGridCreated()"
>
    <e-columns>
        <e-column 
            *ngFor="let column of columns;" 
            [field]="column.field" 
            [headerText]="column.headerText"
            [clipMode]="'EllipsisWithTooltip'"
            [type]="column.type"
            [edit]="column.edit"
            [width]="column.width"
            [filter]="column.filter ? column.filter : { type: 'Menu' }"
            [format]="column.format ? column.format : null"
            [textAlign]="column.textAlign" 
            [visible]="column.visible"
            [isPrimaryKey]="column.isPrimaryKey || false"
            [validationRules]="column?.validationRules || null"
            [commands]="column?.commands ? column.commands : null"
            [allowEditing]="column.allowEditing || false" 
            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
            <ng-template *ngIf="column.field === 'partsOrderRef'" #template let-data>
                <a href="javascript:" (click)="onPartOrderClick(data)">{{data.partsOrderRef}}</a>
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>

<ng-template #shipmentOrderItemsGridColumnChooser>
  <app-column-chooser
      [grid]="shipmentOrderItemsGrid"
      [columns]="columns"
      [showColumnChooser]="showColumnChooser"
      (onClose)="showColumnChooser = false"
  ></app-column-chooser>
</ng-template>


<app-parts-orders-popup 
    *ngIf="showPopup"
    [id]="orderId"
    [tab]="'details'"
    [data]="partOrderData"
    [showComments]='false'
    popupSource="LinkedOrders"
    (onClose)="onClosePartsPopup()"
    (onSave)="partsOrderSaved($event)"
    (onOrderPlaced)="partsOrderSaved($event)"
></app-parts-orders-popup>