<div 
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="ms-dynamic-modal" 
    tabindex="-1" 
    aria-labelledby="ms-dynamic-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-popup-header *ngIf="page"
                [id]="orderId ? orderId : this.page.pageName"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="serviceOrderData ? orderId + ' - '+ serviceOrderData.customerDescription : orderId ? orderId : this.page.pageName ? this.page.pageName : null"
                (onClose)="close()" 
                (onExpandCollapse)="expandCollapse($event)"
            ></app-popup-header>
            <div class="modal-body pt-0">
                <app-loader *ngIf="showLoader"></app-loader>
                <div class="row header-section" *ngIf="page">
                    <div class="col-md-6">
                        <div class="row header-left-section">
                            <div class="col-4 resource-wrapper">
                                <div class="row no-border">
                                    <label class="control-label col-md-auto mt-2">{{ page.elements.parentElements.resource.elementLabel }}:<span class="required-asterik" *ngIf="page.elements.parentElements.resource.required">*</span></label>
                                    <div class="col no-padding mt-1">
                                        <ejs-dropdownlist
                                            id="{{page.elements.parentElements.resource.elementKey}}"
                                            [ngClass]="{'readonly-dropdown': page.elements.parentElements.resource.readonly}"
                                            [readonly]="page.elements.parentElements.resource.readonly"
                                            [dataSource]="page.elements.parentElements.resource.dataSource"
                                            [fields]="page.elements.parentElements.resource.fields"
                                            [showClearButton]="page.elements.parentElements.resource.showClearButton"
                                            [fields]="page.elements.parentElements.resource.fields"
                                            [allowFiltering]="page.elements.parentElements.resource.allowFiltering"
                                            [placeholder]="page.elements.parentElements.resource.placeholder"
                                            [filterBarPlaceholder]="page.elements.parentElements.resource.filterBarPlaceholder"
                                            [sortOrder]="page.elements.parentElements.resource.sortOrder"
                                            [readonly]="page.elements.parentElements.resource.readonly"
                                            [enabled]="canEdit && page.elements.parentElements.resource.enabled"
                                            [popupWidth]="page.elements.parentElements.resource.popupWidth"
                                            [popupHeight]="page.elements.parentElements.resource.popupHeight"
                                            [value]="page.elements.parentElements.resource.value"
                                        ></ejs-dropdownlist>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 resource-wrapper" *ngIf="module !== 'Calibration'">
                                <div class="row no-border">
                                    <label class="control-label col-md-auto mt-2">Status:</label>
                                    <div class="col no-padding mt-1">
                                        <ejs-textbox
                                            [readonly]="true"
                                            [value]="data.status"
                                        ></ejs-textbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto pt-2 ml-5" *ngIf="module !== 'Calibration'">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': data && data.comments && data.comments.length}"></i> <span>{{data && data.comments && data.comments.length}}</span> Comment<span *ngIf="data && data.comments && data.comments.length>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [moduleId]="module === 'AdjustmentOrder' ? data.adjustmentOrderNumber : data.id"
                                            [module]="module"
                                            [comments]="data.comments || []"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs" *ngIf="page && page.elements && page.elements.tabs && page.elements.tabs.length">
                                    <li class="nav-item" *ngFor="let tab of page.elements.tabs; let index = index">
                                        <a *ngIf="module !== 'Calibration' || module === 'Calibration' && tab.tabType !== tabTypes.attachments && tab.tabType !== tabTypes.history"
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab.isActive}"
                                            [ngStyle]="{'font-size': tab.fontSize}"
                                            data-toggle="tab"
                                            href="#tab-{{tab.tabId}}"
                                            (click)="onTabClick(index, $event)"
                                            [title]="tab.tabType === tabTypes.attachments ? 'Attachments' : tab.tabType === tabTypes.history ? 'History' : tab.tabTitle"
                                        ><i *ngIf="tab.tabIcon" class="fas {{ tab.tabIcon }}" [ngClass]="{'color-green': tab.tabKey === 'attachments' && data && data.attachments && data.attachments.length}"></i> {{ tab.tabTitle }} <span *ngIf="tab.isValid === false"><i class="fas fa-exclamation-circle validation-exclamation"></i></span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="page">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div 
                                *ngFor="let tab of page.elements.tabs; let index = index;" 
                                class="tab-pane pt-3 pb-3" 
                                [ngClass]="{'active show': tab.isActive}"
                                id="tab-{{tab.tabId}}"
                            >
                                <div class="row" *ngIf="tab.tabType === tabTypes.custom">
                                    <ng-container
                                        *ngFor="let section of tab.sections; let sectionIndex = index;"
                                    >
                                        <div 
                                            *ngIf="showSection(section)"
                                            class="calibration-section {{section.cssClass}}"
                                            [ngClass]="{'col-12': section.isFull, 'col-6': !section.isFull}"
                                        >
                                            <h2 class="calibration-section-heading" [ngStyle]="{'font-size': section.fontSize}" *ngIf="section.sectionTitle">{{ section.sectionTitle }}</h2>
                                            <app-dynamic-popup-layout
                                                #pageLayout
                                                [elements]="section && section.elements && section.elements.length ? section.elements : []"
                                                [layoutId]="sectionIndex + 1"
                                                [isEditable]="canEdit"
                                                [rules]="page.rules"
                                                [fields]="fields"
                                                [allElements]="elements"
                                                [tabId]="tab.tabId"
                                                [module] = "module"
                                                [serviceOrderData]="serviceOrderData"
                                                [data]="data"
                                                (onFormStatusChange)="onFormStatusChange(section, tab, $event)"
                                            ></app-dynamic-popup-layout>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row" *ngIf="tab.tabType === tabTypes.attachments">
                                    <div class="col">
                                        <app-attachments-tab
                                            #attachmentsTab
                                            [moduleId]="data.id"
                                            [module]="module"
                                            [canEdit]="canEdit"
                                            [fieldRules]="null"
                                            [attachments]="data.attachments"
                                            (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                        ></app-attachments-tab>
                                    </div>
                                </div>
                                <div class="row" *ngIf="tab.tabType === tabTypes.history">
                                    <div class="col">
                                        <app-history-tab-shared
                                            [history]="data.history"
                                        ></app-history-tab-shared>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="modal-footer" *ngIf="page">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" id="" *ngIf="module === 'AdjustmentOrder'" (click)="reset()" [disabled]="!canEdit">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1 reset" *ngIf="module === 'AdjustmentOrder'" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1 reset" *ngIf="module === 'AdjustmentOrder'" (click)="save(true)">Save &amp; Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" *ngIf="module === 'AdjustmentOrder'" (click)="save(true, true)" [disabled]="!canEdit">Place Order</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" *ngIf="module === 'Calibration'" (click)="generateCertificate()" [disabled]="!canEdit || !isLastTabActive">Generate Certificate</button>
            </div>
        </div>
    </div>
</div>