import { IconList } from "./builder.model";

export enum Positions { 
    top = 'top', 
    bottom = 'bottom',
    center = 'center',
    left = 'left',
    right = 'right'
}

export enum FieldTypes {
    label = 'label', 
    textbox = 'textbox', 
    numerictextbox = 'numerictextbox',
    checkbox = 'checkbox',
    radiobutton = 'radiobutton',
    colorpicker = 'colorpicker',
    dropdownlist = 'dropdownlist',
    multiselect = 'multiselect',
    datepicker = 'datepicker',
    datetimepicker = 'datetimepicker',
    icondropdown = 'icondropdown'
}

export const ElementGeneralProperties = [
    { key: 'elementId', label: 'Field Id:', placeholder: 'Enter field id...', type: FieldTypes.textbox },
    { key: 'elementLabel', label: 'Field Label:', placeholder: 'Enter field label...', type: FieldTypes.textbox, default: null },
    { key: 'elementKey', label: 'Field Key:', placeholder: 'Enter field key...', type: FieldTypes.textbox, default: null },
    { key: 'showLabel', label: 'Show Label?', type: FieldTypes.checkbox, default: true },
    { key: 'placeholder', label: 'Placeholder:', placeholder: 'Enter placeholder...', type: FieldTypes.textbox, default: null },
    { key: 'readonly', label: 'Readonly?', type: FieldTypes.checkbox, default: false },
    { key: 'required', label: 'Required?', type: FieldTypes.checkbox, default: false },
    { key: 'visible', label: 'Visible?', type: FieldTypes.checkbox, default: true },
    { key: 'cssClass', label: 'Css Class:', type: FieldTypes.textbox, default: null },
    { key: 'fontSize', label: 'Font Size:', type: FieldTypes.textbox, placeholder: 'Enter font size... i.e 15px, 10em', default: null },
    { key: 'width', label: 'Width:', type: FieldTypes.textbox, default: null }
]

export const LayoutSettings = [
    { key: 'row', label: 'Row Number:', type: FieldTypes.numerictextbox, default: null },
    { key: 'col', label: 'Col Number:', type: FieldTypes.numerictextbox, default: null },
    { key: 'sizeX', label: 'Size X:', type: FieldTypes.numerictextbox, default: null, min: 1, max: 12 },
    { key: 'sizeY', label: 'Size Y:', type: FieldTypes.numerictextbox, default: null },
    { key: 'minSizeX', label: 'Min Size X:', type: FieldTypes.numerictextbox, default: null, min: 1, max: 12 },
    { key: 'minSizeY', label: 'Min Size Y:', type: FieldTypes.numerictextbox, default: null },
    { key: 'maxSizeX', label: 'Max Size X:', type: FieldTypes.numerictextbox, default: null, min: 1, max: 12 },
    { key: 'maxSizeY', label: 'Max Size Y:', type: FieldTypes.numerictextbox, default: null }
];

export const LabelProperties = [
    { key: 'elementId', label: 'Field Id:', placeholder: 'Enter field id...', type: FieldTypes.textbox },
    { key: 'elementLabel', label: 'Label text:', type: FieldTypes.textbox, placeholder: 'Enter label...', default: 'Label:' },
    { key: 'elementKey', label: 'Field Key:', placeholder: 'Enter field key...', type: FieldTypes.textbox, default: null },
    { key: 'fontSize', label: 'Font Size:', type: FieldTypes.textbox, placeholder: 'Enter font size... i.e 15px, 10em', default: null },
    { key: 'position', label: 'Label Position:', type: FieldTypes.dropdownlist, default: Positions.top, dataSource: [{ text: 'Top', value: Positions.top },{ text: 'Center', value: Positions.center }, { text: 'Bottom', value: Positions.bottom }] },
    { key: 'cssClass', label: 'Css Class:', type: FieldTypes.textbox, default: null },
    { key: 'visible', label: 'Visible?', type: FieldTypes.checkbox, default: true },
    ...LayoutSettings
];

export const TabProperties = [
    { key: 'tabId', label: 'Tab Id:', type: FieldTypes.numerictextbox, placeholder: 'Enter tab id...', readonly: true, format: '#', default: null, showSpinButton: false },
    { key: 'tabKey', label: 'Tab Key:', type: FieldTypes.textbox, placeholder: 'Enter tab key... must be unique', default: '' },
    { key: 'tabTitle', label: 'Tab Title:', type: FieldTypes.textbox, placeholder: 'Enter tab title...', default: 'Untitled' },
    { key: 'tabIcon', label: 'Tab Icon:', type: FieldTypes.icondropdown,  dataSource: IconList, fields: { text: 'iconCode', value: 'iconCode' }, default: 'fa-list-ul' },
    { key: 'fontSize', label: 'Font Size:', type: FieldTypes.textbox, placeholder: 'Enter font size... i.e 15px, 10em', default: null },
    { key: 'cssClass', label: 'Css Class:', type: FieldTypes.textbox, default: null },
    { key: 'tooltip', label: 'Tooltip:', type: FieldTypes.textbox, default: 'Tab', placeholder: 'Enter tooltip text...', readonly: true }
];

export const SectionProperties = [
    { key: 'sectionId', label: 'Section Id:', type: FieldTypes.numerictextbox, placeholder: 'Enter section id...', readonly: true, format: '#', default: null, showSpinButton: false },
    { key: 'sectionKey', label: 'Section Key:', type: FieldTypes.textbox, placeholder: 'Enter section key... must be unique', default: '' },
    { key: 'sectionTitle', label: 'Section Title:', type: FieldTypes.textbox, placeholder: 'Enter section title...', default: 'Section' },
    { key: 'fontSize', label: 'Font Size:', type: FieldTypes.textbox, placeholder: 'Enter font size... i.e 15px, 10em', default: null },
    { key: 'cssClass', label: 'Css Class:', type: FieldTypes.textbox, default: null },
    { key: 'isFull', label: 'Is full width?', type: FieldTypes.checkbox, default: true }
];

export const TextboxProperties =  [
    ...ElementGeneralProperties,
    { key: 'min', label: 'Min:', type: FieldTypes.numerictextbox, min: 0, default: 1 },
    { key: 'max', label: 'Max:', type: FieldTypes.numerictextbox, min: 2, default: null },
    { key: 'enabled', label: 'Enabled?', type: FieldTypes.checkbox, default: true },
    { key: 'multiline', label: 'Multi-line?', type: FieldTypes.checkbox, default: false },
    { key: 'rows', label: 'Multiline Rows:', type: FieldTypes.numerictextbox, default: 4, min: 2, max: 100 },
    { key: 'showClearButton', label: 'Show clear button?', type: FieldTypes.checkbox, default: false },
    ...LayoutSettings
];

export const NumericTextboxProperties = [
    ...ElementGeneralProperties,
    { key: 'min', label: 'Min:', type: 'numerictextbox', default: null, format: '###.###' },
    { key: 'max', label: 'Max:', type: 'numerictextbox', default: null, format: '###.###' },
    { key: 'step', label: 'Step:', type: 'numerictextbox', default: 1, format: '###.###'},
    { key: 'enabled', label: 'Enabled:', type: FieldTypes.checkbox, default: true },
    { key: 'showClearButton', label: 'Show clear button:', type: FieldTypes.checkbox, default: false },
    { key: 'showSpinButton', label: 'Show arrows:', type: FieldTypes.checkbox, default: true },
    { key: 'decimals', label: 'Decimals:', type: FieldTypes.textbox, default: null },
    { key: 'format', label: 'Format:', type: FieldTypes.textbox, default: null },
    ...LayoutSettings
];

export const DropdownListProperties = [
    ...ElementGeneralProperties,
    { key: 'enabled', label: 'Enabled:', type: FieldTypes.checkbox, default: true },
    { key: 'allowFiltering', label: 'Allow Filtering?', type: FieldTypes.checkbox, default: false },
    { key: 'filterBarPlaceholder', label: 'Filter bar placeholder:', type: FieldTypes.textbox, default: null },
    { key: 'showClearButton', label: 'Show clear button:', type: FieldTypes.checkbox, default: false },
    { key: 'fieldText', label: 'Field Text:', type: FieldTypes.textbox, default: null },
    { key: 'fieldValue', label: 'Field Value:', type: FieldTypes.textbox, default: null },
    { key: 'popupHeight', label: 'Popup Height:', type: FieldTypes.textbox, default: null },
    { key: 'popupWidth', label: 'Popup Width:', type: FieldTypes.textbox, default: null },
    { key: 'sortOrder', label: 'Sort Order:', type: FieldTypes.dropdownlist, default: 'None', dataSource: ['None', 'Ascending', 'Descending'] },
    { key: 'datasource', label: 'Datasource:', type: FieldTypes.textbox, readonly: true, default: null },
    ...LayoutSettings,
    { key: 'data', label: 'List Data:', type: FieldTypes.dropdownlist, default: null, placeholder: 'Select Data Source', dataSource: ['Local', 'Api'] }
];

export const MultiSelectProperties = [
    ...ElementGeneralProperties,
    { key: 'enabled', label: 'Enabled?', type: FieldTypes.checkbox, default: true },
    { key: 'allowFiltering', label: 'Allow Filtering?', type: FieldTypes.checkbox, default: false },
    { key: 'allowCustomValue', label: 'Allow Custom Value?', type: FieldTypes.checkbox, default: false },
    { key: 'closePopupOnSelect', label: 'Close popup on selection?', type: FieldTypes.checkbox, default: false },
    { key: 'maximumSelectionLength', label: 'Max Selection Length:', type: FieldTypes.numerictextbox, default: 1000 },
    { key: 'filterBarPlaceholder', label: 'Filter bar placeholder:', type: FieldTypes.textbox, default: null },
    { key: 'openOnClick', label: 'Open on click?', type: FieldTypes.checkbox, default: true },
    { key: 'showDropdownIcon', label: 'Show dropdown icon?', type: FieldTypes.checkbox, default: true },
    { key: 'showSelectAll', label: 'Allow select all?', type: FieldTypes.checkbox, default: true },
    { key: 'showClearButton', label: 'Show clear button?', type: FieldTypes.checkbox, default: false },
    { key: 'fieldText', label: 'Field Text:', type: FieldTypes.textbox, default: null },
    { key: 'fieldValue', label: 'Field Value:', type: FieldTypes.textbox, default: null },
    { key: 'popupHeight', label: 'Popup Height:', type: FieldTypes.textbox, default: null },
    { key: 'popupWidth', label: 'Popup Width:', type: FieldTypes.textbox, default: null },
    { key: 'sortOrder', label: 'Sort Order:', type: FieldTypes.dropdownlist, default: 'None', dataSource:['None', 'Ascending', 'Descending'] },
    ...LayoutSettings
];

export const DatePickerProperties = [
    ...ElementGeneralProperties,
    { key: 'min', label: 'Min:', type: FieldTypes.numerictextbox, default: null },
    { key: 'max', label: 'Max:', type: FieldTypes.numerictextbox, default: null },
    { key: 'allowEdit', label: 'Allow Typing?', type: FieldTypes.checkbox, default: true },
    { key: 'enabled', label: 'Enabled?', type: FieldTypes.checkbox, default: true },
    { key: 'openOnFocus', label: 'Open on focus?', type: FieldTypes.checkbox, default: true },
    { key: 'showClearButton', label: 'Show clear button?', type: FieldTypes.checkbox, default: false },
    { key: 'showTodayButton', label: 'Show today button?', type: FieldTypes.checkbox, default: false },
    { key: 'start', label: 'Calendar View:', type: FieldTypes.dropdownlist, default: 'Month', dataSource:['Month', 'Year', 'Decade'] },
    { key: 'format', label: 'Date Format:', type: FieldTypes.dropdownlist, default: null, dataSource: ['yy-MMM-dd', 'dd-MMMM', 'dd, MMM yyyy'] },
    ...LayoutSettings
];

export const DateTimePickerProperties = [
    ...ElementGeneralProperties,
    { key: 'min', label: 'Min:', type: FieldTypes.numerictextbox, default: null, showSpinButton: false },
    { key: 'max', label: 'Max:', type: FieldTypes.numerictextbox, default: null, showSpinButton: false },
    { key: 'allowEdit', label: 'Allow Typing?', type: FieldTypes.checkbox, default: true },
    { key: 'enabled', label: 'Enabled?', type: FieldTypes.checkbox, default: true },
    { key: 'openOnFocus', label: 'Open on focus?', type: FieldTypes.checkbox, default: true },
    { key: 'showClearButton', label: 'Show clear button?', type: FieldTypes.checkbox, default: false },
    { key: 'showTodayButton', label: 'Show today button?', type: FieldTypes.checkbox, default: false },
    { key: 'start', label: 'Calendar View:', type: FieldTypes.dropdownlist, default: 'Month', dataSource:['Month', 'Year', 'Decade'] },
    ...LayoutSettings
]

export const CheckboxProperties = [
    ...ElementGeneralProperties,
    { key: 'label', label: 'Checkbox Label:', type: FieldTypes.textbox, default: null },
    { key: 'labelPosition', label: 'Label Position:', type: FieldTypes.dropdownlist, default: 'After', dataSource: ['After', 'Before'] },
    { key: 'disabled', label: 'Disabled:', type: FieldTypes.checkbox, default: false },
    { key: 'checked', label: 'Checked:', type: FieldTypes.checkbox, default: false },
    ...LayoutSettings
];

export const RadioProperties = [
    ...ElementGeneralProperties,
    { key: 'label', label: 'Radio 1 Label:', type: FieldTypes.textbox, default: 'First' },
    { key: 'label1', label: 'Radio 2 Label:', type: FieldTypes.textbox, default: 'Second' },
    { key: 'label2', label: 'Radio 3 Label:', type: FieldTypes.textbox, default: 'Third' },
    { key: 'labelPosition', label: 'Radio 1 Label Position:', type: FieldTypes.dropdownlist, default: 'After', dataSource: ['After', 'Before'] },
    { key: 'labelPosition1', label: 'Radio 2 Label Position:', type: FieldTypes.dropdownlist, default: 'After', dataSource: ['After', 'Before'] },
    { key: 'labelPosition2', label: 'Radio 3 Label Position:', type: FieldTypes.dropdownlist, default: 'After', dataSource: ['After', 'Before'] },
    { key: 'disabled', label: 'Disabled:', type: FieldTypes.checkbox, default: false },
    { key: 'checked', label: 'Checked:', type: FieldTypes.checkbox, default: false },
    ...LayoutSettings
]