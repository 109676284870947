import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AttachmentDeleteModel, AttachmentUpdateModel } from '../models/attachment.model';

const BASE_URL = environment.apiUrl; 

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(private http: HttpClient) { }

  delete(data: AttachmentDeleteModel) {
    return this.http.delete(`${BASE_URL}Attachment/DeleteAttachment`, {body: data});
  }

  update(data: AttachmentUpdateModel) {
    return this.http.post(`${BASE_URL}Attachment/UpdateAttachment`, data);
  }  
  
  saveAttachments(data) {
    return this.http.post(`${BASE_URL}Attachment/SaveAttachments`, data);
  }
  
}
