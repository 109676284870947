import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CalibrationData } from '../models/calibration.model';

const BASE_URL = environment.apiUrl;

@Injectable()
export class CalibrationService {

    constructor(
        private http: HttpClient
    ) { }

    getApisList() {
        return this.http.get(`${BASE_URL}Calibration/dropdownApisList`);
    }

    getCalibrationTypes(itemGroup: any = 0) {
        return this.http.post(`${BASE_URL}Calibration/CalibrationTypeDDL?itemGroup=${itemGroup}`, null);
    }
    
    getCalibrationById(id: string) {
        return this.http.get(`${BASE_URL}Calibration/GetCalibrationDetailsById?calibrationId=${id}`);
    }
    
    getCalibrationList() {
        return this.http.get(`${BASE_URL}Calibration/GetAllCalibrationDetails`);
    }

    saveCalibrationData(data: CalibrationData) {
        return this.http.post(`${BASE_URL}Calibration/AddCalibrationDetails`, data);
    }

    updateCalibrationData(data: CalibrationData) {
        return this.http.post(`${BASE_URL}Calibration/UpdateCalibrationDetails`, data);
    }

    getCalibrationRefEquipment(type) {
        return this.http.get(`${BASE_URL}Calibration/GetCalibrationRefEquipment?calibrationType=${type}`);
    }

    getCalibrationEquipmentAndDatesData(item, serialNumber, customerCode, type) {
        return this.http.get(`${BASE_URL}Calibration/GetCalibrationEquipmentAndDatesData?item=${item}&serialNumber=${serialNumber}&customerCode=${customerCode}&calibrationType=${type}`);
    }
  
}
