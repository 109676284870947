import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { GridComponent, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { getCurrentDate } from '../../utils/date-functions';
import { getExcelDataSource, gridActionBeginForFiltering, gridActionsForFiltering, updateFilterIcon } from '../../utils/grid-functions';
import { CommonService } from '../../services/common.service';
import { DataUtil } from '@syncfusion/ej2-data'; 

@Component({
  selector: 'app-history-tab-shared',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent implements OnInit, OnChanges {

  dataSource: Object[];
  gridColumns: any;
  gridToolbar: any;
  
  @Input() history = [];
  @Input() popup = [];

  showColumnChooser: boolean;  

  @ViewChild('historyTabGridColumnChooser') columnChooser;
  
  @ViewChild('historyTabGrid') set historyTabGrid(historyTabGrid: GridComponent) {
    if (historyTabGrid) {
        historyTabGrid.toolbar = [
          { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
          'Search',
          { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = historyTabGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            historyTabGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
        }
    }
  }

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {    
    this.dataSource = this.history; 
    this.dataSource = DataUtil.parse.parseJson(this.dataSource);
    this.gridColumns = this.getColumns();
  }

  ngOnChanges(change: SimpleChanges): void {    
      if ( change && change.history ) {
          this.dataSource = change.history.currentValue;
          this.dataSource = DataUtil.parse.parseJson(this.dataSource);
      }
  }
  
  onGridCreated(grid) {
    updateFilterIcon({ requestType: 'filtering' }, grid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid) {
    gridActionsForFiltering(args, grid);
    if (!grid.searchSettings['properties'].key && grid.filterSettings.columns && grid.filterSettings.columns.length && grid.filterSettings.columns.findIndex(col => col['properties'].field !== 'technicianId') === -1) {
      document.getElementById(grid.element.id).getElementsByClassName('grid-filter-icon')[0]?.classList.remove('filtered');
    } else {
      updateFilterIcon(args, grid);
    }
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if ( args.item.id === 'grid_excelexport') {
      const dataSource = getExcelDataSource(grid);
      let excelExportProperties: ExcelExportProperties = {
          dataSource,
          hierarchyExportMode: 'Expanded',
          theme: {
              header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
          },
          fileName: `${this.popup} History (${getCurrentDate()}).xlsx`
      };
      grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'grid_pdfexport') {
        grid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
        grid.clearFiltering();
        grid.search('');
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    }
  }

  getColumns() {
    return [
      { field: 'createdByName', headerText: 'Updated By', type: 'string', filter: {type: 'CheckBox'}, textAlign: 'Left', width: 70, nonHideable: true},
      { field: 'text', headerText: 'Description', type: 'string', textAlign: 'Left', filter: { operator: 'contains' } },
      { field: 'createdDate', headerText: 'Created Date', type: 'datetime', textAlign: 'Left',  format: { type: 'datetime', format: 'd MMM, y hh:mm:ss a' }, width: 120 }
    ];
  }

}

