import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { ServiceOrderService } from '../../services/service-order.service';
import { CommonService } from 'src/app/shared/services/common.service';

import { Invoice } from '../../models/payment-invoicing.model';

import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponse, Companies } from 'src/app/shared/models/app.model';
import { DomSanitizer } from '@angular/platform-browser';

const BASE_URL = environment.apiUrl;


@Component({
    selector: 'app-credit-card-popup',
    templateUrl: './credit-card-popup.component.html',
    styleUrls: ['./credit-card-popup.component.scss']
})
export class CreditCardPopupComponent implements OnInit {

    isExpanded: boolean = false;
    canGoNext: boolean = false;
    validationApplied: boolean = false;
    paymentTypes = [];
    errorMessage = '';
    successMessage = '';
    status: string = 'New';
    isLoading = false;
    countries = [];
    

    @ViewChild('paymentAttachmentsUpload')
    public uploadObj: UploaderComponent;

    @Input() data = null;
    @Input() customerData = null;
    @Input() invoice: Invoice = null;
    @Input() attachments = [];
    @Input() serviceOrderId = null;

    @Output() onCancel = new EventEmitter();
    @Output() onPay = new EventEmitter();
    @Output() onAuthorize = new EventEmitter();
    @Output() onExpandCollapse = new EventEmitter();
    USER: any;
    transactionData: {
        'serviceOrderId': any,
        'company': any,
        'amount': any,
        'orderDesc': any,
        'currencyCode': any,
        'customerEmail': any,
        'address': any,
        'address2': any,
        'city': any,
        'state': any,
        'zip': any,
        'deliveryFirstName': any,
        'deliveryLastName': any,
        'deliveryAddress': any,
        'deliveryAddress2': any,
        'deliveryEmail': any,
        'deliveryPhone': any,
        'deliveryCity': any,
        'deliveryState': any,
        'deliveryPostalCode': any,
        'billingName': any,
    }
    transactionInfo: any;
    barclaysFormURL: any;
    environment: any;

    constructor(
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService,
        private sanitizer: DomSanitizer
    ) {
        this.USER = commonService.USER;
        this.environment = environment;
    }

    ngOnInit(): void {
        this.setTransactionData();
    }

    setTransactionData(){
        this.transactionData = {
            'serviceOrderId': this.serviceOrderId,
            'company': this.USER.company,
            'amount': this.invoice.paymentAmount.toString(),
            'orderDesc': this.customerData.serviceOrderId,
            'currencyCode': this.customerData.customerCurrency,
            'customerEmail': this.customerData.email,
            'address': this.customerData.address,
            'address2': '',
            'city': this.customerData.city,
            'state': this.customerData.state,
            'zip': this.customerData.zipCode,
            'deliveryFirstName': '',
            'deliveryLastName': '',
            'deliveryAddress': this.customerData.address,
            'deliveryAddress2': '',
            'deliveryEmail': this.customerData.email,
            'deliveryPhone': this.customerData.customerPhoneNumber,
            'deliveryCity': this.customerData.city,
            'deliveryState': this.customerData.state,
            'deliveryPostalCode': this.customerData.zipCode,
            'billingName': this.customerData.customerDescription,
        }
    }

    // ngOnInit(): void {
    //     this.data['customerInfo'] = {
    //         payerName: '',
    //     };
    //     this.data['billingInfo'] = {
    //         country: '',
    //         state: '',
    //         city: '',
    //         zipCode: '',
    //         address: ''
    //     };
    //     this.data['cardInfo'] = {
    //         name: '',
    //         number: '',
    //         expiry: '',
    //         ccv: ''
    //     };
    //     this.loadCountries();

        
    // }

    // loadCountries() {
    //     this.isLoading = true;
    //     this.serviceOrderService.getCountries(this.USER.company)
    //     .subscribe( (res: ApiResponse) => {
    //         if ( res.isSuccessful ) {
    //             this.countries = res.result;
    //         }
    //         this.isLoading = false;
    //     }, (error: HttpErrorResponse) => {
    //         this.isLoading = false;
    //     })
    // }

    // expandCollapse() {
    //     this.isExpanded = !this.isExpanded;
    // }

    onClick(){
    }

    cancel() {
        this.validationApplied = false;
        this.onCancel.emit();
    }

    pay() {
        let amount = this.transactionData.amount;
        amount = amount*100;
        const num = +amount.toFixed(0);
        this.transactionData.amount = ''+num;
        this.serviceOrderService.AuthorizeCCPaymentForBarclays(this.transactionData).subscribe(
        (response: any) => {
            if(response.isSuccessful){
            this.transactionInfo = response.result;
            // const selectedValue = environment.barclaysFormURL;
            // this.barclaysFormURL = this.sanitizer.bypassSecurityTrustResourceUrl(selectedValue);
            this.isLoading = false;
            // this.showIframe = true;
            this.commonService.showNotification('success', response.message);
            } else {
            this.isLoading = false;
            this.commonService.showNotification('error', response.message);
            }
        },
        (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.commonService.showNotification('error', error.message);
        });
    //     if ( this.validate() ) {
    //         const expiryDate = new Date(this.data.cardInfo.expiry).getFullYear() + "-" + new Date(this.data.cardInfo.expiry).getMonth();
    //         const cardInfo = {
    //             createTransactionRequest: {
    //                 merchantAuthentication: {
    //                     name: '5D8AvZW8cG',   // environment.keys(paymentLoginId)
    //                     transactionKey: '652b53GVYeW4qZ3G' // environment.keys(paymentTransactionKey)
    //                 },
    //                 refId: this.data.invoiceId,
    //                 transactionRequest: {
    //                     transactionType: 'authCaptureTransaction',
    //                     amount: this.invoice.paymentAmount,
    //                     payment: {
    //                         creditCard: {
    //                             cardNumber: this.data.cardInfo.number,
    //                             expirationDate: expiryDate,
    //                             cardCode: this.data.cardInfo.ccv
    //                         }
    //                     },
    //                     order: {
    //                         invoiceNumber: this.invoice.invoiceId
    //                     },
    //                     billTo: {
    //                         firstName: this.data.cardInfo.name,
    //                         lastName: '',
    //                         company: this.data.customerDescription,
    //                         address: this.data.address,
    //                         city: this.data.city,
    //                         state: this.data.state,
    //                         zip: this.data.zipCode,
    //                         country: this.data.country
    //                     },
    //                     shipTo: {
    //                         firstName: this.data.customerDescription,
    //                         lastName: '',
    //                         company: this.data.customerDescription,
    //                         address: this.data.address,
    //                         city: this.data.city,
    //                         state: this.data.state,
    //                         zip: this.data.zipCode,
    //                         country: this.data.country
    //                     },
    //                     userFields: {
    //                         userField: [
    //                             {
    //                                 name: 'cardHolderName',
    //                                 value: this.data.cardInfo.name
    //                             },
    //                             {
    //                                 name: 'customerDescription',
    //                                 value: this.data.customerDescription
    //                             }
    //                         ]
    //                     }
    //                 }
    //             }
    //         };
    //         this.isLoading = true;
    //         this.serviceOrderService.captureCreditCardPayment(cardInfo).subscribe(
    //             (res: any) => {
    //                 this.isLoading = false;
    //                 if ( res && res.transactionResponse && res.transactionResponse.responseCode === "1" ) {
    //                     this.status = 'Paid';
    //                     this.errorMessage = '';
    //                     this.successMessage = 'Transaction Successful!';
    //                     this.invoice.checkAuthCode = res.transactionResponse['transId'];
    //                     this.commonService.showNotification('success', 'Transaction Successful!')
    //                     this.onPay.emit(this.invoice);
    //                 } else {
    //                     this.successMessage = '';
    //                     this.errorMessage = this.getErrorMessage(res.transactionResponse);
    //                 }
    //             },
    //             (error: HttpErrorResponse) => {
    //                 this.isLoading = false;
    //                 this.successMessage = '';
    //                 this.errorMessage = 'Your connection timed out, please try again!'
    //             }
    //         );
    //     }
    }

    // getErrorMessage(response: any) {
    //     if ( response && response.errors && response.errors.length ) {
    //         if ( response.cvvResultCode !== 'M' ) {
    //             switch (response.cvvResultCode) {
    //                 case 'N':
    //                     return 'CVV does not match';
    //                 case 'P':
    //                     return 'Transaction not processed, wrong CVV';
    //                 case 'S':
    //                     return 'Should be on card, but is not indicated';
    //                 case 'U':
    //                     return 'Issuer is not certified or has not provided encryption key';
    //                 default:
    //                     return response.errors[0].errorText
    //             }
    //         } else {
    //             switch (response.avsResultCode) {
    //                 case 'E':
    //                     return 'AVS data provided is invalid or AVS is not allowed for the card type that was used.';
    //                 case 'R':
    //                     return 'The AVS system was unavailable at the time of processing.';
    //                 case 'G':
    //                     return 'The card issuing bank is of non-U.S. origin and does not support AVS.';
    //                 case 'U':
    //                     return 'The address information for the cardholder is unavailable.';
    //                 case 'S':
    //                     return 'The U.S. card issuing bank does not support AVS.';
    //                 case 'N':
    //                     return 'Address: No Match, ZIP Code: No Match';
    //                 case 'A':
    //                     return 'Address: Match, ZIP Code: No Match';
    //                 case 'Z':
    //                     return 'Address: No Match, ZIP Code: Match';
    //                 case 'W':
    //                     return 'Address: No Match, ZIP Code: Matched 9 digits';
    //                 case 'X':
    //                     return 'Address: Match, ZIP Code: Matched 9 digits';
    //                 default:
    //                     return response.errors[0].errorText;

    //             }
    //         }
    //     } else {
    //         return 'The transaction has been declined! Please verify the details and try again';
    //     }
    // }

    // validate() {
    //     if ( !this.invoice.paymentAmount ) {
    //         return this.commonService.showNotification('error', 'Invalid invoice amount!');
    //     }
    //     this.validationApplied = true;
        
    //     if ( !this.data.customerInfo.payerName ) {
    //         this.commonService.showNotification('error', 'Payer name in customer information is necessary.')
    //         return false;
    //     } else if ( !(this.data.cardInfo.name && this.data.cardInfo.number && this.data.cardInfo.ccv && this.data.cardInfo.expiry) ) {
    //         this.commonService.showNotification('error', 'Please fill in card details to proceed!')
    //         return false;
    //     } else if ( !(this.data.billingInfo.country && this.data.billingInfo.city && this.data.billingInfo.address && this.data.billingInfo.state && this.data.billingInfo.zipCode) ) {
    //         this.commonService.showNotification('error', 'Please fill all required billing information!')
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    // setBillingInfo(copyCustomerInfo: boolean) {
    //     if ( copyCustomerInfo ) {
    //         this.data['billingInfo'] =  {
    //             country: this.data.country,
    //             state: this.data.state,
    //             city: this.data.city,
    //             zipCode: this.data.zipCode,
    //             address: this.data.address
    //         };
    //     } else {
    //         this.data['billingInfo'] = {
    //             country: '',
    //             state: '',
    //             city: '',
    //             zipCode: '',
    //             address: ''
    //         };
    //     }

    // }
}
