<ng-container *ngIf="data.args.transferOrderId">
    <div [ngSwitch]="data.args.inventoryCategory">
        <app-inventory-transfer-request-popup 
            *ngSwitchCase="transferOrderTypes.requester" 
            [transferOrderType]="transferOrderTypes.requester"
            [rowData]="data?.args" 
            (onSave)="onSave($event)"
            (onClose)="onPopupClose($event)"
        ></app-inventory-transfer-request-popup>
        
        <app-inventory-transfer-response-popup 
            *ngSwitchCase="transferOrderTypes.responder" 
            [rowData]="data?.args"
            (onSave)="onSave($event)"
            (onClose)="onPopupClose($event)"
        ></app-inventory-transfer-response-popup>
    </div>
</ng-container>