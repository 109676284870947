
<div class="modal fade show" [ngClass]="{'expanded': isExpanded}" id="return-order-statment-modal" tabindex="-1"
    aria-labelledby="return-order-statment-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <!-- <h4 class="modal-title mb-0 item-id">Invoice</h4> -->
                        <h4 class="modal-title mb-0 item-id">Return Order Statement</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button class="header-icon close p-0 m-1" (click)="close()" aria-label="Close">
                            <i class="fas fa-times" title="Close Popup"></i>
                        </button>
                        <button
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="onExpandCollapse()"
                        >
                            <i
                                class="fas ml-2 mr-2"
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body invoice_custom_scrollbar pt-3">
                <div id="pdfViewer" class="w-100">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="sendEmail()">Send Email</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="print()">Print</button>
            </div>
        </div>
    </div>

<app-email-popup *ngIf="showEmailPopup"
    [returnOrderId]="returnOrderId"
    [module]="module"
    [technicianName]="employeeName"
    [receiverEmail]=""
    [emailSubject]="emailSubject"
    [isReturnOrderStatement]="true"
    [isAttachment]="false"
    [urlLink]="url"
    (onClose)="onEmailPopupClose()"
></app-email-popup>