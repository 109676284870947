<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="assignment-details-modal"
    tabindex="-1"
    aria-labelledby="assignment-details-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Payment</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button
                            class="header-icon close p-0 m-0"
                            (click)="close()"
                            aria-label="Close"
                        >
                            <i
                                class="fas fa-times"
                                title="Close Popup"
                            ></i>
                        </button>
                        <button
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i
                                class="fas ml-2 mr-2"
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body custom-scrollbar pt-3">
                <div class="row">
                    <div class="col">
                        <div class="field mb-3">
                            <label>Invoice Template:</label>
                            <ejs-dropdownlist
                                #templateDD
                                [placeholder]="'Select template type'"
                                [dataSource]="templateTypes"
                                [fields]="{ text: 'description', value: 'value' }"
                                [filterType]="'contains'"
                                [readonly]="invoiceReadOnly"
                                [value]="invoice?.templateId"
                                (change)="invoice.templateId = $event.itemData.value; invoice.templateDescription = $event.itemData.text"
                            >
                                <ng-template #itemTemplate let-data>
                                    <span [title]="data.description">{{data.description}}</span>
                                </ng-template>
                            </ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-md-1 pt-4">
                        <a href="javascript:" title="View invoice draft" (click)="showInvoicePopup = true"><i class="fas fa-eye"></i></a>
                    </div>
                    <div class="col">
                        <div class="field mb-3">
                            <label>Payment Method:</label>
                            <ejs-textbox
                                [value]="invoice.paymentTypeDescription"
                                [readonly]="true"
                            ></ejs-textbox>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="invoice.paymentType && invoice.paymentType == paymentTypes.creditCard.paymentType">
                    <div class="col"></div>
                    <div class="col-md-1"></div>
                    <div class="col">
                        <div class="field mb-3">
                            <label>Card Type: <span class="required-asterik">*</span></label>
                            <ejs-dropdownlist
                                [placeholder]="'Select card type'"
                                [dataSource]="[{text: 'Master', value: 'Master'}, {text: 'Visa', value: 'Visa'}]"
                                [fields]="{text: 'text', value: 'value'}"
                                [filterType]="'contains'"
                                [readonly]="invoiceReadOnly"
                                (change)="invoice.cardType = $event.itemData.value; toggleNextButton();"
                            ></ejs-dropdownlist>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="invoice.paymentType && ( invoice.paymentType != paymentTypes.creditCard.paymentType)">
                    <div class="col" id="payment-attachment-droparea">
                        <div class="field mb-3">
                            <label>Attachment:</label>
                            <div id='uploadfile' >
                                <ejs-uploader
                                    #paymentAttachmentsUpload
                                    [asyncSettings]='path'
                                    [dropArea]='dropElement'
                                    [maxFileSize]="3.2e+7"
                                    [enabled]="!(invoiceReadOnly)"
                                    [showFileList]="false"
                                    (uploading)="beforeFileUpload($event)"
                                    (success)="onUploadSuccess($event)"
                                ></ejs-uploader>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"><a (click)="onCameraOpen()" class="float-right mt-4"><i class="fas fa-2x fa-camera"></i></a></div>
                    <div class="w-100"></div>
                    <div class="col-12" *ngIf="invoice.paymentAttachments">
                        <div class="row mb-3">
                            <div class="col-3" *ngFor="let paymentAttachment of invoice.paymentAttachments; let i = index;">
                                <div class="attachment-container mb-3" >
                                    <div class="attachments-overlay">
                                        <div class="overlay-button">
                                            <a href="javascript:" (click)="downloadFile(paymentAttachment.uri, paymentAttachment.fileName)" title="Download Attachment"><i class="fas fa-download"></i></a>
                                        </div>
                                        <div class="overlay-button ml-3">
                                            <a href="javascript:" (click)="deleteAttachment(paymentAttachment.tempId)" title="Delete Attachment"><i class="fas fa-trash"></i></a>
                                        </div>
                                    </div>
                                    <div class="image-icon-container text-center">
                                        <i class="far fa-file-pdf" *ngIf="paymentAttachment?.fileType === 'pdf'"></i>
                                        <i class="far fa-file-excel" *ngIf="paymentAttachment?.fileType === 'xls' || paymentAttachment?.fileType === 'xlsx'"></i>
                                        <i class="far fa-file-powerpoint" *ngIf="paymentAttachment?.fileType === 'ppt' || paymentAttachment?.fileType === 'pptx'"></i>
                                        <i class="far fa-file-word" *ngIf="paymentAttachment?.fileType === 'doc' || paymentAttachment?.fileType === 'docx'"></i>
                                        <img *ngIf="paymentAttachment?.fileType === 'jpg' || paymentAttachment?.fileType === 'png' || paymentAttachment?.fileType === 'svg' || paymentAttachment?.fileType === 'jpeg'" [src]="paymentAttachment?.uri" class="img img-responsive grid-view-img">
                                    </div>
                                    <div class="file-name mt-1 text-truncate">{{paymentAttachment?.fileName}}</div>
                                    <div class="user-info mt-1 d-flex align-items-center">
                                        <div class="user-icon mr-2"><i class="fas fa-user"></i></div>
                                        <div class="user-name">{{paymentAttachment?.uploadedByName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="invoice.paymentType && invoice.paymentType == paymentTypes.cheque.paymentType">
                    <div class="col">
                        <div class="field mb-3">
                            <label>Bank:<span class="required-asterik">*</span></label>
                            <ejs-textbox
                                maxLength="30"
                                [value]="invoice.bankName"
                                [readonly]="invoiceReadOnly"
                                [placeholder]="'Enter bank name...'"
                                (keyup)="invoice.bankName = $event.target.value; toggleNextButton()"
                            ></ejs-textbox>
                        </div>
                    </div>
                    <div class="col">
                        <div class="field mb-3">
                            <label>Cheque ID:<span class="required-asterik">*</span></label>
                            <ejs-textbox
                                maxLength="30"
                                [value]="invoice.checkAuthCode"
                                [readonly]="invoiceReadOnly"
                                [placeholder]="'Enter cheque number...'"
                                (keyup)="invoice.checkAuthCode = $event.target.value; toggleNextButton()"
                            ></ejs-textbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="" (click)="next()" [disabled]="!canGoNext">Generate invoice</button>
            </div>
        </div>
    </div>
</div>

<app-invoice-popup
    *ngIf="showInvoicePopup"
    [popupOpenedFrom]="InvoicePopupOpenedFrom.paymentType"
    [invoice]="invoice"
    [customerData]="customerData"
    [isDraft]="true"
    (onClose)="showInvoicePopup = false;"
></app-invoice-popup>

<app-camera *ngIf="showCameraPopup"
    (onSave)="saveCameraCapture($event)"
    (onClose)="showCameraPopup = false"
></app-camera>
