
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class ExecutionTabService {

    constructor(
        private http: HttpClient
    ) { }

    getServiceTypes(company: string) {
        return this.http.get(`${BASE_URL}ServiceType/GetServiceTypesForDDL?company=${company}`);
    }

    getReferenceActivities(company: string, callGroup: string) {
        return this.http.get(`${BASE_URL}CallGroupRefActivity/GetReferenceActivitiesForDDL?company=${company}&callGroup=${callGroup}`);
    }

    getIscReferenceActivities(soldToBp, offlineOrder: boolean = true) {
        return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCReferenceActivitiesForDDL?soldToBp=${soldToBp}&offlineOrder=${offlineOrder}`);
    }

    getReportedProblems(company: string, serializedItemGroup: string) {
        return this.http.get(`${BASE_URL}Problem/GetProblemsForDDL?company=${company}&serializedItemGroup=${serializedItemGroup}`);
    }

    getSolutions(company: string, serializedItemGroup: string) {
        return this.http.get(`${BASE_URL}Solution/GetSolutionsForDDL?company=${company}&serializedItemGroup=${serializedItemGroup}`);
    }

    getCostTypes(company: string) {
        return this.http.get(`${BASE_URL}CostType/GetCostTypesForDDL?company=${company}`);
    }
    
    getIscCostTypes() {
        return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCCostTypesForDDL`);
    }

    getServiceItems(company: string) {
        return this.http.get(`${BASE_URL}ServiceItem/GetServiceItemsForDDL?company=${company}`);
    }

    getReturnReasons(company: string) {
        return this.http.get(`${BASE_URL}Reason/GetReasonsForDDL?company=${company}`);
    }

    getUnits(company: string) {
        return this.http.get(`${BASE_URL}Unit/GetUnitsForDDL?company=${company}`);
    }

    getLaborSalesRate(configurationId: string) {
        return this.http.get(`${BASE_URL}Configuration/GetLaborSalesRate?configuration=${configurationId}`);
    }

    getCallOutCharge(distanceZone, distance) {
        return this.http.get(`${BASE_URL}ZoneClass/GetCallOutCharge?distanceZone=${distanceZone}&distance=${distance}`);
    }

    getCostLineDefaultByUser(userId) {
        return this.http.get(`${BASE_URL}CostLineDefault/GetCostLineDefaultByUser?employeeId=${userId}`);
    }

    getTaxRateByCostType(configurationId: string, category: string) {
        return this.http.get(`${BASE_URL}TaxRate/GetTaxRateByConfiguration?configuration=${configurationId}&&category=${category}`);
    }
}
