<div class="modal fade show" [ngClass]="{'expanded': isExpanded}" id="assignment-details-modal" tabindex="-1"
    aria-labelledby="assignment-details-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <!-- <h4 class="modal-title mb-0 item-id">Invoice</h4> -->
                        <h4 class="modal-title mb-0 item-id">{{heading}}</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button class="header-icon close p-0 m-1" (click)="close()" aria-label="Close">
                            <i class="fas fa-times" title="Close Popup"></i>
                        </button>
                        <button
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="onExpandCollapse()"
                        >
                            <i
                                class="fas ml-2 mr-2"
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body invoice_custom_scrollbar pt-3">
                <div id="pdfViewer" class="w-100">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" *ngIf='showSaveAndCloseButton && !isDraft' class="btn btn-sm btn-success mr-1" [disabled]="!isSigned" (click)="saveAndClose()" id="">Save & close</button>
                <button type="button" *ngIf='!checklistCertificate && (showSaveAndCloseButton && !isDraft)'class="btn btn-sm btn-primary" [disabled]="!isSigned" (click)="sendEmail()">Save & Send Email</button>
                <button type="button" *ngIf='checklistCertificate' class="btn btn-sm btn-primary" (click)="sendEmail()">Send Email</button>
                <!-- <button type="button" *ngIf="fieldRules && fieldRules.invoice && fieldRules.invoice.readonly === true ? false : !isDraft" [disabled]="!isSigned" (click)="saveFile(false)" class="btn btn-sm btn-success mr-1" id="">Save</button> -->
                <button type="button" *ngIf="!checklistCertificate || popupOpenedFrom == InvoicePopupOpened.checklist" (click)="print()" class="btn btn-sm btn-success mr-1" id="">Print</button>
            </div>
        </div>
    </div>

<app-email-popup *ngIf="showEmailPopup"
    [receiverEmail]="customerData.email"
    [attachment]="invoice?.invoiceAttachment"
    [sendOtherDocs]="sendOtherDocs"
    [emailSubject]="emailSubject"
    [checklistType]="checklistType"
    [isInvoice]="popupOpenedFrom == InvoicePopupOpened.popupModal"
    [isChecklist]="popupOpenedFrom == InvoicePopupOpened.checklist"
    [urlLink]="link"
    [serviceOrderId]="serviceOrderData.serviceOrderId"
    [serviceOrderIdLN]="serviceOrderData.serviceOrderIdLN"
    (onClose)="onEmailPopupClose()"
></app-email-popup>
