import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearedEventArgs, DatePicker, NavigatedEventArgs, PopupObjectArgs, PreventableEventArgs, RenderDayCellEventArgs } from '@syncfusion/ej2-angular-calendars';
import { BlurEventArgs, ChangedEventArgs, FocusEventArgs, TextBox } from '@syncfusion/ej2-angular-inputs';

@Component({
    selector: 'sf-datepicker',
    templateUrl: './sf-datepicker.component.html',
    styleUrls: ['./sf-datepicker.component.scss']
}) 

export class SfDatepickerComponent implements OnInit {

    @Input() properties: DatePicker;
    @Output() blur: EventEmitter<BlurEventArgs> = new EventEmitter<BlurEventArgs>();
    @Output() change: EventEmitter<ChangedEventArgs> = new EventEmitter<ChangedEventArgs>();
    @Output() cleared: EventEmitter<ClearedEventArgs> = new EventEmitter<ClearedEventArgs>();
    @Output() close: EventEmitter<PreventableEventArgs|PopupObjectArgs> = new EventEmitter<PreventableEventArgs|PopupObjectArgs>();
    @Output() created: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() destroyed: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() focus: EventEmitter<FocusEventArgs> = new EventEmitter<FocusEventArgs>();
    @Output() navigated: EventEmitter<NavigatedEventArgs> = new EventEmitter<NavigatedEventArgs>();
    @Output() open: EventEmitter<PreventableEventArgs|PopupObjectArgs> = new EventEmitter<PreventableEventArgs|PopupObjectArgs>();
    @Output() renderDayCell: EventEmitter<RenderDayCellEventArgs> = new EventEmitter<RenderDayCellEventArgs>();

    constructor() {}
    ngOnInit() {}

    onBlur(args) { this.blur.emit(args);}
    onChange(args) { this.change.emit(args);}
    onCleared(args) { this.cleared.emit(args);}
    onClose(args) { this.close.emit(args);}
    onCreated(args) { this.created.emit(args);}
    onDestroyed(args) { this.destroyed.emit(args);}
    onFocus(args) { this.focus.emit(args);}
    onNavigated(args) { this.navigated.emit(args);}
    onOpen(args) { this.open.emit(args);}
    onRenderDayCell(args) { this.renderDayCell.emit(args);}

    addDate(dates: Date | Date[]) {}
    currentView() {}
    destroy() {}
    focusIn() {}
    focusOut(args) {}
    getPersistData() {}
    hide() {}
    navigateTo(view: string, date: Date) {}
    removeDate(dates: Date | Date[]) {}
    show() {}
}