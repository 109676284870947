import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../../src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class InventoryService {

    popupLoader: Subject<any> = new Subject();
    receiptsPopupLoader: Subject<any> = new Subject();
    getData: Subject<any> = new Subject();

    constructor(private http: HttpClient) { }

    getStatusesForDDL(moduleName: any) { // 'PartsOrder' as parameter
        return this.http.get(`${BASE_URL}status/GetStatusesForDDL?moduleName=${moduleName}`);
    }

    /********************************* */
    /** Inventory Main Grid API */
    /********************************* */

    getInventory(type?: string, warehouse?: string) {
        const filter = {
            requiresCounts: true,
            onWhere: {
                ignoreAccent: false,
                isComplex: true,
                condition: 'and',
                predicates: [{
                    ignoreAccent: false,
                    isComplex: false,
                    field: 'warehouse',
                    operator: 'equal',
                    value: warehouse,
                    ignoreCase: true,
                    oprtr: 'equal'
                }]
            },
            onPage: {
                pageIndex: 1,
                pageSize: 100
            },
            parameters: [],
            skip: 0,
            take: 100
        };
        return this.http.post(`${BASE_URL}Inventory/GetInventory?inventoryType=${type}`, filter);
    }

    getInventoryForDDL(type?: string, warehouse?: string) {
        const filter = {
            requiresCounts: true,
            onWhere: {
                ignoreAccent: false,
                isComplex: true,
                condition: 'and',
                predicates: [{
                    ignoreAccent: false,
                    isComplex: false,
                    field: 'warehouse',
                    operator: 'equal',
                    value: warehouse,
                    ignoreCase: true,
                    oprtr: 'equal'
                }]
            },
            onPage: {
                pageIndex: 1,
                pageSize: 1000
            },
            parameters: [],
            skip: 0,
            take: 1000
        };
        return this.http.post(`${BASE_URL}Inventory/GetInventory?inventoryType=${type}`, filter);
    }

    /********************************* */
    /** Item/Parts/Replenishment Order APIs */
    /********************************* */

    getPartsOrders(id) {
        return this.http.get(`${BASE_URL}PartsOrder/GetPartsOrdersNew?technicianId=${id}`);
    }

    getPartsOrderById(id: string) {
        if (id) {
            return this.http.get(`${BASE_URL}PartsOrder/GetPartsOrderById?PartsOrderId=${id}`);
        } else {
            return null;
        }
    }

    savePartsOrder(data: any) {
        if (data.partsOrderId) {
            return this.http.post(`${BASE_URL}PartsOrder/UpdatePartsOrder`, data);
        } else {
            return this.http.post(`${BASE_URL}PartsOrder/CreatePartsOrder`, data);
        }
    }

    deletePartOrder(id) {
        return this.http.delete(`${BASE_URL}PartsOrder/DeletePartsOrder?partsOrderId=${id}`);
    }


    /********************************* */
    /** Inventory Transfer APIs */
    /********************************* */

    getTransferOrderById(id) {
        return this.http.get(`${BASE_URL}TransferOrder/GetTransferOrderById?transferOrderId=${id}`);
    }

    saveTransferOrder(data) {
        if (data.transferOrderId) {
            return this.http.post(`${BASE_URL}TransferOrder/UpdateTransferOrder`, data);
        } else {
            return this.http.post(`${BASE_URL}TransferOrder/CreateTransferOrder`, data);
        }
    }

    /**
     * 
     * @param forDashboard : Optional, Is a parameter to inform the backend that this API is being called from the dashboard
     * @returns Transfer Order for request grid's data as an observable
     */

    getTransferOrdersForRequester(forDashboard=false) {
        return this.http.get(`${BASE_URL}Dashboard/GetTransferOrdersForRequester?forDashboardPod=${forDashboard}`);
    }

    getTransferOrdersForResponder(forDashboard=false) {
        return this.http.get(`${BASE_URL}Dashboard/GetTransferOrdersForResponder?forDashboardPod=${forDashboard}`);
    }

    getServiceOrdersForTransferOrder(technicianId: any) {
        return this.http.get(`${BASE_URL}TransferOrder/GetServiceOrdersForTransferOrder?technicianId=${technicianId}`);
    }

    /********************************* */
    /** Return Order APIs */
    /********************************* */

    getReturnOrders() {
        return this.http.get(`${BASE_URL}ReturnOrder/GetReturnOrders`);
    }

    getReturnOrderById(id: string) {
        return this.http.get(`${BASE_URL}ReturnOrder/GetReturnOrderById?returnOrderId=${id}`);
    }

    saveReturnOrder(data: any) {
        if (data.returnOrderId) {
            return this.http.post(`${BASE_URL}ReturnOrder/UpdateReturnOrder`, data);
        } else {
            return this.http.post(`${BASE_URL}ReturnOrder/CreateReturnOrder`, data);
        }
    }

    getReasonOrExceptionCodesForDDL(type) {
        return this.http.get(`${BASE_URL}ExceptionCode/GetExceptionCodesForDDL?type=${type}`);
    }

    getItems(warehouse: string, category: string = 'serviceable', searchString: string = null, pageSize: number = 30) {
        // const columnName = isNaN(+searchString) ? 'itemDescription' : 'item';
        const columnName = 'item';
        const filterData = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize
            },
            skip: 0,
            take: pageSize
        };
        if (searchString) {
            filterData['onWhere'] = {
                ignoreAccent: false,
                isComplex: true,
                condition: "and",
                predicates: [{
                    condition: 'or',
                    ignoreAccent: false,
                    isComplex: false,
                    field: columnName,
                    operator: "contains",
                    value: searchString,
                    ignoreCase: true,
                    oprtr: "contains"
                }]
            };
        }
        return this.http.post(`${BASE_URL}ReturnOrder/GetServiceItemsForReturnOrder?warehouse=${warehouse}&category=${category}`, filterData);
    }

    /********************************* */
    /** Shipment Order APIs */
    /********************************* */

    getShipmentOrders() {
        return this.http.get(`${BASE_URL}ShipmentOrder/GetShipmentOrders`);
    }

    getShipmentOrderById(id: string) {
        return this.http.get(`${BASE_URL}ShipmentOrder/GetShipmentOrderById?ShipmentOrderId=${id}`);
    }

    updateShipmentOrder(data: any) {
        return this.http.post(`${BASE_URL}ShipmentOrder/UpdateShipmentOrder`, data)
    }

    UpdateShipmentStatusDeliveryAck(shipmentOrderId: string) {
        return this.http.post(`${BASE_URL}ShipmentOrder/UpdateShipmentStatusDeliveryAck?shipmentOrderId=${shipmentOrderId}`, null)
    }

    getAdjustmentOrderById(id: string) {
        return this.http.get(`${BASE_URL}ShipmentOrder/GetAdjustmentOrderById?adjustmentOrderId=${id}`);
    }

    getTechnicianOnHandCount(itemCode, warehouse, category: string = 'serviceable') {
        return this.http.get(`${BASE_URL}Inventory/GetTechnicianOnHandCount?item=${itemCode}&warehouse=${warehouse}&category=${category}`);
    }

    GetOpenPartsOrderByServiceOrderId(serviceOrderId) {
        return this.http.get(`${BASE_URL}PartsOrder/GetOpenPartsOrderByServiceOrderId?serviceOrderId=${serviceOrderId}`);
    }

    getOnsiteAddress(id) {
        return this.http.get(`${BASE_URL}ServiceOrder/GetOnsiteAddress?serviceOrderId=${id}`);
    }

    getIscOnsiteAddress(id) {
        return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCOnsiteAddress?serviceOrderId=${id}`);
    }

    getItemHistory(itemId: string, warehouse: string) {
        return this.http.get(`${BASE_URL}Inventory/GetInventoryTransactionsByItem?item=${itemId}&warehouse=${warehouse}`);

    }


    /********************************* */
    /** Inventory adjustment order APIs */
    /********************************* */
    getInventoryAdjustmentOrderById(id) {
        return this.http.get(`${BASE_URL}InventoryAdjustmentOrder/GetInventoryAdjustmentOrderById?inventoryAdjustmentOrderId=${id}`)
    }

    getTechnicianItemRelatedCounts(item, warehouse, technicianId) {
        return this.http.get(`${BASE_URL}InventoryAdjustmentOrder/GetTechnicianItemRelatedCounts?item=${item}&warehouse=${warehouse}&technicianId=${technicianId}`)
    }

    getPendingApprovalInventoryAdjustmentOrders() {
        return this.http.get(`${BASE_URL}InventoryAdjustmentOrder/GetPendingApprovalInventoryAdjustmentOrders`)
    }

    updateInventoryAdjustmentOrder(data: any) {
        return this.http.post(`${BASE_URL}InventoryAdjustmentOrder/UpdateInventoryAdjustmentOrder`, data)
    }

    updateAdjustmentOrderStatus( params ) {
        return this.http.post(`${BASE_URL}InventoryAdjustmentOrder/UpdateInventoryAdjustmentOrderStatus?inventoryAdjustmentOrderId=${params.orderId}&status=${params.status}`, null)
    }

    /********************************* */
    /** Core Return Order APIs */
    /********************************* */

    getOpenCoreReturnOrders() {
        return this.http.get(`${BASE_URL}ReturnOrderCoreReturn/GetOpenCoreReturnOrder`);
    }

    getCoreReturnOrders() {
        return this.http.get(`${BASE_URL}ReturnOrderCoreReturn/GetCoreReturnOrders`);
    }

    getCoreReturnOrderById(id: string) {
        return this.http.get(`${BASE_URL}ReturnOrderCoreReturn/GetCoreReturnOrderById?coreReturnOrderId=${id}`);
    }

    saveCoreReturnOrder(data: any) {
        return this.http.post(`${BASE_URL}ReturnOrderCoreReturn/UpsertCoreReturnOrder`, data);
    }

    generateStatementOfCoreReturnItem(data: any) {
        return this.http.post(`${BASE_URL}ReturnOrderCoreReturn/GenerateCoreReturnOrder`, data);
    }

    /********************************* */
    /** Damaged Return Order APIs */
    /********************************* */

    getOpenDamagedReturnOrders() {
        return this.http.get(`${BASE_URL}ReturnOrderDamagedReturn/GetOpenDamagedReturnOrder`);
    }

    getDamagedReturnOrders() {
        return this.http.get(`${BASE_URL}ReturnOrderDamagedReturn/GetDamagedReturnOrders`);
    }

    getDamagedReturnOrderById(id: string) {
        return this.http.get(`${BASE_URL}ReturnOrderDamagedReturn/GetDamagedReturnOrderById?damagedReturnOrderId=${id}`);
    }

    saveDamagedReturnOrder(data: any) {
        return this.http.post(`${BASE_URL}ReturnOrderDamagedReturn/UpsertDamagedReturnOrder`, data);
    }

    generateStatementOfDamagedReturnItem(data: any) {
        return this.http.post(`${BASE_URL}ReturnOrderDamagedReturn/GenerateDamagedReturnOrder`, data);
    }

    /********************************* */
    /** Exceptions APIs */
    /********************************* */
    updateExceptionStatus(status: string, data: any ) {
        return this.http.post(`${BASE_URL}ShipmentOrder/UpdateExceptionStatus?status=${status}`, data)
    }
}
