import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-po-response-success',
  templateUrl: './po-response-success.component.html',
  styleUrls: ['./po-response-success.component.scss']
})
export class PoResponseSuccessComponent implements OnInit {

  @Input() transactionID = null;
  @Input() transactionDate = null;
  @Input() transactionAmount = null;

  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  printInvoice(){
    this.close.emit();
  }

}
