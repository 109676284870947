import { Element, FieldTypes } from "src/app/modules/builder/models/main.model";
import { Action, Actions, Condition, Operators, Rule } from "src/app/modules/builder/models/rule.model";
import { solveDateEquation, solveEquation, validationMessage } from "./general-utils";
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { formatDate } from '@angular/common';

export const checkConditions = (conditions: Condition[]) => {
    let conditionsValid = true;
    conditions.forEach( (condition: Condition) => {
        conditionsValid = conditionsValid && checkCondition(condition);
    });
    return conditionsValid;
}


const checkCondition = (condition: Condition) => {
    let field, fieldValue, fieldPreviousValue; 
    if ( condition.field.elementType === FieldTypes.checkbox ) {
        const checkboxId = document.getElementById(condition.field.elementKey) ? document.getElementById(condition.field.elementKey).childNodes[0]['getAttribute']('for') : null;
        if (checkboxId) {
            field = document.getElementById(checkboxId)['ej2_instances'][0];
            fieldValue = field.checked;
            fieldPreviousValue = !fieldValue;
        }
    } else if ( condition.field.elementType === FieldTypes.radiobutton ) {
        document.getElementsByName(condition.field.elementKey)?.forEach(elem => {
            if(elem['ej2_instances'][0]?.checked) {
                field = elem['ej2_instances'][0];
                fieldValue = field.value;
            } 
        });
    } else {
        field = document.getElementById(condition.field.elementKey) ? document.getElementById(condition.field.elementKey)['ej2_instances'][0] : null;
        if ( field ) {
            fieldValue = condition.field.elementType === FieldTypes.datepicker && field.value ? new Date(field.value.setHours(0, 0, 0, 0)) : field.value;
            fieldPreviousValue = field.previousItemData ? field.previousItemData.value : field.previousValue;
        }
    }
    if ( condition.field.elementType === FieldTypes.datepicker ) {
        condition.toValue = condition.toValue && !isNaN(new Date(condition.toValue).getTime()) ? new Date(condition.toValue) : solveDateEquation(condition.toValue);
    } 
    switch ( condition.operator ) {
        case Operators.hasValue: {
            return Boolean(fieldValue);
        }
        case Operators.doesntHaveValue: {
            return !Boolean(fieldValue);
        }
        case Operators.changed: {
            return fieldValue !== fieldPreviousValue;
        }
        case Operators.changedFrom: {
            return fieldPreviousValue === condition.fromValue && fieldValue === condition.toValue;
        }
        case Operators.changedTo: {
            return fieldValue === condition.toValue;
        }
        case Operators.equals: {
            return condition.field.elementType === FieldTypes.datepicker && fieldValue && condition.toValue ? fieldValue.getTime() === condition.toValue.getTime() : fieldValue === condition.toValue;
        }
        case Operators.notEqual: {
            return fieldValue !== condition.toValue;
        }
        case Operators.greaterEqual: {
            return fieldValue >= condition.toValue;
        }
        case Operators.greaterThan: {
            return fieldValue > condition.toValue;
        }
        case Operators.lessEqual: {
            return fieldValue <= condition.toValue;
        }
        case Operators.lessThan: {
            return fieldValue < condition.toValue;
        }
    }
}

export const applyActions = (actions: Action[], elements?: Element[]) => {
    actions.forEach( (action: Action) => {
        if (action.field.elementKey === 'class1/2-0kgf-FinalReading') {
            
        }
        applyAction(action, elements);
    });
}

const applyAction = (action: Action, elements?: Element[]) => {
    let actionField, actionFieldValue, actionToField, actionToFieldValue, actionElement;

    if ( action.field ) {
        if ( action.field.elementType === FieldTypes.checkbox ) {
            const checkboxId = document.getElementById(action.field.elementKey) ? document.getElementById(action.field.elementKey).childNodes[0]['getAttribute']('for') : null;
            if ( checkboxId ) actionField = document.getElementById(checkboxId)['ej2_instances'][0];
            actionFieldValue = action.fieldValue;
        } else {
            actionField = document.getElementById(action.field.elementKey) ? document.getElementById(action.field.elementKey)['ej2_instances'][0] : null;
            if ( action.formula ) actionFieldValue = action.field && action.field.elementType === "datepicker" ? solveDateEquation(action.formula) : solveEquation(action.formula);
            else actionFieldValue = action.fieldValue;
        }
        if ( elements && elements.length ) {
            actionElement = elements.find(element => element.elementKey == action.field.elementKey && element.elementId == action.field.elementId);
        }
    }

    if ( action.toField ) {
        if ( action.toField.elementType === FieldTypes.checkbox ) {
            const checkboxId = document.getElementById(action.toField.elementKey).childNodes[0]['getAttribute']('for');
            actionToField = document.getElementById(checkboxId)['ej2_instances'][0];
            actionToFieldValue = actionToField.checked;
        } else {
            actionToField = document.getElementById(action.toField.elementKey)['ej2_instances'][0];
            actionToFieldValue = action.toValue;
        }
    }

    switch ( action.action ) {
        case Actions.setValueOfField: {
            if ( actionField ) {
                if ( action.field.elementType === FieldTypes.checkbox ) {
                    if((actionFieldValue && !actionField.checked) || (!actionFieldValue && actionField.checked)){
                        document.getElementById(action.field.elementKey).click();
                    }
                } else {
                    actionField.value = actionFieldValue;
                }
            }
            break;
        }
        case Actions.setPropertyOfField: {
            let toValue = action.toValue;
            if ( action.formula ) {
                toValue = action.field && action.field.elementType === "datepicker" ? solveDateEquation(action.formula) : solveEquation(action.formula);
            }
            if ( actionElement ) {
                if ( action.fieldProperty['key'] === 'datasource' ) {
                    actionElement.dataSource = JSON.parse(toValue);
                } else {
                    const formObject = document.getElementsByName(action.field.elementKey) && document.getElementsByName(action.field.elementKey).length && document.getElementsByName(action.field.elementKey)[0]['form'] && document.getElementsByName(action.field.elementKey)[0]['form'].ej2_instances ? document.getElementsByName(action.field.elementKey)[0]['form'].ej2_instances[0] : null;
                    if(formObject && (action.fieldProperty['key'] === "required" || action.fieldProperty['key'] === "min" || action.fieldProperty['key'] === "max")){
                        if(action.fieldProperty['key'] === 'required')
                        {
                            if(formObject.rules && formObject.rules[action.field.elementKey] && Object.keys(formObject.rules[action.field.elementKey]).length === 1){
                                formObject.removeRules(action.field.elementKey);
                            } else
                                formObject.removeRules(action.field.elementKey, ['required']);
                            if(toValue)
                                formObject.addRules(action.field.elementKey, { required: [true, validationMessage('This field is required!')] });
                        }
                        if(action.fieldProperty['key'] === 'min'){
                            const propertyKey = action.field.elementType === FieldTypes.textbox ? 'minLength' : 'min';                            
                            if(formObject.rules && formObject.rules[action.field.elementKey] && Object.keys(formObject.rules[action.field.elementKey]).length === 1){
                                formObject.removeRules(action.field.elementKey);
                            } else {
                                formObject.removeRules(action.field.elementKey, [propertyKey]);
                            }
                            const ruleObject = {};
                            let rangeMessage = null;
                            let minValue = (action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker) && toValue != null ? formatDate(new Date(new Date().setDate(new Date().getDate() + toValue)),"dd MMM yyyy",'en') : toValue;
                            let maxValue = formObject.rules && formObject.rules[action.field.elementKey] && formObject.rules[action.field.elementKey].hasOwnProperty("max") ? (action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker) ? null : formObject.rules[action.field.elementKey].max[0] : null;           
                            
                            if(minValue != null && maxValue != null) {
                                if (!Number.isInteger(minValue)) {
                                    let numDecimalPlaces = (minValue.toString().split('.')[1] || 0).length;
                                    if (numDecimalPlaces > 3) {
                                      minValue = minValue.toFixed(3);
                                    } 
                                }
                                if (!Number.isInteger(maxValue)) {
                                    let numDecimalPlaces = (maxValue.toString().split('.')[1] || 0).length;
                                    if (numDecimalPlaces > 3) {
                                        maxValue = maxValue.toFixed(3);
                                    }
                                }
                            }
                            
                            if(maxValue){
                                rangeMessage = `Please enter a value between ${minValue} to ${maxValue} ${action.field.elementType === FieldTypes.textbox ? 'characters': ''}`;
                                formObject.rules[action.field.elementKey].max[1] = validationMessage(rangeMessage);
                            }
                            ruleObject[propertyKey] = [action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker ? validateMinDate.bind(new Date(minValue)) : minValue, validationMessage(rangeMessage || `Please enter ${action.field.elementType === FieldTypes.textbox ? 'no more than ': 'a value greater than or equal to '}${minValue} ${action.field.elementType === FieldTypes.textbox ? 'characters': ''}`)];
                            formObject.addRules(action.field.elementKey, ruleObject);
                        }
                        if(action.fieldProperty['key'] === 'max'){
                            const propertyKey = action.field.elementType === FieldTypes.textbox ? 'maxLength' : 'max';                            
                            if(formObject.rules && formObject.rules[action.field.elementKey] && Object.keys(formObject.rules[action.field.elementKey]).length === 1){
                                formObject.removeRules(action.field.elementKey);
                            } else
                                formObject.removeRules(action.field.elementKey, [propertyKey]);
                            const ruleObject = {};
                            let rangeMessage = null;
                            let maxValue = (action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker) && toValue != null ? formatDate(new Date(new Date().setDate(new Date().getDate() + toValue)),"dd MMM yyyy",'en') : toValue;
                            let minValue = formObject.rules && formObject.rules[action.field.elementKey] && formObject.rules[action.field.elementKey].hasOwnProperty("min") ? (action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker) ? null : formObject.rules[action.field.elementKey].min[0] : null;              
                            
                            if(minValue != null && maxValue != null) {
                                if (!Number.isInteger(minValue)) {
                                    let numDecimalPlaces = (minValue.toString().split('.')[1] || 0).length;
                                    if (numDecimalPlaces > 3) {
                                      minValue = minValue.toFixed(3);
                                    } 
                                }
                                if (!Number.isInteger(maxValue)) {
                                    let numDecimalPlaces = (maxValue.toString().split('.')[1] || 0).length;
                                    if (numDecimalPlaces > 3) {
                                        maxValue = maxValue.toFixed(3);
                                    }
                                }
                            }

                            if(minValue){
                                rangeMessage = `Please enter a value between ${minValue} to ${maxValue} ${action.field.elementType === FieldTypes.textbox ? 'characters': ''}`;
                                formObject.rules[action.field.elementKey].min[1] = validationMessage(rangeMessage);
                            }
                            ruleObject[propertyKey] = [action.field.elementType === FieldTypes.datepicker || action.field.elementType === FieldTypes.datetimepicker ? validateMinDate.bind(new Date(maxValue)) : maxValue, validationMessage(rangeMessage || `Please enter ${action.field.elementType === FieldTypes.textbox ? 'at least ': 'a value less than or equal to '}${maxValue} ${action.field.elementType === FieldTypes.textbox ? 'characters': ''}`)];
                            formObject.addRules(action.field.elementKey, ruleObject);
                        }
                    }
                    else if(action.fieldProperty['key'] === "visible"){
                        if(formObject && !toValue){
                            formObject.removeRules(action.field.elementKey);
                            if(formObject.rules && formObject.rules[action.field.elementKey] && Object.keys(formObject.rules[action.field.elementKey]).length === 1 && formObject.rules[action.field.elementKey].required){
                                formObject.removeRules(action.field.elementKey);
                            } else if(formObject.rules && formObject.rules[action.field.elementKey]) {
                                formObject.removeRules(action.field.elementKey, ["required"]);
                            }
                        }
                        actionElement[action.fieldProperty['key']] = toValue;
                    }
                    else
                        actionElement[action.fieldProperty['key']] = toValue;
                }
            }
            break;
        }
        case Actions.copyValueFrom: {
            if ( actionToField ) {
                if ( action.toField.elementType === FieldTypes.checkbox ) {
                    actionToField.checked = actionFieldValue;
                } else {
                    actionToField.value = actionFieldValue;
                }
            }
            break;
        }
        case Actions.sendEmail: {
            // send email
            break;
        }
        case Actions.sendNotification: {
            // send notification
            break;
        }
        case Actions.conditionalValidation: {
            // show conditional validation
            break;
        }
    }
}

export const applyRulesOnLoad = (elements: Element[], rules: Rule[]) => {
    const allFieldRules = getAllFieldRules(elements, rules);
    allFieldRules.forEach( (rule: Rule) => {
        setTimeout( () => {
            if ( checkConditions(rule.conditions) ) {
                applyActions(rule.actions, elements);
            }
        }, 50);
    });
}

const getAllFieldRules = (elements: Element[], rules: Rule[]) => {
    const fieldRules = [];
    elements.forEach( (element: Element) => {
        if ( rules && rules.length ) {
            rules.filter( (rule: Rule) => {
                if ( rule.conditions.filter(condition => condition.field.elementKey === element.elementKey && !condition.operator.includes('changed')).length ) {
                    fieldRules.push(rule);
                }
            });
        }
    });
    return fieldRules;
}

export function validateMinDate(args: any) {
    const minDate = new Date(new Date(this).setHours(0,0,0,0));
    return !args.value || new Date(args.value) >= minDate;
}

export function validateMaxDate(args: any) {
    const maxDate = new Date(this).setHours(0,0,0,0);
    return !args.value || new Date(args.value).setHours(0,0,0,0) <= maxDate;
}
