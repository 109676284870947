<!-- <div class="bg-red w-100 h-100"> Email popup</div> -->
<div
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="ms-dynamic-modal" 
    tabindex="-1" 
    aria-labelledby="ms-dynamic-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex w-100">
        
                <div class="flex-fill" >
                  <h3 class="modal-title mb-0">{{ modalTitle }}</h3>
                  <p>{{ modalTitleLabel }}</p>
                </div>

                <div class="btns-header">
                  <button 
                      class="header-icon close p-0 m-0" 
                      (click)="close()" 
                      aria-label="Close"
                  ><i class="fas fa-times" 
                        title="Close Popup"
                    ></i>
                  </button>
                  <button class="close header-icon expand-modal p-0 m-0" (click)="isExpanded = !isExpanded">
                    <i 
                        class="fas ml-2 mr-2" 
                        [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                        [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                    ></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mt-4">
                  <ejs-grid #wrongPartsGrid
                      id="shipmentOrderWrongShippedItemsGrid"
                      [dataSource]="dataSource"
                      [allowPaging]="false"
                      [allowFiltering]="true"
                      [allowSorting]="true"
                      [allowResizing]="true"
                      [allowReordering]="true"
                      [enablePersistence]="false"
                      [allowExcelExport]="true"
                      [editSettings]="{ allowEditing: false, allowAdding: false, allowDeleting: false }"
                      [filterSettings]="{ type: 'Menu' }"
                      >
                      <e-columns>
                          <e-column 
                              *ngFor="let column of columns;" 
                              [field]="column.field" 
                              [headerText]="column.headerText"
                              [isPrimaryKey]="column.isPrimaryKey || false"
                              [clipMode]="'EllipsisWithTooltip'"
                              [type]="column.type"
                              [width]="column.width"
                              [foreignKeyField]="column.foreignKeyField"
                              [foreignKeyValue]="column.foreignKeyValue"
                              [validationRules]="column.validationRules"
                              [dataSource]="column.dataSource"
                              [edit]="column.edit"
                              [filter]="column.filter ? column.filter : { type: 'Menu' }"
                              [format]="column.format ? column.format : null"
                              [textAlign]="column.textAlign" 
                              [visible]="column.visible"
                              [commands]="column?.commands ? column.commands : null"
                              [allowEditing]="column.allowEditing" 
                              [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true"
                              >
                              <ng-template *ngIf="column.field === 'adjustmentOrderNumber'" #template let-data>
                                <a href="javascript:" (click)="openLinkedPopup(data)">{{data.adjustmentOrderNumber}}</a>
                            </ng-template>
                          </e-column>
                      </e-columns>
                  </ejs-grid>
              </div>
          </div>
          </div>
          <div class="modal-footer p-0">
              <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
          </div>
        </div>
    </div>
</div>


<app-linked-popup-opener
    *ngIf="linkedPopupData"
    [data]="linkedPopupData"
    (onPopupClose)="linkedPopupData = null;"
></app-linked-popup-opener>
