<div class="row mt-3">
    <!-- <div class="col-3">
        <div class="field mb-3">
            <label>Service Type:</label>
            <ejs-dropdownlist
                [placeholder]="'Select Service Type'"
                [dataSource]="serviceTypes"
                [allowFiltering]="true"
                [filterType]="'contains'"
                [fields]="{text: 'text', value: 'value'}"
                [readonly]="!isEditable"
                [value]="data.serviceType"
                (change)="data.serviceType = $event.itemData.value"
            ></ejs-dropdownlist>
        </div>
    </div> -->
    <app-loader *ngIf="showLoader"></app-loader>
    <div class="col" *ngIf="modelDescription">
        <div class="field mb-3">
            <label>Model:</label>
            <p class="pt-2">{{ modelDescription }}</p>
        </div>
    </div>
    <div class="col" *ngIf="serialNumber">
        <div class="field mb-3">
            <label>Serial Number:</label>
            <p class="pt-2">{{ serialNumber }}</p>
        </div>
    </div>
    <div class="col">
        <div class="field mb-3">
            <label>Warranty:</label>
            <!-- <p class="pt-2">{{ data?.warrantyDescription }}</p> -->
            <ng-container *ngIf="warrantyInfo">
                <div class="text-white {{ desc.class }} pl-2 bg-coverage-gradient" *ngFor="let desc of warrantyInfo">
                    <div *ngIf="desc.costType === 'No coverage'">
                        <span>{{desc.costType}}</span>
                    </div>
                    <div *ngIf="desc.costType !== 'No coverage'">
                        <span class="warranty-cost-type">  {{ desc.costType }}</span> :
                        <span class="warranty-percentage-coverage"> {{ desc.percentageCoverage }}% </span> -
                        <span class="warranty-effective-date">{{ desc.effectiveDate | date }} </span>
                        <span class="warranty-expiry-date"> to {{ desc.expiryDate | date }} </span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col">
        <div class="field mb-3">
            <label>Contract:</label>
            <ng-container *ngIf="contractInfo">
                <div class="text-white {{ desc.class }} pl-2 bg-coverage-gradient" *ngFor="let desc of contractInfo">
                    <span class="contract-cost-type"> {{ desc.costType }}</span>
                    <span class="contract-percentage-coverage" *ngIf="desc.costType !== 'No coverage'">:  {{ desc.percentageCoverage }}% -</span>
                    <span class="contract-effective-date" *ngIf="desc.costType !== 'No coverage'"> {{ desc.effectiveDate | date : 'd MMM, y'}} |</span>
                    <span class="contract-expiry-date" *ngIf="desc.costType !== 'No coverage'"> {{ desc.expiryDate | date : 'd MMM, y'}} </span>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="col">
        <div class="field mb-3">
            <div class="text-left"><label class="font-weight-bold">Approved Amount:</label>
                <span class="ml-3">{{ data.approvedAmount | currency : customerCurrency}}</span>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <!-- <div class="card">
            <div class="card-header d-flex pl-0" data-toggle="collapse" href="#collapseSeeMore">
                <a class="card-link flex-fill">See More</a>
                <i class="fas fa-chevron-up"></i>
            </div>
            <div id="collapseSeeMore" class="collapse show">
                <div class="card-body pl-0 pb-0">
                    <div class="row">
                        <div class="col-3">

                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="card">
            <div class="card-header d-flex pl-0" data-toggle="collapse" href="#collapseAssignmentsTransactions">
                <a class="card-link flex-fill">Assignments/Transactions</a>
                <i class="fas fa-chevron-up"></i>
            </div>
            <div id="collapseAssignmentsTransactions" class="collapse show">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-12">
                            <ejs-grid
                                id="assignmentsGrid"
                                #assignmentsGrid
                                *ngIf="ddlFetched"
                                [dataSource]="assignments"
                                [height]="60"
                                [rowHeight]="30"
                                [allowFiltering]="true"
                                [allowExcelExport]="true"
                                [allowSorting]="true"
                                [allowResizing]="true"
                                [allowReordering]="true"
                                [showColumnChooser]="true"
                                [editSettings]="{ allowEditing: isEditable, allowAdding: true, allowDeleting: true, mode: 'Normal'}"
                                [toolbar]="assignmentGrid?.toolbar"
                                [filterSettings]="{ type: 'Menu' }"
                                [query]="query"
                                (created)="onAssignmentsGridCreated($event)"
                                (rowSelected)="onAssignmentSelected($event)"
                                (rowDataBound)="onRowDataBound($event)"
                                (toolbarClick)="onAssignmentsToolbarClick($event)"
                                (commandClick)="onAssignmentCommandClick($event)"
                                (actionBegin)="assignmentActionBegin($event)"
                                (actionComplete)="onAssignmentGridActionComplete($event, assignmentsGrid)"
                                (excelQueryCellInfo)="onExcelQueryCellInfo($event)"
                            >
                                <e-columns>
                                    <e-column
                                        *ngFor="let column of assignmentGrid?.columns;"
                                        [field]="column.field"
                                        [headerText]="column.headerText"
                                        [clipMode]="'EllipsisWithTooltip'"
                                        [type]="column.type"
                                        [width]="column.width"
                                        [edit]="column.edit"
                                        [editType]="column.editType"
                                        [foreignKeyValue]="column.foreignKeyValue"
                                        [foreignKeyField]="column.foreignKeyField"
                                        [dataSource]="column.dataSource"
                                        [isPrimaryKey]="column.isPrimaryKey || false"
                                        [format]="column.format ? column.format : null"
                                        [textAlign]="column.textAlign"
                                        [visible]="column.visible"
                                        [validationRules]="column.validationRules"
                                        [showInColumnChooser]="column.showInColumnChooser ?? true"
                                        [allowEditing]="column.allowEditing"
                                        [filter]="column.filter ? column.filter : { type: 'Menu'}"
                                        [commands]="column?.commands ? column.commands : null"
                                        [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                        <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                            <a href="javascript:" (click)="onConsignmentClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                                        </ng-template>
                                        <ng-template *ngIf="column.headerText === 'Reference Activity'" #headerTemplate let-data>
                                            <div>
                                                Reference Activity <span class="required-asterik">*</span>
                                            </div>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="pl-2" *ngIf="isKingslynn">
                                <h4>
                                    <strong> Note: </strong> Other Cost item is mandatory for proceeding to Payment.
                                </h4>
                            </div>
                            <ejs-grid
                                #assignmentDetailsGrid
                                [id]="transactionGridName"
                                *ngIf="ddlFetched"
                                [height]="180"
                                [rowHeight]="30"
                                [dataSource]="assignmentDetails"
                                [allowFiltering]="true"
                                [allowExcelExport]="true"
                                [allowSorting]="true"
                                [allowReordering]="true"
                                [showColumnChooser]="true"
                                [enablePersistence]="false"
                                [allowResizing]="true"
                                [editSettings]="{ allowEditing: isEditable, allowAdding: true, allowDeleting: true, mode: 'Normal' }"
                                [toolbar]="assignmentDetailGrid.toolbar"
                                [filterSettings]="{ type: 'Menu' }"
                                [query]="query"
                                (created)="onTransactionsGridCreated($event)"
                                (rowSelected)="onTransactionSelected($event)"
                                (rowDataBound)="onTransactionRowDataBound($event)"
                                (toolbarClick)="onAssignmentDetailsToolbarClick($event)"
                                (commandClick)="onAssignmentDetailsCommandClick($event)"
                                (actionBegin)="onAssignmentDetailsActionBegin($event)"
                                (actionComplete)="onTransactionGridActionComplete($event, assignmentDetailsGrid)"
                                (excelQueryCellInfo)="onExcelQueryCellInfo($event)"
                            >
                                <e-columns>
                                    <e-column
                                        *ngFor="let column of assignmentDetailGrid.columns;"
                                        [field]="column.field"
                                        [headerText]="column.headerText"
                                        [clipMode]="'EllipsisWithTooltip'"
                                        [type]="column.type"
                                        [width]="column.width"
                                        [edit]="column.edit"
                                        [editType]="column.editType"
                                        [foreignKeyValue]="column.foreignKeyValue"
                                        [foreignKeyField]="column.foreignKeyField"
                                        [dataSource]="column.dataSource"
                                        [isPrimaryKey]="column.isPrimaryKey || false"
                                        [format]="column.format ? column.format : null"
                                        [textAlign]="column.textAlign"
                                        [visible]="column.visible"
                                        [validationRules]="column.validationRules"
                                        [showInColumnChooser]="column.showInColumnChooser ?? true"
                                        [filter]="column.filter ? column.filter : { type: 'Menu'}"
                                        [commands]="column?.commands ? column.commands : null"
                                        [allowEditing]="column.allowEditing"
                                        [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                        <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                            <a href="javascript:" (click)="onConsignmentClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                                        </ng-template>
                                            <ng-template *ngIf="column.headerText === 'Reference Activity'" #headerTemplate let-data>
                                                <div>
                                                    Reference Activity <span class="required-asterik">*</span>
                                                </div>
                                            </ng-template>
                                            <ng-template *ngIf="column.headerText === 'Quantity'" #headerTemplate let-data>
                                                <div>
                                                    Quantity <span class="required-asterik">*</span>
                                                </div>
                                            </ng-template>
                                            <ng-template *ngIf="column.headerText === 'Price'" #headerTemplate let-data>
                                                <div>
                                                    Price <span class="required-asterik">*</span>
                                                </div>
                                            </ng-template>
                                            <ng-template *ngIf="column.field== 'itemCode'" #headerTemplate let-data>
                                                <div>
                                                    Item <span class="required-asterik">*</span>
                                                </div>
                                            </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header d-flex pl-0" data-toggle="collapse" href="#collapseDetails">
                <a class="card-link flex-fill">Details</a>
                <i class="fas fa-chevron-up"></i>
            </div>
            <div id="collapseDetails" class="collapse show">
                <div class="card-body pb-0">
                    <div class="row">
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Sales Amount ({{customerCurrency}}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.salesAmount | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Discount Amount ({{customerCurrency}}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.discountAmount | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Warranty Coverage ({{customerCurrency}}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.warrantyCoverage | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Contract Coverage ({{customerCurrency}}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.contractCoverage | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Total Amount ({{ customerCurrency }}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.totalAmount | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="details-amount">Tax ({{ customerCurrency }}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.tax | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 offset-3">
                            <div class="row field mb-3">
                                <div class="col-6">
                                    <label class="font-weight-bold details-amount">Invoice Amount ({{ customerCurrency }}):</label>
                                </div>
                                <div class="col-6">
                                    <b class="font-weight-bold"> {{ data.invoiceAmount | currency : customerCurrency}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-assignments-popup
    *ngIf="showAssignmentsPopup"
    [data]="selectedAssignment"
    [assignments]="assignments"
    [assignmentDetails]="assignmentDetails"
    [equipmentInfo]="equipmentInfoData"
    [soCreatedDate]="data.soCreatedDate"
    [referenceActivities]="referenceActivities"
    [reportedProblems]="reportedProblems"
    [solutions]="solutions"
    [priorities]="priorities"
    (onClose)="selectedAssignment = null; showAssignmentsPopup = false;"
    (onSave)="saveAssignment($event)"
></app-assignments-popup>

<app-assignment-details-popup
    *ngIf="showAssignmentDetailsPopup"
    [costTypes]="costTypes"
    [taxRates]="taxRates"
    [checkinTime]="checkinTime"
    [technicianId]="data?.technicianId"
    [assignments]="assignments"
    [assignmentDetails]="assignmentDetails"
    [loadedAssignmentItemDetails]="loadedAssignmentItemDetails"
    [equipmentInfo]="equipmentInfoData"
    [soCreatedDate]="data.soCreatedDate"
    [serviceOrderId]="data.serviceOrderId"
    [data]="selectedAssignmentDetail"
    [units]="units"
    [returnReasons]="returnReasons"
    [distanceZone]="distanceZone"
    [configurationId]="configurationId"
    [customerCode]="data.customerCode"
    (onClose)="showAssignmentDetailsPopup = false; selectedAssignmentDetail = null;"
    (onSave)="saveAssignmentDetail($event)"
></app-assignment-details-popup>

<ng-template #columnChooserAssignments>
    <app-column-chooser
        [columns]="assignmentGrid.columns"
        [grid]="assignmentsGrid"
        [showColumnChooser]="showAssignmentsColumnChooser"
    ></app-column-chooser>
</ng-template>

<ng-template #columnChooserAssignmentDetails>
    <app-column-chooser
        [columns]="assignmentDetailGrid.columns"
        [grid]="assignmentDetailsGrid"
        [showColumnChooser]="showAssignmentDetailsColumnChooser"
    ></app-column-chooser>
</ng-template>

<ng-template #assignmentTemplate let-data>
    <div class="e-list-item" [title]="data.description">{{data.description}}</div>
</ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [technicianId]="data?.technicianId"
    [serviceItemGroup]="serviceItemGroup"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>

<app-cart-checkout-popup
  *ngIf="showCartCheckoutPopup"
  [recievedItems]="fromExecutionTab"
  [serviceOrderId]="data.serviceOrderId"
></app-cart-checkout-popup>

<!--[recievedItems]="towardsCheckout"
  (onClose)="onCloseFromCheckout()"-->
