import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Invoice } from '../../models/payment-invoicing.model';
import { ServiceOrderService } from '../../services/service-order.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-barclay-payment',
  templateUrl: './barclay-payment.component.html',
})
export class BarclayPaymentComponent implements OnInit {

  @Input() data = null;
  @Input() customerData = null;
  @Input() invoice: Invoice = null;
  @Input() attachments = [];
  @Input() serviceOrderId = null;
  submitButton: HTMLElement;
  
  @ViewChild('myForm', {static: false}) myForm: ElementRef;

  USER: any;
  transactionData: {
      'serviceOrderId': any,
      'company': any,
      'amount': any,
      'orderDesc': any,
      'currencyCode': any,
      'customerEmail': any,
      'address': any,
      'address2': any,
      'city': any,
      'state': any,
      'zip': any,
      'deliveryFirstName': any,
      'deliveryLastName': any,
      'deliveryAddress': any,
      'deliveryAddress2': any,
      'deliveryEmail': any,
      'deliveryPhone': any,
      'deliveryCity': any,
      'deliveryState': any,
      'deliveryPostalCode': any,
      'billingName': any,
  }
  transactionInfo: any;
  barclaysFormURL: any;
  environment: any;
  
  constructor(
    private serviceOrderService: ServiceOrderService,
    private commonService: CommonService,
    private router: Router
  ) { 
    this.USER = commonService.USER;
    this.environment = environment;
  }

  ngOnInit(): void {
    this.setTransactionData();
  }

  setTransactionData(){
    this.transactionData = {
      'serviceOrderId': this.serviceOrderId,
      'company': this.USER.company,
      'amount': this.invoice.paymentAmount.toString(),
      'orderDesc': this.customerData.serviceOrderId,
      'currencyCode': this.customerData.customerCurrency,
      'customerEmail': this.customerData.email,
      'address': this.customerData.address,
      'address2': '',
      'city': this.customerData.city,
      'state': this.customerData.state,
      'zip': this.customerData.zipCode,
      'deliveryFirstName': '',
      'deliveryLastName': '',
      'deliveryAddress': this.customerData.address,
      'deliveryAddress2': '',
      'deliveryEmail': this.customerData.email,
      'deliveryPhone': this.customerData.customerPhoneNumber,
      'deliveryCity': this.customerData.city,
      'deliveryState': this.customerData.state,
      'deliveryPostalCode': this.customerData.zipCode,
      'billingName': this.customerData.customerDescription,
    }

    let amount = this.transactionData.amount;
    amount = amount*100;
    const num = +amount.toFixed(0);
    this.transactionData.amount = ''+num;
    this.serviceOrderService.AuthorizeCCPaymentForBarclays(this.transactionData)
    .subscribe((response: any) => {
      if(response.isSuccessful){
        this.transactionInfo = response.result;
        setTimeout(() => {
          this.submitButton = document.getElementById('barclay-form-submit');
          this.submitButton.click();
        }, 10);
        this.commonService.showNotification('success', response.message);
      } else {
        this.router.navigate([`/home/service-order`, this.transactionData.serviceOrderId, false, false]);
        this.commonService.showNotification('error', response.message);
      }
    },
    (error: HttpErrorResponse) => {
        this.router.navigate([`/home/service-order`, this.transactionData.serviceOrderId, false, false]);
        this.commonService.showNotification('error', error.message);
    });
  }
}
