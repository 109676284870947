import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
    CommandColumnService,
    EditService,
    FilterService,
    ResizeService,
    SortService
} from '@syncfusion/ej2-angular-grids';
import { MenuComponent, SelectEventArgs, SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-master-data',
    templateUrl: './master-data.component.html',
    styleUrls: [
        './master-data.component.scss',
        '../../../../../assets/scss/service-order.scss',
        '../../../../../assets/scss/popup.scss'
    ],
})
export class MasterDataComponent implements OnInit {

    pages = [
        {
            id: "Master_Data_Page_Addresses",
            pageName: "Addresses"
        },
        {
            id: "Master_Data_Page_Business_Partner",
            pageName: "Business Partner"
        },
        {
            id: "Master_Data_Page_Config_Top_Obj",
            pageName: "Configuration Top Objects"
        },
        {
            id: "Master_Data_Page_Configurations",
            pageName: "Configurations"
        },
        {
            id: "Master_Data_Page_Contract_Cost_Terms_Mat",
            pageName: "Contract Cost Terms Material"
        },
        {
            id: "Master_Data_Page_Contract_Coverage_Lines",
            pageName: "Contract Coverage Lines"
        },
        {
            id: "Master_Data_Page_Conversion_Factors",
            pageName: "Conversion Factors"
        },
        {
            id: "Master_Data_Page_Cost_Components",
            pageName: "Cost Components"
        },
        {
            id: "Master_Data_Page_Credit_Rating",
            pageName: "Credit Rating"
        },
        {
            id: "Master_Data_Page_Currencies",
            pageName: "Currencies"
        },
        {
            id: "Master_Data_Page_Currency_Rates",
            pageName: "Currency Rates"
        },
        {
            id: "Master_Data_Page_Distance_Zones",
            pageName: "Distance Zones"
        },
        {
            id: "Master_Data_Page_Equipment",
            pageName: "Equipment"
        },
        {
            id: "Master_Data_Page_ERP_Params",
            pageName: "ERP Parameters"
        },
        {
            id: "Master_Data_Page_General_Tasks",
            pageName: "General Tasks"
        },
        {
            id: "Master_Data_Page_Labour_Rates",
            pageName: "Labour Rates"
        },
        {
            id: "Master_Data_Page_Object",
            pageName: "Object"
        },
        {
            id: "Master_Data_Page_Object_Group",
            pageName: "Object Group"
        },
        {
            id: "Master_Data_Page_Payment_Method",
            pageName: "Payment Method"
        },
        {
            id: "Master_Data_Page_Priority",
            pageName: "Priority"
        },
        {
            id: "Master_Data_Page_Problems",
            pageName: "Problems"
        },
        {
            id: "Master_Data_Page_Problems_Obj_Group",
            pageName: "Problems By Object Group"
        },
        {
            id: "Master_Data_Page_Reasons",
            pageName: "Reasons"
        },
        {
            id: "Master_Data_Page_Ref_Activities",
            pageName: "Reference Activities"
        },
        {
            id: "Master_Data_Page_Service_Cars",
            pageName: "Service Cars"
        },
        {
            id: "Master_Data_Page_Service_Centers",
            pageName: "Service Centers"
        },
        {
            id: "Master_Data_Page_Service_Employees",
            pageName: "Service Employees"
        },
        {
            id: "Master_Data_Page_Service_Items",
            pageName: "Service items"
        },
        {
            id: "Master_Data_Page_Service_Types",
            pageName: "Service Types"
        },
        {
            id: "Master_Data_Page_Signals",
            pageName: "Signals"
        },
        {
            id: "Master_Data_Page_Sold_To_BP",
            pageName: "Sold To BP"
        },
        {
            id: "Master_Data_Page_Solutions",
            pageName: "Solutions"
        },
        {
            id: "Master_Data_Page_Solutions_Obj_Group",
            pageName: "Solutions By Object Group"
        },
        {
            id: "Master_Data_Page_Tasks",
            pageName: "Tasks"
        },
        {
            id: "Master_Data_Page_Tax_Rates",
            pageName: "Tax Rates"
        },
        {
            id: "Master_Data_Page_Templates",
            pageName: "Templates"
        },
        {
            id: "Master_Data_Page_Units",
            pageName: "Units"
        },
        {
            id: "Master_Data_Page_Warehouse",
            pageName: "Warehouse"
        },
        {
            id: "Master_Data_Page_Warranty_Coverage_Lines",
            pageName: "Warranty Coverage Lines"
        },
        {
            id: "Master_Data_Page_Zone_Class",
            pageName: "Zone Class"
        }
    ];

    public sidebarMenuInstance: SidebarComponent;
    @ViewChild('sidebarMenuInstance') mainMenu: MenuComponent;

    selectedPage: any;
    viewLoader: boolean = true;
    selectedPageName: string
    AccountMenuItem: { text: string; items: { id: string; text: string; }[]; }[];

    masterDataMenuItems = [
        { id: '/home/dashboard', text: 'Dashboard', iconCss: 'fas fa-tachometer-alt', items: [] },
        { id: '/master-data/addresses', pageId: "Master_Data_Page_Addresses", text: "Addresses", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/business-partner', pageId: 'Master_Data_Page_Business_Partner', text: "Business Partner", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/configuration-top-objects', pageId: 'Master_Data_Page_Config_Top_Obj', text: "Configuration Top Objects", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/configurations', pageId: 'Master_Data_Page_Configurations', text: "Configurations", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/contract-cost-terms-material', pageId: 'Master_Data_Page_Contract_Cost_Terms_Mat', text: "Contract Cost Terms Material", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/contract-coverage-lines', pageId: 'Master_Data_Page_Contract_Coverage_Lines', text: "Contract Coverage Lines", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/conversion-factors', pageId: 'Master_Data_Page_Conversion_Factors', text: "Conversion Factors", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/cost-components', pageId: 'Master_Data_Page_Cost_Components', text: "Cost Components", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/credit-rating', pageId: 'Master_Data_Page_Credit_Rating', text: "Credit Rating", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/currencies', pageId: 'Master_Data_Page_Currencies', text: "Currencies", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/currency-rates', pageId: 'Master_Data_Page_Currency_Rates', text: "Currency Rates", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/distance-zones', pageId: 'Master_Data_Page_Distance_Zones', text: "Distance Zones", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/equipment', pageId: 'Master_Data_Page_Equipment', text: "Equipment", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/erp-parameters', pageId: 'Master_Data_Page_ERP_Params', text: "ERP Parameters", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/general-tasks', pageId: 'Master_Data_Page_General_Tasks', text: "General Tasks", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/labour-rates', pageId: 'Master_Data_Page_Labour_Rates', text: "Labour Rates", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/object', pageId: 'Master_Data_Page_Object', text: "Object", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/object-group', pageId: 'Master_Data_Page_Object_Group', text: "Object Group", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/payment-method', pageId: 'Master_Data_Page_Payment_Method', text: "Payment Method", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/priority', pageId: 'Master_Data_Page_Priority', text: "Priority", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/problems', pageId: 'Master_Data_Page_Problems', text: "Problems", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/problems-by-object-group', pageId: 'Master_Data_Page_Problems_Obj_Group', text: "Problems By Object Group", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/reasons', pageId: 'Master_Data_Page_Reasons', text: "Reasons", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/reference-activities', pageId: 'Master_Data_Page_Ref_Activities', text: "Reference Activities", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/service-cars', pageId: 'Master_Data_Page_Service_Cars', text: "Service Cars", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/service-centers', pageId: 'Master_Data_Page_Service_Centers', text: "Service Centers", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/service-employees', pageId: 'Master_Data_Page_Service_Employees', text: "Service Employees", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/service-items', pageId: 'Master_Data_Page_Service_Items', text: "Service items", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/service-types', pageId: 'Master_Data_Page_Service_Types', text: "Service Types", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/signals', pageId: 'Master_Data_Page_Signals', text: "Signals", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/sold-to-bp', pageId: 'Master_Data_Page_Sold_To_BP', text: "Sold To BP", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/solutions', pageId: 'Master_Data_Page_Solutions', text: "Solutions", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/solutions-by-object-group', pageId: 'Master_Data_Page_Solutions_Obj_Group', text: "Solutions By Object Group", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/tasks', pageId: 'Master_Data_Page_Tasks', text: "Tasks", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/tax-rates', pageId: 'Master_Data_Page_Tax_Rates', text: "Tax Rates", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/templates', pageId: 'Master_Data_Page_Templates', text: "Templates", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/units', pageId: 'Master_Data_Page_Units', text: "Units", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/warehouse', pageId: 'Master_Data_Page_Warehouse', text: "Warehouse", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/warranty-coverage-lines', pageId: 'Master_Data_Page_Warranty_Coverage_Lines', text: "Warranty Coverage Lines", iconCss: 'fas fa-database', items: [] },
        { id: '/master-data/zone-class', pageId: 'Master_Data_Page_Zone_Class', text: "Zone Class", iconCss: 'fas fa-database', items: [] }
    ]

    public menuItems: Object = {
        id: 'id',
        text: 'text',
        child: 'subChild',
        iconCss: 'iconCss',
        dataSource: this.masterDataMenuItems
    };

    constructor(
        private router: Router,
        private commonService: CommonService,
        private auth: AuthService,
    ) {
        const claims = JSON.parse(localStorage.getItem('roleClaims')).filter(claim => claim.RuleName == 'Master_Data_Page');
        this.masterDataMenuItems = this.masterDataMenuItems.filter(page => claims.some(claim => claim.RuleAction === page.pageId && claim.Visible === true));
    }

    ngOnInit(): void {
        this.selectedPage = this.pages[0];
        this.selectPage(this.selectedPage);
        this.AccountMenuItem = [
            {
                text: this.commonService.USER.firstName + ' ' + this.commonService.USER.lastName,
                items: [
                    {
                        id: '/home/my-profile',
                        text: 'My Profile'
                    },
                    {
                        id: '/app/logout',
                        text: 'Sign out'
                    }
                ]
            }
        ];
    }

    selectPage(page) {
        this.selectedPage = page;
        this.selectedPageName = page.pageName.trim().replace(/\s+/g, '-').toLowerCase();
        this.router.navigate(['master-data/' + this.selectedPageName]);
        this.viewLoader = false;
    }

    onUserMenuClick(args: any) {
        const item = args.item.properties;
        if (item.text === 'My Profile') {
            this.router.navigate([item.id])
        }
        if (item.text === 'Sign out') {
            this.auth.signOut(this.commonService.USER.role === UserRoles.isc);
        }
    }

    onMenuItemSelect(args: any) {
        const id = args?.node?.dataset?.uid;
        if (!['inventory-transfer', 'inventory-view', 'maps', 'inventory-orders', 'service-order'].includes(id)) {
            this.router.navigate([id]);
        } else {
            if (!this.sidebarMenuInstance.isOpen) {
                this.sidebarMenuInstance.toggle();
            }
        }
    }
}
