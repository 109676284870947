<div 
    class="modal fade show" 
    id="payment-selection-modal" 
    tabindex="-1" 
    aria-labelledby="payment-selection-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header pt-1 pb-2">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Calibration</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3">  
                <div class="row">
                    <div class="col">
                        <div class="field mb-3 position-relative"> 
                            <label>Calibration Types:</label>
                            <ejs-dropdownlist 
                                #paymentTypeDD
                                [placeholder]="'Select Calibration Type'" 
                                [dataSource]="calibrationTypes"
                                [fields]="{ text: 'text', value: 'value' }"
                                [allowFiltering]="true"
                                [filterType]="'contains'"
                                (created)="showLoader = false"
                                (change)="calibrationChanged($event);"
                            ></ejs-dropdownlist>
                            <!-- <div class="field-error" *ngIf="validationApplied && !paymentTypeDD.value">Please select a payment method!</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save()" [disabled]="!calibrationType.value ? true : false">Ok</button>
            </div>
        </div>
    </div>
</div>
