import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Attachment } from '../../models/attachment.model';
import { CommonService } from '../../services/common.service';
import { Companies, NotificationTypes } from '../../models/app.model';
import { formatDate } from '@angular/common';
import { returnOrderTypes } from 'src/app/modules/home/models/orders.model';
import { MaintenanceDashboardService } from 'src/app/modules/home/modules/maintenance-dashboard/services/maintenance-dashboard.service';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss']
})
export class EmailPopupComponent implements OnInit {

  @Input() receiverEmail: string = null;
  @Input() emailSubject: any = null;
  @Input() checklistType: any = null;
  @Input() urlLink: string = null;
  @Input() isAttachment: boolean = false;
  @Input() attachment: Attachment;
  @Input() isInvoice: boolean = false;
  @Input() isChecklist: boolean = false;
  @Input() isCalibrationCertificate: boolean = false;
  @Input() isEstimateInvoice: boolean = false;
  @Input() isReturnOrderStatement: boolean = false;
  @Input() exportErrorPodEmail: any;
  @Input() importErrorPodEmail: any;
  @Input() sendOtherDocs: boolean = false;
  @Input() returnOrderId: string;
  @Input() module: string;
  @Input() serviceOrderId: string;
  @Input() serviceOrderIdLN: string;
  @Input() checklistCertificate: string;
  @Input() technicianName: string;
  @Input() ccEmail: string = null;
  @ViewChild('userForm') form: any;
  @ViewChild('emailFormHeader') emailFormHeader: any;

  bccEmail: string = null;
  validationApplied: boolean = false;
  emailValid: boolean = false;
  ccEmailValid: boolean = false;
  showLoader: boolean = false;

  @Output() onClose = new EventEmitter();
  // @Output() emailSendStatus = new EventEmitter();

  emailBody: string;
  isValidated: boolean = false;

  constructor(
    private commonService: CommonService,
    private maintenanceService:MaintenanceDashboardService
  ) {
    this.ccEmail = this.commonService.USER.email;
  }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    let EmailBodyForKL = `Dear Snap-on Customer,
    <br><br>Please find your calibration certificate/repair order attached.
    <br><br>At Snap-on we pride ourselves on ensuring the highest levels of customer service, please can you complete the below customer satisfaction survey that will take less than 2 minutes. As a thank you for completing the survey you will be entered in a monthly draw to win £100.00 in Snap-on tool vouchers. Thank you in advance.
    <br><br><a href="https://www.surveymonkey.co.uk/r/Snap-onAftercare">https://www.surveymonkey.co.uk/r/Snap-onAftercare</a>
    <br><br><img src="https://dev-rims.inavista.com/assets/img/shared/KL-QR.png" alt="KL-QR" width="300" height="300">
    <br><br>If you require any further assistance, please contact the team on the email or telephone number below.
    <br><br>Many thanks
    <br><br>Snap-on Service Team
    <br><br>Tel: 01553 692422
    <br>E: serviceuk@snapon.com

    <br><br>PRIVACY: The information transmitted is intended only for the person or entity to which it is addressed and may contain confidential.
    <br>If you received this in error, please contact the sender and delete the material from any computer.
    <br>Thank you. Snap-on Denney Road, King's Lynn PE30 4HG.
    <br>To read our privacy policy, <a href="https://www.snapon.com/EN/UK/Diagnostics/OffNav/Privacy-Policy" target="_blank">click here</a>
    <br>Snap-on may monitor email traffic data and also the content of email for the purposes of security and training.`;

    if (this.isAttachment) {
      if (this.commonService.USER.company === Companies.kingslynn) {
        this.emailBody = EmailBodyForKL;
      } else {
        if (this.isReturnOrderStatement) {
          this.emailBody = this.emailBodyForReturnOrder();
        } else {
          this.emailBody = 'Hi, <br>Please see attachments.';
        }
      }
    } else if (this.isInvoice) {
      if (this.commonService.USER.company === Companies.conway) {
        this.emailBody = `Hi,<br>Attached is the Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId} Invoice`;
      }
      else if (this.commonService.USER.company === Companies.kingslynn) {
        this.emailBody = EmailBodyForKL;
      }
    } else if (this.isChecklist) {
      if (this.commonService.USER.company === Companies.kingslynn) {
        this.emailBody = EmailBodyForKL;
      } else {
        this.emailBody = `Hi,<br>Attached is the Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId} ${this.checklistType} Checklist`;
      }
    } else if (this.isEstimateInvoice) {
      this.emailBody = `Hi,<br>Attached is the Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId} Estimate Invoice`;
    } else if (this.isCalibrationCertificate) {
      if (this.commonService.USER.company === Companies.kingslynn) {
        this.emailBody = EmailBodyForKL;
      } else {
        this.emailBody = `Hi,<br>Attached is the Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId} Calibration Certificate`;
      }
    } else if (this.exportErrorPodEmail) {
      this.emailBody = this.exportErrorPodEmail;
    } else if (this.importErrorPodEmail) {
      this.emailBody = this.importErrorPodEmail;
    } else if (this.isReturnOrderStatement) {
      this.emailBody = this.emailBodyForReturnOrder();
    } else {
      this.emailBody = 'Hi,';
    }
  }

  exportErrorPodEmailTemplate() {
    let currentDate = formatDate(new Date(), "mediumDate", 'en');
    let orderType = this.module === returnOrderTypes.coreReturnOrder ? 'core' : 'damaged';
    return;
  }

  emailBodyForReturnOrder() {
    let currentDate = formatDate(new Date(), "mediumDate", 'en');
    let orderType = this.module === returnOrderTypes.coreReturnOrder ? 'core' : 'damaged';
    return `Hello,<br><br>Please find attached the requested ${orderType} return order ${this.returnOrderId} created by ${this.technicianName} on ${currentDate}.<br>Thank you.`;
  }

  validateForSave() {
    if (this.ccEmail) {
      return this.receiverEmail && this.emailValid && this.ccEmail && this.ccEmailValid;
    } else {
      return this.receiverEmail && this.emailValid;
    }
  }

  sendEmail() {
    this.validationApplied = true;
    this.isValidated = this.emailFormHeader.validate();
    if (this.isValidated) {
      let data = {
        "Recipient": this.emailFormHeader.getReceiverEmails(),
        "CC": this.emailFormHeader.getCCReceiverEmails(),
        "BlindCarbonCopy": this.bccEmail,
        "Subject": this.emailFormHeader.getEmailSubject(),
        "Body": this.emailBody
      }

      data['serviceOrderId'] = this.serviceOrderId;
      data['sendOtherDocs'] = this.sendOtherDocs;

      if (this.isInvoice && this.serviceOrderId && this.attachment) {
        data['attachment'] = this.attachment;
      } else if (this.isChecklist && this.urlLink) {
        data['checkListCertificateUrl'] = this.urlLink;
      } else if (this.isReturnOrderStatement && this.urlLink) {
        data['attachment'] = this.attachment;
        if (this.module === returnOrderTypes.coreReturnOrder) {
          data['coreReturnOrderId'] = this.returnOrderId;
          data['coreReturnReportUrl'] = this.urlLink;
        } else if (this.module === returnOrderTypes.damagedReturnOrder) {
          data['damagedReturnOrderId'] = this.returnOrderId;
          data['damagedReportUrl'] = this.urlLink;
        }
      } else if (this.isEstimateInvoice && this.attachment) {
        data['attachment'] = this.attachment;
      } else if (this.isCalibrationCertificate && this.urlLink) {
        data['calibrationCertificateUrl'] = this.urlLink;
      } else if (this.isAttachment && this.attachment) {
        data['attachment'] = this.attachment;
      }

      this.showLoader = true;
      let sendDynamicEmail;
      if (this.isInvoice) {
        sendDynamicEmail = this.commonService.sendInvoiceEmail.bind(this.commonService);
      } else if (this.isAttachment || this.isEstimateInvoice) {
        sendDynamicEmail = this.commonService.sendAttachmentEmail.bind(this.commonService)
      } else if (this.isChecklist) {
        sendDynamicEmail = this.commonService.SendCheckListCertificateEmail.bind(this.commonService)
      } else if (this.isCalibrationCertificate) {
        sendDynamicEmail = this.commonService.sendCalibrationCertificateEmail.bind(this.commonService)
      } else if (this.isReturnOrderStatement && this.module === returnOrderTypes.coreReturnOrder) {
        sendDynamicEmail = this.commonService.sendReturnOrderStatementEmail.bind(this.commonService)
      } else if (this.isReturnOrderStatement && this.module === returnOrderTypes.damagedReturnOrder) {
        sendDynamicEmail = this.commonService.sendDamagedReturnReportEmail.bind(this.commonService)
      } else {
        sendDynamicEmail = this.commonService.sendEmail.bind(this.commonService)
      }

      sendDynamicEmail(data).subscribe(res => {
        if (res) {
          this.commonService.showNotification('success', 'Email sent successfuly');
          this.showLoader = false;
          if(this.exportErrorPodEmail){
            this.maintenanceService.updateIsEmailSent.next(true);
          } else if(this.importErrorPodEmail){
            this.maintenanceService.updateIsEmailSentForImportGrid.next(true);
          }
          this.close();

        } else {
          this.commonService.showNotification('error', 'Something went wrong. Please, try again.');
          this.showLoader = false;
        }

      });
    } else {
      this.commonService.showNotification(NotificationTypes.error, 'Please check your inputs')
    }

  }

  close() {
    this.onClose.emit();
    this.emailSubject = null;
  }

}
