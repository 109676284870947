<div
    class="modal fade show"
    id="pause-service-order"
    tabindex="-1"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id flex-fill">
                        <h4 class="modal-title mb-0 item-id">Update Service Order {{ data.rowData?.serviceOrderId }}</h4>
                    </div>

                    <div class="btns-header">
                        <button
                            class="header-icon close p-0 m-0"
                            (click)="closeNTEPopup.emit('close')"
                            aria-label="Close"
                        ><i class="fas fa-times"
                                title="Close Popup"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="field mb-2 position-relative">
                            <form #nteUpdateForm="ngForm">
                                <label>NTE:</label>
                                <span class="required-asterik">*</span>
                                <ejs-numerictextbox
                                    name="NTE"
                                    [(ngModel)]="nteAmount"
                                    [showSpinButton]="false"
                                    [format]="'#'"
                                    required
                                    ></ejs-numerictextbox>
                                    <ng-container *ngIf="nteUpdateForm.controls['NTE']">
                                        <div *ngIf="nteUpdateForm.controls['NTE'].touched && nteUpdateForm.controls['NTE'].invalid">
                                            <div class="field-error" *ngIf="nteUpdateForm.controls['NTE'].errors.required && nteAmount != 0">NTE amount is required.</div>
                                          </div>
                                        <div class="field-error" *ngIf="nteAmount == 0">NTE amount cannot be zero.</div>
                                    </ng-container>
                            </form>
                      </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="closeNTEPopup.emit('close')">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="saveNTEAmount()">Save</button>
            </div>
        </div>
    </div>
</div>
