<div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title m-0" id="exampleModalLabel">Modal title</h4>
                <div class="btns-header">
                    <button type="button" class="close p-0 m-0" (click)="close()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <button type="button" class="close expand-modal p-0 m-0">
                        <i class="fas fa-expand-alt mr-2" title="Enter Full Screen"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row">
                                    <label class="control-label col pt-2">Technician:<span
                                            class="mandatory mandatory-resource risk risk-detail devplan">*</span></label>
                                    <div class="col no-padding no-border">
                                        <span
                                            class="e-input-group e-control-wrapper e-ddl e-lib e-keyboard e-valid-input"
                                            tabindex="0" aria-disabled="false" aria-owns="resource_options"
                                            role="listbox" aria-haspopup="true" aria-expanded="false"
                                            aria-activedescendant="null" aria-labelledby="resource_hidden"
                                            aria-describedby="" style="width: 100%;"><select aria-hidden="true"
                                                tabindex="-1" class="e-ddl-hidden" name="resource" id="resource_hidden">
                                                <option selected="" value="7">Shireesha Veerla </option>
                                            </select>
                                            <div class="sf-ddl e-control e-dropdownlist e-lib" id="resource"
                                                style="display: none;" aria-disabled="false" tabindex="-1"></div><input
                                                role="textbox" type="text" tabindex="-1" class="e-input" readonly=""
                                                placeholder="Select Resource" aria-placeholder="Select Resource"
                                                aria-label="Shireesha Veerla " value="Lorem Ipsem"><span
                                                class="e-input-group-icon e-ddl-icon e-search-icon"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <label class="control-label col pt-2">Status: <span
                                            class="mandatory risk risk-detail project-plan">*</span></label>
                                    <div class="col no-padding">
                                        <span
                                            class="e-input-group e-control-wrapper e-ddl e-lib e-keyboard e-valid-input"
                                            tabindex="0" aria-disabled="false" aria-owns="status_options" role="listbox"
                                            aria-haspopup="true" aria-expanded="false" aria-activedescendant="null"
                                            aria-labelledby="status_hidden" aria-describedby="status"
                                            style="width: 100%;"><select aria-hidden="true" tabindex="-1"
                                                class="e-ddl-hidden" name="status" id="status_hidden" required="">
                                                <option selected="" value="85">In Progress</option>
                                            </select><input type="text"
                                                class="sf-ddl e-control e-dropdownlist e-lib e-input" id="status"
                                                required="" role="textbox" readonly="" placeholder="Select Status"
                                                aria-placeholder="Select Status" style="" aria-disabled="false"
                                                aria-label="In Progress" tabindex="-1" value="In Progress"><span
                                                class="e-input-group-icon e-ddl-icon e-search-icon"></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a class="nav-link position-relative" data-toggle="tab" href="#history-tab"
                                            title="History">
                                            <i class="fas fa-comment"></i>
                                            <div class="counter">3</div>
                                        </a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#attachments-tab"
                                            title="Attachments" aria-expanded="true"><i
                                                class="fas fa-file-upload"></i></a></li>
                                    <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#history-tab"
                                            title="History"><i class="fas fa-history"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex vertical-tab mt-4">
                            <div class="sidebar" [ngClass]="{'collapsed': sidebarCollapsed}">
                                <span class="btn-collapse" (click)="sidebarCollapsed = !sidebarCollapsed"></span>
                                <ul class="nav nav-tabs">
                                    <li 
                                        *ngFor="let section of pageSections"
                                        role="presentation" 
                                        class="nav-item"
                                        [ngClass]="{'active': section.isActive}"
                                    >
                                        <a 
                                            href="javascript:"
                                            class="nav-link" 
                                            data-toggle="tab"
                                            (click)="selectSection(section.id)"
                                        >
                                            <i class="fas {{section.icon}} mr-2"></i>{{section.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content flex-fill pl-4">
                                <ng-container *ngFor="let section of pageSections">
                                    <div
                                        *ngIf="section.isActive"
                                        class="tab-pane active"
                                        >
                                        <div class="tabbable-line">
                                            <ul class="nav nav-tabs">
                                                <li 
                                                    *ngFor="let tab of section.tabs"
                                                    class="nav-item"
                                                    [ngClass]="{'active': tab.isActive}"
                                                    (click)="selectTab(section, tab)"
                                                >
                                                    <a 
                                                        class="nav-link" 
                                                        href="javascript:"
                                                        aria-expanded="false"
                                                    >{{tab.name}}</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content pt-3">
                                                <ng-container *ngFor="let tab of section.tabs" >
                                                    <div
                                                        *ngIf="tab.isActive"
                                                        class="tab-pane active"
                                                    >
                                                        <div class="row">
                                                            <div class="col">
                                                                <app-page-structure
                                                                    [layoutId]="tab.id"
                                                                    [pageStructure]="pageStructure"
                                                                ></app-page-structure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" id="">Reset</button>
                <div class="btn-group dropdown-buttons-container" id="">
                    <a role="button" class="btn btn-sm btn-success save-and-close" id="">Save &amp; Close</a>
                    <a role="button" class="btn btn-sm btn-success dropdown-toggle up" data-toggle="dropdown"
                        aria-expanded="false">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li><a href="javascript:" class="save dropdown-item" id="">Save</a></li>
                    </ul>
                </div>
                <button type="button" class="btn btn-sm btn-success mr-1" id="">Generate Invoice</button>
            </div>
        </div>
    </div>
</div>