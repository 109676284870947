import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CalibrationService } from 'src/app/modules/home/modules/calibrations/services/calibration.service';
import { Input } from '@angular/core';

@Component({
  selector: 'app-calibration-selection-popup',
  templateUrl: './calibration-selection-popup.component.html',
  styleUrls: ['./calibration-selection-popup.component.scss']
})
export class CalibrationSelectionPopupComponent implements OnInit {

    calibrationType: any = {};
    showLoader: boolean = false;
    @ViewChild('calibrationDDL') paymentType: DropDownListComponent;

    @Input() itemGroup: string;
    @Input() callGroup: string;
    @Input() calibrations: any;
    @Input() calibrationTypes: any;
    @Output() onClose = new EventEmitter();

  constructor(
    private calibrationService: CalibrationService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
  }

  calibrationChanged(event){
    this.calibrationType.value = event.itemData.value;
    this.calibrationType.text = event.itemData.text;
    this.calibrationType.pageType = event.itemData.pageType;
  }

  save(){
    this.onClose.emit(this.calibrationType);
  }

  close(){
    this.calibrationType = {};
    this.onClose.emit();
  }

}
