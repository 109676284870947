import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class PageService {

    constructor(
        private http: HttpClient
    ) { }

    savePage(page) {
        return this.http.post(`${BASE_URL}Page/AddPage`, page);
    }

    updatePage(page) {
        return this.http.post(`${BASE_URL}Page/UpdatePage`, page);
    }

    getPages(pageType?: string) {
        return this.http.get(`${BASE_URL}Page/GetPages${pageType ? '?pageType='+pageType : ''}`);
    }

    getPageById(id?: string, pageType?: string) {
        const params = id ? `pageId=${id}` : pageType ? `pageType=${pageType}`: '';
        return this.http.get(`${BASE_URL}Page/GetPageById?${params}`);
    }

    getPageAPIDetails() {
        return this.http.get(`${BASE_URL}Page/GetPageAPIDetails`);
    }
    
    getAdjustmentOrderById(id: string) {
        return this.http.get(`${BASE_URL}ShipmentOrder/GetAdjustmentOrderById?adjustmentOrderId=${id}`);
    }

}
