import { Component, Input, OnInit } from '@angular/core';
import { PageElement } from 'src/app/shared/models/page.model';
import { ElementTypes } from '../../models/popup.model';

@Component({
    selector: 'app-popup-layout',
    templateUrl: './popup-layout.component.html',
    styleUrls: ['./popup-layout.component.scss']
})
export class PopupLayoutComponent implements OnInit {

    @Input() elements: PageElement[] = [];
    
    elementTypes = ElementTypes;

    constructor() { }

    ngOnInit(): void {
    }

}
