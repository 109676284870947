
<div class="row">
    <div class="col">
        <label>{{ label }}</label>
        <hr class="mt-0 mb-2"/>

        <div *ngIf="!showRte" [innerHTML] = "notes || 'Write description here..'"
            class="rte-placeholder border-0 w-100 h-100" 
            (click)="showRte = true" 
        ></div>

        <ejs-richtexteditor #notesRte
            *ngIf="showRte"
            id='defaultRTE' 
            [enableResize]="false" 
            height='200px'
            [placeholder]="'Write description here..'"
            [value]="notes"
            [insertImageSettings]='insertImageSettings' 
            (change)="notes = notesRte.value; notesChanged.emit(notes)"
            (blur)="blur($event)"
        ></ejs-richtexteditor>
    </div>
</div>