<div class="control-section">
    <div id="wrapper">
        <div class="col-lg-12 col-sm-12 col-md-12" id="sidebar-section">
            <!--header-section  declaration -->
            <div class="header-section dock-menu" id="header">
                <ul class="header-list">
                    <li id="hamburger" class="icon-menu icon list" (click)="openClick()"></li>
                    <li id="snapon-logo" class="icon-menu icon list float-left">
                        <img src="../../../assets/img/shared/RIMS-logo.png" alt="Snapon Logo"/>
                    </li>
                    <li class="right-header list">
                        <div [ngClass]="{'flex' : dashboardEnabled}">
                            <div *ngIf="dashboardEnabled" class=" text-right" id="dashboard-buttons">
                                <div class="btn-group" role="group" aria-label="Dashboard layout controls">
                                    <button type="button" class="btn btn-secondary btn-sm" title="Reset to default" (click)='resetDashboardLayout()'><i class="fas fa-sync"></i></button>
                                    <button type="button" class="btn btn-secondary btn-sm" *ngIf="!editLayout" (click)='editDashboardLayout()' title="Edit"><i class="fas fas fa-cog"></i></button>
                                    <button type="button" class="btn btn-primary btn-sm" style="margin-left: 2px; margin-right: 3px;" *ngIf="editLayout" (click)='setOpenDashboardPanelDialog()' title="Add/Remove Panels">Add/Remove</button>
                                    <button type="button" class="btn btn-primary btn-sm" *ngIf="editLayout" (click)='saveDashboardLayout()' title="Save layout setting">Save</button>
                                </div>
                            </div>

                            <div class="horizontal-menu">
                                <!-- Menu element declaration -->
                                <ejs-menu 
                                    [items]='AccountMenuItem' 
                                    cssClass='dock-menu'
                                    (select)="onUserMenuClick($event)"
                                ></ejs-menu>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!--end of header-section  declaration -->
            <!-- sidebar element declaration -->
            <ejs-sidebar #sidebarMenuInstance 
                id="sidebar-menu" 
                class="dock-menu" 
                [enableDock]='enableDock'
                [enableGestures]="false"
                [width]='width' 
                [dockSize]='dockSize' 
                [mediaQuery]='mediaQuery' 
                [target]='target' 
                (change)='sidebarToggle($event)' 
                
            >
                <div class="main-menu" class="treeViewClass">
                    <!-- <p class="main-menu-header">MAIN</p> -->
                    <!-- Menu element declaration -->
                    <ejs-treeview
                    [fields]='menuItems'
                    [expandOn]="'Click'"
                    orientation='Vertical'
                    (nodeClicked)="onMenuItemSelect($event)"
                    >
                    </ejs-treeview>
                </div>
            </ejs-sidebar>



            <!-- end of sidebar element -->

            <!-- main-content declaration -->
            <div class="main-content" id="maintext">
                <div class="sidebar-menu-content">
                    <router-outlet *ngIf="commonService.USER; else loader"></router-outlet>
                    <ng-template #loader>
                        <app-loader></app-loader>
                    </ng-template>
                </div>
            </div>
            <!-- end of main-content -->
        </div>
    </div>
</div>

<app-cycle-counts-exception-popup
    (onClose)="onCloseCycleCountExceptionPopup()"
    [exceptionPopupData]="exceptionPopupData"
    *ngIf="showCycleCountExceptionPopup">
</app-cycle-counts-exception-popup>

<div class="comments-section" *ngIf="commonService.globalComments.showComments">
    <div class="comments-container" #commentsPopup>
        <app-comments
            [moduleId]="commonService.globalComments.moduleId"
            [module]="commonService.globalComments.module"
            (commentUpdated)="commonService.globalComments.grid.refresh()"
            [isIscModule]="commonService.globalComments.isIscModule"
            (onClose)="commonService.globalComments.showComments = false;"
        ></app-comments>
    </div>
</div>