import { ToolbarItems } from "@syncfusion/ej2-grids";

export enum ElementTypes {
    text = 'textbox',
    number = 'number',
    date = 'datepicker',
    datetime = 'datetimepicker',
    dropdown = 'dropdown',
    grid = 'grid',
    treegrid = 'treegrid'
}


export interface PageSection {
    id: number;
    type: string;
    name: string;
    icon: string;
    isActive: boolean;
    structure: PageElement[];
}

export interface Tab {
    id: number;
    name: string;
    isActive: boolean; 
    structure: PageElement[];
}

export interface PageElement {
    id: number;
    label: string;
    type: string;
    key: string;
    // FieldLayoutDefaults
    sizeX: number;
    sizeY: number;
    row: number;
    col: number;
    minSizeX: number;
    minSizeY: number;
    value?: any;
    readonly?: boolean;
    required?: boolean;
    rows?: number;

    placeholder?: string;
    min?: number;
    max?: number;
    decimals?: number;
    name?: string;
    dataSource?: any;
    api?: string;
    allowFiltering?: boolean;
    allowEditing?: boolean;
    allowPaging?: boolean;
    filterSettings?: Object;
    allowSorting?: boolean;
    allowActions?: boolean;
    showToolbar?: boolean;
    toolbar?: ToolbarItems[];
    columns?: any;
    click?: any;
}