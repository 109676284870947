import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExcelExportProperties, ExcelExportService, FilterService, GridComponent, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { CommonService } from 'src/app/shared/services/common.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { getExcelDataSource, gridActionBeginForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';

@Component({
    selector: 'app-links-tab',
    templateUrl: './links-tab.component.html',
    styleUrls: ['./links-tab.component.scss'],
    providers: [
        ToolbarService,
        FilterService,
        SortService,
        ExcelExportService
    ]
})
export class LinksTabComponent implements OnInit {
    showColumnChooser: boolean = false;
    gridColumns: any;
    pageSettings: Object;
    @ViewChild('linksGrid') linksGrid: GridComponent;
    @ViewChild('serviceOrderLinksColumnChooser') columnChooser;
    @Input() links;

    constructor(
        private commonService: CommonService
    ) { }

    ngOnInit(): void {
        this.initGrid();
        this.pageSettings = { pageSizes: true, pageCount: 4 };
    }

    initGrid() {
        this.gridColumns = this.getGridColumns();
    }

    onGridCreated() {
    this.linksGrid.toolbar = [
    { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search', 
    { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.linksGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        this.linksGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
    updateFilterIcon({requestType: 'filtering'}, this.linksGrid);
    }

    getGridColumns() {
        return [
            { field: 'serialNumber', headerText: 'Sr. No', textAlign: 'Left', type: 'Number', allowFiltering: false, allowSorting: false, width: 90, tree: true },
            { field: 'orderNumber', headerText: 'Order No.', textAlign: 'Left', type: 'Number', disableHtmlEncode: false, width: 150, allowFiltering: true, isPrimaryKey: true },
            { field: 'consignment', headerText: 'Consignment' },
            { field: 'orderDate', headerText: 'Order Date', format: { type:'date', format: 'd MMM, y'}, width: 160 },
            { field: 'status', headerText: 'Status', filter: {type: 'CheckBox'}, width: 200 },
            { field: 'estimatedArrival', headerText: 'Estimated Arrival', format: { type:'date', format: 'd MMM, y'}, width: 180 }
        ]
    }

    onGridActionBegin(args, grid?) {
        gridActionBeginForFiltering(args, grid);
    }

    onGridActionComplete(args, grid) {
        updateFilterIcon(args, grid);
    }

    onToolbarClick(args, grid) {
        if ( args.item.id === 'grid_excelexport') {
            const dataSource = getExcelDataSource(grid);
            let excelExportProperties: ExcelExportProperties = {
                dataSource,
                hierarchyExportMode: 'Expanded',
                theme: {
                    header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
                },
                fileName: `Service Orders Links (${getCurrentDate()}).xlsx`
            };
            grid.excelExport(excelExportProperties);
          } else if ( args.item.id === 'column-chooser' ) {
            // this.showColumnChooser = !this.showColumnChooser;
        } else if ( args.item.id === 'clear-filter' ) {
            this.linksGrid.clearFiltering();
            this.linksGrid.search('');
        } 
    }

    onConsignmentClick(consignment) {
        alert(consignment);
    }

    getStatusClass(status: string) {
        return this.commonService.getStatusClass(status);
    }

}
