<ejs-datepicker 
    [allowEdit]='properties.allowEdit !==undefined ? properties.allowEdit : true'
    [enablePersistence]='properties.enablePersistence !== undefined ? properties.enablePersistence : false'  
    [enabled]='properties.enabled !== undefined ? properties.enabled : true'
    [format]='properties.format || null'
    [placeholder]="properties.placeholder || null"
    [min]="properties.min || null"
    [max]="properties.max || null" 
    [readonly]='properties.readonly !== undefined ? properties.readonly : false'
    [serverTimezoneOffset]='properties.serverTimezoneOffset || null'
    [showClearButton]='properties.showClearButton !== undefined ? properties.showClearButton : true'
    [showTodayButton]='properties.showTodayButton !== undefined ? properties.showTodayButton : true'
    [value]='properties.value || null'
    [weekNumber]='properties.weekNumber || null'

    (change)='onChange($event)'
    (blur)='onBlur($event)'
    (cleared)='onCleared($event)'
    (close)='onClose($event)'
    (created)='onCreated($event)'
    (destroyed)='onDestroyed($event)'
    (focus)='onFocus($event)'
    (open)='onOpen($event)'
    (renderDayCell)='onRenderDayCell($event)'
    
></ejs-datepicker>