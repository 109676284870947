import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { AddSignatureEventArgs, RemoveSignatureEventArgs, PrintService } from '@syncfusion/ej2-angular-pdfviewer';
import {
    PdfViewer, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields
} from '@syncfusion/ej2-pdfviewer';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { Invoice, InvoiceStatuses } from '../../models/payment-invoicing.model';
import { ServiceOrderService } from '../../services/service-order.service';
import { InvoicePopupOpenedFrom } from '../../models/service-order.model';
const BASE_URL = environment.apiUrl;
PdfViewer.Inject(
    Toolbar,
    Magnification,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    ThumbnailView,
    Print,
    TextSelection,
    TextSearch,
    Annotation,
    FormFields
);

@Component({
    selector: 'app-invoice-popup',
    templateUrl: './invoice-popup.component.html',
    styleUrls: ['./invoice-popup.component.scss', '../../../../modules/service-order/components/popup-header/popup-header.component.scss'],
    providers: [PrintService]

})
export class InvoicePopupComponent implements OnInit {
    @Input() popupOpenedFrom: string= '';
    @Input() checklistType: string= '';
    @Input() checklistId: string= '';
    @Input() serviceOrderData: any;
    @Input() heading: string= 'Invoice';
    @Input() invoice: Invoice = null;
    @Input() isDraft: boolean = false;
    @Input() isSigned: boolean = false;
    @Input() showSaveAndCloseButton: boolean = false;
    @Input() fieldRules: any;
    @Input() customerData: any;
    @Input() serviceOrderId: string;

    showEmailPopup: boolean = false;
    emailSubject: string = `Service Order`;
    link: string = null;
    signatureCount: number = 0;
    isExpanded: boolean = false;
    showLoader: boolean = false;
    viewer: PdfViewer;

    invoiceStatuses = InvoiceStatuses;

    @ViewChild('pdfviwer') pdfviewer;
    @Input() Object = ''; // decorate the property with @Input()
    @Input() checklistCertificate = false;

    @Output() onSigned = new EventEmitter();
    @Output() onSignedAndClose = new EventEmitter();
    @Output() onClose = new EventEmitter();
    @Output() certificateGenerate = new EventEmitter();
    InvoicePopupOpened: any;
    sendOtherDocs: boolean;
    invoiceStatusSigned: boolean = false;
    isIsc: boolean = false;
    isIscManager: boolean = false;
    isIscSupervisor: boolean = false;

    constructor(
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService
    ) {
      this.InvoicePopupOpened = InvoicePopupOpenedFrom;
      this.isIsc = commonService.USER.role === UserRoles.isc;
      this.isIscManager = commonService.USER.role === UserRoles.iscManager;
      this.isIscSupervisor = commonService.USER.role === UserRoles.IscSupervisor;
    }

     temp= {
        // technicianName: '',
        company: '',
        checklistId: '',
        serviceOrderId: '',
        technicianId: '',
        createdDate: '',
        createdBy: '',
        lastUpdatedDate: '',
        lastUpdatedBy: '',
        items: '',
        generalQAC : [],
        liftQAC :[],
        brakeTesterQAC: [],
        headlampTesterQAC:[],
        commentQAC:[],
        checklistItems:""
    };

    ngOnInit(): void {
        console.log('this.serviceOrderData: check so type ',this.serviceOrderData);
        if(this.commonService.roleClaims['SO_Popup_Fields_invoice']?.readOnly){
            this.showSaveAndCloseButton = false;
        } else if(this.invoice) {
            this.showSaveAndCloseButton = !this.isDraft && this.invoice.status !== this.invoiceStatuses.signed;
        }
        if (this.invoice?.invoiceAttachment && this.invoice.status === this.invoiceStatuses.signed) {
            this.initializeViewer(this.invoice.invoiceAttachment.uri);
            this.invoiceStatusSigned = this.invoice.status === this.invoiceStatuses.signed;
        } else {
            if(this.invoice == undefined) {
                this.temp= {
                    company: '389',
                    checklistId: this.Object['checklistId'],
                    serviceOrderId: this.Object['serviceOrderId'],
                    technicianId: this.Object['technicianId'],
                    createdDate: this.Object['createdDate'],
                    createdBy: this.Object['createdBy'],
                    lastUpdatedDate: this.Object['lastUpdatedDate'],
                    lastUpdatedBy: this.Object['lastUpdatedBy'],
                    items: this.Object['items'],
                    generalQAC : [],
                    liftQAC :[],
                    brakeTesterQAC: [],
                    headlampTesterQAC:[],
                    commentQAC:[],
                    checklistItems:""
                };
                this.showLoader = true;
                this.serviceOrderService.generateChecklistInvoice(this.temp)
                .subscribe((res:any)=>{
                    if(res?.uri){
                        this.certificateGenerate.emit();
                        this.link = res.uri;
                        this.initializeViewer(res.uri);
                        this.showLoader = false;
                    } else {
                        this.showLoader = false;
                        this.close();
                        this.commonService.showNotification('error', res.message);
                    }
                }, (error: HttpErrorResponse) => {
                    this.showLoader = false;
                    this.commonService.showNotification('error', error.message);
                });
            } else {
                this.showLoader = true;
                const service = this.serviceOrderService;
                const generateInvoicePdfAPI = this.isIsc || this.isIscManager || this.isIscSupervisor ? this.serviceOrderService.generateIscInvoicePdf.bind(service) : this.serviceOrderService.generateInvoicePdf.bind(service);
                generateInvoicePdfAPI(this.invoice, this.isDraft)
                    .subscribe((res: any) => {
                        if (res?.uri) {
                            this.invoice.status = this.invoiceStatuses.pdfGenerated;
                            this.initializeViewer(res.uri);
                        } else {
                            this.onClose.emit();
                        }
                        this.showLoader = false;
                    }, (error: HttpErrorResponse) => {
                        this.showLoader = false;
                    });
            }
        }
    }

    initializeViewer(url: string) {
        this.viewer = new PdfViewer();
        this.viewer.enableToolbar = false;
        this.viewer.enablePrint = true;
        this.viewer.printMode  = 'NewWindow';
        this.viewer.enableNavigationToolbar = false;
        this.viewer.hideSaveSignature = true;
        this.viewer.serviceUrl = BASE_URL + 'pdfviewer';
        this.viewer.addSignature = this.addSignature.bind(this);
        this.viewer.removeSignature = this.removeSignature.bind(this);
        this.viewer.appendTo('#pdfViewer');
        this.viewer.load(url, null);
    }

    addSignature(args: AddSignatureEventArgs) {
      let signCountToCheck = this.commonService.USER.isSignatureCreated ? 1 : 2;
        if (args.name === 'addSignature') {
            this.signatureCount += 1;
            if (this.signatureCount === signCountToCheck) {
                this.isSigned = true;
            }
        }
    }

    removeSignature(args: RemoveSignatureEventArgs) {
      let signCountToCheck = this.commonService.USER.isSignatureCreated ? 1 : 2;
        if (args.name === 'removeSignature') {
            this.signatureCount -= 1;
            if (this.signatureCount < signCountToCheck) {
                this.isSigned = false;
            }
        }
    }

    onExpandCollapse() {
        this.isExpanded = !this.isExpanded;
        setTimeout(() => {
            this.viewer.updateViewerContainer();
            if(this.isExpanded){
                this.viewer.height = '80vh';
            } else {
                this.viewer.height = '55vh';
            }
            this.viewer.updateViewerContainer();
        }, 100);
    }

    saveAndClose(){
        this.saveFile(true, false);
    }

    close() {
        this.viewer.destroy();
        this.viewer.formFieldCollection = this.viewer.formFieldCollections = null; // to handle tab key jumping to next input
        Array.from(document.querySelectorAll(`[id^="pdfViewer"]`))
        .forEach((el: HTMLElement) => { el.remove(); });
        this.onClose.emit(this.invoice ? this.invoice : null);
    }

    print() {
        this.viewer.print.print();
        this.viewer.saveAsBlob().then((res: any) => {});
    }

    saveFile(closePopup?:boolean, sendEmail?:boolean) {
        this.showLoader = true;
        if ( this.invoice ) {
            const service = this.serviceOrderService;
            const markInvoiceSigned = this.isIsc || this.isIscManager || this.isIscSupervisor ? this.serviceOrderService.markIscInvoiceSigned.bind(service) : this.serviceOrderService.markInvoiceSigned.bind(service);
            markInvoiceSigned(this.invoice.invoiceId)
            .subscribe((response: any) => {
                if (response.isSuccessful) {
                    this.viewer.saveAsBlob().then((res: any) => {
                    });
                    const getInvoiceById = this.isIsc || this.isIscManager || this.isIscSupervisor ? this.serviceOrderService.getIscInvoice.bind(service) : this.serviceOrderService.getInvoice.bind(service);
                    getInvoiceById(this.invoice.invoiceId)
                        .subscribe((res: ApiResponse) => {
                            if (res.isSuccessful) {
                                this.isSigned = true;
                                this.invoiceStatusSigned = true;
                                this.invoice = res.result;
                                // this.viewer.enableAnnotation = false;
                                if(closePopup){
                                    this.viewer.destroy();
                                    this.viewer.formFieldCollection = this.viewer.formFieldCollections = null; // to handle tab key jumping to next input
                                    Array.from(document.querySelectorAll(`[id^="pdfViewer"]`))
                                    .forEach((el: HTMLElement) => { el.remove(); });
                                    this.onSignedAndClose.emit(this.invoice);
                                } else {
                                    this.onSigned.emit(this.invoice);
                                }
                                this.commonService.showNotification('success', `Service order ${this.serviceOrderId} completed successfully!`);
                                if(sendEmail)
                                    this.showPopupForEmail()
                            } else {
                                this.commonService.showNotification("error", res.message);
                            }
                            this.showLoader = false;
                        }, (error: HttpErrorResponse) => {
                            this.commonService.showNotification("error", error.message);
                            this.showLoader = false;
                        });
                } else {
                    this.commonService.showNotification("error", response.message);
                    this.showLoader = false;
                }
            }, (error: HttpErrorResponse) => {
                this.commonService.showNotification("error", error.message);
                this.showLoader = false;
            });
        } else {
            this.viewer.saveAsBlob().then((res: any) => {
                this.commonService.showNotification('success', "File Saved");
                this.showLoader = false;
                if(closePopup)
                    this.close()
                if(sendEmail)
                    this.showPopupForEmail()
            });
        }
    }

    sendEmail(){
        if(this.commonService.USER.company === Companies.conway){
            this.sendEmailProcess(false);
        } else if(this.commonService.USER.company === Companies.kingslynn){
            this.commonService.showConfirmation('You want to send other related documents in this email?')
            .then( result => {
                this.sendEmailProcess(result.isConfirmed);
            });
        }
    }

    sendEmailProcess(sendOtherDocs){
        this.sendOtherDocs = sendOtherDocs;
        if(this.checklistCertificate || this.isSigned || this.invoice.status === this.invoiceStatuses.signed) {
            this.saveFile(false, true);
        } else {
            this.commonService.showConfirmation('Invoice not Signed', 'Ok, Proceed', 'Cancel')
            .then( result => {
                if ( sendOtherDocs ) {
                    this.showPopupForEmail();
                }
            });
        }
    }

    showPopupForEmail(){
        this.showEmailPopup = true;
        if (this.popupOpenedFrom == this.InvoicePopupOpened.popupModal) {
            this.link = this.invoice?.invoiceAttachment?.uri;

            // Issue fix 5258: On Email popup the ID should be LNOrderId like we have for Invoice.
            const serviceOrderNumber = this.serviceOrderData.serviceOrderIdLN || this.invoice?.serviceOrderList[0];
            this.emailSubject = `invoice of service order ${serviceOrderNumber}`;
        } else if (this.popupOpenedFrom == this.InvoicePopupOpened.checklist) {
            this.emailSubject = `Service Order ${this.serviceOrderData.serviceOrderIdLN ? this.serviceOrderData.serviceOrderIdLN : this.serviceOrderData.serviceOrderId} ${this.checklistType} Checklist ${this.Object['checklistId']}`
        }
    }

    onEmailPopupClose(){
        this.showEmailPopup = false;
    }
}
