
<ejs-grid #customerRulesGrid
    id="customerRulesGrid"
    [dataSource]="claimForm?.value?.iscCustomerRules || []"
    [allowPaging]="false"
    [allowFiltering]="true"
    [allowSorting]="true"
    [allowResizing]="true"
    [enablePersistence]="false"
    [allowReordering]="false"
    [height]="300"
    [allowExcelExport]="true"
    [query]="claimsQuery"
    [toolbar]="toolbar"
    [filterSettings]="{ type: 'Excel' }"
    (commandClick)="onCommandClick($event)"
    (rowDataBound)="rowDataBound($event)"
    (toolbarClick)="toolbarClick($event, customerRulesGrid)"
    (created)="onGridCreated()"
    ><e-columns>
        <e-column 
            *ngFor="let column of columns;" 
            [field]="column.field" 
            [headerText]="column.headerText"
            [clipMode]="'EllipsisWithTooltip'"
            [type]="column.type"
            [width]="column.width"
            [visible]="column.visible"
            [allowFiltering]="column.allowFiltering"
            [allowSorting]="column.allowSorting"
            [format]="column.format ? column.format : null"
            [textAlign]="column.textAlign" 
            [commands]="column?.commands ? column.commands : null"
            [isPrimaryKey]="column.isPrimaryKey || false">
            <ng-template *ngIf="column.field === 'status'" #template let-data>
                <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>

<ng-template #customerRulesGridColumnChooser>
    <app-column-chooser
        [grid]="customerRulesGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>
