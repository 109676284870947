<div class="modal fade show"
     [ngClass]="{'expanded': isExpanded}"
     id="service-order-modal"
     tabindex="-1"
     aria-labelledby="service-order-modalLabel"
     aria-hidden="true"
     [ngStyle]="{'display': 'block'}">
     <app-loader *ngIf="showLoader"></app-loader>
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <app-popup-header
                [id]="id"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="id ? 'Receipt - '+id : 'Receipt'"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
          ></app-popup-header>
          <div class="modal-body pt-0">
            <div class="row header-section">
              <div class="col-md-7">
                <div class="row header-left-section">
                  <div class="col resource-wrapper">
                    <div class="row field no-border">
                      <label class="control-label col-md-auto mt-2"> Resource:<span class="required-asterik">*</span></label>
                      <div class="col pt-2"> {{ data.technicianName }}</div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row field no-border">
                      <label class="control-label col-md-auto mt-2"> Status:</label>
                      <div class="col no-padding mt-1">
                        <div class="pt-1">{{data?.status}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto" *ngIf="id">
                    <div class="comments-section position-relative">
                      <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                      <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                        <app-comments
                            [enableAddNew]="showComments"
                            [serviceOrderId]="id"
                            [moduleId]="id"
                            module="ShipmentOrder"
                            [comments]="data?.comments"
                            (commentUpdated)="onCommentUpdate($event)"
                            (onClose)="showComments = false;"
                        ></app-comments>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col tabbable-line pt-1">
                    <ul class="nav nav-tabs float-right" id="tabs">
                      <li class="nav-item">
                        <a class="nav-link position-relative"
                          [ngClass]="{'active': tab === 'details'}"
                          data-toggle="tab"
                          href="#shipment-order-details-tab"
                          title="Details">Details</a>
                      </li>
                      <li class="nav-item">
                        <a id="receipts-tab-button" class="nav-link position-relative"
                          [ngClass]="{'active': tab === 'receipts'}"
                          data-toggle="tab"
                          href="#shipment-order-receipts-tab"
                          title="receipts">Receipts<i
                          *ngIf="validationApplied && (!receiptsTab.isTabValid)"
                          class="fas fa-exclamation-circle validation-exclamation"></i></a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          [ngStyle]="{'color': data.linkColorCode }"
                          [ngClass]="{'active': tab === 'links'}"
                          data-toggle="tab"
                          href="#shipment-order-links-tab"
                          title="Links"
                          aria-expanded="true"><i class="fas fa-link"></i></a>
                      </li>
                      <li class="nav-item" *ngIf="id">
                        <a class="nav-link"
                          [ngClass]="{'active': tab === 'attachments'}"
                          data-toggle="tab"
                          href="#shipment-order-attachments-tab"
                          title="Attachments"
                          aria-expanded="true"><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link"
                          [ngClass]="{'active': tab === 'history'}"
                          data-toggle="tab"
                          href="#shipment-order-history-tab"
                          title="History"><i class="fas fa-history"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="tab-content custom-scrollbar flex-fill">
                  <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="shipment-order-details-tab">
                      <div id="general-shipment-collapse-details" class="collapse show fluid pt-2 pb-1">
                        <app-shipment-details-tab #detailsTab
                            [shipmentOrderId]="id"
                            [shipmentOrderData]="data"
                            [details]="detailsData"
                            [items]="itemsData"
                            [status]="data.status"
                            (updateStatus)="updateStatus($event)">
                        </app-shipment-details-tab>
                      </div>
                  </div>
                  <div class="tab-pane" [ngClass]="{'active show': tab === 'receipts'}" id="shipment-order-receipts-tab">
                    <div id="general-shipment-collapse-receipts" class="collapse show fluid pt-2 pb-1">
                      <app-shipment-receipts-tab #receiptsTab
                        [shipmentOrderId]="id"
                        [shipmentOrderData]="data"
                        [receipts]="itemsData"
                        [exceptions]="exceptionsData"
                        [status]="data.status"
                        (onUpdate)="onShipmentItemsUpdate($event)"
                      ></app-shipment-receipts-tab>
                    </div>
                  </div>
                  <div id="shipment-order-links-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'links'}">
                    <app-links-tab-shared #linksTab
                      [links]="links"
                      (onLinkIdClick)="onLinkIdClick.emit($event)"
                    ></app-links-tab-shared>
                  </div>
                  <div id="shipment-order-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                    <app-attachments-tab #attachmentsTab
                        [moduleId]="data?.shipmentOrderId"
                        module="ShipmentOrder"
                        [attachments]="data?.attachments"
                        (attachmentsUpdated)="onAttachmentsUpdate($event)"
                    ></app-attachments-tab>
                  </div>
                  <div id="shipment-order-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                    <app-history-tab-shared
                        [popup]="'Shipment Order'"
                        [history]="data?.history"
                    ></app-history-tab-shared>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            <button type="button" class="btn btn-sm btn-info mr-1" *ngIf="!isPartReceived" [disabled]="resetBtnDisabled" (click)="reset()">Reset</button>
            <!-- <button type="button" class="btn btn-sm btn-success mr-1" (click)="save(false, data?.status === 'Delivery Acknowledged')" [disabled]="!isTechnician">Save</button> -->
            <button type="button" class="btn btn-sm btn-success mr-1"  *ngIf="!isPartReceived" [disabled]="receiveBtnDisabled || !isDeliverAcknowledged" (click)="saveAndClose(true, isDeliverAcknowledged, true)">Receive &amp; Close</button>
            <!-- <button type="button" class="btn btn-sm btn-primary mr-1" (click)="save(true, false, true)" [disabled]="isManager || isCSA || data?.status !== 'Delivery Acknowledged' || !enableReceiveOrder">Receive Order</button> -->
          </div>
        </div>
      </div>
</div>
