import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ServiceOrderService } from '../../services/service-order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-barclay-response',
  templateUrl: './barclay-response.component.html',
  styleUrls: ['./barclay-response.component.scss']
})
export class BarclayResponseComponent implements OnInit {

  transactionSuccessful: boolean = false;
  transactionFailed: boolean = false;

  epdqErrorCodes = {
    "0": "Transaction successful",
    "1": "Transaction not allowed",
    "2": "Acquirer declines the authorization",
    "93": "Acquirer declines the authorization",
    "52 ": "Uncertain",
    "92 ": "Uncertain",
    "4": "Authorized.",
    "5": "Stored.",
    "9": "Accepted.",
    "41": "Pending, Waiting to be accepted.",
    "51": "Pending, Waiting to be accepted.",
    "91": "Pending, Waiting to be accepted.",
    // add more error codes as necessary
  };
  
  transactionID: any;
  transactionDate: any;
  transactionAmount: any;
  transactionCurrency: any;
  transactionErrorMessage: any;
  transactionErrorCode: any;
  transactionErrorSuggestion: any = 'Please try again or use different payment method.';
  orderID: any;
  

  constructor(
    private route: ActivatedRoute,
    private serviceOrderService: ServiceOrderService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(transactionData => {
      let transactionDataObj = { };
      if(transactionData.orderID){
        transactionDataObj = { ShopperID: null };
        transactionDataObj = { ...transactionData, ShopperID: transactionData['amp;ShopperID'] };
        delete transactionDataObj['amp;ShopperID'];
      }
      this.checkResponseForError(transactionDataObj);
    });
  }

  checkResponseForError(bankResponse){
    const errorMessage = this.epdqErrorCodes[bankResponse.NCERROR] ?? "An unknown error occurred";
    this.orderID = bankResponse.orderID;
    if(bankResponse.NCERROR === '0'){
      this.transactionID = bankResponse.PAYID;
      this.transactionDate = bankResponse.TRXDATE;
      this.transactionAmount = bankResponse.amount;
      this.transactionCurrency = bankResponse.currency;
      this.transactionSuccessful = true;
    } else if(bankResponse.NCERROR) {
      this.transactionErrorMessage = errorMessage;
      this.transactionErrorCode = bankResponse.NCERROR;
      this.transactionFailed = true;
    } else {
      this.transactionErrorMessage = 'Something went wrong, please try again!';
      this.transactionErrorCode = '';
      this.transactionFailed = true;
    }
    this.processBarclayResponse(bankResponse);
  }

  processBarclayResponse(transactionDataObj) {
    this.serviceOrderService.processBarclaysPaymentResponse(JSON.parse(JSON.stringify(transactionDataObj)))
      .subscribe((response: any) => {
        // if(response.isSuccessful){
        //   this.router.navigate([`/home/service-order`, transactionDataObj.orderID, transactionDataObj.NCERROR === '0']);
        //   this.commonService.showNotification('success', response.message);
        // } else {
        //   this.router.navigate([`/home/service-order`, transactionDataObj.orderID, false]);
        //   this.commonService.showNotification('error', response.message);
        // }
      },
      (error: HttpErrorResponse) => {
        // this.router.navigate([`/home/service-order`, transactionDataObj.orderID, false]);
          this.commonService.showNotification('error', "Something went wrong. Please, try again.");
      });
  }

  ccPaymentComplete(){
    this.router.navigate([`/home/service-order`, this.orderID, true, false]);
  }

  retryPayment(){
    if(this.orderID){
      this.router.navigate([`/home/service-order`, this.orderID, false, true]);
    } else {
      this.router.navigate([`/home/service-order`]);
    }
  }

  failedAndClose(){
    this.router.navigate([`/home/service-order`, this.orderID, false, false]);
  }

}
