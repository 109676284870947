<ng-container *ngIf="popupData">
    <div [ngSwitch]="data.type">

        <app-core-return-order-popup
            *ngSwitchCase="orderTypes.coreReturnOrder" 
            [id]="data.id"
            [data]="popupData"
            [category]="popupData.category"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-core-return-order-popup>

        <app-core-return-order-popup
            *ngSwitchCase="orderTypes.damagedReturnOrder" 
            [id]="data.id"
            [data]="popupData"
            [category]="popupData.category"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-core-return-order-popup>

        <app-parts-orders-popup 
            *ngSwitchCase="orderTypes.partsOrder" 
            [id]="data.id"
            [data]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-parts-orders-popup>
        
        <app-cart-checkout-popup 
            *ngSwitchCase="orderTypes.purchaseOrder"
            [recievedItems]="[popupData]"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-cart-checkout-popup>
        
        <app-popup
            *ngSwitchCase="orderTypes.serviceOrder"
            [id]="data.id"
            [data]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-popup>
        
        <app-isc-popup
            *ngSwitchCase="orderTypes.iscServiceOrder"
            [id]="data.id"
            [data]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-isc-popup>

        <app-return-order-popup 
            *ngSwitchCase="orderTypes.returnOrder"
            [id]="data.id"
            [data]="popupData"
            [category]="popupData.category"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
            (onSave)="orderSaved($event)"
        ></app-return-order-popup>
        
        <app-shipment-order-popup
            *ngSwitchCase="orderTypes.shipmentOrder"
            [id]="data.id"
            [data]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-shipment-order-popup>
        
        <app-ms-dynamic-popup
            *ngSwitchCase="orderTypes.adjustmentorder"
            [tabName]="'details'"
            pageId="PG0000149"
            [orderId]="popupData.adjustmentOrderNumber"
            [data]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-ms-dynamic-popup>

        <app-cycle-counts-popup #cycleCountPopup 
            *ngSwitchCase="orderTypes.cycleCountOrder"
            [cycleCountData]="popupData"
            [cycleCountId]="data.id"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-cycle-counts-popup>

        <app-cart-checkout-popup
            *ngSwitchCase="orderTypes.purchaseOrder"
            [recievedItems]="popupData"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-cart-checkout-popup>

        <app-shipment-adjustment-order-popup
            *ngSwitchCase="orderTypes.inventoryAdjustmentOrder"
            [data]="popupData"
            [orderId]="data.id"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-shipment-adjustment-order-popup>
        
        <app-transfer-order-popup-opener
            *ngSwitchCase="orderTypes.transferOrder"
            [data]="data"
            (onLinkIdClick)="onLoopLinkClicked($event)"
            (onClose)="onClose()"
        ></app-transfer-order-popup-opener>
        <div *ngSwitchDefault></div>
    </div>    
</ng-container>

<app-linked-popup-opener
    *ngIf="showLinkPopup"
    [data]="data"
    (onLinkIdClick)="onLoopLinkClicked($event)"
    (onPopupClose)="showLinkPopup=false; data = null;"
></app-linked-popup-opener>