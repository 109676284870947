export const partsOrderStatuses = {
    open: 'Open',
    orderSent: 'Order Sent',
    submitted: 'Submitted',
    receivedInLn: 'Received in LN',
    inProgress: 'In Progress',
    closed: 'Closed',
    partiallyShipped: 'Partially Shipped',
    shipped: 'Shipped'
}

export const inventoryRequestStatuses = {
    open: 'Open',
    submitted: 'Submitted',
    cancelled: 'Canceled',
    accepted: 'Accepted',
    rejected: 'Rejected',
    inTransit: 'In Transit',
    received: 'Received',
    closed: 'Closed'
}

export const cycleCountStatuses = {
    open: 'Open',
    planned: 'Planning',
    scheduled: 'Scheduled',
    inProgress: 'In Progress',
    counted: 'Counted',
    reconciled: 'Reconciled',
    closed: 'Closed'
}

export const inventoryResponseStatuses = {
    requested: 'Requested',
    cancelled: 'Canceled',
    accepted: 'Accepted',
    rejected: 'Rejected',
    shipped: 'Shipped',
    closed: 'Closed',
    received: 'Received'
}

export const shipmentOrderStatuses = {
    readyToBeShipped: 'Ready to be Shipped',
    shipped: 'Shipped',
    deliveryAcknowledged: 'Delivery Acknowledged',
    received: 'Parts Received',
    exceptions: 'Exceptions',
    closed: 'Closed'
}

export const parentStatuses = {
    open: 'Open',
    inProgress: 'In Progress',
    completed: 'Completed'
}

export const returnOrderStatuses = {
    open: 'Open',
    orderSent: 'Order Sent',
    submitted: 'Submitted',
    inProgress: 'In Progress',
    receivedInLn: 'Received in LN',
    exceptions: 'Exceptions',
    closed: 'Closed'
}

export const returnOrderCategories = [
    { text : 'Serviceable'},
    { text : 'Wrong Part'},  
]

export const inventoryAdjustmentStatuses = {
    open: 'Open',
    adjusted: 'Adjusted',
    submitted: 'Submitted',
    approved: 'Approved', 
    rejected: 'Rejected'
}

export const coreInventoryRetrunOrderStatuses = {
    open: 'Open',
    closed: 'Closed'
}

export const ExceptionsStatuses = {
    pendingApproval: 'Pending Approval',
    adjusted: 'Adjusted',
    approved: 'Approved',
    onhold: 'Onhold',
}

export const returnOrdersCategoryTypes = {
    serviceable: 'Serviceable',
    defective : 'Defective',
    quarantined : 'Quarantined'
}

export const returnOrderTypes = {
    returnOrder : 'ReturnOrder',
    coreReturnOrder : 'CoreReturnOrder',
    damagedReturnOrder: 'DamagedReturnOrder'
}

export enum PartOrderTypes {
    partOrder = 'PartsOrder',
    purchaseOrder = 'PurchaseOrder'
}

export const IReceiptsExceptionsTypes = {
    damaged : "Damaged", 
    unknown: "Unknown", 
    wrongpart: "Wrong part"
}

export interface ShipmentOrderReceiptsExceptionModel {
    reasonDescription?: string;
    itemCode?: string;
    OrderDate?: Date;
    Freight?: number;
    ShipCountry?: string;
}

export const IInventoryGroupType = {
    core : 'Core Item',
    standard: 'Standard Item'
}

export const ReturnOrderTypesForDDL = [
    { value: "serviceable", text: "Serviceable"},
    { value: "wrongparts", text: "Wrong Parts"},
]

export const returnOrdersCategory = {
    serviceable : 'serviceable',
    wrongparts : 'wrongparts'
}

export const TransferOrderTypes = {
    requester : 'requester',
    responder : 'responder',
}

export const ReturnOrderServiceable = 'returnOrderServiceable';

export const ReturnOrderReasons = {
    damaged: {
        "text": "Damaged",
        "value": "R002",
        "description": "Damaged",
        "valueList": null
    },
    overStock: {
        "text": "Over stocked",
        "value": "R006",
        "description": "Over stocked",
        "valueList": null
    },
    defective: {
        "text": "Defective",
        "value": "R007",
        "description": "Defective",
        "valueList": null
    }
}