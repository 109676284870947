import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { serviceOrderStatuses } from 'src/app/modules/service-order/models/service-order.model';

@Component({
  selector: 'app-reason-popup',
  templateUrl: './reason-popup.component.html',
  styleUrls: ['./reason-popup.component.scss']
})
export class ReasonPopupComponent implements OnInit {

  @Input() popupTitle;
  @Input() reasons;
  @Input() reasonFieldName;
  @Input() id;
  @Output() saveReasonCodePopup = new EventEmitter();
  @Output() closeReasonPopup = new EventEmitter();
  @ViewChild('reasonsDDL') reasonsDDL;
  
  validationApplied: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }  

  save() {
    this.validationApplied = true;
    if (this.validationApplied && this.reasonsDDL.value) {
      this.saveReasonCodePopup.emit({id: this.id, reasonCode: this.reasonsDDL.value});
      // this.updateServiceOrder(this.id, null, new Date().toISOString(), serviceOrderStatuses.paused, reasonCode, null);
      this.closeReasonPopup.emit();
    }
  }

  close() {
    this.id = null;
    this.validationApplied = false;
    this.closeReasonPopup.emit();
  }
  
}
