<app-loader *ngIf="showLoader"></app-loader>
    <ejs-grid
        #cycleCountCostAnalysisGrid
        id="{{gridName}}"
        [dataSource]="dataSource"
        [query]="query"
        [height]="gridHeight"
        [pageSettings]="pageSettings"
        [gridLines]="false"
        [allowPaging]="false"
        [enablePersistence]="false"
        [allowFiltering]="true"
        [allowSorting]="true"
        [allowResizing]="true"
        [allowReordering]="true"
        [showColumnChooser]="true"
        [allowExcelExport]="true"
        [filterSettings]="{ type: 'Menu' }"
        [editSettings]="{ allowEditing: this.allowEdit, allowAdding: true, allowDeleting: true , newRowPosition: 'Top' }"
        [toolbar]="grid?.toolbar"
        (queryCellInfo)="queryCellInfo($event)"
        (created)="onGridCreated()"
        (excelQueryCellInfo)='excelQueryCellInfo($event)'
        (commandClick)="onCommandClick($event)"
        (rowDataBound)="onRowDataBound($event, cycleCountCostAnalysisGrid)"
        (toolbarClick)="onToolbarClick($event, cycleCountCostAnalysisGrid)"
        (actionBegin)="onGridActionBegin($event)"
        (actionComplete)="onGridActionComplete($event, cycleCountCostAnalysisGrid)">
        <e-columns>
            <e-column
                *ngFor="let column of mainGrid?.columns;"
                [rowHeight]="30"
                [field]="column.field"
                [headerText]="column.headerText"
                [clipMode]="'EllipsisWithTooltip'"
                [type]="column.type"
                [width]="column.width"
                [isPrimaryKey]="column.isPrimaryKey || false"
                [validationRules]="column?.validationRules || null"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null"
                [textAlign]="column.textAlign"
                [editType]="column.editType"
                [showInColumnChooser]="column.showInColumnChooser ?? true"
                [visible]="column.visible"
                [allowEditing]="column?.allowEditing"
                [commands]="column?.commands ? column.commands : null"
                [allowReordering]="column.allowReordering ?? true"
                [freeze]="column.freeze ? column.freeze: null"
                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                <ng-template *ngIf="column.field === 'rimsVarianceQty'" #template let-data>
                    <span [ngClass]="checkIfNegative(data.rimsVarianceQty)">
                        {{ data.rimsVarianceQty }}
                      </span>
                </ng-template>
                <ng-template *ngIf="column.field === 'lnVarianceQty'" #template let-data>
                    <span [ngClass]="checkIfNegative(data.lnVarianceQty)">
                        {{ data.lnVarianceQty }}
                      </span>
                </ng-template>
                <ng-template *ngIf="column.field === 'costVariance'" #template let-data>
                    <ng-container *ngIf="data.costVariance">
                        <span [ngClass]="checkIfNegative(data.costVariance)">
                            ${{ data.costVariance | number: '1.2-2' }}
                        </span>
                    </ng-container>
                </ng-template>
            </e-column>
        </e-columns>
        <e-aggregates>
            <e-aggregate>
                <e-columns>
                    <e-column field="itemCode" type="sum">
                        <ng-template #footerTemplate let-data><strong>Total</strong></ng-template>
                    </e-column>
                    <e-column field="extCost" type="sum">
                        <ng-template style="font-weight:800" #footerTemplate let-data>
                            <span [ngClass]="checkIfNegative(data.sum)">
                                <strong>${{data.sum | number : '1.2-2'}} </strong>
                            </span>
                        </ng-template>
                    </e-column>
                    <e-column field="rimsVarianceQty" type="sum">
                        <ng-template #footerTemplate let-data>
                            <span [ngClass]="checkIfNegative(data.sum)">
                                <strong>{{data.sum}}</strong>
                            </span>
                        </ng-template>
                    </e-column>
                    <e-column field="lnVarianceQty" type="sum">
                        <ng-template #footerTemplate let-data>
                            <span [ngClass]="checkIfNegative(data.sum)">
                                <strong>{{data.sum}}</strong>
                            </span>
                        </ng-template>
                    </e-column>
                    <e-column field="costVariance" type="sum">
                        <ng-template #footerTemplate let-data>
                            <span [ngClass]="checkIfNegative(data.sum)">
                                <strong>${{ data.sum | number: '1.2-2' }}</strong>
                            </span>
                            <span *ngIf="costDifference" [ngClass]="getCostDifferenceClass()">
                                &nbsp;<strong>({{ costDifference }}%)</strong>
                            </span>
                          </ng-template>
                    </e-column>
                </e-columns>
            </e-aggregate>
        </e-aggregates>
    </ejs-grid>

<ng-template #rimsVarCheckbox>
    <div class="mr-2">
        <ejs-checkbox
            #rimsVar
            id='rimsVar'
            label="VAR (RIMS)"
            [enablePersistence]='true'
            (change)="checkRimsVar($event.checked)"
            >
        </ejs-checkbox>
    </div>
</ng-template>

<ng-template #lnVarCheckbox>
    <div class="mr-2">
        <ejs-checkbox
            #lnVar
            id='lnVar'
            label="VAR (LN)"
            [enablePersistence]='true'
            (change)="checkLnVar($event.checked)"
            >
        </ejs-checkbox>
    </div>
</ng-template>

<ng-template #adjustedCheckbox>
    <div class="mr-2">
        <ejs-checkbox
            #adjusted
            id='adjusted'
            label="Adjusted"
            [enablePersistence]='true'
            (change)="checkAdjusted($event.checked)"
            >
        </ejs-checkbox>
    </div>
</ng-template>

<ng-template #columnChooser>
    <app-column-chooser
        [columns]="mainGrid?.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-cycle-counts-upload-detail *ngIf="showUploadDetailPopup"
    [uploadDetailData]="uploadDetailData"
    (browseFile)="onBrowseFile()"
    (reloadPopupData)="reloadPopupData.emit(); this.isFileUploaded.emit(true); updateList()"
    [showLoader]="showLoader"
    [fileInvalid]="fileInvalid"
    [cycleCountId]="cycleCountId"
    (onClose)="showUploadDetailPopup = false"
    >
</app-cycle-counts-upload-detail>

<!-- <app-cycle-count-inventory-quantity 
    [itemDate]="inventoryQuantityData"
    [warehouseId]="warehouseId"
    *ngIf="showItemLinkPopup"
    (onClose)="showItemLinkPopup = false"
    >
</app-cycle-count-inventory-quantity> -->

<app-inventory-popup
    *ngIf="showItemLinkPopup"
    [itemCode]="itemCode"
    [warehouse]="warehouse"
    [popupTitle]="popupTitle"
    [secondDescrption]="openLinkSecondDescrption"
    (onClose)="showItemLinkPopup = false"
    (onSave)="showItemLinkPopup = false"
></app-inventory-popup>

<input #fileInputRef type="file" style="display: none" (change)="onFileSelected($event)" accept=".xlsx">