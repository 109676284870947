import { Attachment } from "src/app/shared/models/attachment.model";

export interface CalibrationData {
    company?: string;
    id?: string;
    calibrationId?: string;
    calibrationType: string;
    technicianId: string;
    technicianName: string;
    serviceOrderId: string;
    model: string;
    serialNo: string;
    createdBy?: Date;
    createdByName?: Date;
    createdDate: Date;
    fields: any;
    certificateNumber?: any;
    lastUpdatedDate?: Date;
    attachments: Attachment[];
    comments: Object[];
    history?: Object[];
}

export enum CalibrationTypes {
    generic = 'Calibration_CA1',
    gas = 'Calibration_CA2',
    smoke = 'Calibration_CA3',
    motPlayDetector = 'Calibration_CA4',
    brakeTester = 'Calibration_CA5',
    liftLoad = 'Calibration_CA6',
    beamSetter = 'Calibration_CA7',
    levelStandingArea = 'Calibration_CA8',
}

export interface DataField {
    key: string;
    value: any;
}

export interface SmokeCalibrationData {
    // referenceEquipmentTab: ReferenceEquipmentTabData;
    updateReferenceEquipmentCheckbox: boolean;
    assetNumber: number;
    assetNumberThermometer: number;
    assetNumberMultimeter: number;
    certificateNumber: number;
    certificateNumberThermometer: number;
    certificateNumberMultimeter: number;
    certificateExpiry: Date;
    certificateExpiryThermometer: number;
    certificateExpiryMultimeter: number;
    standardTemperature: string;
    wirelessTemperature: string;
    // equipmentTab: EquipmentTabData;
    model: string;
    serialNo: string;
    procedureValue: string;
    serviceOrderId: string;
    ambientTemperaure: number;
    roti: string;
    // datesTab: CalibrationDatesTab;
    noPreviousCalibrationDateCheckbox: boolean;
    previousCalibrationDate: Date;
    calibrationDueDate: Date;
    facilityBasedCalibrationCheckbox: boolean;
}

export interface BeamSetterCalibrationData {
    'field-1': boolean;  // Update reference equipment checkbox
    'field-21': boolean; // Previouse Calibration Checkbox
    'field-22': boolean; // Override calibration date checkbox
    assetNumber: number;
    certificateNumber: number;
    certificateExpiryDate: Date;
    manufacturer: string;
    model: string;
    serialNo: string;
    serviceOrderId: string;
    procedureValue: string;
    vtsNumber: string;
}

export interface BrakeCalibrationData {
    'field-101': boolean; // Update reference equipment checkbox
    assetNumber: number;
    certificateNumber: number;
    certificateExpiryDate: Date;
    equipmentType: string;
    scalesSetAssetNumber: number;
    scalesSetCertificateNumber: number;
    scalesSetExpiryDate: Date;
    brakeCalAxleWeight: number;
    axelCertificateNumber: number;
    axelExpiryDate: Date
    // Equipment 
    classOfBrakeTester: string;
    manufacture: string;
    model: string;
    serialNo: string;
    serviceOrderId: string;
    procedureValue: string;
    otherApprovalCheckbox: boolean;
    maxBrakeForceReadings: string;
    vtsNumber: string;
    axleWeighingBrakeTesterCheckbox: boolean;
    'otherApprovalClass4/7': boolean
    // Dates
    noPreviousCalibrationDateCheckbox: boolean;
    overrideCalibrationDueDateCheckbox: boolean;
    previousCalibrationDate: Date;
    calibrationDueDate: Date;
}

export interface LiftLoadTestData { // PG0000143
    'field-101': boolean; // Update reference equipment checkbox
    'field-121': Date; // Certificate Expiry Date
    'field-131': string; // Reference Weight Type
    referenceWeigh: number;
    // Equipment 
    manufacturer: string
    model: string;
    serialNo: string;
    serviceOrderId: string;
    procedureValue: string;
    '': string; // Safe working load sticker applied at:
}

export interface MOTPlayDetectorData { // PG0000141
    // Equipment 
    manufacturer: string
    model: string;
    serialNo: string;
    serviceOrderId: string;
    calibrationPeriod: string;
    procedureValue: string;
    // Dates
}

export interface ReferenceEquipmentTabData {
    updateReferenceEquipmentCheckbox: boolean;
    assetNumber: number;
    assetNumberThermometer: number;
    assetNumberMultimeter: number;
    certificateNumber: number;
    certificateNumberThermometer: number;
    certificateNumberMultimeter: number;
    certificateExpiry: Date;
    certificateExpiryThermometer: number;
    certificateExpiryMultimeter: number;
    standardTemperature: string;
    wirelessTemperature: string;
}

export interface EquipmentTabData {
    equipmentInformationSection: EquipmentInformationSection;
    basicSmokeCalibrationSection: BasicSmokeCalibrationSection;
}

export interface EquipmentInformationSection {
    model: string;
    serialNo: string;
    procedureValue: string;
}

export interface BasicSmokeCalibrationSection {
    serviceOrderId: string;
    ambientTemperaure: number;
    roti: string;
}

export interface CalibrationDatesTab {
    noPreviousCalibrationDateCheckbox: boolean;
    previousCalibrationDate: Date;
    calibrationDueDate: Date;
    facilityBasedCalibrationCheckbox: boolean;
}

