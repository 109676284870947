<app-loader *ngIf="showLoader"></app-loader>
<div class="w-100" *ngIf="showCycleCountGrid">
    <ejs-grid
        #cycleCountGrid
        id="{{gridName}}"
        [dataSource]="cycleCountList"
        [query]="query"
        [searchSettings]='searchSettings'
        [height]="gridHeight"
        [pageSettings]="pageSettings"
        [gridLines]="false"
        [allowPaging]="true"
        [stopEditingWhenGridLosesFocus]="true"
        [enablePersistence]="true"
        [allowFiltering]="true"
        [allowSorting]="true"
        [allowResizing]="true"
        [allowReordering]="true"
        [showColumnChooser]="true"
        [allowExcelExport]="true"
        [filterSettings]="{ type: 'Menu' }"
        [editSettings]="{ allowEditing: canEditStartDate, allowAdding: true, allowDeleting: true , newRowPosition: 'Top' }"
        [toolbar]="grid?.toolbar"
        (created)="onGridCreated()"
        (rowDataBound)="onRowDataBound($event)"
        (commandClick)="onCommandClick($event)"
        (toolbarClick)="onToolbarClick($event, cycleCountGrid)"
        (actionBegin)="onGridActionBegin($event)"
        (actionComplete)="onGridActionComplete($event, cycleCountGrid)">
        <e-columns>
            <e-column
                *ngFor="let column of mainGrid?.columns;"
                [rowHeight]="30"
                [field]="column.field"
                [headerText]="column.headerText"
                [clipMode]="'EllipsisWithTooltip'"
                [type]="column.type"
                [width]="column.width"
                [isPrimaryKey]="column.isPrimaryKey || false"
                [validationRules]="column?.validationRules || null"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null"
                [textAlign]="column.textAlign"
                [editType]="column.editType"
                [showInColumnChooser]="column.showInColumnChooser ?? true"
                [visible]="column.visible"
                [commands]="column?.commands ? column.commands : null"
                [allowEditing]="column.allowEditing"
                [allowSorting]="column.allowSorting"
                [allowReordering]="column.allowReordering ?? true"
                [edit]="column.edit"
                [freeze]="column.freeze ? column.freeze: null"
                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">

                <ng-template *ngIf="column.field === 'status'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.status)}}">
                        {{data.status}}
                    </span>
                </ng-template>

                <ng-template *ngIf="column.field === 'cycleCountId'" #template let-data>
                    <a href="javascript:" (click)="onCycleCountClick(data)">{{data.cycleCountId}}</a>
                </ng-template>

                <ng-template *ngIf="column.field === 'duration'" #template let-data>
                    <span *ngIf="data.duration">{{data.duration}} Hours </span>
                </ng-template>
                
                <ng-template *ngIf="column.field === 'totalCost'" #template let-data>
                    <span *ngIf="data.totalCost != null">
                        ${{data.totalCost | number: '1.2-2'}}
                    </span>
                </ng-template>

                <ng-template *ngIf="column.field === 'totalExtCost'" #template let-data>
                    <span *ngIf="data.totalExtCost != null">
                        ${{data.totalExtCost | number: '1.2-2'}}
                    </span>
                </ng-template>

            </e-column>
        </e-columns>
    </ejs-grid>
</div>

<ng-template #columnChooser>
    <app-column-chooser
        [columns]="mainGrid?.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-cycle-counts-popup #cycleCountPopup 
    [cycleCountData]="rowCycleCountData"
    [showComments]="showComments"
    [cycleCountId]="cycleCountId"
    [tab]="selectedTab"
    (onClose)="refreshGrid(); showPopup = false"
    *ngIf="showPopup">
</app-cycle-counts-popup>

<app-cycle-counts-add-popup #cycleCountOpenPopup *ngIf="showAddPopup"
    (refreshMainGrid)="refreshGrid()"
    (onClose)="showAddPopup = false">
</app-cycle-counts-add-popup>
