
<ejs-grid #businessRulesGrid
    id="businessRulesGrid"
    [dataSource]="businessRules"
    [allowPaging]="false"
    [allowFiltering]="true"
    [allowSorting]="true"
    [allowResizing]="true"
    [height]="300"
    [enablePersistence]="false"
    [allowReordering]="false"
    [allowExcelExport]="true"
    [filterSettings]="{ type: 'Excel' }"
    (toolbarClick)="toolbarClick($event, businessRulesGrid)"
    (created)="onGridCreated()"
    (actionComplete)="onGridActionComplete($event)"
    ><e-columns>
        <e-column 
            *ngFor="let column of columns;" 
            [field]="column.field" 
            [headerText]="column.headerText"
            [clipMode]="'EllipsisWithTooltip'"
            [type]="column.type"
            [width]="column.width"
            [format]="column.format ? column.format : null"
            [textAlign]="column.textAlign" 
            [isPrimaryKey]="column.isPrimaryKey || false">
            <ng-template *ngIf="column.field === 'status'" #template let-data>
                <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>
