
<div class="row mt-3">
    <div class="col">
        <div class="field mb-3 position-relative">
            <label>Address:</label><span class="required-asterik" *ngIf="!isKingslynn">*</span>
            <ejs-dropdownlist #address
                [placeholder]="'Address'"
                [dataSource]="details?.addressType === 'technician' && technicianAddresses || details?.addressType === 'onSite' && [onsiteAddress]"
                [allowFiltering]="true"
                [filterType]="'contains'"
                [fields]="{text: 'address', value: 'addressCode'}"
                [value]="details?.addressCode"
                [enabled]="status === statuses.open && details?.addressType === 'technician' && !isManager && !isCSA"
                (change)="onAddressChange($event)"
            ></ejs-dropdownlist>
            <div class="field-error" *ngIf="validationApplied && !details.addressCode">Address is required</div>
            <div class="address-area p-2" *ngIf="details?.addressCode">
                <span class="address-name" *ngIf="details.addressName">{{details.addressName}}</span><br/>
                <span class="address">{{details.address}}</span><br/>
                <span class="address-city" *ngIf="details.city">{{details.city}}, </span><span class="address-state"> {{details.state}} </span><span class="address-zip-code"> {{details.zipCode}}</span><br/>
                <span class="address-country" *ngIf="details.country">{{details.country}}</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="field mb-3 position-relative">
            <label>Shipment method: </label><span class="required-asterik">*</span>
            <ejs-dropdownlist #shipmentMethod
                [placeholder]="'Shipment Method'"
                [dataSource]="shipmentMethods"
                [allowFiltering]="true"
                [filterType]="'contains'"
                [fields]="{text: 'text', value: 'value'}"
                [value]="details?.shipmentMethodCode"
                [enabled]="status === statuses.open && !isManager && !isCSA"
                (change)="details.shipmentMethodCode = $event.itemData.value; details.shipmentMethodDescription = $event.itemData.text; tabUpdated.emit(details)"
            ></ejs-dropdownlist>
            <div class="field-error" *ngIf="validationApplied && !details.shipmentMethodCode">Shipment Method is required</div>
        </div>
        <div class="w-100"></div>
        <div class="field mb-3">
            <ejs-radiobutton
                name="addressType"
                label="Use Technicians Address"
                value="technician"
                [(ngModel)]="checkType"
                [checked]="details?.addressType !== 'onSite'"
                [disabled]="status !== statuses.open || isManager || isCSA"
                (change)="details.addressType = $event.value; radioButtonChanged($event)"
            ></ejs-radiobutton>
        </div>
        <div class="field mb-3" *ngIf="details?.serviceOrderId">
            <ejs-radiobutton
                name="addressType"
                label="Use On-site Address"
                value="onSite"
                [(ngModel)]="checkType"
                [checked]="details?.addressType === 'onSite'"
                [disabled]="!details?.serviceOrderId || status !== statuses.open || isManager || isCSA"
                (change)="details.addressType = $event.value; radioButtonChanged($event)"
            ></ejs-radiobutton>
        </div>
    </div>
    <div class="col">
        <div class="field mb-3" *ngIf="!isIsc && !isIscManager && !isIscSupervisor">
            <label>LN Warehouse Order:</label>
            <ejs-textbox
                placeholder="LN Warehouse Order ID"
                [value]="details?.warehouseOrderLN"
                [enabled]="false"
                (change)="details.warehouseOrderLN = $event.value; tabUpdated.emit(details)"
            ></ejs-textbox>
        </div>
        <div class="w-100"></div>
        <div class="field mb-3" *ngIf="isIsc || isIscManager || isIscSupervisor">
            <label>Sales Order Id</label>
            <ejs-textbox
                placeholder="Sales Order Id"
                [value]="details?.salesOrderId"
                [enabled]="false"
                (change)="details.salesOrderId = $event.value; tabUpdated.emit(details)"
            ></ejs-textbox>
        </div>
    </div>
</div>
