import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    RichTextEditorComponent,
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    CountService,
    QuickToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { environment } from 'src/environments/environment';
import { ApiResponse, UserRoles } from '../../models/app.model';
import { CommentsService } from '../../services/comments.service';
import { CommonService } from '../../services/common.service';

const token: string = localStorage.getItem('access_token');
const BASE_URL = environment.apiUrl;
const IMAGE_URL = environment.imageUrl;

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
    providers: [
        ToolbarService,
        LinkService,
        ImageService,
        HtmlEditorService,
        CountService,
        QuickToolbarService,
        CommentsService
    ]
})
export class CommentsComponent implements OnInit, AfterViewInit {

    USER;
    addComment: boolean = true;
    loading: boolean = false;

    tools: object = {
        items: [
            'Bold', 'Italic', 'Underline', 'FontColor', 'BackgroundColor', '|',
            'Alignments', 'OrderedList', 'UnorderedList', '|', 'Image',
            'CreateLink', 'ClearFormat', 'FullScreen', '|', 'Undo', 'Redo'
        ]
    }

    placeholderText = "@ to tag someone";

    @Input() enableAddComment: boolean = true;
    @Input() comments = [];
    @Input() serviceOrderId = null;
    @Input() moduleId = null;
    @Input() module = null;
    @Input() isIscModule: boolean = false
    @Input() showComments: boolean = false

    @ViewChild('addCommentRTE') addCommentRTE: any;
    @ViewChild('commentRTE') commentRTE: any;
    @Output() commentUpdated = new EventEmitter();
    @Output() onClose = new EventEmitter();
    mentionUsers: any[];
    mentionUsersEmails: any = [];
    isPrivate: boolean = false
    showIsPrivateCheckBox: boolean = false
    public fieldsData: { [key: string]: string } = { text: 'name', value: 'email' };

    constructor(
        private commentsService: CommentsService,
        private commonService: CommonService,
        private eRef: ElementRef,
        public sanitizer: DomSanitizer
    ) {
        this.USER = this.commonService.USER;
        this.showIsPrivateCheckBox = [UserRoles.csa, UserRoles.conwayServiceManager, UserRoles.administratorRole].includes(this.USER.role)
    }

    ngOnInit(): void {
        this.mentionUsers = [];
        this.commonService.getUsersForMention().subscribe((response: ApiResponse) => {
            if(response.isSuccessful && this.moduleId){
                this.mentionUsers = response.result
            }
        });
        if(this.commonService.globalComments.showComments || this.showComments){
            this.loading = true
            this.commentsService.getCommentsByPrimaryId(this.moduleId, this.module).subscribe((res: ApiResponse) => {
                this.comments = res.result
                this.loading = false
            }, error => {
                this.commonService.showNotification('error', error.message)
                this.loading = false
                throw error;
            });
        }
    }

    onActionBegin(args: any) {
        const mentionPopup = document.querySelector('.e-mention.e-popup-open');
        if (args.requestType === 'EnterAction' && mentionPopup !== null) {
          args.cancel = true;
        }
    }

    ngAfterViewInit(): void {
        this.commentRTE?.focus();
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (event.target.classList.value !== 'comments-opener' && !event.target.classList.value.includes('mentionName') && !event.target.classList.value.includes('mentionNameList') && !event.target.classList.value.includes('e-list-item') && !event.target.classList.value.includes('comments') && !event.target.classList.value.includes('e-btn-icon') && !event.target.classList.value.includes('e-rte-square-palette') && /*!document.getElementsByClassName('e-dialog')[0].contains(event.target) &&*/ !this.eRef.nativeElement.contains(event.target) && !event.target.classList.value.includes('comment-action-item')) {
            if (event.target && event.target.parentNode && event.target.parentNode.classList.value.includes('e-footer-content') 
                || event.target.classList.contains('placeholder') || event.target.tagName === "BODY" 
                || ['Align Justify', 'Align Right', 'Align Center', 'Align Left'].includes(event.target.title)) {
                // do nothing
            } else {
                this.onClose.emit();
            }
        }
    }

    sanitizedComment(comment) {
        return this.sanitizer.bypassSecurityTrustHtml(comment);
    }

    private insertImageSettings: Object = {
        saveUrl: BASE_URL + 'PdfViewer/SaveFile',
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
        path: IMAGE_URL,
    };

    onImageUploading(args) {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
    }

    onActionComplete(args) {
        ['Maximize', 'Minimize'].includes(args.requestType) && this.commentRTE.refreshUI();
    }

    // onFocus(commentRTE: RichTextEditorComponent) {
    //     let tribute = new Tribute({
    //         values: this.mentionUsers,
    //         positionMenu: true,
    //         selectTemplate: (item) => {
    //             if (typeof item === "undefined") {
    //                 return null;
    //             } else {
    //                 this.mentionUsersEmails.push(item.original.email);
    //                 return (
    //                     '<span class="tribute-mention" title="' +
    //                     item.original.value +
    //                     '"  email="' +
    //                     item.original.email +
    //                     '">' +
    //                     item.original.key +
    //                     "</span>"
    //                 );
    //             }
    //         }
    //     });

    //     tribute.attach(commentRTE.inputElement);
    // }

    saveComment(commentRTE: RichTextEditorComponent) {
        if (commentRTE.value) {
            let data
            if(this.isIscModule) {
                data = {
                    id: this.moduleId+'',
                    moduleName: this.module,
                    commentId: 0,
                    lastUpdatedBy: this.USER.userId,
                    lastUpdatedByName: this.USER.userName,
                    comment : {
                        commentId: 0,
                        comment: commentRTE.value,
                        firstName: this.USER.userName,
                        lastName: '',
                        commentDateTime: new Date(),
                        beingEdited: false,
                        isDeleted: false,
                        isNew: true,
                        imagePath: '',
                        userId: this.USER.userId,
                        isPrivate: this.isPrivate
                    }
                }
            } else {
                data = {
                    id: this.moduleId+'',
                    moduleName: this.module,
                    commentId: 0,
                    lastUpdatedBy: this.USER.userId,
                    lastUpdatedByName: this.USER.userName,
                    comment : {
                        commentId: 0,
                        comment: commentRTE.value,
                        firstName: this.USER.userName,
                        lastName: '',
                        commentDateTime: new Date(),
                        beingEdited: false,
                        isDeleted: false,
                        isNew: true,
                        imagePath: '',
                        userId: this.USER.userId,
                    }
                }
            }
            this.loading = true;
            this.commentsService.saveComment(data).subscribe((res: ApiResponse)  => {
                if(res.isSuccessful){
                    res.result.beingEdited = false;
                    res.result.isDeleted = false;
                    // res.result.isNew = true;
                    this.comments.unshift( res.result );
                    this.addComment = false;
                    this.loading = false;
                    this.commentUpdated.emit(this.comments);
                } else {
                    this.commonService.showNotification('error', res.message);
                }
                this.isPrivate = false
            }, error => {
                this.commonService.showNotification('error', error.message);
                this.loading = false;
                this.isPrivate = false
                throw error
            });
        }

        
    }

    deleteComment(commentId: string) {
        const index = this.comments.findIndex(comment => comment.commentId === commentId);
        if (index > -1) {
            const comment = this.comments[index];
            const data = {
                id: this.moduleId,
                moduleName: this.module,
                commentId: commentId,
                lastUpdatedBy: this.USER.userId,
                lastUpdatedByName: this.USER.userName,
            }
            if (comment.isNew) {
                this.deleteCommentFromList(index);
            } else {
                this.loading = true;
                this.commentsService.deleteComment(data)
                    .subscribe((res: any) => {
                        this.deleteCommentFromList(index);
                        this.loading = false;
                    }, (error: HttpErrorResponse) => {
                        this.loading = false;
                    });
            }
        }
    }

    updateUserComment(comment: any, commentRTE: RichTextEditorComponent) {
        if (commentRTE.value) {
            comment.comment = commentRTE.value;
            comment.beingEdited = false;
        }
        const data = {
            id: this.moduleId,
            moduleName: this.module,
            commentId: comment.commentId,
            lastUpdatedBy: this.USER.userId,
            lastUpdatedByName: this.USER.userName,
            comment
        }
        this.loading = true;
        this.commentsService.updateComment(data).subscribe(res => {
            this.loading = false;
            this.commentUpdated.emit(this.comments);
        },error => {
            this.loading = false;
            throw error
        });
    }

    close() {
        this.onClose.emit();
    }

    deleteCommentFromList(index: number) {
        this.comments.splice(index, 1);
        this.commentUpdated.emit(this.comments);
    }
}
