<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="service-order-modal"
    tabindex="-1"
    aria-labelledby="service-order-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header
                [id]="id"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="id ? id: 'Parts Order'"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0"  id="parts-order-popup-body">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Technician' }}:<span class="required-asterik">*</span></label>
                                    <div class="col pt-2"> {{ data.technicianName }} </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2"> Status: </label>
                                    <div class="col pt-2"> {{ data.status }} </div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="id">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="id"
                                            [moduleId]="id"
                                            module="PartsOrder"
                                            [comments]="data?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">

                                    <li class="nav-item">
                                        <a
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}"
                                            data-toggle="tab"
                                            href="#parts-order-details-tab"
                                            title="Details"
                                        >Details <i *ngIf="validationApplied && !validateDetails && !validateItems" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>

                                    <!-- <li class="nav-item">
                                        <a
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'items'}"
                                            data-toggle="tab"
                                            href="#parts-order-items-tab"
                                            title="Items"
                                        >Items <i *ngIf="validationApplied && !validateItems" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li> -->

                                    <!-- [ngClass]="{'active': tab === 'links', 'color-red': data?.openLinkCount, 'color-green': data?.linkCount && data?.openLinkCount === 0}" -->

                                     <li class="nav-item">
                                             <a
                                                 class="nav-link"
                                                 [ngStyle]="{'color': data.linkColorCode}"
                                                 [ngClass]="{'active': tab === 'links'}"
                                                 data-toggle="tab"
                                                 href="#parts-order-links-tab"
                                                 title="Links"
                                                aria-expanded="true"
                                             ><i class="fas fa-link"></i></a>
                                         </li>
                                    <li class="nav-item" *ngIf="id">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === 'attachments'}"
                                            data-toggle="tab"
                                            href="#parts-order-attachments-tab"
                                            title="Attachments"
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === 'history'}"
                                            data-toggle="tab"
                                            href="#parts-order-history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                      <div class="tab-content custom-scrollbar flex-fill">

                        <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="parts-order-details-tab">
                          <div class="card">
                              <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-collapse-details">
                                  <a class="card-link flex-fill">General</a>
                                  <i class="fas fa-chevron-up"></i>
                              </div>
                            <div id="general-collapse-details" class="collapse show container-fluid">
                                <app-details-tab #detailsTab
                                    [status]="data?.status"
                                    [isManager]="isManager"
                                    [priorities]="priorities"
                                    [popupSource]="popupSource"
                                    [shipmentMethods]="shipmentMethods"
                                    [technicianAddresses]="technicianAddresses"
                                    [onsiteAddress]="onsiteAddress"
                                    [details]="detailsData"
                                    (tabUpdated)="detailsTabUpdated($event)"
                                ></app-details-tab>
                            </div>
                          </div>

                           <div class="card">
                              <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-collapse-items">
                                  <a class="card-link flex-fill">Items<i
                                    *ngIf="validationApplied && !validateItems"
                                    class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                  <i class="fas fa-chevron-up"></i>
                              </div>
                            <div id="general-collapse-items" class="collapse show">
                                  <app-items-tab #itemsTab
                                      [isManager]="isManager"
                                      [status]="data?.status"
                                      [items]="itemsData"
                                      [technicianId]="data?.technicianId"
                                      (onShipmentIdClick)="onLinkIdClick.emit($event)"
                                  ></app-items-tab>
                            </div>
                          </div>
                        </div>
                            <!-- <div class="card">
                              <div class="card-header d-flex pl-0" data-toggle="collapse" href="#items-collapse-details">
                                  <a class="card-link flex-fill">Items</a>
                                  <i class="fas fa-chevron-up"></i>
                              </div> -->
                        <!--m<div class="tab-pane" [ngClass]="{'active show': tab === 'items'}" id="parts-order-items-tab">
                            <div id="items-collapse-details" class="collapse show">
                                <app-items-tab #itemsTab
                                    [isManager]="isManager"
                                    [status]="data?.status"
                                    [items]="itemsData"
                                    (onShipmentIdClick)="onLinkIdClick.emit($event)"
                                ></app-items-tab>
                            </div>
                        </div> -->
                          <!-- </div> -->

                          <div id="parts-order-links-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'links'}">
                                <app-links-tab-shared
                                    [links]="links"
                                    (onLinkIdClick)="onLinkIdClick.emit($event)"
                                ></app-links-tab-shared>
                          </div>

                          <div id="parts-order-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab
                                    [moduleId]="data?.partsOrderId"
                                    module="PartsOrder"
                                    [attachments]="attachmentsData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                          </div>

                          <div id="parts-order-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [popup]="'Parts Order'"
                                    [history]="data?.history"
                                ></app-history-tab-shared>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1" id="" [disabled]="isManager || isReadOnly" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1" [disabled]="isReadOnly" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1" [disabled]="isReadOnly" (click)="save(true)">Save &amp; Close</button>
                <button id="parts-order-btn" type="button" class="btn btn-sm btn-primary mr-1" [disabled]="isManager || isCSA || placeOrderBtnDisabled || isReadOnly" (click)="save(true, true)">Place Order</button>
            </div>
        </div>
    </div>
</div>
