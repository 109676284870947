import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageElement } from 'src/app/modules/service-order/models/popup.model';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { StatusDropdown } from 'src/app/modules/service-order/structure/structure';
import { ApiResponse, Companies, LinksEnum, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { parentStatuses, coreInventoryRetrunOrderStatuses, returnOrdersCategoryTypes, returnOrderTypes } from '../../models/orders.model';
import { InventoryService } from '../../services/inventory.service';

@Component({
  selector: 'app-core-return-order-popup',
  templateUrl: './core-return-order-popup.component.html',
  styleUrls: ['./core-return-order-popup.component.scss']
})
export class CoreReturnOrderPopupComponent implements OnInit {

  USER;
  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = 'details';
  @Input() id: string = null;
  @Input() showComments: boolean = false;
  @Input() data = null;
  @Input() itemsData = [];
  @Input() category: string;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  // @Output() onOrderPlaced = new EventEmitter();
  // @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  // statusDropdown: PageElement = StatusDropdown;

  validateDetails: boolean = true;
  validateItems: boolean = true;
  showLoader: boolean = true;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  isTechnician: boolean = false;
  createdDate: Date = new Date();
  shipmentMethods = [];
  // categories = {};
  commentCount = 0;
  attachmentCount = 0;
  detailsData: Object;
  generateStatmentBtnDisabled: boolean = false;
  itemsDDL: Object[] = [];

  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('itemsTab') items;
  @ViewChild('coreItemsTab') coreItems;
  @ViewChild('attachmentsTab') attachments;

  attachmentsData = [];
  returnItemsReasons = []
  coreReturnOrderOpenStatus: string;
  showStatement: boolean = false;
  statmentUrl: any = '';
  statementPopupTitle: string = "Return Order Statement";
  returnType: any = "ReturnOrderDefective";
  isCoreReturnOrder: boolean = false;
  returnOrderTypes: { returnOrder: string; coreReturnOrder: string; damagedReturnOrder: string; };
  constructor(
    private commonService: CommonService,
    private serviceOrderService: ServiceOrderService,
    private inventoryService: InventoryService
  ) {
      this.USER = this.commonService.USER;
      this.isTechnician = [UserRoles.fst].includes(this.USER.role);
      this.coreReturnOrderOpenStatus = coreInventoryRetrunOrderStatuses.open;
      this.returnOrderTypes = returnOrderTypes;
  }

  ngOnInit(): void {
    this.isCoreReturnOrder = this.category === returnOrdersCategoryTypes.defective;
    this.inventoryService.popupLoader.pipe(
      takeUntil(this.destroy$),
    ).subscribe((showLoader: any) => {
        this.showLoader = showLoader;
    });
    this.loadPage();
  }

  loadPage() {
    this.setPageData();
    this.setItemsTabData();
    this.setAttachmentsTabData();
    this.loadAPIs();
  }

  setPageData() {
    if ( !this.data ) {
      this.data = {
          returnOrderId: '',
          status: coreInventoryRetrunOrderStatuses.open,
          parentStatus: parentStatuses.open,
          company: this.USER.company,
          warehouse: this.USER.warehouse,
          orderCurrency: this.USER.orderCurrency,
          createdBy: this.data?.createdBy || this.USER.userId,
          technicianId: this.data?.technicianId || this.USER.userId,
          technicianName: this.data?.technicianName || this.USER.userName,
          lastUpdatedBy: this.data?.lastUpdatedBy || this.USER.userName,
          lastUpdatedByName: this.data?.lastUpdatedByName || this.USER.userName,
          itemList: [],
          comments: [],
          attachments: []
      };
    } else {
      this.commentCount = this.data.comments.length;
      this.attachmentCount = this.data.attachments.length;
    }
  }

  setDetailsTabData() {
    this.detailsData = {
        shipmentOrderNumber: this.data?.shipmentOrderNumber || null,
        shipmentMethodCode: this.data?.shipmentMethodCode || this.getDefaultShipmentMethod('value'),
        shipmentMethodDescription: this.data?.shipmentMethodDescription || this.getDefaultShipmentMethod('description'),
        estimatedDeliveryDate: this.data?.estimatedDeliveryDate && new Date(this.data.estimatedDeliveryDate) || null,
        trackingId: this.data?.trackingId || null,
        warehouseOrderLN: this.data?.warehouseOrderLN || null,
        notes: this.data?.notes || null
    }
  }

  getDefaultShipmentMethod(key) {
    let shipmentMethod: any;
    if(this.commonService.USER.company === Companies.kingslynn){
      shipmentMethod = this.shipmentMethods.find( method => method.text === "UPS WORLD EXP 1-2 DAY 1030AM") || null;
    } else {
      shipmentMethod = this.shipmentMethods.find( method => method.text === 'UPS GROUND') || this.shipmentMethods[0];
    }
    return shipmentMethod[key];
  }

  setItemsTabData() {
    this.itemsData = this.data?.itemList && this.data?.itemList.length ? this.data.itemList : this.itemsData || [];

    this.itemsData?.length && this.itemsData?.map((item, index) => {
      return this.setItemsRow(item, index);
    });
  }

  setItemsRow(item, index) {
      item.returnOrderDetailId = item.returnOrderDetailId || '',
      item.tempRowId = index;
      item.lineNumber = item.lineNumber || index + 1;
      item.itemCode = item.itemCode || item.item || '';
      item.itemDescription = item.itemDescription || item.description || '';
      item.itemGroup = item.itemGroup || '';
      item.serviceItemGroup = item.serviceItemGroup || '';
      item.quantity = item.quantity;
      item.reasonCode = item.reasonCode || '';
      item.createdBy = item.createdBy || '';
      item.technicianId = item.technicianId || '';
      item.technicianName = item.technicianName || '';
      item.lastUpdatedBy = item.lastUpdatedBy || '';
      item.lastUpdatedByName = item.lastUpdatedByName || '';
      item.isDeleted = item.isDeleted || false;
      item.serviceOrderId = item.serviceOrderId;
      item.serviceOrderIdLN = item.serviceOrderIdLN;
      if(this.category === returnOrdersCategoryTypes.defective){
        item.isDefective = item.isDefective;
        item.itemSOKey = `${item.itemCode}-${item.serviceOrderIdLN?item.serviceOrderIdLN:item.serviceOrderId}`;
      } else if(this.category === returnOrdersCategoryTypes.quarantined){
        item.inQuarantine = item.inQuarantine;
        item.itemSOKey = item.itemCode;
      }
      return item;
  }

  setAttachmentsTabData() {
      this.attachmentsData = this.data?.attachments || [];
  }

  loadAPIs() {
    // this.showLoader = true;
    const promises = [
      this.commonService.getShippingMethodsForDDL(this.USER.company),
      this.inventoryService.getReasonOrExceptionCodesForDDL(this.returnType)
    ];

    forkJoin(promises).subscribe( (res: any) => {
        this.shipmentMethods = res[0].result;
        this.returnItemsReasons = res[1].result;
        this.data.status = this.data && this.data.status ? this.data.status : coreInventoryRetrunOrderStatuses.open;
        this.data.parentStatus = this.data && this.data.parentStatus ? this.data.parentStatus : parentStatuses.open;
        this.setDetailsTabData();
        this.setData();
        this.generateStatmentBtnDisabled = this.data.status !== this.coreReturnOrderOpenStatus;
        this.loadedData = JSON.stringify(this.data);
        this.showLoader = false;
    }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        throw error;
    });
  }

  getDataForSave(){
    if(this.isCoreReturnOrder){
      return {
        ...this.data,
        ...this.details?.getData(),
        ...this.coreItems?.getData()
      };
    } else {
      return{
        ...this.data,
        ...this.details?.getData(),
        ...this.items?.getData()
      };
    }
  }

  getDataForGenerateStatement(){
    if(this.data.status === this.coreReturnOrderOpenStatus){
      const data = this.getDataForSave();
      data.status = coreInventoryRetrunOrderStatuses.closed;
      return data;
    } else {
      if(this.isCoreReturnOrder){
        return {
          ...this.data,
          ...this.details?.getData(),
          ...this.coreItems.getSelectedData()
        }
      } else {
        return {
          ...this.data,
          ...this.details?.getData(),
          ...this.items?.getSelectedData()
        }
      }
    }
  }

  setData() {
      if(this.isCoreReturnOrder){
        this.data = {
          ...this.data,
          ...this.details?.getData(),
          ...this.coreItems?.getData()
        };
      } else {
        this.data = {
          ...this.data,
          ...this.details?.getData(),
          ...this.items?.getData()
        };
      }
  }

  setDataForStatement(){
    if(this.data.status === this.coreReturnOrderOpenStatus){
      this.setData();
    } else {
      if(this.isCoreReturnOrder){
        return {
          ...this.data,
          ...this.details?.getData(),
          ...this.coreItems.getSelectedData()
        }
      } else {
        return {
          ...this.data,
          ...this.details?.getData(),
          ...this.items?.getSelectedData()
        }
      }
    }
  }

  onCommentUpdate(data: any) {
    this.commentCount = data.filter(comment => !comment.isDeleted).length;
    this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
      this.data.attachments = data;
      this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  detailsTabUpdated(args) {
    this.data = {
      ...this.data,
      ...args
    }
  }

  isChanged() {
      return this.loadedData !== JSON.stringify(this.data);
  }

  validate() {
    this.validateDetails = this.details.validate();
    this.validateItems = this.items.validate();
    return this.validateDetails && this.validateItems;
  }

  validateForSave(generateStatement?: boolean){
    this.validateDetails = this.details.validate(generateStatement);
    if(this.isCoreReturnOrder){
      this.validateItems = generateStatement ? this.coreItems.validateForGenerateStatment():this.coreItems.validateForSave();
    } else {
      this.validateItems = generateStatement ? this.items.validateForGenerateStatment():this.items.validateForSave();
    }
    return this.validateDetails && this.validateItems;
  }

  saveOrder(close?){
    if ( this.validateForSave()) {
      this.inventoryService.popupLoader.next(true);
      this.validationApplied = true;
      this.saveApiCalled(close, false);
    } else {
      this.inventoryService.popupLoader.next(false);
      this.validationApplied = false;
    }
  }

  generateStatement(){
    if ( this.validateForSave(true)) {
      this.inventoryService.popupLoader.next(true);
      this.validationApplied = true;
      this.saveApiCalled(false, this.data.status === this.coreReturnOrderOpenStatus);
    } else {
      this.inventoryService.popupLoader.next(false);
      this.validationApplied = false;
    }
  }

  saveApiCalled(close, updateStatus?){
    let data = {};
    if(updateStatus || this.data.status !== this.coreReturnOrderOpenStatus){
      data = this.getDataForGenerateStatement();
    } else {
      data = this.getDataForSave();
    }

    
    if(this.data.status === this.coreReturnOrderOpenStatus){
      let saveObservable = null;
      
      if(this.category === returnOrdersCategoryTypes.defective){
        saveObservable = this.inventoryService.saveCoreReturnOrder(data);
      } else if(this.category === returnOrdersCategoryTypes.quarantined){
        saveObservable = this.inventoryService.saveDamagedReturnOrder(data);
      }

      saveObservable.subscribe(
        (apiResponse) => {
          this.afterGettingSaveResponse(apiResponse, close, updateStatus);
        },
        (error) => {
          this.inventoryService.popupLoader.next(false);
          this.commonService.showNotification('error', error.message);
        }
      );
    } else {
      this.generateStatementFunction(data);
    }
  }

  afterGettingSaveResponse(res, close, updateStatus) {
    if (res.isSuccessful) {
      this.handleSuccessfulResponse(res, close, updateStatus);
    } else {
      this.handleErrorResponse(res);
    }
  }
  
  handleSuccessfulResponse(res, close, updateStatus) {
    if (!updateStatus) {
      this.commonService.showNotification('success', res.message);
    }
  
    this.id = res.result.returnOrderId;
    this.data = res.result;
    this.setAttachmentsTabData();
    this.setItemsTabData();
    this.loadedData = JSON.stringify(this.data);
  
    if (updateStatus) {
      this.generateStatementFunction(this.data);
    } else {
      this.loadPage();
    }
  
    this.onSave.emit(res.result);
  
    if (close) {
      this.close(true);
    }
  }
  
  handleErrorResponse(res) {
    this.commonService.showNotification('error', res.message);
    this.inventoryService.popupLoader.next(false);
  }

  toggleGenerateStatementButton(event){
    const elem: any = document.getElementById('core-return-order-btn');
    if(event.length){
      elem.disabled = false;
    } else {
      elem.disabled = true;
    }
  }


  generateStatementFunction(data){
    // Generate statement API will be called here
    let saveObservable = null;
    if(this.category === returnOrdersCategoryTypes.defective){
      saveObservable = this.inventoryService.generateStatementOfCoreReturnItem(data)
      this.statementPopupTitle = "Core Defective Items Return Order Statement";
    } else if(this.category === returnOrdersCategoryTypes.quarantined){
      saveObservable = this.inventoryService.generateStatementOfDamagedReturnItem(data)
      this.statementPopupTitle = "Damaged Items Return Order Statement";
    }
    saveObservable.subscribe((res: any) => {
      if ( res.fileId ) {
        this.statmentUrl = res.uri;
        this.showStatement = true;
      } else {
        this.commonService.showNotification('error', res.message);
      }
      this.inventoryService.popupLoader.next(false);
    }, (error: HttpErrorResponse) => {
        this.inventoryService.popupLoader.next(false);
        throw error;
    });
  }

  statementViewerClosed(){
    this.showLoader = true;
    this.showStatement = false;
    this.statmentUrl = '';
    this.statementPopupTitle = 'Return Order Statement';
    this.refreshPopup();
  }

  refreshPopup(){
    if(this.category === returnOrdersCategoryTypes.defective){
      this.inventoryService.getCoreReturnOrderById(this.data.returnOrderId)
      .subscribe((res: any) => {
        if ( res.isSuccessful ) {
          // this.commonService.showNotification('success', res.message);
          this.data = res.result;
          this.setPageData();
          this.setItemsTabData();
          this.setAttachmentsTabData();
        } else {
          this.commonService.showNotification('error', res.message);
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.inventoryService.popupLoader.next(false);
        throw error;
      });
    } else if(this.category === returnOrdersCategoryTypes.quarantined){
      this.inventoryService.getDamagedReturnOrderById(this.data.returnOrderId)
      .subscribe((res: any) => {
        if ( res.isSuccessful ) {
          // this.commonService.showNotification('success', res.message);
          this.data = res.result;
          this.setPageData();
          this.setItemsTabData();
          this.setAttachmentsTabData();
        } else {
          this.commonService.showNotification('error', res.message);
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.inventoryService.popupLoader.next(false);
        throw error;
      });
    }
  }
  
  close(forceClose?) {
      if ( !this.isChanged() || forceClose) {
          this.onClose.emit();
      } else {
        if(this.data.status === this.coreReturnOrderOpenStatus){
          this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if ( result.isConfirmed ) {
                  this.onClose.emit();
              }
          })
        } else {
          this.onClose.emit();
        }
      }
  }

  reset() {
    if ( this.isChanged() ) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
        .then(result => {
            if ( result.isConfirmed ) {
              this.data = JSON.parse(this.loadedData);
              this.loadPage();
            }
        })
    } else {
      this.commonService.showNotification('info','There is nothing to reset!');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
