import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  saveUser(userData) {
    return this.http.post(`${BASE_URL}User/SaveUser`, userData);
  }

  getIscTechnicianManagerList() {
    return this.http.get(`${BASE_URL}ServiceEmployee/GetIscTechnicianManagerList`);
  }

  getUserData(employeeId) {
    return this.http.get(`${BASE_URL}User/GetUserData?employeId=${employeeId}`);
  }

  EnableDisableUser(userData) {
    return this.http.post(`${BASE_URL}User/EnableDisableUser`, userData);
  }
}
