import { Component, Input, OnInit, ViewChild, OnChanges, OnDestroy, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { 
  ExcelExportProperties, 
  GridComponent, 
  ForeignKeyService, 
  ToolbarService, 
  FilterService, 
  SortService, 
  ExcelExportService, 
  CommandColumnService, 
  EditService 
} from '@syncfusion/ej2-angular-grids';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-angular-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { IscCostTypes, IscCostTypesList, IscRuleModel, IscSubCostTypeList, SubCostTypes } from 'src/app/modules/service-order/models/service-order.model';
import { ExecutionTabService } from 'src/app/modules/service-order/services/execution-tab.service';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { Query } from '@syncfusion/ej2-data';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { ClaimStatuses } from 'src/app/shared/models/claim.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ACTION_NOT_PERMISSIBLE, CANNOT_CLAIM_TRANSACTION_MESSAGE, UPLOAD_RECEIPT_MESSAGE } from 'src/app/shared/messages';
import { getValue } from '@syncfusion/ej2-base';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-isc-so-claims',
  templateUrl: './isc-so-claims.component.html',
  styleUrls: ['./isc-so-claims.component.scss'], 
  providers: [
    ToolbarService,
    FilterService,
    SortService,
    ExcelExportService,
    CommandColumnService,
    EditService,
    ForeignKeyService
  ]
})
export class IscSOClaimsComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$: Subject<null> = new Subject();

  showColumnChooser: boolean;
  isIsc: boolean;
  isIscManager: boolean;
  isIscSupervisor: boolean = false;
  isManager: boolean;
  isCSA: boolean;
  isTabValid: boolean = true;
  canApprove: boolean = false;

  USER;
  claimsQuery: any;

  referenceActivity: any;
  referenceActivityObject: any;

  costType: any;
  costTypeObject: any;

  subcostType: any;
  subcostTypeObject: any;

  mileage: any;
  mileageObject: any;

  itemDescription: any;
  itemDescriptionObject: any;

  discountAmount: any;
  discountAmountObject: any;

  taxAmount: any;
  taxAmountObject: any;

  taxPercent: any;
  taxPercentObject: any;

  quantity: any;
  quantityObject: any;

  price: any;
  priceObject: any;

  totalAmount: any;
  totalAmountObject: any;

  totalClaim: any;

  item: any;
  itemCodeObject
  onItemUpdate = new Subject<string>();
  itemsList = [];
  rowServiceItemGroup: any;

  getPriceForItem: boolean = true;
  itemChangedFromAdvanceSearch: boolean = false;

  columns = [];

  isKingslynn: boolean = false;

  tempUnit: string = '';
  serviceItemGroup: any;
  coreItemGroup = ['450SVC'];
  itemGroup: any;
  selectedRowId: string;
  warehouse

  showItemAdvancedSearchPopup: boolean = false;

  // validServiceTypeCodes = ["WO", "WAR", "DEM", "CON", "TRN"];
  invalidServiceTypeCodes = ['TRN', 'POL', 'PAD', 'DEM', 'WSC', 'RFB', 'ECN'];

  @Input() claimsForm: UntypedFormGroup;
  @Input() iscRulesForSO: IscRuleModel;
  @Input() iscEmployeeDetails;
  @Input() costTypes = IscCostTypesList;
  @Input() taxRates = [];
  @Input() soReferenceActivities: any;
  @Input() referenceActivities: any;
  @Input() businessRules: any;
  @Input() businessRulesPassed: boolean = false;

  // @Output() claimRulesUpdated = new EventEmitter();
  @Output() defaultComment = new EventEmitter();
  @Output() lineId = new EventEmitter();
  @Output() lineComment = new EventEmitter();
  @Output() businessRulesUpdated = new EventEmitter();

  @ViewChild('claimsGridColumnChooser') columnChooser;
  @ViewChild('claimsGrid') claimsGrid: GridComponent;
  @ViewChild('includeTaxOption') includeTaxOption;
  @ViewChild('includeTaxCheckbox') includeTaxCheckbox;

  currentStatus: any;
  enableToolbarClass: string;
  tempReferenceActivityDescription: any;
  additionalMileage: HTMLInputElement;
  additionalMileageObject: any;
  serviceTypeCode: string;
  calloutRate: any;

  constructor(
    private commonService: CommonService,
    private claimService: ClaimsService,
    private serviceOrderService: ServiceOrderService,
    private fb: UntypedFormBuilder
  ) { }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const claimsGridElement = document.getElementById('claimsGrid');
    if (this.claimsGrid?.isEdit && claimsGridElement) {
      const excludedClasses = ['e-ddl', 'e-popup', 'e-lib', 'e-control', 'e-popup-close'];
      let targetElement = event.target as HTMLElement;
      while (targetElement) {
        if (excludedClasses.some(className => targetElement.classList.contains(className))) {
          event.stopPropagation();
          return;
        }
        targetElement = targetElement.parentElement;
      }
      this.claimsGrid.endEdit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.USER = this.commonService.USER;
    this.isIsc = this.USER.role === UserRoles.isc;
    this.isIscManager = this.USER.role === UserRoles.iscManager;
    this.isIscSupervisor = this.USER.role === UserRoles.IscSupervisor;
    this.isCSA = this.USER.role === UserRoles.csa;
    this.isManager = this.USER.role === UserRoles.conwayServiceManager;
    this.isKingslynn = this.USER.company === Companies.kingslynn
    this.warehouse = this.claimsForm.get('technicianWarehouse') || this.USER.warehouse;
    this.enableToolbarClass = [ClaimStatuses.open, ClaimStatuses.awaitingInfo].includes(this.claimsForm.value.status) ? '' : 'disabled-grid-button';

    this.getGridColumns();

    this.onItemUpdate
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        if (this.itemCodeObject) {
          this.itemCodeObject.showPopup();
          this.itemCodeObject.showSpinner();
        }
        this.getItemList({ item: value });
      });
  }

  getItemList(rowData?) {
    const value = rowData?.item || '';
    let serviceItemGroup = '';
    this.commonService.getItems(value, 30, this.USER.company)
      .subscribe((res: ApiResponse) => {
        if (res && res.result && res.result.length) {
          res.result.map(item => {
            item.value = item.item;
            item.text = `${item.item} | ${item.itemDescription}`;
          });

          const existingItems = [];
          (this.claimsGrid?.dataSource as any)?.forEach(transaction => {
            if (transaction.item !== this.itemCodeObject?.value) {
              existingItems.push(transaction.item);
            }
          });
          this.itemsList = res.result.filter(item => !existingItems.includes(item.value));
          if (this.itemCodeObject) {
            this.itemCodeObject.hideSpinner();
            this.itemCodeObject.dataSource = this.itemsList;
          }
        } else {
          this.itemsList = [];
          if (this.itemCodeObject) {
            this.itemCodeObject.hideSpinner();
            this.itemCodeObject.dataSource = [];
          }
        }
      });
  }

  updateTotalClaimAmounts() {
    let claimAmount = 0;
    let disputedAmount = 0;
    let netClaimAmount = 0;
    let taxAmount = 0;
    this.claimsForm.value.iscClaimDetails.forEach((claim, index) => {
      if (!claim.isDeleted) {
        if ([ClaimStatuses.rejected, ClaimStatuses.flagged].includes(claim.status)) {
          disputedAmount += claim.totalAmount;
        }
        claimAmount += claim.totalAmount;
        if (this.claimsForm.value.includeTax && ![ClaimStatuses.rejected, ClaimStatuses.flagged].includes(claim.status)) {
          taxAmount += claim.taxAmount ?? 0;
        }
      }
    }, 0);
    this.claimsForm.get('claimAmount').setValue(claimAmount);
    this.claimsForm.get('disputedAmount').setValue(disputedAmount);
    this.claimsForm.get('taxAmount').setValue(taxAmount);
    netClaimAmount = claimAmount > disputedAmount ? claimAmount - disputedAmount : 0;
    this.claimsForm.get('netClaimAmount').setValue(netClaimAmount);
    this.claimsForm.get('totalClaimAmount').setValue(netClaimAmount + taxAmount);
  }

  getGridColumns() {
    this.columns = [
      { field: 'rowId', headerText: 'Sr.No', type: 'number', isPrimaryKey: true, showInColumnChooser: true, width: 100, visible: true },
      { field: 'id', headerText: 'ID', type: 'number', format: 'n', textAlign: 'Left', width: 100, showInColumnChooser: false, visible: false },
      { field: 'claimId', headerText: 'claim Id', type: 'string', textAlign: 'Left', visible: false },
      {
        field: 'referenceActivityCode', headerText: 'Reference Activity', textAlign: 'Left', showInColumnChooser: false, validationRules: { required: true }, filter: { type: 'Excel' },
        foreignKeyValue: 'description', foreignKeyField: 'value', dataSource: this.soReferenceActivities, edit: this.editReferenceActivity()
      },
      { field: 'lineComments', headerText: 'lineComments', type: 'string', textAlign: 'Left', visible: false, showInColumnChooser: false },
      {
        field: 'costType', headerText: 'Cost Type', type: 'string', width: 115, textAlign: 'Left', filter: { type: 'Excel' }, visible: true, showInColumnChooser: true,
        foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.costTypes, edit: this.editCostType(), validationRules: { required: true }
      },
      {
        field: 'subCostType', headerText: 'Sub-Cost Type', width: 137, textAlign: 'Left', filter: { type: 'Excel' }, visible: true, showInColumnChooser: true,
        foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: IscSubCostTypeList, edit: this.editSubCostType()
      },
      { field: 'item', headerText: 'Item', width: 175, textAlign: 'Left', filter: { type: 'Excel' }, visible: true, showInColumnChooser: true, edit: this.editItem(), validationRules: { required: true } },
      { field: 'status', headerText: 'Status', width: 175, textAlign: 'Left', visible: false, filter: { type: 'Excel' }, showInColumnChooser: false },
      { field: 'description', headerText: 'Description', width: 175, textAlign: 'Left', edit: this.editItemDescription(), visible: true, showInColumnChooser: true },
      {
        field: 'quantity', headerText: 'Quantity', textAlign: 'Left', visible: true, showInColumnChooser: true, width: 110, format: 'n2', step: 1,
        decimals: 2, validateDecimalOnType: true, edit: this.editQuantity(), validationRules: { required: true }
      },
      { field: 'mileage', headerText: 'Mileage', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 130, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, edit: this.editMileage(), validationRules: { required: false } },
      // { field: 'addtMileageCharged', headerText: 'Addt Mile Chg', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 130, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, validationRules: { required: false }, allowEditing: false, edit: this.editAdditionalMileageCharge() },
      {
        field: 'price', headerText: 'Price', textAlign: 'Left', visible: true, showInColumnChooser: true, width: 90, format: 'n2',
        edit: this.editPrice(), validationRules: { required: true }
      },
      { field: 'discountAmount', headerText: 'Discount', width: 115, visible: true, showInColumnChooser: true, textAlign: 'Left', format: 'n2', edit: this.editDiscountAmount() },
      { field: 'totalAmount', headerText: 'Total Amount', width: 150, textAlign: 'Left', visible: true, showInColumnChooser: true, format: 'n2', edit: this.editTotalAmount() },
      { field: 'taxPercent', headerText: 'Tax %', width: 105, visible: true, showInColumnChooser: true, textAlign: 'Left', format: 'n2', edit: this.editTaxPercent() },
      { field: 'taxAmount', headerText: 'Tax Amount', type: 'number', width: 130, visible: true, showInColumnChooser: true, textAlign: 'Left', format: 'n2', edit: this.editTaxAmount(), validationRules: { required: true} },
      { field: 'origin', headerText: 'Origin', width: 175, textAlign: 'Left', visible: true, showInColumnChooser: true, allowEditing: false },
      { field: 'lastUpdatedDate', headerText: 'Last Edited', type: 'datetime', textAlign: 'Left', format: { type: 'datetime', format: 'd MMM, y h:mm a' }, showInColumnChooser: true, allowEditing: false, width: 250, visible: false },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', allowEditing: false, allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 160,
        commands: []
      }
    ];
    this.setActions()
  };

  setActions() {
    const isReviewer = this.claimsForm.value.reviewer && this.claimsForm.value.reviewer === this.USER.employeeId;
    const actionsColumn = this.claimsGrid ? (this.claimsGrid.columns as any).find(column => column.field === 'Actions') : this.columns.find(column => column.field === 'Actions');
    actionsColumn.commands = []
    actionsColumn.commands.push({ title: 'Comment', buttonOption: { iconCss: `fa fa-comment`, cssClass: 'e-flat comments' } });
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Action_Approve']?.visible
      && (this.USER.role === UserRoles.csa && this.claimsForm.value.status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && this.claimsForm.value.status === ClaimStatuses.pendingApproval
       || this.USER.role === UserRoles.administratorRole && this.claimsForm.value.status === ClaimStatuses.pendingApproval
      )
    ) {
      actionsColumn.commands.push({ title: 'Approve', buttonOption: { iconCss: 'fas fa-check', cssClass: 'e-flat text-success approve-btn' } });
    }
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Action_Reject']?.visible
      && (this.USER.role === UserRoles.csa && this.claimsForm.value.status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && this.claimsForm.value.status === ClaimStatuses.pendingApproval
       || this.USER.role === UserRoles.administratorRole && this.claimsForm.value.status === ClaimStatuses.pendingApproval
      )
    ) {
      actionsColumn.commands.push({ title: 'Reject', buttonOption: { iconCss: 'fas fa-times', cssClass: `e-flat text-danger reject-btn` } });
    }
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Action_Flag']?.visible
      && (this.USER.role === UserRoles.csa && this.claimsForm.value.status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && this.claimsForm.value.status === ClaimStatuses.pendingApproval
       || this.USER.role === UserRoles.administratorRole && this.claimsForm.value.status === ClaimStatuses.pendingApproval
      )
    ) {
      actionsColumn.commands.push({ title: 'Flag', buttonOption: { iconCss: 'fas fa-flag', cssClass: `e-flat flag-btn` } });
    }
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Action_Delete']?.visible) {
      actionsColumn.commands.push({ title: 'Delete', buttonOption: { iconCss: 'fas fa-trash delete-button', cssClass: `e-flat delete-button` } });
    }
    this.claimsGrid?.refresh();
  }

  onCommandClick(args) {
    switch (args.commandColumn.title) {
      case 'Delete':
        this.commonService.showConfirmation('Are you sure? You want to delete this claim?').then(result => {
          if (result.isConfirmed) {
            if (args.rowData.id) {
              args.rowData.isDeleted = true;
            } else {
              const index = this.claimsForm.value.iscClaimDetails.findIndex(ex => ex.rowId === args.rowData.rowId);
              this.claimsForm.value.iscClaimDetails.splice(index, 1);
            }
            this.claimsForm.value.iscClaimDetails.sort((a, b) => a.isDeleted - b.isDeleted).map((ex, index) => { ex.rowId = index + 1; return ex; })
            this.claimsGrid.refresh();
            this.updateTotalClaimAmounts();
            this.updateBusinessRulesStatuses(this.claimsForm.value);
          }
        });
        break;
      case 'Approve':
        this.claimsGrid.setCellValue(args.rowData.rowId, 'status', ClaimStatuses.approved);
        this.claimsGrid.refresh();
        break;
      case 'Reject':
        this.claimsGrid.setCellValue(args.rowData.rowId, 'status', ClaimStatuses.rejected);
        this.claimsGrid.refresh();
        break;
      case 'Flag':
        this.claimsGrid.setCellValue(args.rowData.rowId, 'status', ClaimStatuses.flagged);
        this.claimsGrid.refresh();
        break;
      case 'Comment':
        const commentTemplate = "<div style='display:flex; background: #ffff0052'><div style='text-decoration: underline'>Reference:</div><div>&nbsp;Claim line no. " + args.rowData.rowId + "</div></div><br>"
        this.defaultComment.emit(commentTemplate)
        this.lineId.emit(args.rowData.id)
        this.lineComment.emit(args.rowData.lineComments)
        break;
      default: break;
    }
    this.updateTotalClaimAmounts();
    this.updateBusinessRulesStatuses(this.claimsForm.value);
  }

  dataBound(args) {
  }

  rowDataBound(args: any) {
    const data = args.data;
    const $row = args.row;

    if (data.isManual && [ClaimStatuses.open, ClaimStatuses.awaitingInfo].includes(this.claimsForm.value.status)) {
      $row.getElementsByClassName('delete-button')[0]?.classList?.remove('disabled');
    } else {
      $row.getElementsByClassName('delete-button')[0]?.classList?.add('disabled');
    }
    // if ([ClaimStatuses.open, ClaimStatuses.inProgress, ClaimStatuses.awaitingInfo, ClaimStatuses.pendingApproval].includes(this.claimsForm.value.status)) {
    //   $row.getElementsByClassName('comments')[0]?.classList?.remove('disabled');
    // } else {
    //   $row.getElementsByClassName('comments')[0]?.classList?.add('disabled');
    // }
    if ([ClaimStatuses.inProgress, ClaimStatuses.pendingApproval].includes(this.claimsForm.value.status)) {
      $row.getElementsByClassName('approve-btn')[0]?.classList?.remove('disabled');
      $row.getElementsByClassName('approve-btn')[0]?.classList?.add('text-success');
      $row.getElementsByClassName('reject-btn')[0]?.classList?.remove('disabled');
      $row.getElementsByClassName('reject-btn')[0]?.classList?.add('text-danger');
      $row.getElementsByClassName('flag-btn')[0]?.classList?.remove('disabled');
    } else {
      $row.getElementsByClassName('approve-btn')[0]?.classList?.add('disabled');
      $row.getElementsByClassName('approve-btn')[0]?.classList?.remove('text-success');
      $row.getElementsByClassName('reject-btn')[0]?.classList?.add('disabled');
      $row.getElementsByClassName('reject-btn')[0]?.classList?.remove('text-danger');
      $row.getElementsByClassName('flag-btn')[0]?.classList?.add('disabled');
    }
    if (!data.id)
      $row.getElementsByClassName('comments')[0]?.classList.add('e-hide');
    else
      $row.getElementsByClassName('comments')[0]?.classList.remove('e-hide');

    if (data.lineComments?.length > 0) {
      $row.getElementsByClassName('comments')[0]?.classList.add('color-orange');
    } else {
      $row.getElementsByClassName('comments')[0]?.classList.remove('color-orange');
    }
  }

  onGridCreated() {
    this.claimsQuery = new Query().where('isDeleted', 'equal', false);
    // this.canApprove = this.claimStatus === ClaimStatuses.inProgress && this.commonService.roleClaims['Claims_Popup_CanApproveBtn']?.visible && this.businessRulesPassed || this.commonService.roleClaims['Claims_Popup_CanApproveBtn']?.visible && this.claimStatus === ClaimStatuses.pendingApproval;
    this.canApprove = this.claimsForm.value.status === ClaimStatuses.inProgress && this.isCSA && this.claimsForm.value.businessRulesPassed || this.isManager && this.claimsForm.value.status === ClaimStatuses.pendingApproval;
    this.showRoleBasedElementsInToolbar(this.claimsForm.value.status);
    updateFilterIcon({ requestType: 'filtering' }, this.claimsGrid);
  }

  showRoleBasedElementsInToolbar(status) {
    this.claimsGrid.toolbar = [
      { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const addButtonIndex = this.claimsGrid.toolbar.findIndex((item: any) => item.id === 'column-chooser') + 1;
    const approveAllIndex = this.showAddClaimButtonInToolbar(addButtonIndex, status);
    const rejectAllIndex = this.showApproveAllInToolbar(approveAllIndex, status);
    const flagAllIndex = this.showRejectAllInToolbar(rejectAllIndex, status);
    this.showFlagAllInToolbar(flagAllIndex, status);
    let searchIndex = this.claimsGrid.toolbar.findIndex(item => item === 'Search');
    searchIndex = this.showIncludeTaxCheckbox(searchIndex, status);
    this.showExcelExportInToolbar(searchIndex + 1);
  }

  includeTaxCheckboxCreated(checkboxRef) {
  }

  includeTaxChange(args) {
    this.claimsForm.get('includeTax').setValue(args.checked);
    this.updateTotalClaimAmounts();
  }

  showAddClaimButtonInToolbar(index, status) {
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Toolbar_AddClaimLine']?.visible
      && [ClaimStatuses.open, ClaimStatuses.awaitingInfo].includes(status)
    ) {
      this.claimsGrid.toolbar.splice(index, 0, { text: '', id: 'add-item', prefixIcon: 'e-add', cssClass: `grid-add-claim-button ${this.enableToolbarClass}`, tooltipText: 'Add Claim Item' });
      return index + 1;
    }
    return index;
  }

  showApproveAllInToolbar(index, status) {
    const isReviewer = this.claimsForm.value.reviewer && this.claimsForm.value.reviewer === this.USER.employeeId;
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Toolbar_ApproveAll']?.visible
      && (this.USER.role === UserRoles.csa && status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && status === ClaimStatuses.pendingApproval
        || this.USER.role === UserRoles.administratorRole && status === ClaimStatuses.pendingApproval)
    ) {
      this.claimsGrid.toolbar.splice(index, 0, { text: '', id: 'approve-all', align: 'Right', template: '<div style="color: #14db14; font-weight: 500">Approve All</div>', tooltipText: 'Approve All', cssClass: 'grid-approve-all-icon' });
      return index + 1;
    }
    return index;
  }

  showRejectAllInToolbar(index, status) {
    const isReviewer = this.claimsForm.value.reviewer && this.claimsForm.value.reviewer === this.USER.employeeId;
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Toolbar_RejectAll']?.visible
      && (this.USER.role === UserRoles.csa && status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && status === ClaimStatuses.pendingApproval
        || this.USER.role === UserRoles.administratorRole && status === ClaimStatuses.pendingApproval)
    ) {
      this.claimsGrid.toolbar.splice(index, 0, { text: '', id: 'reject-all', align: 'Right', template: '<div style="color: #ff2f2f; font-weight: 500">Reject All</div>', tooltipText: 'Reject All', cssClass: 'grid-reject-all-icon' });
      return index + 1;
    }
    return index;
  }

  showFlagAllInToolbar(index, status) {
    const isReviewer = this.claimsForm.value.reviewer && this.claimsForm.value.reviewer === this.USER.employeeId;
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Toolbar_FlagAll']?.visible
      && (this.USER.role === UserRoles.csa && status === ClaimStatuses.inProgress && isReviewer
        || this.USER.role === UserRoles.conwayServiceManager && status === ClaimStatuses.pendingApproval
        || this.USER.role === UserRoles.administratorRole && status === ClaimStatuses.pendingApproval)
    ) {
      this.claimsGrid.toolbar.splice(index, 0, { text: '', id: 'flag-all', align: 'Right', template: '<div style="color: #ffbc00; font-weight: 500; margin-right: 10px">Flag All</div>', tooltipText: 'Flag All', cssClass: 'grid-flag-all-icon' });
    }
  }

  showIncludeTaxCheckbox(index, status) {
    if (this.commonService.roleClaims['Claims_Popup_ServiceOrderGrid_Toolbar_IncludeTax']?.visible
      && [ClaimStatuses.open, ClaimStatuses.awaitingInfo].includes(status)) {
      this.claimsGrid.toolbar.splice(index, 0, { id: 'include-tax-checkbox', align: 'Right', template: this.includeTaxOption, tooltipText: '' });
      return index + 1;
    }
    return index;
  }

  showExcelExportInToolbar(index) {
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.claimsGrid.toolbar.splice(index, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
  }

  updateAllClaimsStatuses(status) {
    (this.claimsGrid.dataSource as any).forEach(claim => {
      this.claimsGrid.setCellValue(claim.rowId, 'status', status);
    });
    this.claimsGrid.refresh();
    this.updateTotalClaimAmounts();
    this.updateBusinessRulesStatuses(this.claimsForm.value);
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'approve-all') {
      this.updateAllClaimsStatuses(ClaimStatuses.approved);
    } else if (args.item.id === 'reject-all') {
      this.updateAllClaimsStatuses(ClaimStatuses.rejected);
    } else if (args.item.id === 'flag-all') {
      this.updateAllClaimsStatuses(ClaimStatuses.flagged);
    } else if (args.item.id === 'add-item') {
      grid.endEdit();
      if (grid.isEdit) {
        this.commonService.showNotification('warning', 'Claim is already in edit mode!');
      } else {
        grid.clearFiltering();
        grid.search('');
        grid.clearSorting();
        this.addItem();
      }
    } else if (args.item.id === 'grid_excelexport') {
      const dataSource = getExcelDataSource(grid);
      let excelExportProperties: ExcelExportProperties = {
        dataSource,
        hierarchyExportMode: 'Expanded',
        theme: {
          header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
        },
        fileName: `Claims (${getCurrentDate()}).xlsx`
      };
      grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'grid_pdfexport') {
      grid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
      grid.clearFiltering();
      grid.search('');
    } else if (args.item.id === 'column-chooser') {
      // this.showColumnChooser = !this.showColumnChooser;
    }
  }

  addItem() {
    this.serviceOrderService.popupLoader.next(true);
    let newIndex = (this.claimsGrid.dataSource as any).filter(item => !item.isDeleted).length;
    let newRow = this.setClaimsRow({}, newIndex);
    this.claimsForm.value.iscClaimDetails.push(newRow);
    this.claimsGrid.refresh();

    setTimeout(() => {
      this.claimsGrid.selectRow(newIndex);
      this.claimsGrid.startEdit();
      this.serviceOrderService.popupLoader.next(false);
    }, 500);
  }

  getMaxRowId(datasource) {
    if (datasource.length) {
      return Math.max.apply(Math, datasource.map(row => row.rowId)) || 0;
    } else {
      return 0;
    }
  }

  setClaimsRow(claim, index) {
    const referenceActivity = this.soReferenceActivities.length === 1 ? this.soReferenceActivities[0].referenceActivity : '';
    const referenceActivityDescription = this.soReferenceActivities.length === 1 ? this.soReferenceActivities[0].referenceActivityDescription : '';
    claim.rowId = this.getMaxRowId(this.claimsGrid.dataSource as any) + 1;
    claim.id = claim.id || 0;
    claim.claimIdRef = claim.claimIdRef || 0;
    claim.claimId = this.claimsForm.value.claimId || '';
    claim.costType = IscCostTypes.material;
    claim.subCostType = claim.subCostType || '';
    claim.item = claim.item || '';
    claim.description = claim.description || '';
    claim.quantity = claim.quantity || 1;
    claim.price = claim.price || 0;
    claim.discountAmount = claim.discountAmount || 0;
    claim.totalAmount = claim.totalAmount || 0;
    claim.taxPercent = claim.taxPercent || 0;
    claim.isDeleted = claim.isDeleted || false;
    claim.createdDate = claim.createdDate || null;
    claim.createdBy = claim.createdBy || null;
    claim.lastUpdatedDate = claim.lastUpdatedDate || null;
    claim.lastUpdatedBy = claim.lastUpdatedBy || null;
    claim.referenceActivityCode = referenceActivity || claim.referenceActivityCode || '';
    claim.referenceActivityDescription = referenceActivityDescription || claim.referenceActivityDescription || '';
    claim.status = claim.status || 'Open';
    claim.serviceTypeCode = claim.serviceTypeCode || '';
    claim.isFlagged = claim.isFlagged || false;
    claim.lineComments = claim.lineComments || [];
    claim.isManual = true;
    claim.origin = 'Manual';
    claim.mileage = 0;
    claim.addtMileageCharged = 0;
    return claim;
  }

  validate() {
    this.claimsGrid?.endEdit();
    this.isTabValid = !this.claimsGrid.isEdit;
    return this.isTabValid;
  }

  refreshGrid() {
    this.claimsGrid.refresh();
  }

  actionBegin(args) {
    this.getPriceForItem = false;
    if (args.requestType === 'beginEdit') {
      // if (!args.rowData.isManual && args.rowData.status !== ClaimStatuses.flagged) {
      //   args.cancel = true;
      // }
      this.setItemCodeValidation(args.rowData.costType);
      this.claimsGrid.element?.getElementsByClassName('delete-button')[0]?.classList?.add('disabled-grid-button');
      if (args.rowData.costType === IscCostTypes.material) {
        this.getItemList(args.rowData);
      }
      this.tempReferenceActivityDescription = args.rowData.referenceActivityDescription;
      this.serviceTypeCode = args.rowData.serviceTypeCode;
    } else if (args.requestType === 'save') {
      args.data.referenceActivityDescription = this.tempReferenceActivityDescription;
      args.data.serviceTypeCode = this.serviceTypeCode;
    }
    if (args.requestType === 'sorting' || args.requestType === 'filterbeforeopen' || args.requestType === 'filterchoicerequest') {
      if (this.claimsGrid.isEdit) {
        args.cancel = true;
      }
    }
  }

  showUploadReceiptMessage() {
    if (this.subcostTypeObject.value && ![SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value)) {
      this.commonService.showNotification('info', UPLOAD_RECEIPT_MESSAGE);
    }
  }

  resetGlobals() {
    this.itemsList = [];
    this.serviceTypeCode = '';
    this.tempReferenceActivityDescription = '';
  }

  actionComplete(args, grid) {
    if (args.requestType === 'save') {
      this.updateTotalClaimAmounts();
      this.showUploadReceiptMessage();
      args.row?.getElementsByClassName('delete-button')[0]?.classList?.remove('disabled-grid-button');
      this.resetGlobals();
      this.isTabValid = true;
    } else if (args.requestType === 'beginEdit') {
      args.row.getElementsByClassName('comments')[0]?.classList?.add('e-hide');
      args.row?.getElementsByClassName('delete-button')[0]?.classList?.add('disabled-grid-button');
    } else if (args.requestType === 'cancel') {
      if (!args.rowData.claimId && args.rowData.isManual) {
        const index = grid.dataSource.findIndex(row => row.rowId === args.data.rowId);
        grid.dataSource.splice(index, 1);
        grid.refresh();
      }
    }
    updateFilterIcon({ requestType: 'filtering' }, grid);
  }

  editReferenceActivity() {
    return {
      create: () => {
        this.referenceActivity = document.createElement('input');
        return this.referenceActivity;
      },
      read: () => {
        if (this.referenceActivityObject) {
          return this.referenceActivityObject.value;
        }
      },
      destroy: () => {
        this.referenceActivityObject.destroy();
      },
      write: (args) => {
        if (!this.claimsForm.value.isNationalAccount) {
          this.soReferenceActivities = this.soReferenceActivities.filter(x => x.description !== "Billable Service");
        }
        this.referenceActivityObject = new DropDownList({
          dataSource: this.soReferenceActivities,
          fields: { value: 'value', text: 'description' },
          value: args.rowData.referenceActivityCode,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
          enabled: args.rowData.isManual,
          change: e => {
            this.tempReferenceActivityDescription = e.itemData.description;
            this.serviceTypeCode = e.itemData.serviceTypeCode;
            this.discountAmount.enabled = this.isDiscountEnabled();
          }
        });
        this.referenceActivityObject.appendTo(this.referenceActivity);
      }
    }
  }

  editCostType() {
    return {
      create: () => {
        this.costType = document.createElement('input');
        return this.costType;
      },
      read: () => {
        if (this.costTypeObject) {
          return this.costTypeObject.value;
        }
      },
      destroy: () => {
        this.costTypeObject.destroy();
      },
      write: (args) => {
        this.costTypeObject = new DropDownList({
          dataSource: this.costTypes,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.costType,
          popupWidth: '150px',
          enabled: args.rowData.isManual,
          change: event => {
            this.priceObject.enabled = this.isIscPriceEnabled(args.rowData);
            this.setItemCodeValidation(event.value);
            this.setClaimLineValues(event.value, args.rowData);
            this.discountAmount.enabled = this.isDiscountEnabled();
          }
        });
        this.costTypeObject.appendTo(this.costType);
      }
    }
  }

  isIscPriceEnabled(rowData) {
    const isBillable = this.getRefActivityCategory(rowData.term) === 'Billable Service';
    if (!this.claimsForm.value.isNationalAccount && isBillable) { return true } else {
      return this.subcostTypeObject?.value && ![SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value);
    }
  }

  setItemCodeValidation(costType) {
    this.claimsGrid.getColumnByField('item').validationRules['required'] = costType === IscCostTypes.material;
    costType !== IscCostTypes.material && document.getElementById('itemCode_Error')?.remove();
  }

  setClaimLineValues(costType: any, rowData) {
    const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
    this.subcostTypeObject.enabled = costType === IscCostTypes.other;
    this.itemCodeObject.enabled = costType === IscCostTypes.material;
    this.subcostTypeObject.value = null;
    this.itemCodeObject.value = null;
    this.itemDescriptionObject.value = null;
    if (costType === IscCostTypes.labor) {
      this.priceObject.value = this.claimsForm.value?.isNationalAccount ? this.iscEmployeeDetails.t2LaborRate : this.iscEmployeeDetails.t1LaborRate;
      this.itemDescriptionObject.value = 'Labor charge';
    }

    if ([IscCostTypes.labor, IscCostTypes.other].includes(costType))
      anchorElement.classList.add('d-none')
    else
      anchorElement.classList.remove('d-none');
  }

  editSubCostType() {
    return {
      create: () => {
        this.subcostType = document.createElement('input');
        return this.subcostType;
      },
      read: () => {
        if (this.subcostTypeObject) {
          return this.subcostTypeObject.value;
        }
      },
      destroy: () => {
        this.subcostTypeObject.destroy();
      },
      write: (args) => {
        this.subcostTypeObject = new DropDownList({
          dataSource: IscSubCostTypeList,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.subCostType,
          popupWidth: '150px',
          enabled: args.rowData.costType === IscCostTypes.other && args.rowData.isManual,
          change: event => {
            if (this.costTypeObject.value === IscCostTypes.other) {
              this.claimsForm?.value?.isNationalAccount && this.checkIscRulesForSubcost(event.value, args.rowData);
              this.itemDescriptionObject.value = event.itemData?.text || null;
              const isTravelCallout_mileage = [SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(event.value);
              this.priceObject.enabled = !isTravelCallout_mileage;
              this.mileageObject.enabled = args.rowData.isManual && event.value && event.value === SubCostTypes.travelCalloutCharge
              if (event.value === SubCostTypes.travelCalloutCharge)
                this.priceObject.value = this.claimsForm?.value?.isNationalAccount ? this.iscEmployeeDetails.t2CalloutRate : this.iscEmployeeDetails.t1CalloutRate;
              else if (event.value === SubCostTypes.mileage)
                this.priceObject.value = this.iscEmployeeDetails.mileageRate;
              
              this.discountAmount.enabled = this.isDiscountEnabled();
            }
          }
        });
        this.subcostTypeObject.appendTo(this.subcostType);
      }
    }
  }

  checkIscRulesForSubcost(subcostType, rowData) {
    subcostType === SubCostTypes.travelCalloutCharge && this.claimMultipleTripCharge(rowData);
    subcostType === SubCostTypes.travelCalloutCharge && this.billMultipleTripCharge(rowData);
    subcostType === SubCostTypes.local && this.claimLocalParts();
    subcostType === SubCostTypes.local && this.billLocalParts();
    subcostType === SubCostTypes.mileage && this.claimAdditionalMileage();
    subcostType === SubCostTypes.mileage && this.billAdditionalMileage();
    subcostType === SubCostTypes.freight && this.claimFreightCharge();
    subcostType === SubCostTypes.freight && this.billFreightCharge();
    subcostType === SubCostTypes.freight && this.claimTax();
  }

  claimMultipleTripCharge(rowData) {
    let travelCalloutChargeExists = false;
    (this.claimsGrid?.dataSource as any)?.forEach(claim => {
      travelCalloutChargeExists = claim.rowId !== rowData.rowId && this.subcostTypeObject.value === SubCostTypes.travelCalloutCharge;
    });
    travelCalloutChargeExists && !this.iscRulesForSO.claimMultipleTripCharge && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
  }

  billMultipleTripCharge(rowData) {
    let travelCalloutChargeExists = false;
    (this.claimsGrid?.dataSource as any)?.forEach(claim => {
      travelCalloutChargeExists = claim.rowId !== rowData.rowId && this.subcostTypeObject.value === SubCostTypes.travelCalloutCharge
    });
    if (travelCalloutChargeExists && !this.iscRulesForSO.billMultipleTripCharge) {
      this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
    }
  }

  claimLocalParts() {
    !this.iscRulesForSO.claimLocalPart && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  billLocalParts() {
    this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  claimAdditionalMileage() {
    !this.iscRulesForSO.claimAddtMileage && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  billAdditionalMileage() {
    !this.iscRulesForSO.billAddtMileage && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  claimFreightCharge() {
    !this.iscRulesForSO.claimFrieght && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);

  }

  billFreightCharge() {
    !this.iscRulesForSO.billFrieght && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  claimTax() {
    !this.iscRulesForSO.iscTax && this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  checkIscRulesForMileageItem(costType) {
    this.claimsForm.value?.isNationalAccount && costType === IscCostTypes.material && this.claimMileageItem();
    this.claimsForm.value?.isNationalAccount && costType === IscCostTypes.material && this.billMileageItem();
  }

  claimMileageItem() {
    !this.iscRulesForSO.addMileageItem && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
  }

  billMileageItem() {
    this.commonService.showNotification('warning', ACTION_NOT_PERMISSIBLE, 'center', 5000);
  }

  editItem() {
    return {
      create: args => {
        this.selectedRowId = args.data.rowId;
        this.item = document.createElement('input');
        return this.item;
      },
      read: () => {
        if (this.itemCodeObject) {
          return this.itemCodeObject.value;
        }
      },
      destroy: () => {
        this.itemCodeObject.destroy();
      },
      write: (args) => {
        this.itemCodeObject = new DropDownList({
          dataSource: this.itemsList,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.item,
          cssClass: 'generic-items-dropdown-list',
          // itemTemplate: '<span title="${text}">${text}</span>',
          allowFiltering: true,
          showClearButton: true,
          popupWidth: '350px',
          filterType: 'Contains',
          enabled: args.rowData.isManual && this.costTypeObject.value === IscCostTypes.material,
          filtering: args => { this.onItemUpdate.next(args.text) },
          focus: args => { this.itemCodeObject.showPopup() },
          blur: args => {
            if (this.itemCodeObject['isSelectCustom']) {
              this.itemCodeObject.value = null;
            }
          },
          change: event => {
            if (event.value && event.item) {
              this.getPriceForItem = event.isInteracted || this.itemChangedFromAdvanceSearch;
              const unit = this.tempUnit;
              if (event.itemData?.value !== 'LOCAL' && this.getPriceForItem) {
                this.getItemPrice(event.itemData?.value, unit, args.rowData, this.quantityObject.value);
              }
              if (event.value === 'Mileage') {
                this.checkIscRulesForMileageItem(event.value);
              }
              if (this.isKingslynn && event.itemData && this.coreItemGroup.includes(event.itemData.serviceItemGroup)) {
                this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
              }
              this.itemDescriptionObject.value = event.itemData?.itemDescription ? event.itemData.itemDescription : null;
              this.serviceItemGroup = event.itemData?.serviceItemGroup;
              this.rowServiceItemGroup = event.itemData?.serviceItemGroup;
              this.itemGroup = event.itemData?.itemGroup;
              this.itemChangedFromAdvanceSearch = false;
            }
          }
        });
        this.itemCodeObject.appendTo(this.item);
        this.addAdvancedSearchIcon(args.rowData.costType);
      }
    }
  }

  queryCellInfo(args) {
    if (args.column.field) {
      if (getValue('status', args.data) == ClaimStatuses.approved) {
        args.cell.bgColor = '#c0ffc0';
      } else if (getValue('status', args.data) == ClaimStatuses.rejected) {
        args.cell.bgColor = '#ffa8a8';
      } else if (getValue('status', args.data) == ClaimStatuses.flagged) {
        args.cell.bgColor = '#fbff0080';
      }
    }
  }

  addAdvancedSearchIcon(costType) {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    if (costType !== IscCostTypes.material) {
      itemAnchor.classList.add('d-none');
    }
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Advanced Search');
    itemAnchor.addEventListener('click', () => {
      this.showItemAdvancedSearchPopup = true;
    });
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.item.parentElement.parentElement.append(itemAnchor);
  }

  // showItemPopup() {
  //     if ( this.costTypeObject && this.costTypeObject.value && this.costTypeObject.value === IscCostTypes.otherCost) {
  //         this.serviceItemGroup = '350SVC';
  //     } else {
  //         this.serviceItemGroup = '';
  //     }
  //     this.showItemAdvancedSearchPopup = true;
  // }

  onItemAdvancedSearchPopupClose(data: any) {
    this.itemChangedFromAdvanceSearch = true;
    this.rowServiceItemGroup = data?.serviceItemGroup;
    if (this.claimsGrid.isEdit) {
      if (data) {
        this.itemDescriptionObject.value = data?.itemDescription;
        this.itemCodeObject.dataSource = [{
          text: `${data?.item} | ${data?.itemDescription}`,
          value: data?.item,
          ...data
        }];
        this.itemCodeObject.value = data?.item;
      }
      setTimeout(() => {
        if (this.isKingslynn && data && this.coreItemGroup.includes(data?.serviceItemGroup)) {
          this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
        }
        this.showItemAdvancedSearchPopup = false;
        this.serviceOrderService.popupLoader.next(false);
      }, 400);
    } else {
      const rowIndex = this.claimsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
      this.serviceOrderService.popupLoader.next(true);
      this.showItemAdvancedSearchPopup = false;
      setTimeout(() => {
        this.claimsGrid.selectRow(rowIndex);
        this.claimsGrid.startEdit();
        if (data) {
          setTimeout(() => {
            if (this.isKingslynn) {
              this.coreItemGroup.includes(data?.serviceItemGroup)
                && this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
            }
            this.serviceOrderService.popupLoader.next(false);
            this.itemDescriptionObject.value = data?.itemDescription;
            this.itemCodeObject.dataSource = [{
              text: `${data?.item} | ${data?.itemDescription}`,
              value: data?.item,
              ...data
            }];
            this.itemCodeObject.value = data?.item;
          }, 400);
        } else {
          this.serviceOrderService.popupLoader.next(false);
        }
      }, 2000);
    }
  }

  getItemPrice(item, unit, rowData, quantity = 1) {
    const data = {
      company: this.USER.company,
      item: item,
      unit: 'ea',
      quantity,
      configuration: this.iscEmployeeDetails.iscCode
    };
    this.serviceOrderService.getPrice(data)
      .subscribe((res: ApiResponse) => {
        if (res && res.isSuccessful && res.result) {
          this.priceObject.value = res.result.bookPrice ? +res.result.bookPrice.bookPriceValue?.toFixed(2) : 0;
          this.discountAmountObject.value = res.result?.discountAmount ? +res.result.discountAmount.discountAmountValue?.toFixed(2) : 0;
        } else {
          this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
        }
      }, (error: HttpErrorResponse) => {
        this.priceObject.value = 0;
        this.discountAmountObject.value = 0;
        this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
      });
  }

  getRefActivityCategory(refActivityCode) {
    const refActivity = this.referenceActivities.find(act => act.value === refActivityCode);
    return refActivity ? refActivity.category : '';
  }

  // updateItemOnHandQuantity(item, onHandQty, rowData){
  //     let calculatedOnHand = onHandQty;
  //     this.claimItems.forEach(x => {
  //         if(item === x.item){
  //             calculatedOnHand += x.issuedQty;
  //         }
  //     });
  //     (this.claimsGrid.dataSource as any).forEach(x => {
  //         if(item === x.item && !x.isDeleted){
  //             if(!this.claimsGrid.isEdit || rowData.rowId != x.rowId)
  //                 calculatedOnHand -= x.qty;
  //         }
  //     });
  //     return calculatedOnHand;
  // }

  editItemDescription() {
    return {
      create: () => {
        this.itemDescription = document.createElement('input');
        return this.itemDescription;
      },
      read: () => {
        if (this.itemDescriptionObject) {
          return this.itemDescriptionObject.value;
        }
      },
      destroy: () => {
        this.itemDescriptionObject.destroy();
      },
      write: (args) => {
        this.itemDescriptionObject = new TextBox({
          value: args.rowData.description,
          enabled: false
        });
        this.itemDescriptionObject.appendTo(this.itemDescription);
      }
    }
  }

  editPrice() {
    return {
      create: () => {
        this.price = document.createElement('input');
        return this.price;
      },
      read: () => {
        if (this.priceObject) {
          return this.priceObject.value;
        }
      },
      destroy: () => {
        this.priceObject.destroy();
      },
      write: (args) => {
        this.priceObject = new NumericTextBox({
          value: args.rowData.price,
          showSpinButton: false,
          min: 0,
          enabled: args.rowData.isManual,
          created: () => {
            this.price.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105 || [8, 127].includes(event.keyCode)) {
                if (event.target.value != null) this.priceObject.value = event.target.value;
              }
            }
          },
          focus: args => {
              this.priceObject.min = this.quantityObject?.value ? this.discountAmountObject.value / this.quantityObject.value || null : null;
          },
          change: (event) => {
            if (event.value) {
              const price = event.value;
              const qty = this.quantityObject ? this.quantityObject.value : args.rowData.quantity;
              let discount = this.discountAmountObject ? this.discountAmountObject.value : args.rowData.discountAmount;
              this.totalAmountObject.value = qty && qty * (price - discount) || 0;
              this.discountAmountObject.max = price * qty ? price * qty : null; 
            } else {
              this.priceObject.value = 0;
            }
            this.discountAmountObject.max = event.value * this.quantityObject?.value;
          }
        });
        this.priceObject.appendTo(this.price);
      }
    }
  }

  isDiscountEnabled() {
    const isCostTypeOther = this.costTypeObject?.value === IscCostTypes.other;
    const isTravelCallout_mileage = [SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value);
    return isCostTypeOther && this.subcostTypeObject?.value && !isTravelCallout_mileage;
  }

  editDiscountAmount() {
    return {
      create: () => {
        this.discountAmount = document.createElement('input');
        return this.discountAmount;
      },
      read: () => {
        if (this.discountAmountObject) {
          return this.discountAmountObject.value;
        }
      },
      destroy: () => {
        this.discountAmountObject.destroy();
      },
      write: (args) => {
        this.discountAmountObject = new NumericTextBox({
          value: args.rowData.discountAmount || 0,
          min: 0,
          max: this.priceObject.value,
          enabled: this.isDiscountEnabled(),
          showSpinButton: false,
          created: args => {
            this.discountAmount.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.discountAmountObject.value = parseInt(event.target.value);
              }
            }
          },
          focus: args => {
              const price = this.priceObject?.value,
                      qty = this.quantityObject?.value;
              this.discountAmountObject.max = price * qty ?? null;
          },
          change: (event) => {
            const qty = this.quantityObject.value;
            const price = this.priceObject.value;
            if (event.value != null) {
              this.totalAmountObject.value = qty && price ? qty * (price - event.value) : 0;
            } else {
              this.discountAmountObject.value = 0;
            }
            this.priceObject.min = this.quantityObject?.value && event.value ? event.value / this.quantityObject.value || null : null;
          }
        });
        this.discountAmountObject.appendTo(this.discountAmount);
      }
    }
  }

  editTaxPercent() {
    return {
      create: () => {
        this.taxPercent = document.createElement('input');
        return this.taxPercent;
      },
      read: () => {
        if (this.taxPercentObject) {
          return this.taxPercentObject.value;
        }
      },
      destroy: () => {
        this.taxPercentObject.destroy();
      },
      write: (args) => {
        this.taxPercentObject = new NumericTextBox({
          value: args.rowData.taxPercent || 0,
          enabled: true,
          min: 0,
          max: 100,
          validateDecimalOnType: true,
          showSpinButton: false,
          change: (event) => {
            const qty = this.quantityObject.value;
            const price = this.priceObject.value;
            const discount = this.discountAmountObject.value;

            if (event.value != null) {
              this.taxAmountObject.value = (qty * price - discount) * event.value / 100;
            }
          }
        });
        this.taxPercentObject.appendTo(this.taxPercent);
      }
    }
  }

  editTaxAmount() {
    return {
      create: () => {
        this.taxAmount = document.createElement('input');
        return this.taxAmount;
      },
      read: () => {
        if (this.taxAmountObject) {
          return this.taxAmountObject.value;
        }
      },
      destroy: () => {
        this.taxAmountObject.destroy();
      },
      write: (args) => {
        this.taxAmountObject = new NumericTextBox({
          value: args.rowData.taxAmount || 0,
          enabled: false, //args.rowData.isManual,
          showSpinButton: false,
          change: (event) => {
          }
        });
        this.taxAmountObject.appendTo(this.taxAmount);
      }
    }
  }

  editQuantity() {
    return {
      create: () => {
        this.quantity = document.createElement('input');
        return this.quantity;
      },
      read: () => {
        if (this.quantityObject) {
          return this.quantityObject.value;
        }
      },
      destroy: () => {
        this.quantityObject.destroy();
      },
      write: (args) => {
        this.quantityObject = new NumericTextBox({
          value: args.rowData.quantity,
          showSpinButton: false,
          format: 'n2',
          min: 0,
          decimals: 2,
          validateDecimalOnType: true,
          enabled: args.rowData.isManual || args.rowData.status === ClaimStatuses.flagged,
          created: args => {
            this.quantity.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.quantityObject.value = event.target.value;
              }
            }
          },
          change: event => {
            const qty = event.value;
            const price = this.priceObject ? this.priceObject.value : args.rowData.price;
            const tax = this.taxAmountObject.value;
            let discount = this.discountAmountObject ? this.discountAmountObject.value : args.rowData.discountAmount;
            this.discountAmountObject.max = price && qty ? price * qty : null;
            this.totalAmountObject.value = qty && qty * (price - discount) || 0;
          }
        });
        this.quantityObject.appendTo(this.quantity);
      }
    }
  }    
  
  editMileage() {
    return {
        create: () => {
            this.mileage = document.createElement('input');
            return this.mileage;
        },
        read: () => {
            if (this.mileageObject) {
                return this.mileageObject.value;
            }
        },
        destroy: () => {
            if (this.mileageObject) {
                this.mileageObject.destroy();
            }
        },
        write: (args) => {
            this.mileageObject = new NumericTextBox({
                value: args.rowData.mileage,
                showSpinButton: false,
                format: 'n2',
                min: 0,
                decimals: 2,
                enabled: args.rowData.isManual && args.rowData.subCostType && args.rowData.subCostType === SubCostTypes.travelCalloutCharge,
                created: () => {
                    this.mileage.onkeyup = event => {
                        if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                            this.mileageObject.value = event.target.value;
                        }
                    }
                },
                change: (event) => {
                  const isBillable = this.getRefActivityCategory(this.referenceActivityObject?.itemData?.referenceActivityCode) === 'Billable Service';
                  if (event.value != null) {
                      if ( isBillable && !this.claimsForm.value?.isNationalAccount) { // regular account and billable service
                          // do nothing
                      } else {
                          this.calculateTotalForMileage(event.value, args.rowData);
                      }
                  }
                }
            });
            this.mileageObject.appendTo(this.mileage);
        }
    }
  }

  calculateTotalForMileage(mileage, rowData) {
      const isBillable = this.getRefActivityCategory(this.referenceActivityObject?.itemData?.referenceActivityCode) === 'Billable Service';
      if (mileage) {
          let mileageExceededPrice = mileage > this.iscEmployeeDetails.mileageThreshold ? mileage * this.iscEmployeeDetails.mileageRate : undefined;
          const calloutRate = isBillable ? this.calloutRate : ( mileageExceededPrice ?? this.iscEmployeeDetails.t1CalloutRate);
          this.priceObject.value = this.claimsForm.value?.isNationalAccount ? mileageExceededPrice ?? this.iscEmployeeDetails.t2CalloutRate : calloutRate;
          
          this.totalAmountObject.value = this.isAmountClaimable(rowData) ? this.quantityObject.value * this.priceObject.value - this.discountAmountObject.value : 0;
          this.claimsForm.value?.isNationalAccount && this.checkIscRulesForSubcost(this.subcostTypeObject.value, rowData);
      }
  }

  isAmountClaimable(rowData) {
    switch(this.subcostTypeObject.value) {
        case SubCostTypes.travelCalloutCharge: {
            const travelCalloutChargeExists =  (this.claimsGrid.dataSource as any)?.findIndex(x => x.rowId !== rowData.rowId && !x.isDeleted && x.subCostType === SubCostTypes.travelCalloutCharge);
            if (travelCalloutChargeExists > -1) {
                return this.iscRulesForSO?.claimMultipleTripCharge ?? true;
            } else {
                return true;
            }
        }
        case SubCostTypes.local:
            return this.iscRulesForSO?.claimLocalPart ?? true;
        case SubCostTypes.mileage:
            return this.iscRulesForSO?.claimAddtMileage ?? true;
        case SubCostTypes.freight:
            return this.iscRulesForSO?.claimFrieght ?? true;
        case SubCostTypes.tax:
            return this.iscRulesForSO?.iscTax ?? true;
        default: 
            return true;
    }
}

  editTotalAmount() {
    return {
      create: () => {
        this.totalAmount = document.createElement('input');
        return this.totalAmount;
      },
      read: () => {
        if (this.totalAmountObject) {
          return this.totalAmountObject.value;
        }
      },
      destroy: () => {
        this.totalAmountObject.destroy();
      },
      write: (args) => {
        this.totalAmountObject = new NumericTextBox({
          value: args.rowData.totalAmount,
          showSpinButton: false,
          enabled: false,
          min: 0,
          change: (event) => {
          }
        });
        this.totalAmountObject.appendTo(this.totalAmount);
      }
    }
  }

  updateBusinessRulesStatuses(claimsData, refActivities?) {
    this.businessRules = claimsData.iscBusinessRules;
    refActivities && (this.referenceActivities = refActivities);
    this.checkServiceTypeValid(claimsData.iscClaimDetails);
    this.checkInstallDateForINS(claimsData);
    this.checkInstallDateValid(claimsData);
    // this.checkSerialNumberIsValid(claimsData.serialNumber);
    this.serviceDateInRange(claimsData.serviceCompletionDate, claimsData.submissionDate);
    // this.checkLaborRate(claimsData);
    this.checkTravelMiles(claimsData);
    // this.checkNoMileage(claimsData.iscClaimDetails);
    this.checkClaimLimit();
    this.updateBusinessRulesInForm();
    this.claimsForm.value.businessRulesPassed = this.businessRules.findIndex(rule => rule.status === "Failed") > -1 ? false : true;
    this.businessRulesUpdated.emit();
  }

  updateBusinessRulesInForm() {
    const iscBusinessRulesArray = this.claimsForm.get('iscBusinessRules') as UntypedFormArray;
    const iscBusinessRulesData = this.businessRules;
    iscBusinessRulesArray.clear()
    iscBusinessRulesData ? iscBusinessRulesData.forEach((rule) => {
      const businessRule = this.fb.group(rule);
      iscBusinessRulesArray.push(businessRule);
    }) : [];
  }

  getTaxRate(costType) {
    if (this.taxRates) {
      const laborTypeKeys = {
        [IscCostTypes.labor]: 'LABMOBILE',
        [IscCostTypes.material]: 'MATERIAL',
        [IscCostTypes.other]: 'Other'
      }
      let taxRateItem = this.taxRates.find(taxRate => taxRate.productCategory === laborTypeKeys[costType]);
      if (taxRateItem && taxRateItem.taxRatePercentage) {
        return taxRateItem.taxRatePercentage;
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  checkServiceTypeValid(claimItems) {
    const index = claimItems.findIndex(item => item.status !== ClaimStatuses.rejected && this.invalidServiceTypeCodes.includes(item.serviceTypeCode));
    this.businessRules[0].status = index === -1 ? "Passed" : "Failed";
  }

  checkInstallDateValid(claimsData) {
    this.businessRules[1].status = claimsData?.installationDate ? "Passed" : "Failed";
  }

  checkInstallDateForINS(claimsData) { // details tab check
    // claimsData.serviceType, claimsData.installationDate, claimsData.serviceCompletionDate, claimsData.callGroup
    let installationDateForINSRuleStatus = "Failed";
    const hasServiceTypeCodeINS = claimsData.iscClaimDetails.findIndex(x => x.serviceTypeCode === 'INS') > -1;
    if (claimsData.callGroup === "INS" || hasServiceTypeCodeINS) {
      if (claimsData.installationDate && claimsData.serviceCompletionDate) {
        const installationDate = new Date(claimsData.installationDate);
        const serviceDate = new Date(claimsData.serviceCompletionDate);
        if (installationDate.setHours(0, 0, 0, 0) === serviceDate.setHours(0, 0, 0, 0)) {
          installationDateForINSRuleStatus = "Passed";
        }
      }
    } else {
      installationDateForINSRuleStatus = "Passed";
    }
    this.businessRules[2].status = installationDateForINSRuleStatus;
  }

  // checkSerialNumberIsValid(serialNumber) { // details tab check
  //   let serialNumberRuleStatus = "Failed";
  //   if (this.claimsForm.value.callType === 'WO' || serialNumber) {
  //     serialNumberRuleStatus = "Passed";
  //   }
  //   this.businessRules[3].status = serialNumberRuleStatus;
  // }

  serviceDateInRange(serviceDate, submissionDate) {
    let serviceDateRuleStatus = "Failed";
    if (serviceDate && submissionDate) {
      serviceDate = new Date(serviceDate);
      const validDateRangeMax = new Date(submissionDate);
      let validDateRangeMin = new Date(submissionDate);
      validDateRangeMin.setDate(validDateRangeMin.getDate() - 30);
      if (validDateRangeMin <= serviceDate) {
        serviceDateRuleStatus = "Passed";
      }
    }
    this.businessRules[3].status = serviceDateRuleStatus;
  }

  // checkLaborRate(claimsData) {
  //   let totalLabor = 0;
  //   claimsData.iscClaimDetails.forEach(item => {
  //     if (item.status !== ClaimStatuses.rejected && item.costType === "Labor") {
  //       totalLabor += item.price;
  //     }
  //   });
  //   this.businessRules[5].status = claimsData.country === "UNITED STATES" && totalLabor > 82 || claimsData.country === "CANADA" && totalLabor > 94 ? "Failed" : "Passed";
  // }

  checkTravelMiles(claimsData) {
    let totalMileage = 0;
    claimsData.iscClaimDetails.forEach(item => {
      if (item.status !== ClaimStatuses.rejected && item.costType === IscCostTypes.other && item.subCostType === SubCostTypes.travel) {
        totalMileage += item.quantity;
      }
    });
    this.businessRules[4].status = claimsData.country === "UNITED STATES" && totalMileage > 100 || claimsData.country === "CANADA" && totalMileage > 200 ? "Failed" : "Passed";
  }

  checkClaimLimit() {
    this.businessRules[5].status = this.claimsForm.value.totalClaimAmount > 4000 ? "Failed" : "Passed";
  }

  // checkNoMileage(claimItems) {
  //   let laborHrs = 0, mileage = 0;
  //   claimItems.forEach(item => {
  //     const serviceType = item.serviceTypeCode;
  //     if (item.status !== ClaimStatuses.rejected && serviceType === 'TRN')
  //       if (item.costType === IscCostTypes.other || item.costType === "Mileage") {
  //         mileage += item.quantity;
  //       }
  //     if (item.costType === "Labor") {
  //       laborHrs += item.quantity;
  //     }
  //   });
  //   this.businessRules[8].status = laborHrs > 2 || mileage > 0 ? "Failed" : "Passed";
  // }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
