import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { getExcelDataSource, updateFilterIcon, gridActionBeginForFiltering } from '../../utils/grid-functions';
import { getCurrentDate } from '../../utils/date-functions';
import { InvoicePopupOpenedFrom } from 'src/app/modules/service-order/models/service-order.model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-showchecklist',
  templateUrl: './showchecklist.component.html',
  styleUrls: ['./showchecklist.component.scss']
})
export class ShowchecklistComponent implements OnInit {
  loading: boolean = false;
  isExpanded: boolean = false;
  @Input() customerData;
  @Input() serviceOrderData;
  @Input() company;
  @Input() serializedItemGroupDescription;
  @Input() serializedItemGroupCode;
  @Input() technicianName;

  @Input() serviceOrderId: string;
  @Input() technicianId: string;
  @Input() createdDate: string;
  @Input() createdBy: string;
  @Input() lastUpdatedDate: string;
  @Input() lastUpdatedBy: string;
  @Input() newchecklistid: string;

  @Input() hasChecklist = '';
  @Input() increment : boolean = false;
  showInvoicePopup: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() checklistId = new EventEmitter();
  @Output() certificateGenerate = new EventEmitter();

  checklistID: string;
  mydayDropdownData: any;
  gridColumns: any;
  gridId: string = 'gridnamestring';
  gridHeight = null;
  showColumnChooser: boolean = false;
  USER;
  object={};
  checklistCertificate: boolean = false;
  saveButtonobject={};
  @ViewChild('checklistPopupGrid') checklistPopupGrid: GridComponent;
  @ViewChild('columnChooser') columnChooser;
  checklistPopupGridId = 'checklist-popup-grid';
  disabledButton = document.querySelector('#button');
  disabledStatus: boolean = true;

  public dropDownValues=[];

  public gridData: any;

  mydayOverdueId = 'myday-grid-overdue';
  selectedItem:any;
  dropDownValue: any;
  dropdownEnabled =true;
  heading: string;
  hasChecklistReprt: boolean=false;

  commentText: HTMLInputElement;
  commentTextObject: any;
  InvoicePopupOpened: typeof InvoicePopupOpenedFrom;
  showLoader: boolean;

  constructor(
    private commonService: CommonService
  ) {
    this.USER = this.commonService.USER;
    this.InvoicePopupOpened = InvoicePopupOpenedFrom;
  }


  ngOnInit(): void {
    this.heading='Checklist Certificate';
    this.gridColumns = this.getColumns();

    if( this.hasChecklist ) {
      this.dropdownEnabled=false;
      this.checklistID = this.hasChecklist;
        this.showLoader = true;
        forkJoin([
          this.commonService.getKingslynnChecklistForDDLTableData(this.serializedItemGroupCode),
          this.commonService.getChecklistbyId(this.hasChecklist)
        ]).subscribe((res: any) => {

          if ( res[0].isSuccessful ) {
            res[0].result.forEach((item:any)=>{
              this.dropDownValues.push(item.checklistType);
            })
          }

          if ( res[1].isSuccessful ) {
            this.selectedItem = res[1].result?.items[0]?.checklistType;
            this.hasChecklistReprt = res[1].result.hasChecklistReprt;
            this.gridData=res[1].result.items;

            if( res[1].result.hasChecklistReprt ) {
              this.dropdownEnabled=false;
            }

            this.object={
              company: res[1].result.company,
              checklistId: res[1].result.checklistId,
              technicianName: res[1].result.technicianName,
              serviceOrderId: res[1].result.serviceOrderId,
              technicianId: res[1].result.technicianId,
              createdDate: res[1].result.createdDate,
              createdBy: res[1].result.createdBy,
              lastUpdatedDate: res[1].result.lastUpdatedDate,
              lastUpdatedBy: res[1].result.lastUpdatedBy,
              items: res[1].result.items
            }            
            this.disabledStatus = false;
          } else {
            this.commonService.showNotification('error', res.message);
          }
          this.showLoader = false;
        }, error => {
          this.commonService.showNotification('error', error.message);
          this.showLoader = false;
        });
    } else {
      this.getItemGroupDDL();
    }
  }

  expandCollapse(value: boolean) {
    this.isExpanded = value;
  }

  getItemGroupDDL(){
    this.commonService.getKingslynnChecklistForDDLTableData(this.serializedItemGroupCode).subscribe((res:any)=>{
      res.result.forEach((item:any)=>{
        this.dropDownValues.push(item.checklistType);
      })
    })
  }

  acceptAllClick(args: any)
  {
    this.gridData.forEach((res:any)=>{
        res.status='Approved';
      // }
    })
    this.checklistPopupGrid.refresh();
  }

  rejectAllClick(args: any)
  {
    this.gridData.forEach((res:any)=>{
        res.status='Rejected';
    })
  this.checklistPopupGrid.refresh();

  }

  getColumns() {
    let columns = [
        { field: 'id', allowEditing: false, headerText: 'Sr No.', width: 65, visible: true, nonHideable: true, isPrimaryKey: true },
        { field: 'value', allowEditing: false, headerText: 'Description', width: 180, visible: true, nonHideable: true },
        { field: 'status', allowEditing: false, headerText: 'Status', width: 65, visible: true, nonHideable: true },
        { field: 'comment', allowEditing: true, headerText: 'Comments', width: 170, visible: true, nonHideable: true, type: 'string' },
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 80, textAlign: 'Center', allowFiltering: false, allowSorting: false,
            commands: [
                { title: 'Approved', type: 'Edit', buttonOption: { iconCss: `fas fa-check approve-btn`, cssClass: 'e-flat text-success approve-btn' } },
                { title: 'Rejected', type: 'Edit', buttonOption: { iconCss: `fas fa-times reject-btn`, cssClass: 'e-flat text-danger reject-btn' } }
            ]
        }
    ];
    return columns;
  }

  onChecklistChange(args): void {
    if(args.isInteracted){
      this.commonService.getKingslynnChecklistForDDL(args.value, this.serializedItemGroupCode).subscribe((res:any)=>{
      this.gridData=res.result;
          this.object={
          company: this.company,
          checklistId: this.hasChecklist,
          technicianName: this.technicianName,
          serviceOrderId: this.serviceOrderId,
          technicianId: this.technicianId,
          createdDate: this.createdDate,
          createdBy: this.createdBy,
          lastUpdatedDate: this.lastUpdatedDate,
          lastUpdatedBy: this.lastUpdatedBy,
          items: this.gridData
        }

      })
    }

    let element: HTMLElement = document.createElement('p');
    if (args.isInteracted) {
        element.innerText = 'Changes happened by Interaction';
    } else {
        element.innerText = 'Changes happened by programmatic';
    }

    const btn = document.getElementById('btn') as HTMLButtonElement | null;
    if (btn != null)
    {
       btn.disabled = false;
    }

    this.disabledStatus = false;

    document.getElementById('event').append(element);
  }

  getStatusClass(status: string) {
    return this.commonService.getStatusClassForChecklist(status);
  }

  generate(){
    this.checklistPopupGrid.endEdit();
    if(this.hasChecklistReprt){
      this.commonService.showConfirmation('Are you sure? Any previous checklist certificate for this Service Order will be replaced with this one.')
          .then( result => {
            if(result.isConfirmed){
              this.saveChecklist(true, false);
            }
          });
    }
    else{
        this.saveChecklist(true, false);
    }
  }

  saveChecklist(generate?, saveAndClose?){
    this.checklistPopupGrid.endEdit();
    this.showLoader = true;
    this.saveButtonobject={
      company: this.company,
      checklistId: this.hasChecklist,
      technicianName: this.technicianName,
      serviceOrderId: this.serviceOrderId,
      technicianId: this.technicianId,
      createdDate: this.createdDate,
      createdBy: this.createdBy,
      lastUpdatedDate: this.lastUpdatedDate,
      lastUpdatedBy: this.lastUpdatedBy,
      items: this.gridData,
      generalQAC : [],
      liftQAC :[],
      brakeTesterQAC: [],
      headlampTesterQAC:[],
      commentQAC:[],
      checklistItems:""
    }
    this.commonService.SaveChecklist(this.saveButtonobject).subscribe((res:any) => {
      this.showLoader = false;
      if(res.isSuccessful){
        this.checklistID = res.result.checklistId;
        this.checklistId.emit(res.result.checklistId);
        this.object['checklistId']=res.result.checklistId;
        if( generate ) {
          this.checklistCertificate = true;
          this.showInvoicePopup=true;

        }
        if(saveAndClose) {
          this.close();
        }
        this.commonService.showNotification('success', res.message);
      } else{
        this.commonService.showNotification('error', res.message);
      }
    })
  }

  close() {
    this.onClose.emit(this.checklistID);
  }



  onGridCreated() {
    this.gridHeight = document.getElementById('checklist-popup-grid').clientHeight - 77;
    this.checklistPopupGrid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    let columnChooserIndex = this.checklistPopupGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        this.checklistPopupGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    updateFilterIcon({ requestType: 'filtering' }, this.checklistPopupGrid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onCommandClick(args: any) {
    args.rowData.status=args.commandColumn.title;
    this.gridData.forEach((res:any)=>{
      if(res.id==args.rowData.id){
        res.status=args.rowData.status;
      }
    })
    this.checklistPopupGrid.refresh();

  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'clear-filter') {
      grid.clearFiltering();
      grid.clearSorting();
      grid.search('');
      grid.refresh();
    } else if (args.item.id === 'excel-export') {
      const dataSource = getExcelDataSource(grid);
      let excelExportProperties: ExcelExportProperties = {
          dataSource,
          hierarchyExportMode: 'Expanded',
          theme: {
              header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
          },
          fileName: `Service Orders (${getCurrentDate()}).xlsx`
      };
      grid.excelExport(excelExportProperties);
    }
  }

  onInvoicePopupClosed(){
    this.showInvoicePopup=false;
  }
}



