import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CheckBoxChangeEventArgs, GridComponent, RowDataBoundEventArgs, RowDeselectEventArgs, RowSelectEventArgs, RowSelectingEventArgs } from '@syncfusion/ej2-angular-grids';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { CommonService } from 'src/app/shared/services/common.service';
import { updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { ServiceOrderService } from '../../services/service-order.service';
import { Invoice, InvoiceStatuses, PaymentMethods } from '../../models/payment-invoicing.model';
import { ChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';

@Component({
    selector: 'app-payment-selection-popup',
    templateUrl: './payment-selection-popup.component.html',
    styleUrls: ['./payment-selection-popup.component.scss'],
})
export class PaymentSelectionPopupComponent implements OnInit {

    isExpanded: boolean = false;
    showLoader: boolean = false;
    showConsolidateInvoice: boolean = false;
    consolidateInvoices: boolean = false;
    validationApplied: boolean = false;
    initialRowsSelected: boolean = false;
    invoiceId: string = null;
    paymentTypes = [];
    creditCardPaymentType: string;

    serviceOrderGridSettings: any;
    serviceOrderGrid: GridComponent;
    serviceOrders = [];
    invoiceStatuses = InvoiceStatuses;
    paymentReadOnly = false
    isIsc: boolean = false;
    isIscManager: boolean = false;
    USER;
    companies = Companies;

    @Input() serviceOrderData: any = null;
    @Input() invoice: Invoice = null;
    @Input() fieldRules: any;
    paymentMethod = null;
    isIscSupervisor: boolean = false;

    @ViewChild('serviceOrderGrid') set gridComponent(gridComponent: GridComponent) {
        if (gridComponent) {
            this.serviceOrderGrid = gridComponent;
            this.setGridToolbar();
        }
    }
    @ViewChild('paymentTypeDD') paymentType: DropDownListComponent;

    @Output() onClose = new EventEmitter();

    constructor(
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService
    ) {
        this.USER = commonService.USER;
        this.isIsc = this.USER.role === UserRoles.isc;
        this.isIscManager = this.USER.role === UserRoles.iscManager;
        this.isIscSupervisor = this.USER.role === UserRoles.IscSupervisor;
        this.paymentMethod = this.USER.company === Companies.kingslynn ? PaymentMethods.invoice.paymentType : null;
        this.paymentReadOnly = this.commonService.roleClaims['SO_Popup_Fields_payment']?.readOnly;
        this.creditCardPaymentType = PaymentMethods.creditCard.paymentType;
    }

    ngOnInit(): void {
        this.initPopup();
        this.showConsolidateInvoice = this.USER.company !== this.companies.conway;
        this.getServiceOrders(this.serviceOrderData.serviceOrderId, this.invoice ? this.invoice.invoiceId : null);
        if (this.invoice && this.invoice.invoiceId) {
            this.invoice.paymentType = this.invoice.paymentType + '';
            this.consolidateInvoices = this.invoice.serviceOrderList && this.invoice.serviceOrderList.length > 1 ? true : false;
        }
    }

    initPopup() {
        this.setGrid();
        this.loadPaymentTypes();
    }

    setGridToolbar() {
        this.serviceOrderGrid.toolbar = [
            'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
        ];
    }

    setGrid() {
        this.serviceOrderGridSettings = {
            toolbar: [],
            columns: [
                { field: 'checkbox', textAlign: 'Center', type: "checkbox", width: 120, allowFiltering: false, visible: true, showInColumnChooser: false },
                { field: 'serviceOrderId', headerText: 'Order No.', textAlign: 'Left', isPrimaryKey: true, width: 120, visible: true, showInColumnChooser: false },
                { field: 'serviceOrderIdLN', headerText: 'LN Order No.', textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
                { field: 'status', headerText: 'Status', textAlign: 'Left', type: 'String', filter: { type: 'Excel' }, width: 180, visible: true },
                { field: 'itemDescription', headerText: 'Item/Object', type: 'String', textAlign: 'Left', width: 150, filter: { type: 'Excel' }, visible: true },
                { field: 'startDate', headerText: 'Start Date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 140, visible: true },
                { field: 'createdDate', headerText: 'Created Date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 160, visible: true },
                { field: 'lastUpdatedDate', headerText: 'Last Updated Date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 160, visible: true },
                { field: 'callGroup', headerText: 'Call Group', textAlign: 'Left', type: 'String', width: 170, visible: true }
            ]
        };
    }

    loadPaymentTypes() {
        this.showLoader = true;
        this.serviceOrderService.getPaymentTypes(this.USER.company)
            .subscribe((res: ApiResponse) => {
                const creditCardIndex = res.result?.findIndex(ptype => ptype.description === "Credit Card");
                if ( this.commonService.USER.role === UserRoles.isc || this.commonService.USER.role === UserRoles.iscManager || this.commonService.USER.role === UserRoles.IscSupervisor) {
                    res.result?.splice(creditCardIndex, 1);
                }
                this.paymentTypes = res.result;
                this.paymentMethod = this.invoice ? this.invoice.paymentType : this.USER.company === Companies.kingslynn ? PaymentMethods.invoice.paymentType : null;
                this.setPaymentType();
                this.showLoader = false;
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
            });
    }

    setPaymentType() {
        if ((this.invoice?.paymentAmount <= 0.1 || this.serviceOrderData.totalAmount <= 0.1)) {
            const paymentType = this.USER.company === Companies.conway ? PaymentMethods.notApplicable.paymentType : PaymentMethods.invoice.paymentType;
            this.paymentMethod = paymentType;
            if (this.invoice) {
                this.setInvoicePaymentType(paymentType);
            }
        }
    }

    setInvoicePaymentType(paymentType) {
        this.invoice.paymentType = paymentType;
        this.invoice.paymentTypeDescription = this.USER.company === Companies.conway ? PaymentMethods.notApplicable.description : PaymentMethods.invoice.description;
        this.invoice.checkAuthCode = null;
        this.invoice.bankName = null;
    }

    getServiceOrders(serviceOrderId: string, invoiceId?: string) {
        this.serviceOrderService.getServiceOrdersByCustomer(this.serviceOrderData.customerCode, serviceOrderId,  invoiceId)
            .subscribe((res: any) => {
                if (res.isSuccessful) {
                    res.result.map(sOrder => {
                        sOrder.startDate = sOrder.startDate ? new Date(sOrder.startDate) : null;
                        sOrder.createdDate = sOrder.createdDate ? new Date(sOrder.createdDate) : null;
                        sOrder.lastUpdatedDate = sOrder.lastUpdatedDate ? new Date(sOrder.lastUpdatedDate) : null;
                    });
                    this.serviceOrders = res.result;
                } else {
                    this.commonService.showNotification('error', res.message);
                }
            }, (error: HttpErrorResponse) => {
                this.commonService.showNotification('error', error.message);
            });
    }

    selectDefaultRow(id) {
        const index = this.serviceOrders.findIndex(so => so.serviceOrderId === id);
        this.serviceOrderGrid.selectRow(index);
    }

    onDataBound(args: RowDataBoundEventArgs) {
        if (!this.initialRowsSelected && this.invoice && this.invoice.serviceOrderList && this.invoice.serviceOrderList.length) {
            const indexes = [];
            this.invoice.serviceOrderList.forEach(id => {
                indexes.push(this.serviceOrders.findIndex(so => so.serviceOrderId === id));
                this.serviceOrderGrid.selectRows(indexes);
            });
            this.initialRowsSelected = true;
        } else {
            this.selectDefaultRow(this.serviceOrderData.serviceOrderId);
        }
    }

    onRowDataBound(args: RowDataBoundEventArgs) {
        if (args.data['serviceOrderId'] === this.serviceOrderData.serviceOrderId) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row.getElementsByClassName('e-checkbox-wrapper')[0].classList.add('disablecheckbox')
        }
    }

    onCheckBoxChange(args: CheckBoxChangeEventArgs) {
        if (!args.checked && args.target.classList.contains('e-checkselectall')) {
            this.selectDefaultRow(this.serviceOrderData.serviceOrderId);
        }
    }

    onRowDeselecting(args: RowSelectingEventArgs) {
        if (this.invoice && this.invoice.status !== this.invoiceStatuses.created) {
            args.cancel = true;
        }
    }

    onToolbarClick(args: ToolbarClickEventArgs) {
        if (args.item.id === 'clear-filter') {
            this.serviceOrderGrid.clearFiltering();
            this.serviceOrderGrid.search('');
        }
    }

    onGridActionComplete(args: any, grid: GridComponent) {
        updateFilterIcon(args, grid);
    }

    getStatusClass(status: string) {
        return this.commonService.getStatusClass(status);
    }

    onPaymentMethodChange(args: ChangeEventArgs) {
        if (args.previousItemData?.value === PaymentMethods.cheque.paymentType) {
            if (this.invoice) {
                this.invoice.checkAuthCode = null;
            }
        }
    }

    save() {
        this.validationApplied = true;
        if (this.paymentType.value) {
            this.showLoader = true;
            let paymentAmount = 0;
            const serviceOrderList = this.consolidateInvoices ? this.serviceOrderGrid.getSelectedRecords().map((so: any) => {
                paymentAmount += so.invoiceAmount;
                return so.serviceOrderId;
            }) : [this.serviceOrderData.serviceOrderId];
            paymentAmount = this.consolidateInvoices ? paymentAmount : this.serviceOrderData.invoiceAmount;
            const invoice: Invoice = {
                technicianId: this.serviceOrderData.technicianId,
                technicianName: this.serviceOrderData.technicianName,
                paymentType: this.paymentTypes.find( row => row.paymentType === this.paymentType.value).paymentType,
                paymentTypeDescription: this.paymentType.text,
                paymentAmount,
                serviceOrderList
            };
            if (this.invoice && this.invoice.invoiceId) {
                invoice.invoiceId = this.invoice.invoiceId;
                invoice.checkAuthCode = this.invoice.checkAuthCode;
                this.serviceOrderService.updateInvoice(invoice)
                    .subscribe((res: ApiResponse) => {
                        if (res.isSuccessful) {
                            this.invoice = res.result;
                            this.close();
                        }
                        this.showLoader = false;
                    }, (error: HttpErrorResponse) => {
                        this.showLoader = false;
                    });
            } else {
                const service = this.serviceOrderService;
                const createInvoice = this.isIsc || this.isIscManager || this.isIscSupervisor ? this.serviceOrderService.createIscInvoice.bind(service) : this.serviceOrderService.createInvoice.bind(service);
                createInvoice(invoice)
                    .subscribe((res: ApiResponse) => {
                        if (res.isSuccessful) {
                            this.invoice = res.result;
                            this.close();
                        }
                        this.showLoader = false;
                    }, (error: HttpErrorResponse) => {
                        this.showLoader = false;
                    });
            }
        } else {

        }
    }

    close() {
        this.validationApplied = false;
        this.onClose.emit(this.invoice);
    }


}
