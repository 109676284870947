import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { DataManager, Query } from '@syncfusion/ej2-data';
import {
    CommandColumnService,
    EditService,
    ExcelExportService,
    FilterService,
    GridComponent,
    PdfExportService,
    ResizeService,
    SortService,
    ToolbarService,
    DetailRowService,
    ExcelQueryCellInfoEventArgs,
    PdfQueryCellInfoEventArgs,
    ExcelExportProperties
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { CustomAdaptor, getExcelDataSource, gridActionsForFiltering } from '../../utils/grid-functions';
import { environment } from 'src/environments/environment';
import { GridPersistRequests, PersistenceData } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { getCurrentDate } from '../../utils/date-functions';

const BASE_URL = environment.apiUrl;
const token: string = localStorage.getItem('access_token');


@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    providers: [
        EditService,
        FilterService,
        ResizeService,
        SortService,
        CommandColumnService,
        ToolbarService,
        ExcelExportService,
        PdfExportService,
        DetailRowService
    ]
})
export class EjsGridComponent implements OnInit, OnChanges {

    @Input()
    gridProps;

    @Input()
    gridUpdated;

    @Input() pageName: string;
    @Input() gridName;
    @Input() preference;

    @Output() onGridCreated = new EventEmitter();
    @Output() onToolbarDropdownSelection = new EventEmitter<MenuEventArgs>();

    

    showPopup: boolean = false;
    popupId: string;
    gridColumns: any;
    query: Query;
    data: any;
    gridData: any = [];
    dropdownProps: Object;

    allowExcelexport: boolean = false;
    allowPdfexport: boolean;
    openColumnChooser: boolean;
    toolbar: any;
    pageSettings: Object;
    employeeId = localStorage.getItem('employeeId');

    @ViewChild('columnChooser') columnChooser;
    @ViewChild('ejs_splitButton') splitButtonddb;
    USER: any;
    grid: GridComponent;

    @ViewChild('grid') set gridComponent(gridComponent: GridComponent) {
        if (gridComponent) {
            this.grid = gridComponent;
            this.setGridToolbar();
        }
    }


    constructor(
        private commonService: CommonService
    ) {
        this.USER = commonService.USER;
    }

    ngOnInit(): void {
        if ( this.gridProps && this.gridProps.allowPaging ) {
            this.gridData = new DataManager({
                url: BASE_URL+this.gridProps.api,
                adaptor: new CustomAdaptor()
            });
            this.query= new Query().addParams('company', this.USER.company );
        } else {
            this.gridData = this.gridProps.dataSource;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ( changes && changes.gridProps && changes.gridProps.currentValue && changes.gridProps.currentValue.allowPaging ) {
            this.query = new Query().addParams('company', this.USER.company).addParams('serviceDepartment', '692006');
            // this.pageSettings = { pageSize: this.gridProps.recordsPerPage || 20, pageSizes: true };
            this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20};
            this.gridColumns = this.gridProps?.columns;
        }

        if ( this.gridProps) { this.gridProps.click = this.clickHandler.bind(this); this.gridProps.height = window.innerHeight - 160; }
        if ( this.grid && this.grid.columns ) {
            this.grid.columns.forEach( column => {
                const updatedColumn = this.gridColumns.find(col => col.field === column.field);
                if ( updatedColumn ) {
                    column.headerText = updatedColumn.headerText || updatedColumn.field;
                    column.allowFiltering = updatedColumn.allowFiltering;
                    column.allowEditing = updatedColumn.allowEditing;
                    column.allowResizing = true;
                    column.allowReordering = true;
                    column.visible = updatedColumn.visible;
                    column.format = updatedColumn.type === 'datetime' ? { type: 'datetime', format: 'd MMM, y hh:mm a' } : updatedColumn.type === 'date' ? { type: 'date', format: 'd MMM, y' } : null;
                    column.showInColumnChooser = updatedColumn.showInColumnChooser;
                    column.width = updatedColumn.width ? updatedColumn.width : 120;
                    column.type = updatedColumn.type;
                    column.textAlign = updatedColumn.textAlign;
                    column.commands = updatedColumn.commands;
                    column.valueAccessor = updatedColumn.valueAccessor;
                    column.disableHtmlEncode = updatedColumn.disableHtmlEncode;
                    column.filter = { type: updatedColumn.filterType };
                }
            });
        }
        if ( this.grid && this.grid.childGrid ) {
            this.grid.childGrid.excelQueryCellInfo = this.exportQueryCellInfo;
        }
        this.grid?.refreshHeader();
        this.grid?.refreshColumns();
    }

    setGridToolbar(){
        if ( (this.grid || this.gridProps?.type === 'grid') && this.gridProps?.toolbar ) {
            this.toolbar = Object.assign([],this.gridProps.toolbar);
            // this code is added to remove excel export functionality from the master data grid, we need to do this from page builder. this is tempraray fix
            this.toolbar.forEach((element, index) => {
                if (element.key === "excelExport"){
                    this.toolbar.splice(index, 1);
                }
            });
            // above 5 lines of code is added to remove excel export functionality from the master data grid, we need to do this from page builder. this is tempraray fix
            const columnShowHide = this.toolbar.find(x => x.id === 'column-chooser');
            if ( columnShowHide ) {
                columnShowHide.template = this.columnChooser;
            }

            const createOrder = this.toolbar.find(x => x.id === 'create_order_ddl');
            if ( createOrder ) {
                createOrder.template = this.splitButtonddb;
                this.dropdownProps = createOrder.dropdownProps;
            }

            this.allowExcelexport = Boolean(this.toolbar.find(x => x.id === 'grid_excelexport'));
            this.allowPdfexport = Boolean(this.toolbar.find(x => x.id === 'grid_pdfexport'));
        }
    }

    toolbarClick(args: ClickEventArgs, grid: GridComponent) {
        if ( args.item.id === 'grid_excelexport') {
            const dataSource = getExcelDataSource(grid);
            let excelExportProperties: ExcelExportProperties = {
                dataSource,
                hierarchyExportMode: 'Expanded',
                theme: {
                    header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
                },
                fileName: `${this.pageName} (${getCurrentDate()}).xlsx`
            };
            grid.excelExport(excelExportProperties);
        } else if (args.item.id === 'grid_pdfexport') {
            grid.pdfExport();
        } else if (args.item.id === 'clear-filter') {
            grid.clearFiltering();
            grid.search('');
            this.commonService.saveUserPreference({userId: this.employeeId, name: this.gridName, value: ''});
        } else if (args.item.id === 'column-chooser') {
            this.openColumnChooser = !this.openColumnChooser;
            // if ( Boolean(document.getElementsByClassName('dropdown-menu settings-menu')[0]?.contains(args.originalEvent.target)) ) {
            // }
        }
    }

    exportQueryCellInfo(args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs): void {
        args.value = args.column.headerText === 'Title' ? (args as any).data.title :
                    args.column.headerText === 'Status' ? (args as any).data.status :
                    args.column.headerText === 'Service Order' ? (args as any).data.serviceOrder : args.value;
    }
    created(args, grid) {
        this.onGridCreated.emit(grid);
        this.updateFilterIcon({ requestType: 'filtering' }, this.grid);
    }

    onActionComplete(args, grid) {
       gridActionsForFiltering(args, grid);
        this.updateFilterIcon(args, grid);
        if ( GridPersistRequests.includes(args.requestType) ) {
            const persistenceData: PersistenceData = {
                userId: this.employeeId,
                name: this.gridName,
                value: this.grid.getPersistData()
            }
            this.commonService.saveUserPreference(persistenceData);
            localStorage.setItem(`grid${this.gridName}`, this.grid.getPersistData());
        }
    }

    clickHandler(event: any) {
        this.popupId = event.target.classList[event.target.classList.length-1];
        this.showPopup = true;
    }

    toggleShowHide(field, checked) {
        if ( checked ) {
            this.grid.showColumns( field, 'field' );
        } else {
            this.grid.hideColumns(field, 'field');
        }
    }

    /**
     * Takes the grid object and updates the clear filter icon based on applied filters
     * @param {Object} args: The args object of actionComplete event
     * @param {Object} grid: The grid object
     * @param {Object} list: A dropdown list other than grid filters and search
     */
     updateFilterIcon = (args: any, grid: GridComponent) => {
        let isFiltered = false;
        if ( args.requestType === 'filtering' || args.requestType === 'searching' || args.requestType === 'refresh') {
            isFiltered = grid.filterSettings.columns.length || (grid.searchSettings.key && grid.searchSettings.key.length) ? true : false;
            if ( isFiltered ) {
                document.getElementById('clear-filter')?.classList.add('filtered');
            } else {
                document.getElementById('clear-filter')?.classList.remove('filtered');
            }
        }
    }
}
