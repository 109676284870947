<div
    class="modal fade show"
    id="pause-service-order"
    tabindex="-1"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id flex-fill">
                        <h4 class="modal-title mb-0 item-id">{{popupTitle}}</h4>
                    </div>

                    <div class="btns-header">
                        <button
                            class="header-icon close p-0 m-0"
                            (click)="close()"
                            aria-label="Close"
                        ><i class="fas fa-times"
                                title="Close Popup"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="field mb-3 position-relative">
                            <label for="">Reason:</label><span class="required-asterik">*</span>
                            <ejs-dropdownlist #reasonsDDL *ngIf="reasonFieldName === 'Paused'"
                                placeholder="Select Pause Reason"
                                [dataSource]="reasons"
                                [allowFiltering]="true"
                                [filterType]="'contains'"
                                [sortOrder]="'Ascending'"
                                [fields]="{text: 'description', value: 'value'}"
                            ></ejs-dropdownlist>
                            <ejs-textbox #reasonsDDL *ngIf="reasonFieldName === 'Rejected'"
                                placeholder="Select Rejection Reason"
                            ></ejs-textbox>
                            <div class="field-error" *ngIf="validationApplied && !reasonsDDL.value">{{reasonFieldName}} reason is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save()">Save</button>
            </div>
        </div>
    </div>
</div>