<app-loader *ngIf="loading"></app-loader>
<div class="item-comments custom-scrollbar h-100">
    <div class="comment pb-2">
        <button 
        class="header-icon close p-0 m-0" 
        (click)="close()" 
        aria-label="Close"
        >
            <i 
                class="fas fa-times" 
                title="Close Popup"
            ></i>
        </button>
    </div>
    <div class="comment">
        <div class="user-image align-items-start float-left">
            <i class="fas fa-user"></i>
        </div>
        <div class="comment-container float-left">
            <div class="user-comment comment-action-item" *ngIf="!addComment" (click)="addComment = true">
                <div class="placeholder">Write comment here...</div>
            </div>
            <div *ngIf="addComment">
                <ejs-richtexteditor
                    #addCommentRTE
                    id="mention_integration"
                    [placeholder]="placeholderText"
                    [toolbarSettings]="tools"
                    [insertImageSettings]='insertImageSettings' 
                    (actionBegin)="onActionBegin($event)"
                    (imageUploading)="onImageUploading($event)"
                >
            </ejs-richtexteditor>
                <ejs-mention class="ejs-mention" [dataSource]='mentionUsers' target='#mention_integration_rte-edit-view' [fields]="fieldsData" [showMentionChar]="false" [allowSpaces]="true"
                    popupWidth='250px' popupHeight='200px'>
                    <ng-template #itemTemplate let-data>
                        <table>
                            <tr>
                                <td class="mentionNameList">
                                    <span class="mentionName">{{data.name}}</span>
                                </td>
                            </tr>
                        </table>
                    </ng-template>
                    <ng-template #displayTemplate let-data>
                        <a class="mentionedName" href="mailto:{{data.email}}" title="{{data.name}}">{{data.name}}</a>
                    </ng-template>
                </ejs-mention>
                <div style="display: flex;" class="comment-buttons text-right mt-1">
                    <ng-container *ngIf="isIscModule && showIsPrivateCheckBox">
                        <ejs-checkbox  [(ngModel)]="isPrivate" style="flex: 1" label="Mark as Private"></ejs-checkbox>
                    </ng-container>
                    <div style="flex: 3">
                        <button class="btn btn-sm btn-danger mr-2 comment-action-item" (click)="addComment = false">Cancel</button>
                        <div class="btn-group dropup">
                            <button type="button" (click)="saveComment(addCommentRTE)" class="btn btn-sm btn-success comment-action-item">
                                Save & Close
                            </button>
                            <!-- <button type="button" class="btn btn-sm btn-success comment-action-item dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" (click)="saveComment(addCommentRTE, item.status)" *ngFor="let item of menuItem">{{ item.option }}</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="comment" *ngFor="let comment of comments">
        <div *ngIf="!comment.isDeleted">
            <div class="user-image align-items-start float-left">
                <i *ngIf="!comment.imagePath" class="fas fa-user"></i>
                <img *ngIf="comment.imagePath" [src]="comment.imagePath" alt="user image" />
            </div>
            <div class="comment-container float-left">
                <div *ngIf="comment.beingEdited">
                    <ejs-richtexteditor 
                        #commentRTE 
                        id="mention_integration_edit"
                        [value]="comment.comment"
                        [placeholder]="placeholderText"
                        [toolbarSettings]="tools" 
                        [insertImageSettings]='insertImageSettings' 
                        (dialogOpen)="commentRTE.refreshUI()"
                        (actionComplete)="onActionComplete($event)"
                    >
                    </ejs-richtexteditor>
                    <ejs-mention class="ejs-mention" [dataSource]='mentionUsers' target='#mention_integration_edit_rte-edit-view' [fields]="fieldsData" [showMentionChar]="false" [allowSpaces]="true"
                        popupWidth='250px' popupHeight='200px'>
                        <ng-template #itemTemplate let-data>
                            <table>
                                <tr>
                                    <td class="mentionNameList">
                                        <span class="mentionName">{{data.name}}</span>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                        <ng-template #displayTemplate let-data>
                            <a class="mentionedName" href="mailto:{{data.email}}" title="{{data.name}}">{{data.name}}</a>
                        </ng-template>
                    </ejs-mention>
                    <div style="display: flex;" class="comment-buttons text-right mt-1">
                        <ng-container *ngIf="isIscModule && showIsPrivateCheckBox">
                            <ejs-checkbox style="flex: 1" [(ngModel)]="comment.isPrivate" label="Mark as Private"></ejs-checkbox>
                        </ng-container>
                        <div style="flex: 3">
                            <button class="btn btn-sm btn-danger mr-2 comment-action-item" (click)="comment.beingEdited = false">Cancel</button>
                            <button class="btn btn-sm btn-success comment-action-item" (click)="updateUserComment(comment, commentRTE)">Update</button>
                        </div>
                    </div>
                </div>
                <div class="user-comment position-relative" *ngIf="!comment.beingEdited">
                    <div class="comment-action-buttons position-absolute right-0 top-0"  *ngIf="comment.userId === USER.employeeId">
                        <ng-container *ngIf="isIscModule && showIsPrivateCheckBox">
                          <a class="mr-2 comment-action-item isPrivate-btn" href="javascript:" *ngIf="comment.isPrivate" title="Is Private"><i class="fas fa-eye-slash comment-action-item"></i></a>
                          <a class="mr-2 comment-action-item isPrivate-btn" href="javascript:" *ngIf="!comment.isPrivate" title="Is Not Private"><i class="fas fa-eye comment-action-item"></i></a>
                        </ng-container>
                      
                        <a class="mr-2 comment-action-item edit-btn" href="javascript:" (click)="comment.beingEdited = true" title="Edit Comment"><i class="fas fa-pencil-alt comment-action-item"></i></a>
                        <a class="comment-action-item delete-btn" href="javascript:" (click)="deleteComment(comment.commentId)" title="Delete Comment"><i class="fas fa-trash comment-action-item"></i></a>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                        <div class="user-name">{{ comment.firstName +' '+ comment.lastName }}</div>
                        <div class="comment-date" title="{{ comment.commentDateTime | date: 'MMM d, y, h:mm' }}">{{ comment.commentDateTime | dateAgo }}</div>
                    </div>
                    <div class="custom-scrollbar comments" [innerHTML]="sanitizedComment(comment.comment)"></div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
