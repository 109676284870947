import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { ApiResponse, OrderTypes } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { IscService } from 'src/app/modules/home/services/isc.service';
import { CycleCountService } from 'src/app/modules/home/modules/cycle-counts/cycle-counts.service';


@Component({
    selector: 'app-linked-popup-opener',
    templateUrl: './linked-popup-opener.component.html',
    styleUrls: ['./linked-popup-opener.component.scss']
})
export class LinkedPopupOpenerComponent implements OnInit, OnDestroy {


    @Input() data: any;
    popupData: any;

    @Output() onPopupClose = new EventEmitter();
    showLinkPopup: boolean = false;
    orderTypes = OrderTypes

    constructor(
        private inventoryService: InventoryService,
        private serviceOrderService: ServiceOrderService,
        private iscService: IscService,
        private commonService: CommonService,
        private cycleCountService: CycleCountService,
    ) { }

    ngOnInit(): void {
        if(this.data && this.data.type){
            this.data.type = this.data.type.toLowerCase().replace(/\s+/g, '');
            this.loadPopup();
        }
    }

    openShipmentOrderLinks(args) {
        this.data.type = args.orderType.toLowerCase().replace(/\s+/g, '');
        this.data.id = args.orderId;
        this.loadPopup();
    }

    openLinkedPopup(data) {
        this.showLinkPopup = true;
        this.data = JSON.parse(JSON.stringify({
            id: data.orderId,
            type: data.orderType
        }));
        this.loadPopup();
    }

    loadPopup() {
        if ( this.data.type === OrderTypes.serviceOrder ) {
            this.getServiceOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.iscServiceOrder ) {
            this.getServiceOrder(this.data.id, true);
        } else if ( this.data.type === OrderTypes.partsOrder ) {
            this.getPartsOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.returnOrder ) {
            this.getReturnOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.coreReturnOrder ) {
            this.getCoreReturnOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.damagedReturnOrder ) {
            this.getDamagedReturnOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.shipmentOrder ) {
            this.getShipmentOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.purchaseOrder ) {
            this.getPurchaseOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.adjustmentorder ) {
            this.getAdjustmentOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.inventoryAdjustmentOrder ) {
            this.getShipmentInventoryAdjustmentOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.cycleCountOrder ) {
            this.getCycleCountOrder(this.data.id);
        } else if ( this.data.type === OrderTypes.transferOrder ) {
            this.popupData = this.data;
        }
    }

    getPartsOrder(id: string) {
        this.inventoryService.getPartsOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    onLoopLinkClicked($event) {
        this.commonService.showNotification('info', 'The popup is already open in the background')
    }

    getPurchaseOrder(id: string) {
        this.iscService.getPurchaseOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = [res.result];
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getServiceOrder(id: string, isISC: boolean = false) {
        this.serviceOrderService.getServiceOrderById(id, isISC)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                res.result && res.result.assignmentDetails.length && res.result.assignmentDetails.map((detail, index) => {
                    detail.rowId = index + 1;
                    return detail;
                });
                res.result && res.result.assignments.length && res.result.assignments.map((assignment, index) => { 
                    assignment.rowId = index +1;
                    assignment.priority = assignment.priority.toString();
                    assignment.tempId = assignment.assignmentId;
                    return assignment;
                });
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getReturnOrder(id: string) {
        this.inventoryService.getReturnOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getCoreReturnOrder(id: string) {
        this.inventoryService.getCoreReturnOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getDamagedReturnOrder(id: string) {
        this.inventoryService.getDamagedReturnOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getShipmentOrder(id: string) {
        this.inventoryService.getShipmentOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                res.result && res.result.itemList.length && res.result.itemList.map((item, index) => {
                    item.lineNumber = index + 1;
                    return item;
                });
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }

    getAdjustmentOrder(id: string) {
        this.inventoryService.getAdjustmentOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }
    
    getShipmentInventoryAdjustmentOrder(id: string) {
        this.inventoryService.getInventoryAdjustmentOrderById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }
    
    getCycleCountOrder(id: string) {
        this.cycleCountService.getCycleCountById(id)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
            } else {
                this.onClose();
                this.commonService.showNotification('error', res.message);
            }
        }, (error: HttpErrorResponse) => {
            this.onClose();
            this.commonService.showNotification('error', error.message);
        });
    }
    
    onClose() {
        this.popupData = null;
        this.onPopupClose.emit(null);
    }

    ngOnDestroy(){
        this.data = null;
        this.popupData = null;
    }

}
