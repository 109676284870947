export interface PurchaseOrderModel {
  commentCount?: number,
  attachmentCount?: number,
  linkCount?: number,
  openLinkCount?: number,
  linkColorCode?: string,
  id?: number,
  partsOrderId?: string,
  status?: string,
  parentStatus?: string,
  priority?: string,
  shipmentMethodCode?: string,
  shipmentMethodDescription?: string,
  addressType?: string,
  addressCode?: string,
  alternatePORef?:string,
  addressName?: string,
  address?: string,
  city?: string,
  state?: string,
  zipCode?: string,
  country?: string,
  phoneNumber?: string,
  telephone?: string,
  notes?: string,
  company?: string,
  technicianId?: string,
  technicianName?: string,
  warehouse?: string,
  orderCurrency?: string,
  shipFromWarehouse?: string,
  orderSubmissionDate?: string,
  itemList?: PurchaseOrderItem[],
  comments?: [],
  attachments?: [],
  history?: [],
  serviceOrderId?: string
  serviceOrderIdLN?: string
  soldToBP?: string
  orderIdLN?: string
  estimatedDeliveryDate?: string
  warehouseOrderLN?: string
  carrierCodeLN?: string
  orderCreationDateLN?: string
  orderOriginLN?: string
  setLN?: string
  shiptoCodeLN?: string
  isFstOrder?: string
  origin?: string
  originId?: string
  paymentMethod?: string,
  amount?: number,
  grossAmount?: number,
  cardType?: string
  bankName?: string
  checkAuthCode?: string
  ccmPaymentRef?: string
  createdDate?: Date
  createdBy?: string
  createdByName?: string
  lastUpdatedDate?: string
  lastUpdatedBy?: string
  lastUpdatedByName?: string,
  shippingCost?: number,
  tax?: number,
  totalAmount?: number,
  paymentStatus?: string,
  email?: string
}

interface PurchaseOrderItem {
  id?: number,
  lineNumber?: number,
  poid?: number,
  itemCode?: string,
  itemDescription?: string,
  itemGroup?: string,
  serviceItemGroup?: string,
  quantity?: number,
  plannedReceiptDate?: string,
  itemPrice?: number,
  itemDiscount?: number,
  itemTotal?: number,
  status?: string,
  trackingNumber?: string,
  estimatedDeliveryDate?: string,
  blockedLN?: string,
  lineNumberLN?: string,
  plannedReceiptDateLN?: string,
  quantityOrderedLN?: string,
  sequenceLN?: string,
  warehouseOrderLN?: string,
  isDirectDelivery?: string,
  purchaseOrder?: string,
  createdBy?: string,
  createdDate?: Date,
  createdByName?: string,
  lastUpdatedDate?: Date,
  lastUpdatedBy?: string,
  isDeleted?: boolean,
  quantityReceived?: string,
  quantityShippedInInventoryUnit?: string,
  shipmentOrderId?: string,
  shipmentStatus?: string,
  shipmentOrderLN?: string,
  unit?: string,
}

export const PurchaseOrderStatus = {
  submitted: 'Submitted',
  shipped: 'Shipped',
  inProgress: 'In Progress',
  paymentPending:'Payment Pending',
  paid:'Paid'
}

//////////////////////////////////////////////////////////////////////
