export const FieldLayoutDefaults = {
    sizeX: 2,
    sizeY: 1,
    row: null,
    col: null,
    minSizeX: 2,
    minSizeY: 1
};

export const GridLayoutDefaults = {
    sizeX: 12,
    sizeY: 3,
    row: null,
    col: null,
    minSizeX: 6,
    minSizeY: 2
};

export const IconList = [
    { iconCode: 'fa-list-ul' },
    { iconCode: 'fa-list-alt' },
    { iconCode: 'fa-pencil-alt' },
    { iconCode: 'fa-file-upload' },
    { iconCode: 'fa-bars' },
    { iconCode: 'fa-exchange-alt' },
    { iconCode: 'fa-tasks' },
    { iconCode: 'fa-th-list' },
    { iconCode: 'fa-sitemap' },
    { iconCode: 'fa-history' },
    { iconCode: 'fa-trash' },
    { iconCode: 'fa-sitemap' },
    { iconCode: 'fa-bug' },
    { iconCode: 'fa-viruses' },
    { iconCode: 'fa-virus' },
    { iconCode: 'fa-virus-slash' },
    { iconCode: 'fa-spider' },
    { iconCode: 'fa-check-square' },
    { iconCode: 'fa-chart-bar' },
    { iconCode: 'fa-link' },
    { iconCode: 'fa-unlink' },
    { iconCode: 'fa-clipboard' },
    { iconCode: 'fa-clipboard-check' },
    { iconCode: 'fa-sticky-note' },
    { iconCode: 'fa-trophy' },
    { iconCode: 'fa-cog' },
    { iconCode: 'fa-exclamation' },
    { iconCode: 'fa-exclamation-circle' },
    { iconCode: 'fa-exclamation-triangle' },
    { iconCode: 'fa-globe' },
    { iconCode: 'fa-crop-alt' },
    { iconCode: 'fa-crop' },
    { iconCode: 'fa-credit-card' },
    { iconCode: 'fa-question-circle' },
    { iconCode: 'fa-envelope' },
    { iconCode: 'fa-phone' },
    { iconCode: 'fa-info' },
    { iconCode: 'fa-flask' },
    { iconCode: 'fa-key' },
    { iconCode: 'fa-plane' },
    { iconCode: 'fa-user-circle' },
    { iconCode: 'fa-code' },
    { iconCode: 'fa-stethoscope' }
];