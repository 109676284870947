import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
    DashboardLayout,
    ResizeEventArgs,
    ChangeEventArgs
} from '@syncfusion/ej2-angular-layouts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Element, FieldTypes, GridElement, GridTypes, Positions } from 'src/app/modules/builder/models/main.model';
import { Operators, Rule } from 'src/app/modules/builder/models/rule.model';
import { calculateMinMaxDates } from 'src/app/shared/utils/date-functions';
import { applyActions, applyRulesOnLoad, checkConditions, validateMaxDate, validateMinDate } from 'src/app/shared/utils/rules-functions';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { validationMessage } from "src/app/shared/utils/general-utils";
import { formatDate } from '@angular/common';
import { CallGroups } from 'src/app/modules/service-order/models/service-order.model';

@Component({
    selector: 'app-dynamic-popup-layout',
    templateUrl: './dynamic-popup-layout.component.html',
    styleUrls: ['./dynamic-popup-layout.component.scss'],
    providers: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DynamicPopupLayoutComponent implements OnInit {

    
    private destroy$: Subject<null> = new Subject();
    fieldTypes = FieldTypes;
    gridTypes = GridTypes;
    positions = Positions;
    selectedItem: Element = null;
    formObject: FormValidator = null;
    
    @ViewChild('default_layout') layout: DashboardLayout;
    @ViewChild('fieldsForm') fieldsForm: NgForm;
    
    @Input() elements: Element[];
    @Input() allElements: Element[];
    @Input() rules: Rule[];
    @Input() fields: any;
    @Input() layoutId: any;
    @Input() tabId: any;
    @Input() module: string;
    @Input() isEditable;
    @Input() serviceOrderData: any;
    @Input() data: any;

    @Output() onItemSelected: EventEmitter<any> = new EventEmitter();
    @Output() onFormStatusChange: EventEmitter<any> = new EventEmitter();
    @Output() panelResized: EventEmitter<any> = new EventEmitter();
    @Output() panelLocationChanged: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void { 
    }

    ngAfterViewInit(): void {
        if(this.module === 'Calibration'){
            const rules = {};
            this.elements.forEach( (ele: Element) => {
                const eleRule = {};
                let rangeMessage = null;
                const minDate = (ele.elementType === this.fieldTypes.datepicker || ele.elementType === this.fieldTypes.datetimepicker) && ele.min != null ? formatDate(new Date(new Date().setDate(new Date().getDate() + ele.min)),"dd MMM yyyy",'en') : null;
                let maxDate = (ele.elementType === this.fieldTypes.datepicker || ele.elementType === this.fieldTypes.datetimepicker) && ele.max != null ? formatDate(new Date(new Date().setDate(new Date().getDate() + ele.max)),"dd MMM yyyy",'en')  : null;
                if(ele.elementKey == "serialNo" && ((this.serviceOrderData.callGroup && this.serviceOrderData.callGroup == CallGroups.initialCalibration) || ele.value == null)){
                    ele.readonly = false
                }
                if((ele.elementKey == "calibrationDueDate") && (this.data && this.data.calibrationType && ['Gas','BeamSetter','BrakeTester','Smoke'].includes(this.data.calibrationType))){
                    const months = this.data.calibrationType == 'Smoke'? 12: 6
                    if(this.data.previousCalibrationDate) {
                        let monthsLater = new Date() 
                        const previousDate = new Date(formatDate(this.data.previousCalibrationDate, "dd MMM yyyy", 'en'));
                        const currentDate = new Date(formatDate(new Date(), "dd MMM yyyy", 'en'))
                        if(previousDate < currentDate) {
                            monthsLater = new Date(new Date().setMonth(new Date().getMonth() + months));
                        } else {
                            monthsLater = new Date(new Date(previousDate).setMonth(new Date(previousDate).getMonth() + months));
                        }
                        maxDate = formatDate(monthsLater, "dd MMM yyyy", 'en');  
                        ele.maxDate = new Date(maxDate)
                    }
                }
                if(ele.required)
                    eleRule["required"] = [true,validationMessage("This field is required!")];
                if ( ele.min != null && ele.max){
                    rangeMessage = `Please enter a value between ${ele.min} to ${ele.max} ${ele.elementType === this.fieldTypes.textbox ? 'characters': ''}`;
                }
                if(ele.min != null) // && (typeof ele.min === 'string' && ele.min))
                    eleRule[ele.elementType === this.fieldTypes.textbox ? 'minLength' : 'min'] = [minDate ? validateMinDate.bind(new Date(minDate)) : ele.min,validationMessage(rangeMessage || `Please enter ${ele.elementType === this.fieldTypes.textbox ? 'no more than ': 'a value greater than or equal to '}${minDate || ele.min} ${ele.elementType === this.fieldTypes.textbox ? 'characters': ''}`)];
                if(ele.max)
                    eleRule[ele.elementType === this.fieldTypes.textbox ? 'maxLength' : 'max'] = [maxDate ? validateMaxDate.bind(new Date(maxDate)) : ele.max,validationMessage(rangeMessage || `Please enter ${ele.elementType === this.fieldTypes.textbox ? 'at least ': 'a value less than or equal to '}${maxDate || ele.max} ${ele.elementType === this.fieldTypes.textbox ? 'characters': ''}`)];
                if(Object.keys(eleRule).length > 0){
                    rules[ele.elementKey] = eleRule;
                }
            });
            let options: FormValidatorModel = {
                rules: rules
            };
            this.formObject = new FormValidator('#form-'+this.tabId+'-'+this.layoutId, options);
        }
        this.fieldsForm.statusChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            result => {
                if ( this.fieldsForm.form.touched ) {
                    this.onFormStatusChange.emit(this.fieldsForm.valid);
                }
            }
        );
        setTimeout( () => {
            applyRulesOnLoad(this.allElements, this.rules);
        }, 50)
    }

    ngOnChanges(change: SimpleChanges) {
        if ( change && change.fields && change.fields.currentValue ) {
            this.fields = change.fields.currentValue;
            if ( !change.elements ) {
                this.elements.map( (element: Element | GridElement) => {
                    if ( element.elementType === GridTypes.grid ) {
                        element.dataSource = this.fields[element.elementKey] || [];
                    } else {
                        element.value = this.fields[element.elementKey];
                    }
                });
            }
        }
        if ( change && change.elements && change.elements.currentValue ) {
            change.elements.currentValue.sort(function (a, b) {   
                return a.row - b.row || a.col - b.col;
            });
            change.elements.currentValue.map( (element: Element | GridElement) => {
                if ( element.elementType === GridTypes.grid ) {
                    element.dataSource = this.fields[element.elementKey] || [];
                } else {
                    element.minDate = calculateMinMaxDates(element.min);
                    element.maxDate = calculateMinMaxDates(element.max);
                    element.value = this.fields[element.elementKey] || null;
                }
            });
            this.elements = change.elements.currentValue;
        }
    }

    validate() {
        this.fieldsForm.form.markAllAsTouched();
        this.onFormStatusChange.emit(this.formObject != null ? this.formObject.validate() : this.fieldsForm.valid);
        return this.formObject != null ? this.formObject.validate() : this.fieldsForm.valid;
    }

    resetRules() {
        applyRulesOnLoad(this.allElements, this.rules);
    }

    onResize(event: ResizeEventArgs) { }

    onLocationChange(event: ChangeEventArgs) { }

    selectItem(event: any, element: Element) {
        event.stopPropagation();
        this.selectedItem = element;
        this.onItemSelected.emit(element);
    }

    getFieldRules(field: Element, rules: Rule[]) {
        const fieldRules = [];
        if ( rules && rules.length ) {
            rules.filter( (rule: Rule) => {
                if ( rule.conditions.filter(condition => condition.field.elementKey === field.elementKey && condition.operator !== Operators.doesntHaveValue).length ) {
                    fieldRules.push(rule);
                }
            });
        }
        return fieldRules;
    }

    onChange(field: Element, args: any) {
        const fieldRules = this.getFieldRules(field, this.rules);
        fieldRules.forEach( (rule: Rule) => {
            setTimeout( () => {
                if ( checkConditions(rule.conditions) ) {
                    applyActions(rule.actions, this.allElements);
                }
            }, 50)
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }


}
