import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { EventArgs } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-email-address-input',
    templateUrl: './email-address-input.component.html',
    styleUrls: ['./email-address-input.component.scss']
})
export class EmailAddressInputComponent implements OnInit {
  
    @Input() receiversEmails: string;
    @Input() ccReceiversEmails: string;
    @Input() emailSubject: string;

    emailValid: boolean = true;
    ccEmailValid: boolean = true;
    emailSubjectValid: boolean = true;
    validationApplied: boolean = false;
    receiversEamilsList: string[];
    ccReceiversEamilsList: string[];

    constructor(
        private commonService: CommonService,
    ) { 
    }

    ngOnInit(): void {
    }
    
    validate(){
        this.validationApplied = true;
        this.checkReceiverEmail();
        this.checkCCEmail();
        return this.validationApplied && (this.receiversEmails && this.emailValid) &&  this.ccEmailValid;
    }

    checkReceiverEmailInput(event){
        if(event.value.charAt(event.value.length - 1) === ';'){
            this.checkReceiverEmail();
        }
    }
    
    checkReceiverEmail(){
        let receiversEmails = '';
        if(this.receiversEmails){
            this.receiversEamilsList = this.receiversEmails.split(';')
            this.receiversEamilsList.forEach( (email) => {
                if(email.trim()){
                    receiversEmails = receiversEmails + email.trim() + '; ';
                }
            });
            this.receiversEamilsList.every( (email) => {
                if( email.trim()){
                    this.emailValid = this.commonService.checkValidEmail(email.trim());
                    return this.emailValid;
                }
            })
        } else {
            this.emailValid = true;
        }
        this.receiversEmails = '';
        this.receiversEmails = receiversEmails;
    }

    checkCCReceiverEmailInput(event){
        if(event.value.charAt(event.value.length - 1) === ';'){
            this.checkCCEmail();
        }
    }

    checkCCEmail(){
        let ccReceiversEmails = '';
        if(this.ccReceiversEmails){
            this.ccReceiversEamilsList = this.ccReceiversEmails.split(';')
            this.ccReceiversEamilsList.forEach( (email) => {
                if(email.trim()){
                    ccReceiversEmails = ccReceiversEmails + email.trim() + '; ';
                }
            });
            this.ccReceiversEamilsList.every( (email) => {
                if(email.trim()){
                    this.ccEmailValid = this.commonService.checkValidEmail(email.trim());
                    return this.ccEmailValid;
                }
            })
        } else {
            this.ccEmailValid = true;
        }
        this.ccReceiversEmails = '';
        this.ccReceiversEmails = ccReceiversEmails;
    }

    getReceiverEmails(){
        return this.receiversEmails;
    }
    getCCReceiverEmails(){
        return this.ccReceiversEmails;
    }
    getEmailSubject(){
        return this.emailSubject;
    }

}
