<div class="row mt-3">
    <div class="col">
        <div class="row">
            <div class="col">
                <div class="field mb-3 position-relative">
                    <label>Warehouse:</label>
                    <div class="col pt-2">{{ data?.warehouse }}</div>
                </div>
            </div>
            <div class="col">
                <div class="field mb-3 position-relative">
                    <label>Adjustment Reason:</label>
                    <div class="col pt-2">{{ data?.reasonForAdjustment }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="row">
            <div class="col">
                <div class="field mb-3 position-relative">
                    <label>Electronic Order Number:</label>
                    <div class="col pt-2">{{ data?.electronicOrderNumber }}</div>
                </div>
            </div>
            <div class="col"></div>
        </div>
    </div>
</div>

