<div 
    class="modal fade show"
    *ngIf="showPopup"
    [ngClass]="{'expanded': isExpanded}"
    id="assignment-details-modal" 
    tabindex="-1" 
    aria-labelledby="assignment-details-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Transaction Item</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body custom-scrollbar pt-3">  
                <div class="error-text text-right" *ngIf="data.totalAmount < 0">Total Amount can't be less than 0.</div>
                <app-loader *ngIf="showLoader"></app-loader>
                <form #transactionsForm="ngForm">
                    <div class="card">
                        <div class="card-header d-flex pl-0" data-toggle="collapse" href="#collapseGeneral">
                            <a class="card-link flex-fill">General</a>
                            <i class="fas fa-chevron-up"></i>
                        </div>
                        <div id="collapseGeneral" class="collapse show">
                            <div class="card-body pb-0">

                                <div class="row">
                                    <div class="col-3">
                                        <div class="field mb-3 position-relative"> 
                                            <label>Term:</label><span class="required-asterik">*</span>
                                            <ejs-dropdownlist 
                                                required
                                                name="assignmentId"
                                                #assignment="ngModel"
                                                [title]="data.referenceActivityDescription"
                                                [(ngModel)]="data.assignmentId"
                                                [placeholder]="'Select assignment'" 
                                                [dataSource]="assignments"
                                                [fields]="{text: 'referenceActivityDescription', value: 'tempId'}"
                                                [allowFiltering]="true"
                                                [filterType]="'contains'"
                                                [sortOrder]="'Ascending'"
                                                (change)="onAssignmentChange($event)"
                                            ></ejs-dropdownlist>
                                            <div class="field-error" *ngIf="assignment.invalid && assignment.touched && assignment.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3 position-relative"> 
                                            <label>Cost Type:</label><span class="required-asterik">*</span>
                                            <ejs-dropdownlist #costTypeDDL
                                                required
                                                name="costType"
                                                #costType="ngModel"
                                                [title]="data.costType"
                                                [(ngModel)]="data.costType"
                                                [placeholder]="'Select Cost Type'" 
                                                [dataSource]="costTypes"
                                                [allowFiltering]="true"
                                                [filterType]="'contains'"
                                                [fields]="{text: 'text', value: 'value'}"
                                                (change)="onCostTypeChange($event)"
                                            ></ejs-dropdownlist>
                                            <div class="field-error" *ngIf="costType.invalid && costType.touched && costType.errors.required">This field is required</div>
                                        </div>
                                    </div>
                                    <div class="col-3" *ngIf="data.costType === CostTypes.material || data.costType === CostTypes.otherCost">
                                        <div class="field mb-3 position-relative"> 
                                            <label>Item:</label><span class="required-asterik" *ngIf="data.costType === CostTypes.material">*</span>
                                            <a class="item-advance-search-icon" href="javascript:" title="Advanced Search" (click)="itemAdvanceSearch($event)"><i class="fas fa-info"></i></a>
                                            <ejs-combobox  
                                                required
                                                #itemsCombo
                                                name="itemCode"
                                                #item="ngModel"
                                                [title]="data.itemCode"
                                                [(ngModel)]="data.itemCode"
                                                [placeholder]="'Select Item'" 
                                                [dataSource]="itemsList"
                                                [allowFiltering]="true"
                                                [filterType]="'contains'"
                                                [popupWidth]="'350px'"
                                                [enabled]="data.costType === CostTypes.material || data.costType === CostTypes.otherCost"
                                                [sortOrder]="'Ascending'"
                                                [fields]="{text: 'text', value: 'value'}"
                                                (filtering)="onItemUpdate.next($event.text)"
                                                (focus)="itemsCombo.showPopup()"
                                                (change)="onServiceItemChange($event)"
                                            ></ejs-combobox>
                                            <div class="field-error" *ngIf="item.invalid && item.touched && item.errors.required && data.costType === CostTypes.material">This field is required</div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Description:</label>
                                            <ejs-textbox 
                                                [readonly]="true"
                                                [title]="data.itemDescription"
                                                [value]="data.itemDescription"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                    <div class="col-3" *ngIf="data.costType === CostTypes.labor">
                                        <div class="field mb-3"> 
                                            <label>Labor Type:</label>
                                            <ejs-textbox 
                                                [readonly]="true"
                                                [title]="data.laborType"
                                                [value]="data.laborType"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Comments:</label>
                                            <ejs-textbox 
                                                name="comments"
                                                #comments="ngModel"
                                                [title]="data.comments"
                                                [(ngModel)]="data.comments"
                                                [placeholder]="'Enter comments...'"
                                                rows=2
                                                [multiline]="true"
                                                [value]="data.comments"
                                                (change)="data.comments = $event.value;"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="field mb-3 position-relative"> 
                                            <label>Quantity({{data.unit}}):</label><span class="required-asterik">*</span>
                                            <ejs-numerictextbox
                                                required="true"
                                                name="qty"
                                                #qty="ngModel"
                                                [title]="data.qty"
                                                [(ngModel)]="data.qty"
                                                [min]="0"
                                                [step]="data.costType === 'Material' ? 1 : 0.1"
                                                [format]="data.costType === 'Material' ? 'n' : 'n2'"
                                                [decimals]="data.costType === 'Material' ? 0 : 2"
                                                [validateDecimalOnType]="true"
                                                [enabled]="isQuantityEnabled()"
                                                (change)="onQuantityChange($event)"
                                                (blur)="onQuantityChangeBlur($event)"
                                            ></ejs-numerictextbox>
                                            <div class="field-error" *ngIf="qty.invalid && qty.touched && qty.errors.required">This field is required</div>
                                            <div class="field-error" [ngClass]="{'color-green' : data.costType === CostTypes.material && data.qty <= onHandCount}" *ngIf="qty.valid && onHandCount > -1 && data.costType === CostTypes.material && data.itemCode">On Hand: {{ onHandCount }}</div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Price:</label><span class="required-asterik">*</span>
                                            <ejs-numerictextbox 
                                                required
                                                name="price"
                                                #price="ngModel"
                                                [title]="data.price"
                                                [(ngModel)]="data.price"
                                                [min]="0"
                                                [enabled]="!isKingslynn && (data.costType === CostTypes.material)"
                                                (created)="calculateTaxAmount()"
                                                (change)="onPriceChange($event)"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Discount:</label>
                                            <ejs-numerictextbox
                                                name="discountAmount"
                                                #discountAmount="ngModel"
                                                [title]="data.discountAmount"
                                                [(ngModel)]="data.discountAmount"
                                                [min]="0"
                                                [enabled]="data.referenceActivityDescription === 'Billable Service'"
                                                (change)="onDiscountChange($event)"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Total Amount:</label>
                                            <ejs-numerictextbox 
                                                name="totalAmount"
                                                #totalAmount="ngModel"
                                                [title]="data.totalAmount"
                                                [(ngModel)]="data.totalAmount"
                                                [enabled]="false"
                                                [showSpinButton]="false"
                                                [readonly]="true"
                                                [value]="data.totalAmount"
                                                (change)="onTotalAmountChange($event)"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Warranty Amount:</label>
                                                <ejs-numerictextbox
                                                    [min]="0"
                                                    [value]="data.warrantyCoverage"
                                                    [enabled]="false"
                                                    [showSpinButton]="false"
                                                    [readonly]="true"
                                                ></ejs-numerictextbox>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="field mb-3"> 
                                                <label>Contract Amount:</label>
                                                <ejs-numerictextbox
                                                    [min]="0"
                                                    [value]="data.contractCoverage"
                                                    [enabled]="false"
                                                    [showSpinButton]="false"
                                                    [readonly]="true"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Tax %:</label>
                                            <ejs-numerictextbox
                                                [readonly]="true"
                                                [showSpinButton]="false"
                                                [value]="data.taxpercent"
                                                format="n4"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Tax Amount:</label>
                                            <ejs-numerictextbox
                                                [readonly]="true"
                                                [showSpinButton]="false"
                                                [value]="data.taxAmount"
                                            ></ejs-numerictextbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Unit:</label>
                                            <ejs-dropdownlist 
                                                required
                                                name="unit"
                                                #unit="ngModel"
                                                [title]="data.unit"
                                                [(ngModel)]="data.unit"
                                                [placeholder]="'Select Unit'" 
                                                [dataSource]="units"
                                                [allowFiltering]="true"
                                                [filterType]="'contains'"
                                                [sortOrder]="'Ascending'"
                                                [enabled]="false"
                                                [value]="data.unit"
                                                [fields]="{text: 'text', value: 'value'}"
                                                (change)="data.unit = $event.itemData.value"
                                            ></ejs-dropdownlist>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Currency:</label>
                                            <ejs-textbox 
                                                [readonly]="true" 
                                                [value]="data.currency"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Cost Component:</label>
                                            <ejs-textbox
                                                [readonly]="true"
                                                [value]="data.costComponent"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                    <div class="col-3" *ngIf="data.costType === CostTypes.material">
                                        <div class="field mb-3"> 
                                            <label>Warehouse:</label>
                                            <ejs-textbox 
                                                [readonly]="true"
                                                [title]="warehouse"
                                                [value]="data.warehouse"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="data.costType === CostTypes.labor">
                                    <div class="col-3">
                                        <div class="field mb-3">
                                            <label>Start Time:</label>
                                            <ejs-datetimepicker
                                                name="startTime"
                                                #startTime="ngModel"
                                                [(ngModel)]="data.startDate"
                                                #startDatePicker
                                                [min]="checkinTime || today"
                                                [max]="data.endDate || null"
                                                [format]="'d MMMM, y, h:mm a'"
                                                [placeholder]="'Select start time...'"
                                                [enabled]="data.costType === CostTypes.labor"
                                                (focus)="$event.model.show()"
                                                (change)="onStartDateChange($event)"
                                            ></ejs-datetimepicker>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3">
                                            <label>End Time:</label>
                                            <ejs-datetimepicker
                                                #endDatePicker
                                                name="endTime"
                                                #endTime="ngModel"
                                                [(ngModel)]="data.endDate"
                                                [min]="data.startDate || null"
                                                [placeholder]="'Select end time...'"
                                                [format]="'d MMMM, y, h:mm a'"
                                                [enabled]="data.costType === CostTypes.labor"
                                                (focus)="$event.model.show()"
                                                (change)="onEndDateChange($event)"
                                            ></ejs-datetimepicker>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="field mb-3"> 
                                            <label>Task:</label>
                                            <ejs-textbox 
                                                required
                                                name="task"
                                                #task="ngModel"
                                                [title]="data.task"
                                                [enabled]="false"
                                                [(ngModel)]="data.task"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="" 
                    [disabled]="transactionsForm.invalid || data.totalAmount < 0 || data.costType === CostTypes.material && onHandCount < data.qty" 
                    (click)="saveAssignment()"
                >Save</button>
            </div>
        </div>
    </div>
</div>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [technicianId]="technicianId"
    [serviceItemGroup]="serviceItemGroup"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>