import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class GeneralSectionService {

    constructor(
        private http: HttpClient
    ) { }

    getServiceCenters(company) {
        return this.http.get(`${BASE_URL}ServiceCentre/GetServiceCentresForDDL?company=${company}`);
    }

    getConfigurations(serviceDepartment: string, text: string, company: string) {
        return this.http.get(`${BASE_URL}Configuration/GetConfigurationsForDDL?company=${company}&filterText=${text}&serviceDepartment=${serviceDepartment}`);
    }

    getCustomerInfoByConfiguration(configurationId: string, serial?: any, item?: any) {
        const serialForWorkSiteAddress = serial ? `&serialNumber=${serial}` : '';
        const itemForWorkSiteAddress = item ? `&item=${item}` : '';
        return this.http.get(`${BASE_URL}Configuration/GetCustomerInfoByConfiguration?configuration=${configurationId}${serialForWorkSiteAddress}${itemForWorkSiteAddress}`);
    }

    getSignals(company) {
        return this.http.get(`${BASE_URL}Signal/GetSignalsForDDL?company=${company}`);
    }

    getCallgroups(company) {
        return this.http.get(`${BASE_URL}CallGroup/GetCallGroupsForDDL?company=${company}`);
    }

    getIscCallGroups(soldToBp?: string, offlineOrder: boolean = true) {
        const customerCode: string = soldToBp && `&soldToBp=${soldToBp}` || '';
        return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCCallGroupsForDDL?offlineOrder=${offlineOrder}${customerCode}`);
    }

    getObjects(configurationId: string) {
        return this.http.get(`${BASE_URL}EquipmentObject/GetObjectsForDDL?configuration=${configurationId}`);
    }

    getEstimatedDistance(CustomerAddressCode, ISCAddressCode) {
        return this.http.get(`${BASE_URL}ISCServiceOrder/ISCCustomerDistanceCalculator?ISCAddressCode=${ISCAddressCode}&CustomerAddressCode=${CustomerAddressCode}`);
    }

}
