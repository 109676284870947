import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { Companies } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { serviceOrderStatuses } from '../../models/service-order.model';

@Component({
    selector: 'app-notes-comments-section',
    templateUrl: './notes-comments-section.component.html',
    styleUrls: ['./notes-comments-section.component.scss']
})
export class NotesCommentsSectionComponent implements OnInit, OnChanges {

    @Input() status = serviceOrderStatuses.open;
    @Input() data = null;
    @Input() isEditable: boolean = true;
    @Input() fieldRules: any;
    USER: any;
    isKingslynn: boolean = true;
    validationApplied: boolean = false;
    serviceOrderStatuses = serviceOrderStatuses;
    @Output() notesSectionUpdated: EventEmitter<any> = new EventEmitter();
    commentsToCustomerReadOnly: boolean = false;
    customerComplaintReadOnly: boolean = false;

    constructor(private commonService: CommonService) { 
        this.USER = commonService.USER;
        this.commentsToCustomerReadOnly = this.commonService.roleClaims['SO_Popup_Field_fstCommentsToCustomer']?.readOnly 
        this.customerComplaintReadOnly = this.commonService.roleClaims['SO_Popup_Field_customerComplaint']?.readOnly 
    }

    ngOnInit(): void {
        this.isKingslynn = this.USER.company === Companies.kingslynn;
        this.setPageData();
    }

    ngOnChanges(change: SimpleChanges): void {
        if (change && change.status) {
            this.status = change.status.currentValue;
        }
    }

    setPageData() {
        if (!this.data) {
            this.data = {
                activityText: null,
                customerComplaint: null,
                fstCommentsToCustomer: null,
                notesToCustomerCare: null,
                cancellationNotes: null,
                reasonCode: null,
                reasonDescription: null,
                offlineOrder: true
            };
        }
    }

    validate(onInvoicePaymentValidate) {
        this.validationApplied = true;
        if (this.status === serviceOrderStatuses.cancelled  && !this.data.reasonCode || this.isKingslynn && onInvoicePaymentValidate && !this.data.fstCommentsToCustomer ) {
            return false;
        } else {
            return true;
        }
    }

    getData() {
        return this.data;
    }

    onTextInput(args, textareaObj) {
        textareaObj.element.style.height = "auto";
        textareaObj.element.style.height = (textareaObj.element.scrollHeight) + "px";
    }

    fstCommentsToCustomerChange(args: any){
        this.data.fstCommentsToCustomer = args?.value
        this.notesSectionUpdated.emit(this.data)
    }

}
