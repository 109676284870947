import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { parentStatuses, shipmentOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { ApiResponse, LinksEnum, UserRoles } from 'src/app/shared/models/app.model';

@Component({
    selector: 'app-shipment-order-popup',
    templateUrl: './shipment-order-popup.component.html',
    styleUrls: ['./shipment-order-popup.component.scss']
})
export class ShipmentOrderPopupComponent implements OnInit {
    private destroy$: Subject<null> = new Subject();
    USER;

    @Input() tab: string = 'details';
    @Input() id: string = null;
    @Input() showComments: boolean = false;
    @Input() data = null;

    loadedData = null;

    showLoader: boolean = false;
    isExpanded: boolean = true;
    isManager: boolean = false;
    isCSA: boolean = false;
    enableReceiveOrder: boolean = false;
    detailsData: Object;
    itemsDDL = [];
    itemsData = [];
    exceptionItem;
    updatedException;
    exceptionsData = [];
    links = [];
    attachmentsData: Object[];

    commentCount = 0;
    attachmentCount = 0;
    isKLManager: boolean = false;
    resetBtnDisabled: boolean = false;
    receiveBtnDisabled: boolean = false;

    @ViewChild('commentsButton') commentsButton: ElementRef;
    @ViewChild('commentsPopup') commentsPopup: ElementRef;

    @ViewChild('detailsTab') details;
    // @ViewChild('itemsTab') items;
    // @ViewChild('exceptionsTab') exceptions;
    @ViewChild('attachmentsTab') attachments;
    @ViewChild('receiptsTab') receipts;

    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();
    @Output() onNeedRefresh = new EventEmitter();
    @Output() onLinkIdClick = new EventEmitter();
    isTechnician: boolean = false;
    validationApplied: boolean;
    isDeliverAcknowledged: boolean = false;
    isPartReceived: boolean = false;

    constructor(
        private commonService: CommonService,
        private inventoryService: InventoryService
    ) {
        this.USER = this.commonService.USER;
        this.resetBtnDisabled = this.commonService.roleClaims['Inv_Order_Receipt_Popup_ResetBtn']?.disable;
        this.receiveBtnDisabled = this.commonService.roleClaims['Inv_Order_Receipt_Popup_ReceiveBtn']?.disable;
    }

    ngOnInit(): void {
        this.checkStatuses();
        this.inventoryService.receiptsPopupLoader.pipe(
            takeUntil(this.destroy$),
        ).subscribe((showLoader: any) => {
            this.showLoader = showLoader;
        });

        this.loadPage();
    }
    loadPage() {
        this.inventoryService.receiptsPopupLoader.next(true);
        this.setPageData();

        this.setDetailsTabData();
        this.setItemsTabData();
        this.toggleReceiveButton();
        this.setExceptionsTabData();
        this.setAttachmentsTabData();

        this.loadAPIs();
    }

    checkStatuses(){
        this.isDeliverAcknowledged = this.data.status === shipmentOrderStatuses.deliveryAcknowledged;
        this.isPartReceived = this.data.status === shipmentOrderStatuses.received;
    }

    setPageData() {
        this.data = {
            shipmentOrderId: this.data.shipmentOrderId,
            shipmentOrderLN: this.data.shipmentOrderLN,
            status: this.data.status,
            parentStatus: parentStatuses.open,
            trackingId: this.data.trackingId,
            shipmentMethodCode: this.data.shipmentMethodCode,
            shipmentMethodDescription: this.data.shipmentMethodDescription,
            notes: this.data.notes,
            company: this.data.company,
            estimatedDeliveryDate: this.data.estimatedDeliveryDate && new Date(this.data.estimatedDeliveryDate),
            createdBy: this.data.createdBy,
            createdDate: this.data.createdDate && new Date(this.data.createdDate),
            createdByName: this.data.createdByName,
            technicianId: this.data.technicianId,
            technicianName: this.data.technicianName,
            lastUpdatedDate: this.data.lastUpdatedDate && new Date(this.data.lastUpdatedDate),
            lastUpdatedBy: this.data.lastUpdatedBy,
            lastUpdatedByName: this.data.lastUpdatedByName,
            linkColorCode: this.data.linkColorCode,
            itemList: this.data.itemList,
            exceptionList: this.data.exceptionList,
            comments: this.data.comments,
            attachments: this.data.attachments,
            history: this.data.history,
            shipToWarehouse: this.data.shipToWarehouse,
            totMisc: this.data.totMisc,
            totQtyFromLN: this.data.totQtyFromLN,
            totServiceableReceived: this.data.totServiceableReceived,
        };
        this.commentCount = this.data.comments.length;
        this.attachmentCount = this.data.attachments.length;
    }

    setDetailsTabData() {
        this.detailsData = {
            shipmentOrderLN: this.data.shipmentOrderLN,
            trackingId: this.data.trackingId,
            createdDate: this.data?.createdDate && new Date(this.data.createdDate),
            shipmentMethod: this.data?.shipmentMethod,
            shipmentMethodDescription: this.data?.shipmentMethodDescription || '',
            shipmentMethodCode: this.data?.shipmentMethodCode || '',
            estimatedDeliveryDate: this.data?.estimatedDeliveryDate && new Date(this.data?.estimatedDeliveryDate) || null,
            notes: this.data?.notes || null
        }
    }

    setItemsTabData() {
        this.itemsData = this.data?.itemList || [];
    }

    onShipmentItemsUpdate(itemsData) {
        this.itemsData = itemsData[0];
        this.exceptionsData = itemsData[1];
        this.toggleReceiveButton();
    }

    toggleReceiveButton() {
        const missingQuantityServiceableIndex = this.itemsData.findIndex(ex => ex.quantityServiceable === null);
        const missingQuantityReceivedIndex = this.exceptionsData.findIndex(element => element.quantity === null);
        this.enableReceiveOrder = this.itemsData.length && missingQuantityReceivedIndex === -1 && missingQuantityServiceableIndex === -1;
    }

    setExceptionsTabData() {
        this.exceptionsData = this.data?.exceptionList || [];
    }

    setAttachmentsTabData() {
        this.attachmentsData = this.data?.attachments || [];
    }

    loadAPIs() {
        const promises = [
            this.commonService.getServiceItemsForDDL(this.USER.company),
        ];
        this.id && promises.push(this.commonService.getLinkedRecords(LinksEnum.shipmentOrder, this.id));

        forkJoin(promises).subscribe((res: any) => {
            this.itemsDDL = res[0].result.map(item => {
                item.text = item.value + ' | ' + item.description;
                return item;
            });
            this.links = this.id ? res[1].result : [];
            this.setData();
            this.loadedData = JSON.stringify(this.data);
            this.inventoryService.receiptsPopupLoader.next(false);
        }, (error: HttpErrorResponse) => {
            this.inventoryService.receiptsPopupLoader.next(false);
            throw error;
        });
    }

    expandCollapse(value: boolean) {
        this.isExpanded = value;
    }

    updateStatus(args) {
        this.data.status = args.checked ? shipmentOrderStatuses.deliveryAcknowledged : shipmentOrderStatuses.shipped;
        this.checkStatuses();
        this.save(false, true);
    }

    onCommentUpdate(data: any) {
        this.data.comments = data.map(comment => {
            if (comment.isNew) {
                comment.commentId = 0;
            }
            return comment;
        });
        this.commentCount = data.filter(comment => !comment.isDeleted).length;
        this.onNeedRefresh.emit();
    }

    setData() {
        const status = this.data.status;
        this.data = {
            ...this.data,
            ...this.details?.getData() || this.detailsData,
            ...this.receipts?.getItemsData() || this.itemsData,
            ...this.receipts?.getExceptionsData() || this.exceptionsData,
            status
        };

    }

    onAttachmentsUpdate(data: any) {
        this.data.attachments = data;
        this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
    }

    isChanged() {
        this.setData();
        return this.loadedData !== JSON.stringify(this.data);
    }

    reset() {
        if (this.isChanged()) {
            this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                .then(result => {
                    if (result.isConfirmed) {
                        this.data = JSON.parse(this.loadedData);
                        this.loadPage();
                    }
                })
        } else {
            this.commonService.showNotification('info', 'There is nothing to reset!');
        }
    }

    validate(skipValidation) {
        this.validationApplied = true;
        return this.receipts.validate(skipValidation);
    }

    saveAndClose(close?: boolean, skipValidation?: boolean, receiveOrder?: boolean){
        if (this.validate(skipValidation)) {
            this.commonService.showConfirmation('The part receipts and exceptions created will be confirmed. This cannot be reversed.Do you want to proceed?', 'Yes', 'No')
            .then(result => {
                if (result.isConfirmed) {
                    this.inventoryService.popupLoader.next(true);
                    this.save(close, skipValidation, receiveOrder)
                }
            });
        } else {
            this.commonService.showNotification('info', 'Please provide all required infromation. There might be incomplete exception row added.');
        }
    }

    save(close?: boolean, skipValidation?: boolean, receiveOrder?: boolean) {
        this.showLoader = true;
        this.setData();
        if(receiveOrder){
            this.data.status = shipmentOrderStatuses.received;
        }
        this.inventoryService.updateShipmentOrder(this.data)
        .subscribe((res: ApiResponse) => {
            if (res.isSuccessful) {
                this.data.shipmentOrderId = res.result.shipmentOrderId;
                this.exceptionsData = res.result?.exceptionList;
                this.loadedData = JSON.stringify(this.data);
                this.commonService.showNotification('success', res.message);
                this.onSave.emit(res.result);
                if (close) {
                    this.close(this.data);
                }
            }
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
            throw error;
        });

    }

    close(itemList?) {
        if (!this.isChanged()) {
            this.onClose.emit(itemList);
        } else {
            if(this.data.status !== shipmentOrderStatuses.received){
                this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                    .then(result => {
                        if (result.isConfirmed) {
                            this.onClose.emit(itemList);
                        }
                    })
            } else {
                this.onClose.emit(itemList);
            }
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
