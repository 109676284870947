import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { cycleCountStatuses, inventoryRequestStatuses } from 'src/app/modules/home/models/orders.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/shared/services/common.service';
import { ApiResponse, UserRoles } from 'src/app/shared/models/app.model';
import { CycleCountService } from '../cycle-counts.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cycle-counts-popup',
  templateUrl: './cycle-counts-popup.component.html',
  styleUrls: [
    './cycle-counts-popup.component.scss',
    // '../../../../assets/scss/popup.scss',
    // '../../../../assets/scss/service-order.scss'
  ]
})
export class CycleCountPopupComponent implements OnInit  {

  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = 'details';
  // @Input() transferOrderId: string = null;
  @Input() showComments: boolean = false;
//   @Input() itemsData = [];
  @Input() popupSource;
  @Input() rowData = null;
  @Input() transferOrderType;
  @Input() cycleCountData = null;
  @Input() cycleCountId = null;
  @Input() statusList = null;
  exceptionExist: boolean = false
  exceptionGridExist: boolean = false
  openExceptions


  USER;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  technicianList;
  technician: any;
  techAddresses;
  transferOrderId;
  onHandCount;

  disablePlaceOrder: boolean = true;
  validateDetails: boolean = false;
  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  showResourceSelectionPopup: boolean = false;
  showServiceOrderSelectionPopup: boolean = false;
  isCsa: boolean = false;
  isManager: boolean = false;  
  skipQuantityValidation: boolean = false;
  status = inventoryRequestStatuses.open;
  statusesDDL;
  noFilterStatusesDDL
  itemDescription: string;
  statusDropdownReadOnly: boolean = false;
  technicianId = null;
  cycleCountDuration
  canEditStartDate: boolean = false;
  dataChanged: boolean = false
  updatedcostAnalysisList
  isFileUploaded: boolean = false
  currentDate
  totalExtCost
  totalCostDifference
  showMsgOnClosed: boolean = true
  isStatusChanged: boolean = false

  commentCount = 0;
  attachmentCount = 0;
  links = [];
  lastDataForReset
  attachmentsData = [];

  @ViewChild('requesterddl') technicianDDL;
  @ViewChild('statusddl') statusDDL;
  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('addressDDL') addressDDL;
  @ViewChild('cycleCountStartDate') cycleCountStartDate;
  @ViewChild('appCostAnalysisGrid') appCostAnalysisGrid;
  @ViewChild('attachmentsTab') attachments;


  constructor(
      private commonService: CommonService,
      private cycleCountService: CycleCountService,
      private route: ActivatedRoute,
  ) {
      this.USER = this.commonService.USER;
      this.currentDate = new Date()
      this.canEditStartDate = this.commonService.roleClaims['CycleCount_StartDate']?.edit; "True for Manager & CSA"
      this.technicianId = this.cycleCountData?.technicianId ? this.cycleCountData?.technicianId : this.USER.userId
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if(params && params.get('id') && params.get('showComments')) {
        this.cycleCountId = params.get('id');
        this.showComments = params.get('showComments') == 'showComments' ? true : false;
      }
    });
    this.loadPopup();
  }

  loadPopup() {
    this.loadAPIs();
  }

  loadAPIs() {
      this.showLoader = true;
      const apiList = [
        this.commonService.getStatusesForDDL('CycleCount'),
        this.cycleCountService.getCycleCountById(this.cycleCountData?.cycleCountId? this.cycleCountData?.cycleCountId: this.cycleCountId)
      ];

      forkJoin(apiList).subscribe((res: any) => {
        if(res[0]?.isSuccessful) {
          this.statusesDDL = res[0].result; 
          this.noFilterStatusesDDL = this.statusesDDL
        } else {
          this.commonService.showNotification('error', res.message);
        }
        if(res[1]?.isSuccessful) {
          this.cycleCountData = res[1]?.result;
          this.statusesDDL = this.getStatuses(this.cycleCountData.status)
          this.commentCount = this.cycleCountData.comments.length;
          this.attachmentCount = this.cycleCountData.attachments.length;
          this.openExceptions = this.cycleCountData.openExceptions
          this.cycleCountData.openExceptions = this.openExceptions
          this.cycleCountId = this.cycleCountData.cycleCountId
          this.attachmentsData = this.cycleCountData.attachments
          if (this.cycleCountData?.startDate != null && this.cycleCountData?.endDate != null) {
            var startDate = new Date(this.cycleCountData?.startDate);
            var endDate = new Date(this.cycleCountData?.endDate);
            startDate.setUTCHours(0, 0, 0, 0);
            endDate.setUTCHours(0, 0, 0, 0);
            var timeDiff = endDate.getTime() - startDate.getTime();
            var daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            this.cycleCountDuration = daysDiff
          } 
          this.lastDataForReset = { ...this.cycleCountData };
          this.exceptionGridExist = true;
          if(this.openExceptions?.length > 0) {
            this.exceptionExist = true
          } else {
            this.exceptionExist = false
          }
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.commonService.showNotification('error', error.message)
        throw error;
      });
  }

  onStartDateChange(event) {
    this.cycleCountData.startDate = event.value
    if(event.value && this.cycleCountData && this.cycleCountData.status) {
      if(this.cycleCountData.status == cycleCountStatuses.open) {
        this.statusesDDL = this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.open, cycleCountStatuses.planned].includes(s.value));
        this.cycleCountData.status = cycleCountStatuses.planned
      }
    }
  }

  reloadPopupData() {
    this.loadAPIs()
  }

  onStatusChange(event) {
    const status = event?.itemData?.value
    const parentStatus = event?.itemData?.parent
    this.isStatusChanged = true
    if(status === 'Scheduled') {
      if (this.exceptionExist) {
        event.cancel = true
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please adjust all exceptions before scheduling the cycle count!',
          confirmButtonColor: '#479e47'
        })
      } else if (this.cycleCountData.startDate === null) {
        event.cancel = true
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please add start date before scheduling the cycle count!',
          confirmButtonColor: '#479e47'
        })
      } 
      else {
        this.cycleCountData.status = status
        this.cycleCountData.parentStatus = parentStatus
      }
    }
    else if (status === 'In Progress' && (this.cycleCountData?.cycleCountNumber == null || this.cycleCountData?.cycleCountNumber == '')) {
      event.cancel = true
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please add cycle count number!',
        confirmButtonColor: '#479e47'
      })
    } else if (status === 'Counted' && !this.isFileUploaded) {
      event.cancel = true
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please upload the file before scheduling the cycle count!',
        confirmButtonColor: '#479e47'
      })
    }
    else {
      this.cycleCountData.status = status
      this.cycleCountData.parentStatus = parentStatus
    }
  }

  expandCollapse(value: boolean) {
      this.isExpanded = value;
  }

  showMsgOnStatusClosed() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'The order will be closed and the out going messages to LN will be resumed. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#479e47',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.showMsgOnClosed = false
        this.save()
        this.showMsgOnClosed = true
        this.isStatusChanged = false
      }
    })
  }

  onCommentUpdate(data: any) {
      this.commentCount = data.filter(comment => !comment.isDeleted).length;
      this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
      this.attachmentsData = data;
      this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  isChanged() {
    return this.lastDataForReset !== this.cycleCountData
  }


  onResourcePopupClose(technician) {
    this.showResourceSelectionPopup = false;
    if (technician) {
      this.technicianList = [technician];
      this.technicianId = technician.employeeId;
    }
  }

  // checkIfCycleCountValid(event) {
  //   if(event.value != null && event.value != '') {
  //     this.cycleCountService.checkIfCycleCountNoExists(event.value).pipe(
  //       takeUntil(this.destroy$),
  //       debounceTime(500),
  //       distinctUntilChanged())
  //       .subscribe((res: ApiResponse) => {
  //         if (res.isSuccessful) {
  //           if(res.result == false) {
  //             event.value = ''
  //             this.cycleCountData.cycleCountNumber = ''
  //             Swal.fire({
  //               icon: 'warning',
  //               title: 'Warning',
  //               html: 'The cycle count number entered has not been processed in RIMS yet <br> <div style="margin-top: 10px;font-size: 17px;"> Please Try Again Later! </div>',
  //               confirmButtonColor: '#479e47'
  //             })
  //           }
  //         } else {
  //           this.commonService.showNotification('error',res.message);
  //         }
  //         this.showLoader = false
  //       }, (error: HttpErrorResponse) => {
  //         this.showLoader = false
  //         this.commonService.showNotification('error',error.message);
  //         throw error;
  //       });
  //     }
  // }

  save(close?: boolean, placeOrder?: boolean) {

    if (this.showMsgOnClosed && this.isStatusChanged && this.cycleCountData?.status == cycleCountStatuses.closed) {
      this.showMsgOnStatusClosed()
      return
    }

    this.appCostAnalysisGrid.endEdit()
    
      const itemList = this.updatedcostAnalysisList?.map((item) => ({
        id: item.id,
        cycleCountId: item.cycleCountId,
        itemCode: item.itemCode,
        rimsQtyOnhand: parseFloat(item.rimsQtyOnhand),
        lnQtyOnhand: item.lnQtyOnhand,
        actualQtyOnhand: parseFloat(item.actualQtyOnhand),
        actualQtyCorrected: parseFloat(item.actualQtyCorrected),
        cost: item.cost,
        isDeleted: item.isDeleted,
        comments: item.comments
      }));
      const payload = {
        id: this.cycleCountData?.id,
        company: this.USER?.company,
        cycleCountId: this.cycleCountData?.cycleCountId,
        warehouse: this.cycleCountData?.warehouse,
        status: this.cycleCountData?.status,
        parentStatus: this.cycleCountData?.parentStatus,
        startDate: this.cycleCountData?.startDate,
        endDate: this.cycleCountData?.endDate,
        cycleCountNumber: this.cycleCountData?.cycleCountNumber,
        technicianId: this.cycleCountData?.technicianId,
        technicianName: this.cycleCountData?.technicianName,
        itemList: itemList,
        attachments: this.cycleCountData?.attachments,
        createdDate: this.cycleCountData?.createdDate,
        createdBy: this.cycleCountData?.createdBy,
        lastUpdatedDate: this.cycleCountData?.lastUpdatedDate,
        lastUpdatedBy: this.cycleCountData?.lastUpdatedBy,
        lastUpdatedByName: this.cycleCountData?.lastUpdatedByName,
        totalExtCost: this.totalExtCost,
        totalCost: this.totalCostDifference
      }
      this.showLoader = true
      this.cycleCountService.updateCycleCount(payload)
          .subscribe((res: ApiResponse) => {
            if (res.isSuccessful) {
              const message = `The Cycle Count saved successfully.`;
              this.commonService.showNotification('success', message, 'center', 10000);
              this.loadAPIs();
              this.onSave.emit(this.cycleCountData);
              this.appCostAnalysisGrid.disableEdit()
              if(close) {
                this.onClose.emit()
              }
            } else {
              this.commonService.showNotification('error',res.message);
            }
            this.showLoader = false
          }, (error: HttpErrorResponse) => {
            this.showLoader = false
            this.commonService.showNotification('error',error.message);
            throw error;
      });
  }

  close(forceClose?) {
      if (!this.isChanged() || forceClose) {
          this.onClose.emit();
      } else {
        this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.onClose.emit();
                  this.cycleCountData = null
              }
          })
      }
  }

  reset() {
    if (this.isChanged()) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.cycleCountData = { ...this.lastDataForReset }
              }
          })
    } else {
      this.commonService.showNotification('info', 'Nothing to reset!');
    }
  }

  getStatuses(status) {
    switch (status) {
      case cycleCountStatuses.open:
          return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.open].includes(s.value));
      case cycleCountStatuses.planned:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.planned, cycleCountStatuses.scheduled].includes(s.value));
      case cycleCountStatuses.scheduled:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.planned, cycleCountStatuses.scheduled].includes(s.value));
      case cycleCountStatuses.inProgress:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.inProgress, cycleCountStatuses.counted].includes(s.value));
      case cycleCountStatuses.counted:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.counted, cycleCountStatuses.reconciled].includes(s.value));
      case cycleCountStatuses.reconciled:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.reconciled, cycleCountStatuses.closed].includes(s.value));
      case cycleCountStatuses.closed:
        return this.noFilterStatusesDDL.filter(s => [cycleCountStatuses.closed].includes(s.value));
      default: break;
    }
  }

  ngOnDestroy() {
      this.cycleCountData = null;
      this.destroy$.next();
      this.destroy$.unsubscribe();
  }
}
