import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { inventoryRequestStatuses } from 'src/app/modules/home/models/orders.model';
import { inventoryResponseStatuses } from 'src/app/modules/home/models/orders.model';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { ApiResponse, UserRoles } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-inventory-transfer-response-popup',
  templateUrl: './inventory-transfer-response-popup.component.html',
  styleUrls: [
    './inventory-transfer-response-popup.component.scss',
    '../../../../assets/scss/popup.scss',
    '../../../../assets/scss/service-order.scss'
  ]
})

export class InventoryTransferResponsePopupComponent implements OnInit {

  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = 'details';
  @Input() showComments: boolean = false;
  @Input() popupSource;
  @Input() rowData = null;
  @Input() transferOrderType;
  data;

  USER;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  statuses;
  technicianList;
  technician: any;
  techAddresses;
  statusList: any;
  transferOrderId;
  onHandCount;

  disablePlaceOrder: boolean = true;
  validateDetails: boolean = false;
  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  isCsa: boolean = false;
  status = inventoryRequestStatuses.open;
  itemDescription: string;
  statusDropdownReadOnly: boolean = false;

  commentCount = 0;
  attachmentCount = 0;
  links = [];
  attachmentsData = [];

  @ViewChild('responderddl') technicianDDL;
  @ViewChild('statusddl') statusDDL: ElementRef;
  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('attachmentsTab') attachments;

  constructor(
      private commonService: CommonService,
      private serviceOrderService: ServiceOrderService,
      private inventoryService: InventoryService,
      private route: ActivatedRoute,
  ) {
      this.USER = this.commonService.USER;
      this.statusDropdownReadOnly = this.commonService.roleClaims['Inv_Transfer_Response_Popup_Status_DropDown']?.readOnly
  }

  ngOnInit(): void {
    this.transferOrderId = this.rowData?.transferOrderId;
    this.isCsa = this.USER.role === UserRoles.csa;
    this.route.paramMap.pipe(
      takeUntil(this.destroy$)).subscribe(params => {
      if (params && params.get('id') && params.get('showComments')) {
        this.transferOrderId = params.get('id')
        this.showComments = params.get('showComments') == 'showComments' ? true : false
      }
    });

      this.inventoryService.popupLoader.pipe(
          takeUntil(this.destroy$),
      ).subscribe((showLoader: any) => {
          this.showLoader = showLoader;
      });
      this.loadPopup();
  }

  loadPopup() {
    this.loadAPIs();
  }

  setAttachmentsTabData() {
      this.attachmentsData = this.data?.attachments?.length && this.data?.attachments || [];
  }

  loadAPIs() {
      this.showLoader = true;
      const apiList = [
        this.commonService.getStatusesForDDL('TransferOrderResponder')
      ];

      if ( this.transferOrderId ) {
        apiList.push(this.inventoryService.getTransferOrderById(this.transferOrderId));
      }

      forkJoin(apiList).subscribe((res: any) => {
        if ( res[0].isSuccessful || res[1]?.isSuccessful ) {
          this.statusList = res[0].result;
          this.setPopupData(res[1].result);
          this.inventoryService.getTechnicianOnHandCount(this.data.itemCode, this.data.responder.warehouse).subscribe((res: ApiResponse) => this.onHandCount = res.result.onHandCount);
          this.statuses = this.getStatuses(this.data.responder.status);
          this.setTechnicianDDL();
          this.techAddresses = [this.data.requester]; //Address will always be requester address
          this.loadedData = JSON.stringify(this.data);
          this.attachmentsData = this.data.attachments;
          this.itemDescription = this.data.itemCode + ' - ' + this.data.itemDescription;
        } else {
          this.commonService.showNotification('error', res.message);
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.commonService.showNotification('error', error.message)
        throw error;
      });
  }

  setTechnicianDDL() {
    this.technicianList = [{technicianId: this.data.responder.technicianId, technicianName: this.data.responder.technicianName }];
  }

  setPopupData(data) {
    this.data = {
      transferOrderId: data?.transferOrderId,
      company: data?.company,
      itemCode: data?.itemCode,
      itemDescription: data?.itemDescription, // "DRIVER TIP NO6 SPANNER",
      quantity: data?.quantity,
      orderSubmissionDate: data?.orderSubmissionDate || null,
      notes: data?.notes || null,
      serviceOrderList: data?.serviceOrderList,
      requester: {
        warehouse: data?.requester?.warehouse,
        status: data?.requester?.status,
        technicianId: data?.requester?.technicianId,
        technicianName: data?.requester?.technicianName,
        addressCode: data?.requester?.addressCode,
        addressName: data?.requester?.addressName || null,
        address: data?.requester?.address,
        city: data?.requester?.city,
        state: data?.requester?.state,
        zipCode: data?.requester?.zipCode,
        country: data?.requester?.country,
        phoneNumber: data?.requester?.phoneNumber
      },
      responder: {
        warehouse: data?.responder?.warehouse,
        status: data?.responder?.status,
        technicianId: data?.responder?.technicianId,
        technicianName: data?.responder?.technicianName,
        carrier: data?.responder?.carrier,
        trackingNumber: data?.responder?.trackingNumber,
        dateShipped: data?.responder?.dateShipped,
      },
      comments: data?.comments || [],
      attachments: data?.attachments || [],
      history: data?.history || []
    };
    this.commentCount = this.data.comments.length;
    this.attachmentCount = this.data.attachments.length;
  }

  onAddressChange(args) {
    this.data.requester.address = args.itemData.address;
    this.data.requester.addressCode = args.itemData.addressCode;
    this.data.requester.addressName = args.itemData.addressName || null;
    this.data.requester.city = args.itemData.city;
    this.data.requester.state = args.itemData.stateProvince || args.itemData.state;
    this.data.requester.country = args.itemData.country;
    this.data.requester.zipCode = args.itemData.zipCode;
    this.data.requester.phoneNumber = args.itemData.phoneNumber;
  }

  expandCollapse(value: boolean) {
      this.isExpanded = value;
  }

  onCommentUpdate(data: any) {
      this.commentCount = data.filter(comment => !comment.isDeleted).length;
      this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
      this.data.attachments = data;
      this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  isChanged() {
      return this.loadedData !== JSON.stringify(this.data);
  }

  validateDetailsTab() {
    return this.data.requester.technicianId && this.data.requester.technicianName && this.data.requester.status && this.data.quantity && this.data.requester.address;
  }

  validate() {
    this.validateDetails = this.validateDetailsTab();
      return this.validateDetails;
  }

  onQuantityChange(args) {
    this.validateDetails = this.validateDetailsTab();
    this.data.quantity = args.value;
  }

  save(close?: boolean, placeOrder?: boolean) {
      this.validationApplied = true;
      if (this.validate()) {
        this.inventoryService.popupLoader.next(true);
        this.inventoryService.saveTransferOrder(this.data)
            .subscribe((res: ApiResponse) => {
              if (res.isSuccessful) {
                const message = this.transferOrderId ? `The Transfer Order ${this.transferOrderId} saved successfully.` : `The Transfer Order ${res.result.transferOrderId} created successfully.`;
                this.transferOrderId = res.result.transferOrderId;
                !close && this.loadAPIs();
                // this.loadedData = JSON.stringify(this.data);
                this.commonService.showNotification('success', message, 'center', 10000);
                this.onSave.emit(this.data);
              } else {
                this.commonService.showNotification('error',res.message);
              }
              this.inventoryService.popupLoader.next(false);
              close && this.onClose.emit();
            }, (error: HttpErrorResponse) => {
                this.inventoryService.popupLoader.next(false);
                throw error;
            });
    } else {
        this.commonService.showNotification('error', 'Please provide all the required information!');
        this.inventoryService.popupLoader.next(false);
    }
  }

  close(forceClose?) {
      if (!this.isChanged() || forceClose) {
          this.onClose.emit();
      } else {
        this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.onClose.emit();
              }
          })
      }
  }

  reset() {
    if (this.isChanged()) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if (result.isConfirmed) {
                  this.data = JSON.parse(this.loadedData);
                  this.loadPopup();
              }
          })
    } else {
      this.commonService.showNotification('info', 'Nothing to reset!');
    }
  }

  getStatuses(status) {
    switch (status) {
      case inventoryResponseStatuses.requested:
        return this.statusList.filter(s => [inventoryResponseStatuses.requested, inventoryResponseStatuses.accepted, inventoryResponseStatuses.rejected, inventoryResponseStatuses.shipped].includes(s.value));
      case inventoryResponseStatuses.accepted:
          return this.statusList.filter(s => [inventoryResponseStatuses.accepted, inventoryResponseStatuses.rejected, inventoryResponseStatuses.shipped].includes(s.value));
      case inventoryResponseStatuses.shipped:
        return this.statusList.filter(s => [inventoryResponseStatuses.shipped].includes(s.value));
      case inventoryResponseStatuses.cancelled:
        return this.statusList.filter(s => [inventoryResponseStatuses.cancelled].includes(s.value));
      case inventoryResponseStatuses.rejected:
        return this.statusList.filter(s => [inventoryResponseStatuses.rejected].includes(s.value));
      case inventoryResponseStatuses.closed:
        return this.statusList.filter(s => [inventoryResponseStatuses.closed].includes(s.value));
      default:
        return this.statusList.filter(s => s.value === status);
    }
  }

  ngOnDestroy() {
      this.data = null;
      this.destroy$.next();
      this.destroy$.unsubscribe();
  }

}
