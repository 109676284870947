
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HomeService { 

    sidebarToggle: Subject<any> = new Subject();
    
    constructor(
        private http: HttpClient,
    ) { }

    renewToken() {
        const data = {
          'accessToken':localStorage.getItem('access_token'), 
          'refreshToken':localStorage.getItem('refresh_token')
        }
        return this.http.post(`${environment.webAuthUrl}Authentication/refreshtoken`, data);
    }
}
