import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { GridComponent, PageService, SelectionService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CustomAdaptor, gridActionBeginForFiltering, gridActionsForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserRoles } from 'src/app/shared/models/app.model';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-config-advanced-search',
  templateUrl: './config-advanced-search.component.html',
  styleUrls: ['./config-advanced-search.component.scss'],
  providers: [
    ToolbarService,
    SortService,
    PageService,
    SelectionService
  ]
})

export class ConfigAdvancedSearchComponent implements OnInit {
  isExpanded: boolean = true;
  validationApplied: boolean = false;
  data: any;
  mainGrid: any;
  filterSettings: any;
  query: Query;
  selectedRowData: any;
  @Input() gridType: any = 'config';

  USER;

  @Input() serviceDepartment;

  @Output() onClose = new EventEmitter();
  @ViewChild('columnChooser') columnChooser;
  @ViewChild('serviceOrderGrid') serviceOrderGrid;

  userRoles: any;

  city = ''; state = ''; zipCode = ''; customerName = ''; address = '';
  constructor(private commonService: CommonService) {
    this.USER = commonService.USER;
   }

  ngOnInit(): void {
      this.USER = this.commonService.USER;
      this.userRoles = UserRoles;
      if ( this.USER.role !== UserRoles.iscManager && this.USER.role !== UserRoles.isc && this.USER.role !== UserRoles.IscSupervisor) {
        this.filterSettings = { type: 'Menu' };
        this.getCustomers(null, true);
      } else {
        this.data = [];
      }
  }

  validate() {
    this.validationApplied = true;
    return this.customerName && this.city && this.state;
  }

  getCustomers(event, onInit?: boolean) {
    if ( this.validate() || onInit) {
      const isIsc = this.USER.role === UserRoles.iscManager || this.USER.role === UserRoles.IscSupervisor|| this.USER.role === UserRoles.isc;
      const getCustomerApi = isIsc ? 'SoldToBP/GetISCCustomerSearchView' : 'SoldToBP/GetCustomerSearchView'
      const api = this.gridType === 'config' ? getCustomerApi : 'SoldToBP/GetSoldToBP';
      this.data = new DataManager({
        url: `${BASE_URL}${api}`,
        adaptor: new CustomAdaptor()
      });
      this.query = new Query().addParams('company', this.USER.company);//.addParams('serviceDepartment', this.USER.serviceDepartment);
      if (this.gridType !== 'config' ) {
        this.query.addParams('$orderBy', 'tax');
      }
      if ( isIsc) {
        this.query.addParams('isIsc', 'true');
        this.query.addParams('serviceDepartment', this.USER.serviceDepartment);
        this.city && this.query.addParams('city', this.city);
        this.state && this.query.addParams('stateProvince', this.state);
        this.zipCode && this.query.addParams('zipCode', this.zipCode);
        this.customerName && this.query.addParams('bpDescription', this.customerName);
        this.address && this.query.addParams('address', this.address);
      }
    }
  }

  ngAfterViewInit(): void {
    this.setGrid();
  }

  setGrid() {
    const columns = this.gridType === 'config' ? [
        { field: 'soldToBP', headerText: 'ID', width: 120, visible: true, nonHideable: true, type: 'string' },
        { field: 'bpDescription', headerText: 'Business Partner', visible: true, type: 'string' },
        { field: 'address', headerText: 'Address', visible: true, type: 'string' },
        { field: 'city', headerText: 'City', visible: true, type: 'string', filter: { type: 'Excel' }},
        { field: 'stateProvince', headerText: 'State/Province', visible: true, type: 'string', filter: { type: 'Excel' } },
        { field: 'zipCode', headerText: 'Zip Code', visible: true, type: 'string' },
        { field: 'telephone', headerText: 'Telephone', visible: true, type: 'string' },
        { field: 'country', headerText: 'Country', visible: true, type: 'string', filter: { type: 'Excel' } },
        { field: 'eMail', headerText: 'Email', visible: true, type: 'string' },
        { field: 'isNationalAccount', headerText: 'National Account', visible: false, showInColumnChooser: false, type: 'boolean', allowFiltering: false },
        { field: 'nationalAccount', headerText: 'National Account', visible: true, type: 'string', filter: { type: 'Menu' } },
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
          commands: [
              {
                  title: 'Proceed',
                  buttonOption: {
                      iconCss: `fas fa-arrow-right`,
                      cssClass: `e-flat action-button`
                  }
              }
          ]
        }
      ] : [
        { field: 'soldToBP', headerText: 'ID', width: 120, visible: true, nonHideable: true, type: 'string' },
        { field: 'bpDescription', headerText: 'Business Partner Description', visible: true, type: 'string' },
        { field: 'addressCode', headerText: 'Address Code', visible: true, type: 'string' }
      ]
      this.mainGrid = {
          columns,
          toolbar : [ 'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
          ],
          pageSettings: { pageSize: 10 }
      };
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if ( args.item.id === 'clear-filter' ) {
        grid.clearFiltering();
        grid.search('');
    }
  }

  onGridCreated() {
    updateFilterIcon({requestType: 'filtering'}, this.serviceOrderGrid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Proceed') {
        this.onClose.emit(args.rowData);
    }
  }

  close() {
    this.validationApplied = false;
    this.onClose.emit(null);
  }

  proceed() {
    this.onClose.emit(this.selectedRowData);
  }

  rowSelected(args) {
    this.selectedRowData = args.data;
  }

}
