import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GridComponent, ExcelExportProperties, ForeignKeyService } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { ComboBox, DropDownList } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { CommonService } from 'src/app/shared/services/common.service';
import { excelQueryCellInfo, getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { coreInventoryRetrunOrderStatuses, returnOrdersCategoryTypes, returnOrderStatuses } from '../../../models/orders.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiResponse, UserRoles, gridNames } from 'src/app/shared/models/app.model';
import { InventoryService } from '../../../services/inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MouseEventArgs } from '@syncfusion/ej2-base';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';

@Component({
  selector: 'app-core-return-order-popup-core-items-tab',
  templateUrl: './core-return-order-popup-core-items-tab.component.html',
  styleUrls: ['./core-return-order-popup-core-items-tab.component.scss']
})
export class CoreReturnOrderPopupCoreItemsTabComponent implements OnChanges, OnInit {

  @ViewChild('returnOrderItemsGrid') returnOrderItemsGrid: GridComponent;
  @ViewChild('returnOrderItemsColumnChooser') columnChooser;

  @Input() items = [];
  @Input() category: any;
  @Input() warehouse: any;
  @Input() inventoryItems: any;
  @Input() reasonsDDL: any;
  @Input() status: any;

  @Output() toggleGenerateStatementButton = new EventEmitter();

  itemsList: any;

  columns: any;
  gridName = gridNames.returnOrderItemsGrid;
  itemsQuery: any;
  showColumnChooser: boolean = false;
  validationApplied: boolean = false;
  // reasonValidationFail: boolean = false;
  skipBeginEditEvent: boolean = false;

  itemsField;
  itemsObject;

  itemDescriptionField;
  itemDescriptionObject: any;

  itemGroup: any;
  itemGroupField;
  itemGroupObject: any;

  itemQuantityField;
  itemQuantityObject: any;

  itemReasonsField;
  itemReasonsObject: any;
  selectedRowId: string;
  showItemAdvancedSearchPopup: boolean = false;

  onItemUpdate = new Subject<string>();
  reasonDescription: any;

  USER;
  isManager: boolean = false;
  isCSA: boolean = false;
  gridHeight: number;
  showLoader: boolean = false;
  isReadOnly: boolean = false;
  serviceOrderId: string;
  showServiceOrderPopup: boolean = false;
  serviceOrderData: any;
  defectiveOnHand: any = 0;
  itemOnhandQuantityField;
  itemOnhandQuantityObject: any;
  itemCommentField;
  itemCommentObject: any;
  serviceOrderIdLN: any;
  itemServiceOrderObject: TextBox;
  itemServiceOrderField: any;
  itemCode: any;
  comment: string = '';
  itemServiceOrderLNField: HTMLInputElement;
  itemServiceOrderLNObject: any;
  allowEditing = false;

  @HostListener('document:click', ['$event'])
  onClick(event) {
      const itemsGridElement = document.getElementById('return-order-items-grid');
      if (this.returnOrderItemsGrid?.isEdit && itemsGridElement) {
          const excludedClasses = ['e-ddl', 'e-popup', 'e-lib', 'e-control', 'e-popup-close'];
          let targetElement = event.target as HTMLElement;
          while (targetElement) {
              if (excludedClasses.some(className => targetElement.classList.contains(className))) {
                  event.stopPropagation();
                  return;
              }
              targetElement = targetElement.parentElement;
          }
          this.returnOrderItemsGrid.endEdit();
      }
  }

  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private serviceOrderService: ServiceOrderService,
    private eRef: ElementRef
  ) {
    this.isCSA = this.commonService.USER.role === UserRoles.csa;
    this.isManager = this.commonService.USER.role === UserRoles.manager;
    this.isReadOnly = this.commonService.USER.role === UserRoles.readOnly;
  }

  ngOnChanges(change: SimpleChanges) {
    this.returnOrderItemsGrid?.refresh();
    this.toggleButtonDisable();

    if(change && change.status && change.status.currentValue && change.status.previousValue === coreInventoryRetrunOrderStatuses.open){
        this.returnOrderItemsGrid.hideColumns('Actions');
    }
  }

  ngOnInit(): void {
    this.gridHeight = document.getElementById('core-return-order-popup-body').clientHeight - 370;
    this.warehouse = this.commonService.USER.role === UserRoles.fst ? this.commonService.USER.warehouse : this.warehouse;
    this.allowEditing = this.status === returnOrderStatuses.open && !this.isCSA && !this.isManager && !this.isReadOnly;
    // this.allowEditing = this.status === returnOrderStatuses.open && this.commonService.roleClaims['Inv_ServiceableTabGrid_Toolbar_Return_Order']?.visible;
    this.columns = this.getGridColumns();
    this.setItemsData();
    this.getItemList();
    this.onItemUpdate
    .pipe(
        debounceTime(500),
        distinctUntilChanged()
    ).subscribe(value => {
        if (this.itemsObject) {
            this.itemsObject.showPopup();
            this.itemsObject.showSpinner();
            this.getItemList();
        }
    });
  }

  getItemList(searchString: string = null) {
    // this.inventoryService.popupLoader.next(true);
    const category = this.category?.toLowerCase();
    this.inventoryService.getInventoryForDDL(category, this.warehouse)
    .subscribe((res: ApiResponse) => {
        if (res && res.result && res.result.length) {
          this.itemsList = res.result.filter(x => !x.isDeleted);
          this.itemsList.map( (item) => {
            item.itemSOKey = `${item.item}-${item.serviceOrderId}`;
            item.value = item.itemSOKey;
            item.text = `${item.itemSOKey} | ${item.description}`;
          })

          const existingItems = [];
          (this.returnOrderItemsGrid.dataSource as any).forEach(item => {
            if (item.itemSOKey && !item.isDeleted && item.itemSOKey !== searchString) {
                existingItems.push(item.itemSOKey);
            }
          });

          this.itemsList = this.itemsList.filter(item => {
            item.itemSOKey = `${item.item}-${item.serviceOrderIdLN?item.serviceOrderIdLN:item.serviceOrderId}`;
            item.value = item.itemSOKey;
            item.text = `${item.itemSOKey} | ${item.description}`;
            this.inventoryService.popupLoader.next(false);
            if (existingItems.length) {
              return !existingItems.includes(item.itemSOKey)
            } else {
              return true;
            }

          });

          if (this.itemsObject) {
            this.itemsObject.dataSource = this.itemsList;
            this.itemsObject.hideSpinner();
          }
          this.inventoryService.popupLoader.next(false);
        } else {
          this.itemsList = [];
          if (this.itemsObject) {
              this.itemsObject.hideSpinner();
              this.itemsObject.dataSource = [];
          }
          this.inventoryService.popupLoader.next(false);
        }
    });
  }

  getGridColumns() {
    const disabled = this.status === coreInventoryRetrunOrderStatuses.open ? 'e-flat' : 'e-flat disabled-grid-button';
    let columns : any = [
        { field: 'tempRowId', headerText: 'Temp. Id', type: 'string', visible: false, showInColumnChooser: false, isPrimaryKey: true},
        { field: 'itemSOKey', headerText: 'Item', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, width: 250, edit: this.initItemsDDL(), showInColumnChooser: false},
        { field: 'serviceOrderId', headerText: 'Service Order', type: 'string', visible: false, textAlign: 'Left', width: 150, edit: this.initItemServiceOrder(), filter: { type: 'Excel' }, showInColumnChooser: false},
        { field: 'serviceOrderIdLN', headerText: 'Service Order LN', type: 'string', visible: true, textAlign: 'Left', width: 150, edit: this.initItemServiceOrderLN(), filter: { type: 'Excel' }},
        { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left', edit: this.initItemDescription(), showInColumnChooser: false, },
        { field: 'defectiveOnHand', headerText: 'Defective On Hand', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'right', width: 142, edit: this.initItemOnHnad()},
        { field: 'quantity', headerText: 'Return Quantity', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'right', width: 142, showInColumnChooser: false, edit: this.initItemQuantity(), editType: 'numericedit', validationRules: { required: false } },
        { field: 'comments', headerText: 'Comments', type:"string", textAlign: 'Left', edit: this.initItemComment() },   
    ];

    if (this.status === coreInventoryRetrunOrderStatuses.open) { 
        columns.push({
            field: 'Actions', headerText: 'Actions', textAlign: 'Center', allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 130,
            commands: [
                { title: 'Delete Item', buttonOption: { iconCss: 'fas fa-trash', cssClass: `${disabled}` } }
            ]
        })
    } else {
        columns.unshift({ field: "", type: 'checkbox', headerText: "", textAlign: 'Center', allowFiltering: false, allowSorting: false, allowSearching: false, width: 55, showInColumnChooser: false });
    }
    return columns;
  }

  initItemsDDL() {
    return {
        create: args => {
            this.selectedRowId = args.data.rowId;
            this.itemsField = document.createElement('input');
            return this.itemsField;
        },
        read: () => {
            if (this.itemsObject) {
                return this.itemsObject.value;
            }
        },
        destroy: () => {
            this.itemsObject.destroy();
        },
        write: (args) => {
            this.itemsObject = new DropDownList({
                dataSource: this.itemsList,
                fields: { value: 'value', text: 'text' },
                value: args.rowData.itemSOKey,
                cssClass: 'generic-items-dropdown-list',
                allowFiltering: true,
                popupWidth: '450px',
                filterType: 'Contains',
                focus: args => { 
                  this.itemsObject.showPopup() 
                },
                filtering: args => { this.onItemUpdate.next(args.itemSOKey); },
                created: eventArgs => { this.itemCode = args.rowData.itemCode; },
                blur: args => {
                    if(this.itemsObject['isSelectCustom']){
                        this.itemsObject.value = null;
                    }
                },
                change: (event: any) => {
                    if ( event.value && event.item ) {
                        this.itemCode = event.itemData.item;
                        this.itemDescriptionObject.value = event.itemData.description;
                        this.itemGroup = event.itemData.itemGroup;
                        this.serviceOrderId = event.itemData.serviceOrderId;
                        this.itemServiceOrderLNObject.value =  event.itemData.serviceOrderIdLN;
                        this.serviceOrderIdLN = event.itemData.serviceOrderIdLN;
                        this.serviceOrderId = event.itemData.serviceOrderId;
                        if(this.itemServiceOrderObject){
                            this.itemServiceOrderObject.value =  event.itemData.serviceOrderId;
                        }
                        this.itemOnhandQuantityObject.value = event.itemData.isDefective;
                        this.itemQuantityObject.value = this.itemQuantityObject.value ?? this.itemOnhandQuantityObject.value;
                    }
                }
            });
            this.itemsObject.appendTo(this.itemsField);
            // this.addAdvancedSearchIcon();
        }
    }
  }

  initItemServiceOrder() {
    return {
        create: () => {
            this.itemServiceOrderField = document.createElement('input');
            return this.itemServiceOrderField;
        },
        read: () => {
            if (this.itemServiceOrderObject) {
                return this.itemServiceOrderObject.value;
            }
        },
        destroy: () => {
            this.itemServiceOrderObject.destroy();
        },
        write: (args) => {
            this.itemServiceOrderObject = new TextBox({
                value: args.rowData.serviceOrderId,
                enabled: false
            });
            this.itemServiceOrderObject.appendTo(this.itemServiceOrderField);
        }
    }
  }

  initItemServiceOrderLN() {
    return {
        create: () => {
            this.itemServiceOrderLNField = document.createElement('input');
            return this.itemServiceOrderLNField;
        },
        read: () => {
            if (this.itemServiceOrderLNObject) {
                return this.itemServiceOrderLNObject.value;
            }
        },
        destroy: () => {
            this.itemServiceOrderLNObject.destroy();
        },
        write: (args) => {
            this.itemServiceOrderLNObject = new TextBox({
                value: args.rowData.serviceOrderIdLN,
                enabled: false
            });
            this.itemServiceOrderLNObject.appendTo(this.itemServiceOrderLNField);
        }
    }
}

  initItemDescription() {
    return {
        create: () => {
            this.itemDescriptionField = document.createElement('input');
            return this.itemDescriptionField;
        },
        read: () => {
            if (this.itemDescriptionObject) {
                return this.itemDescriptionObject.value;
            }
        },
        destroy: () => {
            this.itemDescriptionObject.destroy();
        },
        write: (args) => {
            this.itemDescriptionObject = new TextBox({
                value: args.rowData.itemDescription,
                enabled: false
            });
            this.itemDescriptionObject.appendTo(this.itemDescriptionField);
        }
    }
  }

  initItemOnHnad(){
    return {
        create: () => {
            this.itemOnhandQuantityField = document.createElement('input');
            return this.itemOnhandQuantityField;
        },
        read: () => {
            if (this.itemOnhandQuantityObject) {
                return this.itemOnhandQuantityObject.value;
            }
        },
        destroy: () => {
            this.itemOnhandQuantityObject.destroy();
        },
        write: (args) => {
            this.itemOnhandQuantityObject = new TextBox({
                value: args.rowData.defectiveOnHand,
                enabled: false,
            });
            this.itemOnhandQuantityObject.appendTo(this.itemOnhandQuantityField);
        }
    }
  }

  initItemQuantity() {
    return {
        create: () => {
            this.itemQuantityField = document.createElement('input');
            return this.itemQuantityField;
        },
        read: () => {
            if (this.itemQuantityObject) {
                return this.itemQuantityObject.value;
            }
        },
        destroy: () => {
            this.itemQuantityObject.destroy();
        },
        write: (args) => {
            this.itemQuantityObject = new NumericTextBox({
                value: args.rowData.quantity,
                showSpinButton: false,
                min: 0,
                max: args.rowData.defectiveOnHand,
                format: 'n',
                step: 1,
                decimals: 0,
                validateDecimalOnType: true,
                // max: row ? row[key] : undefined,
                created: args => {
                    this.itemQuantityField.onkeyup = event => {
                        if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                            this.itemQuantityObject.value = +event.target.value;
                        }
                    }
                }
            });
            this.itemQuantityObject.appendTo(this.itemQuantityField);
        }
    }
  }

  initItemComment() {
    return {
        create: () => {
            this.itemCommentField = document.createElement('input');
            return this.itemCommentField;
        },
        read: () => {
            if (this.itemCommentObject) {
                return this.itemCommentObject.value;
            }
        },
        destroy: () => {
            this.itemCommentObject.destroy();
        },
        write: (args) => {
            this.itemCommentObject = new TextBox({
                value: args.rowData.comments,
                created: () => { this.comment = args.rowData.comments; },
                change: (event: any) => {
                    this.comment = event.value;
                }
            });
            this.itemCommentObject.appendTo(this.itemCommentField);
        }
    }
  }

  rowSelected(event, grid){
    this.status !== coreInventoryRetrunOrderStatuses.open && this.toggleGenerateStatementButton.emit(grid.getSelectedRecords())
  }

  rowDeselected(event, grid){
      this.status !== coreInventoryRetrunOrderStatuses.open && this.toggleGenerateStatementButton.emit(grid.getSelectedRecords())
  }

  dataBound(args) {
    this.returnOrderItemsGrid?.toolbarModule?.enableItems(['add-item'], this.status === coreInventoryRetrunOrderStatuses.open);
  }

  onServiceOrderClick(serviceOrderId: string) {
    this.serviceOrderId = serviceOrderId;
    this.inventoryService.popupLoader.next(true);
    this.serviceOrderService.getServiceOrderById(serviceOrderId)
      .subscribe((res: any) => {
        this.serviceOrderId = serviceOrderId;
        this.serviceOrderData = res.result;
        this.inventoryService.popupLoader.next(false);
        this.showServiceOrderPopup = true;
      }, (error: HttpErrorResponse) => {
        this.inventoryService.popupLoader.next(false);
      });
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (this.returnOrderItemsGrid.isEdit) {
      this.commonService.showNotification('warning', 'Item is in edit mode!');
    }
    else {
      if (args.item.id === 'grid_excelexport') {
        const dataSource = getExcelDataSource(grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Return Orders Items(${getCurrentDate()}).xlsx`
        };
        grid.excelExport(excelExportProperties);
      } else if (args.item.id === 'grid_pdfexport') {
          grid.pdfExport();
      } else if (args.item.id === 'clear-filter') {
          grid.clearFiltering();
          grid.search('');
          grid.clearSorting();
      } else if (args.item.id === 'column-chooser') {
          this.showColumnChooser = !this.showColumnChooser;
      }  else if (args.item.id === 'add-item') {
          this.inventoryService.popupLoader.next(true);
          if (!grid.isEdit) {
              this.checkEmptyRow(grid);
          } else {
              this.commonService.showNotification("warning", "Item is in edit mode!");
          }
          this.inventoryService.popupLoader.next(false);
      }
    }
  }

  rowDataBound(args) {
    if (args.data && args.data.quantityReceived) {
      args.data.quantityReceived < args.data.quantity ? args.row.classList.add('bg-custom-danger') :
        args.data.quantityReceived === args.data.quantity ? args.row.classList.add('bg-custom-success') : args.row.classList.add('bg-custom-warning');
    }
  }

  setItemsData() {
    this.items?.map((item, index) => {
        return this.setItemsRow(item, index);
    });
    if (this.status === coreInventoryRetrunOrderStatuses.open) {
      this.addEmptyRowsToItemsData();
    }
  }

  addEmptyRowsToItemsData() {
    for (let i = 0; i < 2; i++) {
      this.items.push(this.setItemsRow({}, this.getMaxRowId(this.items)));
    }
  }

  load(args) {
    this.returnOrderItemsGrid.element.addEventListener('mouseup', (e: MouseEventArgs) => {
      if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
        const cellIndex = +(e.target as HTMLElement).getAttribute('data-colindex');
        this.returnOrderItemsGrid.isEdit && this.returnOrderItemsGrid.endEdit();
        let rowIndex: number = parseInt((e.target as HTMLElement).getAttribute("Index"));
        this.returnOrderItemsGrid.selectRow(rowIndex);
        this.returnOrderItemsGrid.startEdit();
        const clickedColumnName = this.returnOrderItemsGrid.getColumnByIndex(cellIndex)?.field;
        clickedColumnName && document?.getElementById(gridNames.returnOrderItemsGrid + clickedColumnName)?.focus();
      };
    });
  }

  onGridCreated() {
    this.itemsQuery = new Query().where('isDeleted', 'equal', false);
    this.returnOrderItemsGrid.toolbar = [
        { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.returnOrderItemsGrid.toolbar.findIndex(x => x === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        this.returnOrderItemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    if (this.status === coreInventoryRetrunOrderStatuses.open) {
        this.returnOrderItemsGrid.toolbar.splice(1, 0, { text: '', id: 'add-item', align: 'Left', prefixIcon: 'e-add', tooltipText: 'Add Item' });
    }
    updateFilterIcon({ requestType: 'filtering' }, this.returnOrderItemsGrid);
    this.toggleButtonDisable();
  }

  commandColumnClick(args) {
    if (args.commandColumn.title === 'Delete Item') {
        if (args.rowData.id && args.rowData.itemCode) {
            this.commonService.showConfirmation('Are you sure? You want to delete this item?').then(result => {
                if (result.isConfirmed) {
                    const row = (this.returnOrderItemsGrid?.dataSource as any).find(x => x.tempRowId === args.rowData.tempRowId);
                    row.isDeleted = true;
                    this.checkEmptyRow(this.returnOrderItemsGrid);
                    this.toggleButtonDisable();
                    this.returnOrderItemsGrid.refresh();
                }
            })
        } else {
            const index = (this.returnOrderItemsGrid?.dataSource as any).findIndex(x => x.tempRowId === args.rowData.tempRowId);
            (this.returnOrderItemsGrid.dataSource as any).splice(index, 1);
            this.toggleButtonDisable();
            this.checkEmptyRow(this.returnOrderItemsGrid);
            this.returnOrderItemsGrid.refresh();
        }
    }
  }

  checkEmptyRow(grid){
    if(grid.dataSource.filter(x => !x.itemCode && !x.isDeleted).length < 2){
        this.addItem();
    }
  }

  toggleButtonDisable() {
      // if(this.status !== coreInventoryRetrunOrderStatuses.open){
          const elem: any = document.getElementById('core-return-order-btn');
          if(this.status !== coreInventoryRetrunOrderStatuses.open){
              elem.disabled = this.status !== coreInventoryRetrunOrderStatuses.open;
          } else {
              if ((this.returnOrderItemsGrid?.dataSource as any)?.filter(item => item.itemCode && !item.isDeleted).length) {
                  elem.disabled = false;
              } else {
                  elem.disabled = true;
              }
          }
      // }
  }

  addItem() {
    let newRow = this.setItemsRow({}, this.getMaxRowId(this.returnOrderItemsGrid.dataSource));
    (this.returnOrderItemsGrid.dataSource as any).push(newRow);
    if((this.returnOrderItemsGrid.dataSource as any).filter(x => !x.itemCode && !x.isDeleted).length < 2){
      this.addItem();
    } else {
      this.returnOrderItemsGrid.refresh();
    }
  }

  setItemsRow(item, index) {
    item.id = item.id || 0,
    item.tempRowId = index + 1;
    item.lineNumber = index + 1;
    item.itemCode = item.itemCode;
    item.itemDescription = item.itemDescription || item.description || '';
    item.itemGroup = item.itemGroup || '';
    item.serviceItemGroup = item.serviceItemGroup || '';
    item.defectiveOnHand = item.defectiveOnHand ?? null;
    item.quantity = item.quantity ?? null;
    item.reasonCode = item.reasonCode || this.reasonsDDL[0].value;
    item.reasonDescription  = item.reasonDescription  || this.reasonsDDL[0].description;
    item.createdBy = item.createdBy || '';
    item.createdByName = item.createdByName || null;
    item.lastUpdatedBy = item.lastUpdatedBy || '';
    item.lastUpdatedByName = item.lastUpdatedByName || '';
    item.isDeleted = item.isDeleted || false;
    item.serviceOrderId = item.serviceOrderId || '';
    item.serviceOrderIdLN = item.serviceOrderIdLN || '';
    return item;
  }

  actionBegin(args) {
    if (args.requestType === 'sorting') {
        if (this.returnOrderItemsGrid.isEdit) {
            args.cancel = true;
        }
    }
    if (args && args.requestType === 'beginEdit') {
        this.inventoryService.popupLoader.next(true);
        if ( args.rowData?.itemSOKey ) {
            if ( this.skipBeginEditEvent ) {
                this.skipBeginEditEvent = false;
            } else {
                this.getItemList(args.rowData?.itemSOKey);
            }
        }
        else{
          this.getItemList();
        }
    }
}

  actionComplete(args, grid) {
    updateFilterIcon({ requestType: 'filtering' }, this.returnOrderItemsGrid);
    if (args.requestType === 'save') {
        this.toggleButtonDisable();
        let rowData : any = {};
        if(this.itemsObject.value && (this.returnOrderItemsGrid.dataSource as any).find(item => item.id && item.itemSOKey === this.itemsObject.value)){
          rowData = (this.returnOrderItemsGrid.dataSource as any).find(item => item.itemSOKey === this.itemsObject.value && item.isDeleted);
          rowData.isDeleted = false;
          this.setRowData(rowData)
        } else {
          rowData = (this.returnOrderItemsGrid.dataSource as any).find(item => item.lineNumber === args.rowData.lineNumber);
          this.setRowData(rowData)
        }
    }
  }

  setRowData(rowData){
    rowData.itemCode = this.itemCode;
    rowData.itemSOKey = this.itemsObject.value;
    // rowData.itemDescription = this.itemDescriptionObject.value;
    rowData.itemGroup = this.itemGroup;
    rowData.serviceOrderIdLN = this.itemServiceOrderLNObject.value;
    rowData.serviceOrderId = this.serviceOrderId;
    rowData.reasonDescription = this.reasonDescription;
    const emptyRecordsList = (this.returnOrderItemsGrid.dataSource as any).filter((item, index) => {
      if (!item.itemSOKey) {
        item.rowIndex = index;
        return true;
      }
    });

    this.checkEmptyRow(this.returnOrderItemsGrid);
  }

  getMaxRowId(datasource) {
    if (datasource.length) {
      return Math.max.apply(Math, datasource.map(row => row.tempRowId) || 0);
    } else {
      return 0;
    }
  }

  getData() {
    this.returnOrderItemsGrid?.endEdit();
    return {
        itemList: (this.returnOrderItemsGrid?.dataSource as any).filter(x => x.itemCode)
    }
  }

  getSelectedData(){
      this.returnOrderItemsGrid?.endEdit();
      return {
          itemList: (this.returnOrderItemsGrid.getSelectedRecords() as any).filter(x => x.itemCode)
      }
  }

  validateForSave() {
    this.returnOrderItemsGrid.endEdit();
    const itemsRows = (this.returnOrderItemsGrid.dataSource as any).filter(item => item.itemCode && !item.isDeleted);
  
    if (itemsRows.length) {
      for (const item of itemsRows) {
        if (!this.validateQuantityAndComments(item)) {
          return false;
        }
      }
    }
  
    return true;
  }

  validateQuantityAndComments(item) {
    if (item.quantity === null) {
      this.commonService.showNotification('error', `Quantity is missing for <br> ${item.itemSOKey} - ${item.itemDescription}`, 'center', 5000);
      return false;
    } else if (item.defectiveOnHand !== item.quantity && !item.comments) {
      this.commonService.showNotification('error', `Please add a reason for the difference in quantity of <br> ${item.itemSOKey} - ${item.itemDescription}`, 'center', 5000);
      return false;
    }
    return true;
  }

  validateForGenerateStatment() {
    this.returnOrderItemsGrid.endEdit();
    const itemsRows = (this.returnOrderItemsGrid.dataSource as any).filter(item => item.itemCode && !item.isDeleted);
  
    if (itemsRows.length) {
      for (const item of itemsRows) {
        if (!this.validateQuantityAndComments(item)) {
          return false;
        }
      }
    } else {
        this.commonService.showNotification('error', 'Atleast one item needed in an Order to generate return order statement!');
        return true;
    }

    return true;
  }
}
