import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
    selector: 'app-popup-header',
    templateUrl: './popup-header.component.html',
    styleUrls: ['./popup-header.component.scss']
})
export class PopupHeaderComponent implements OnInit, OnChanges {

    @Input() id: string | number = 0;
    @Input() createdDate: Date = new Date();
    @Input() customerName: string = null;
    // @Input() status: string;
    @Input() isExpanded: boolean = false;
    @Input() hideCreationDate: boolean = false; 
    @Input() linkServiceOrdersPopup: boolean = false; 
    @Input() title: string = "Service Order";
    @Input() highlightedText: string = "";
    @Output() onNameChange = new EventEmitter();
    @Output() onClose = new EventEmitter();
    @Output() onExpandCollapse = new EventEmitter();

    constructor(private commonService: CommonService) { }

    ngOnInit(): void {
        if ( !this.createdDate ) {
            this.createdDate = new Date();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        
    }
    
    expandCollapse() {
        this.isExpanded = !this.isExpanded;
        this.onExpandCollapse.emit(this.isExpanded);
    }

    close() {
        this.onClose.emit();
    }

}
