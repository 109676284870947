import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition, SweetAlertOptions } from 'sweetalert2'
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { environment } from 'src/environments/environment';
import { PersistenceData, gridNames } from '../models/app.model';

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from 'rxjs/operators';
const BASE_URL = environment.apiUrl;
const LOGIN_URL = environment.loginUrl;


interface GridHeight {
  height: number;
}

interface GlobalComments {
  grid: GridComponent;
  module: string | null;
  moduleId: string | null;
  showComments: boolean;
  isIscModule?: boolean
}

@Injectable()
export class CommonService {

  USER;
  userDefaults;
  warantyInfo: string = null;
  contractInfo: string = null;
  defaultLinkColor: string = '#000000de';
  coreItemServiceGroup = '450SVC';
  roleClaims = [];
  allowedExtenstions: string = '.pdf,.doc,.docx,.xls,.xlsx,.xlsb,.jpg,.jpeg,.png,.tiff,.txt,.csv,.mp3,.mp4,.avi,.mov,.zip,.rar,.tar,.gz,.dwg,.dxf';

  globalComments: GlobalComments = {
    grid: null,
    module: null,
    moduleId: null,
    showComments: false,
    isIscModule: false
  }

  private _gridHeight = new BehaviorSubject<GridHeight>({ height: 0 });
  private _gridHeight$ = this._gridHeight.asObservable();

  private _inventoryOrders = new BehaviorSubject<GridHeight>({ height: 0 });
  private _inventoryOrders$ = this._inventoryOrders.asObservable();

  private _missingSignatures = new BehaviorSubject<GridHeight>({ height: 0 });
  private _missingSignatures$ = this._missingSignatures.asObservable();

  private _inventoryRequest = new BehaviorSubject<GridHeight>({ height: 0 });
  private _inventoryRequest$ = this._inventoryRequest.asObservable();

  private _serviceOrders = new BehaviorSubject<GridHeight>({ height: 0 });
  private _serviceOrders$ = this._serviceOrders.asObservable();

  private _partOrdersStatistics = new BehaviorSubject<GridHeight>({ height: 0 });
  private _partOrdersStatistics$ = this._partOrdersStatistics.asObservable();

  private _claimsStatusesCount = new BehaviorSubject<GridHeight>({ height: 0 });
  private _claimsStatusesCount$ = this._claimsStatusesCount.asObservable();

  private _myDayRoutePod = new BehaviorSubject<GridHeight>({ height: 0 });
  private _myDayRoutePod$ = this._myDayRoutePod.asObservable();

  private _pendingApprovalsPod = new BehaviorSubject<GridHeight>({ height: 0 });
  private _pendingApprovalsPod$ = this._pendingApprovalsPod.asObservable();

  private _openDashboardEditDialogue = new BehaviorSubject<boolean>(false);
  public _openDashboardEditDialogue$ = this._openDashboardEditDialogue.asObservable();

  private _setServiceDepartmentList = new Subject();
  public _setServiceDepartmentList$ = this._setServiceDepartmentList.asObservable();

  private _importErrorPodHeight = new BehaviorSubject<GridHeight>({ height: 0 });
  private _importErrorPodHeight$ = this._importErrorPodHeight.asObservable();

  private _exportErrorPodHeight = new BehaviorSubject<GridHeight>({ height: 0 });
  private _exportErrorPodHeight$ = this._exportErrorPodHeight.asObservable();

  private _datadiscrepancyPodHeight = new BehaviorSubject<GridHeight>({ height: 0 });
  private _datadiscrepancyPodHeight$ = this._datadiscrepancyPodHeight.asObservable();

  private _uncostedOrdersPodHeight = new BehaviorSubject<GridHeight>({ height: 0 });
  private _uncostedOrdersPodHeight$ = this._uncostedOrdersPodHeight.asObservable();
  isReminderShowing: boolean = false;
  sessionTimeoutSavedDate

  setServiceDepartmentList(list) {
    return this._setServiceDepartmentList.next(list)
  }

  getServiceDepartmentList() {
    return this._setServiceDepartmentList$;
  }

  setInventoryOrdersForMeGridHeight(latestValue: GridHeight) {
    return this._inventoryOrders.next(latestValue);
  }
  getInventoryOrdersForMeGridHeight(): Observable<GridHeight> {
    return this._inventoryOrders$;
  }

  setMissingSignaturesGridHeight(latestValue: GridHeight) {
    return this._missingSignatures.next(latestValue);
  }
  getMissingSignaturesGridHeight(): Observable<GridHeight> {
    return this._missingSignatures$;
  }


  setInventoryRequestForGridHeight(latestValue: GridHeight) {
    return this._inventoryRequest.next(latestValue);
  }
  getInventoryRequestForGridHeight(): Observable<GridHeight> {
    return this._inventoryRequest$;
  }

  setServiceOrdersWidgetGridHeight(latestValue: GridHeight) {
    return this._serviceOrders.next(latestValue);
  }
  getServiceOrdersWidgetGridHeight(): Observable<GridHeight> {
    return this._serviceOrders$;
  }

  setPartOrdersStatisticsPodHeight(latestValue: GridHeight) {
    return this._partOrdersStatistics.next(latestValue)
  }
  getPartOrdersStatisticsPodHeight(): Observable<GridHeight> {
    return this._partOrdersStatistics$;
  }

  setclaimsStatusesCountPodHeight(latestValue: GridHeight) {
    return this._claimsStatusesCount.next(latestValue)
  }
  getclaimsStatusesCountPodHeight(): Observable<GridHeight> {
    return this._claimsStatusesCount$;
  }

  setMyDayRoutePod(latestValue: GridHeight) {
    return this._myDayRoutePod.next(latestValue)
  }

  setImportErrorPodHeight(latestValue: GridHeight) {
    return this._importErrorPodHeight.next(latestValue)
  }

  getImportErrorPodHeight(): Observable<GridHeight> {
    return this._importErrorPodHeight$;
  }

  setExportErrorPodHeight(latestValue: GridHeight) {
    return this._exportErrorPodHeight.next(latestValue)
  }

  getExportErrorPodHeight(): Observable<GridHeight> {
    return this._exportErrorPodHeight$;
  }

  setDatadiscrepancyPodHeight(latestValue: GridHeight) {
    return this._datadiscrepancyPodHeight.next(latestValue)
  }

  getDatadiscrepancyPodHeight(): Observable<GridHeight> {
    return this._datadiscrepancyPodHeight$;
  }

  setUncostedOrdersPodHeight(latestValue: GridHeight) {
    return this._uncostedOrdersPodHeight.next(latestValue)
  }

  getUncostedOrdersPodHeight(): Observable<GridHeight> {
    return this._uncostedOrdersPodHeight$;
  }

  getMyDayRoutePod(): Observable<GridHeight> {
    return this._myDayRoutePod$;
  }

  setPendingApprovalsPod(latestValue: GridHeight) {
    return this._pendingApprovalsPod.next(latestValue)
  }
  getPendingApprovalsPod(): Observable<GridHeight> {
    return this._pendingApprovalsPod$;
  }

  getGridHeight(): Observable<GridHeight> {
    return this._gridHeight$;
  }

  setGridHeight(latestValue: GridHeight) {
    return this._gridHeight.next(latestValue);
  }

  setOpenDashboardPanelDialog(value: boolean): void {
    this._openDashboardEditDialogue.next(value)
  }

  getOpenDashboardPanelDialog(): Observable<boolean> {
    return this._openDashboardEditDialogue$
  }

  getMaxRowId(datasource) {
    if (datasource.length) {
      return Math.max.apply(Math, datasource.map(row => row.rowId)) || 0;
    } else {
      return 0;
    }
  }

  getMaxTempRowId(datasource) {
    if (datasource.length) {
      return Math.max.apply(Math, datasource.map(row => row.tempRowId)) || 0;
    } else {
      return 0;
    }
  }

  unknownPart = {
    value: '++Unknown',
    text: '++Unknown - Unknown Part',
    itemDescription: 'Unknown Part',
    item: '++Unknown'
  }

  /**
   * Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter,
   * one number and one special character:
   */
  passwordRegex: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,10}';

  private sidenavOpenSubject: Subject<boolean>;
  private resetDashboardLayoutSubject: Subject<boolean>;
  private editDashboardLayoutSubject: Subject<boolean>;
  private saveDashboardLayoutSubject: Subject<boolean>;

  private isClaimLoaded$: Subject<boolean>;
  private isCallingApiSubject = new BehaviorSubject<boolean>(false);

  public isCallingApi$ = this.isCallingApiSubject.asObservable();

  constructor(
    private http: HttpClient
  ) {
    this.sidenavOpenSubject = new Subject<boolean>();
    this.resetDashboardLayoutSubject = new Subject<boolean>();
    this.editDashboardLayoutSubject = new Subject<boolean>();
    this.saveDashboardLayoutSubject = new Subject<boolean>();
    this.isClaimLoaded$ = new Subject<boolean>();
  }

  setIsCallingApi(isCallingApi: boolean): void {
    this.isCallingApiSubject.next(isCallingApi);
  }  

  setIsClaimLoaded() { // the latest code
    this.isClaimLoaded$.next(true)
  }

  getIsClaimLoaded(): Observable<boolean> {
    return this.isClaimLoaded$;
  }

  editDashboardLayout(opening: boolean): void {
    this.editDashboardLayoutSubject.next(opening);
  }

  oneditDashboardLayout(): Observable<boolean> {
    return this.editDashboardLayoutSubject;
  }

  saveDashboardLayout(opening: boolean): void {
    this.saveDashboardLayoutSubject.next(opening);
  }

  onsaveDashboardLayout(): Observable<boolean> {
    return this.saveDashboardLayoutSubject;
  }

  resetDashboardLayout(opening: boolean): void {
    this.resetDashboardLayoutSubject.next(opening);
  }

  onresetDashboardLayout(): Observable<boolean> {
    return this.resetDashboardLayoutSubject;
  }


  toggleSideNav(opening: boolean): void {
    this.sidenavOpenSubject.next(opening);
  }

  onSideNavToggle(): Observable<boolean> {
    return this.sidenavOpenSubject;
  }

  SaveChecklist(payload: any) {
    return this.http.post(`${BASE_URL}Checklist/SaveChecklist`, payload);
  }

  generateChecklistInvoice() {
    // return this.http.post(`${BASE_URL}Checklist/GenerateChecklist?`);
  }

  getChecklistbyId(checklistIdInput: any) {
    return this.http.get(`${BASE_URL}Checklist/GetChecklistbyId?checklistId=${checklistIdInput}`);
  }

  getKingslynnChecklistForDDLTableData(serializedItemGroup: string) {
    return this.http.post(`${BASE_URL}Checklist/GetChecklistTypeForDDL?serializedItemGroup=${serializedItemGroup}`, '');
  }

  getKingslynnChecklistForDDL(checklistType: string, serializedItemGroup: string) {
    return this.http.post(`${BASE_URL}Checklist/GetKingslynnChecklistForDDL?checklistType=${checklistType}&serializedItemGroup=${serializedItemGroup}`, '');
  }

  getStatusClassForChecklist(status: string) {
    return status ? status.toLowerCase().trim() === 'approved' ? 'green' : status.toLowerCase().trim() === 'rejected' ? 'red' : '' : '';
  }

  getStatusClassForImportErrorPod(status: string) {
    return status ? status.toLowerCase().trim() === 'open' ? 'black' : status.toLowerCase().trim() === 'fixed' ? 'red' : '' : '';
  }

  getStatusClassForPartsCatalogItems(status: string) {
    return status.toLowerCase().trim() === 'active' ? 'green' : 'orange';
  }

  getClaimsRuleStatus(status: string) {
    status = status ? status.toLowerCase().trim() : '';
    if (['passed'].includes(status)) {
      return 'green';
    } else if (['failed'].includes(status)) {
      return 'red';
    } else if (['pending approval'].includes(status)) {
      return 'orange';
    } else {
      return 'grey';
    }
  }

  getStatusClass(status: string) {
    status = status ? status.toLowerCase().trim() : '';
    if (['in route', 'in progress', 'inprogress', 'delivery acknowledged'].includes(status)) {
      return 'blue';
    } else if (['partially shipped', 'partiallyshipped'].includes(status)) {
      return 'coral';
    } else if (['open', 'total', 'draft', 'unknown'].includes(status)) {
      return 'grey';
    } else if (['scheduled', 'ready to be shipped', 'order sent', 'pending approval', 'short shipment'].includes(status)) {
      return 'orange';
    } else if (['completed', 'delivered', 'closed', 'estimate approved', 'done', 'approved', 'fixed'].includes(status)) {
      return 'green';
    } else if (['interrupted', 'cancelled', 'canceled', 'estimate rejected', 'damaged', 'rejected', 'error'].includes(status)) {
      return 'red';
    } else if (['received in ln', 'received in ms', 'parts received', 'en route'].includes(status)) {
      return 'light-purple';
    } else if (['enroute'].includes(status)) {
      return 'aqua';
    } else if (['exceptions', 'paused', 'wrong part', 'counted'].includes(status)) {
      return 'exceptions';
    } else if (['shipped', 'awaiting payment', 'awaiting info', 'excess shipment'].includes(status)) {
      return 'light-orange';
    } else if (['adjusted'].includes(status)) {
      return 'adjusted';
    } else if (['submitted'].includes(status)) {
      return 'peach';
    } else if (['planning'].includes(status)) {
      return 'yellow';
    } else if (['reconciled'].includes(status)) {
      return 'light-blue';
    } else if (['active'].includes(status)) {
      return 'green';
    } else if (['inactive'].includes(status)) {
      return 'peach';
    } else {
      return 'grey';
    }
  }

  getPurchaseOrdersClass(status: string) {
    status = status ? status.toLowerCase().trim() : '';
    if (['submitted'].includes(status)) {
      return 'black';
    } else if (['partially shipped'].includes(status)) {
      return 'dark-orange';
    } else if (['shipped'].includes(status)) {
      return 'peach';
    } else if (['pending'].includes(status)) {
      return 'yellow';
    } else if (['shipped'].includes(status)) {
      return 'green';
    } else if (['in progress'].includes(status)) {
      return 'blue';
    } else if (['pending'].includes(status)) {
      return 'yellow';
    } else if (['canceled'].includes(status)) {
      return 'red';
    } else {
      return 'grey';
    }
  }

  getPartOrdersClass(status: string) {
    status = status ? status.toLowerCase().trim() : '';
    if (['open'].includes(status)) {
      return 'black';
    } else if (['partially shipped'].includes(status)) {
      return 'orange';
    } else if (['order sent'].includes(status)) {
      return 'yellow';
    } else if (['shipped'].includes(status)) {
      return 'light-blue';
    } else if (['closed'].includes(status)) {
      return 'green';
    } else if (['in progress'].includes(status)) {
      return 'bluish';
    } else if (['total'].includes(status)) {
      return 'bold';
    } else {
      return 'grey';
    }
  }

  getServiceOrdersClass(status: string) {
    status = status ? status.toLowerCase().trim() : '';
    if (['awaiting payment'].includes(status)) {
      return 'black';
    } else if (['canceled', 'cancelled'].includes(status)) {
      return 'red';
    } else if (['completed'].includes(status)) {
      return 'green';
    } else if (['estimate approved'].includes(status)) {
      return 'orange';
    } else if (['paused'].includes(status)) {
      return 'yellow';
    } else if (['pending approval'].includes(status)) {
      return 'light-blue';
    } else if (['scheduled'].includes(status)) {
      return 'orange';
    } else if (['open'].includes(status)) {
      return 'black';
    } else if (['in progress'].includes(status)) {
      return 'bluish';
    } else if (['total'].includes(status)) {
      return 'bold';
    } else {
      return 'grey';
    }
  }

  iframe(payload) {
    return this.http.post(`${BASE_URL}CCPayment/AuthorizePurchaseOrderCCPayment`, payload);
  }

  getDepartmentsByRole() {
    return this.http.get(`${BASE_URL}ServiceCentre/GetServiceCentresByRole`);
  }

  getPartOrdersByStatus(status: string) {
    return this.http.get(`${BASE_URL}Dashboard/GetPartsOrdersByStatus?status=${status}`);
  }

  getServiceOrdersByStatus(status: string, serviceDepartments) {
    return this.http.post(`${BASE_URL}Dashboard/GetServiceOrdersByStatus?status=${status}`, serviceDepartments);
  }

  getUsersForApproval() {
    return this.http.get(`${BASE_URL}UserProfile/GetISCEmpsForApproval`);
  }

  getClaimsByStatus(status: string) {
    return this.http.get(`${BASE_URL}Dashboard/GetClaimsByStatus?status=${status}`);
  }

  getPartsOrderCountByStatus() {
    return this.http.get(`${BASE_URL}Dashboard/GetPartsOrderCountByStatus`);
  }

  getClaimCountByStatus() {
    return this.http.get(`${BASE_URL}Dashboard/GetClaimCountByStatus`);
  }

  getServiceOrderCountByStatus(data: string[] | number[] | boolean[]) {
    return this.http.post(`${BASE_URL}Dashboard/GetServiceOrderCountByStatus`, data);
  }

  serviceOrdersOnholdForParts() {
    return this.http.get(`${BASE_URL}Dashboard/GetServiceOrdersOnholdForParts`);
  }

  getLinkedRecords(moduleId: number, primaryId: string) {
    return this.http.get(`${BASE_URL}Links/getLinkedRecords?module=${moduleId}&primaryId=${primaryId}`);
  }

  getCommonArrayElementsByKey(a1: Object[], a2: Object[], matchingKey: string) {
    if (a1.length >= a2.length) {
      return a1.filter(elem1 => a2.some(elem2 => elem1[matchingKey] === elem2[matchingKey]));
    } else {
      return a2.filter(elem1 => a1.some(elem2 => elem1[matchingKey] === elem2[matchingKey]));
    }
  }

  sendEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendMail`, data);
  }

  sendInvoiceEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendInvoiceEmail`, data);
  }

  sendAttachmentEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendAttachmentEmail`, data);
  }

  sendCalibrationCertificateEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendCalibrationCertificateEmail`, data);
  }

  SendCheckListCertificateEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendCheckListCertificateEmail`, data);
  }

  sendReturnOrderStatementEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendCoreReturnReportEmail`, data);
  }

  sendDamagedReturnReportEmail(data) {
    return this.http.post(`${BASE_URL}Email/SendDamagedReturnReportEmail`, data);
  }

  anchorValueAccessor(field: string, data: Object) {
    if (!data) {
      return `<a href="javascript:">${data[field]}</a>`;
    } else {
      return data[field];
    }
  }

  getUsers() {
    return this.http.get(`${BASE_URL}User/GetUsersForDDL`);
  }

  getUsersForMention() {
    return this.http.get(`${BASE_URL}Comment/GetUsersForComment`);
  }

  getUserById(employeeId: string) {
    this.getUserDefaults(employeeId);
    return this.http.get(`${BASE_URL}User/GetUserData?employeId=${employeeId}`);
  }

  getRuleActionsByRole(roleName: string) {
    return this.http.get(`${BASE_URL}RuleAction/GetRuleActionsByRole?role=${roleName}`);
  }

  getUserDataByUserId() {
    return this.http.get(`${BASE_URL}User/GetUserDataByUserId`);
  }

  getUserDefaults(userId: string) {
    this.http.get(`${BASE_URL}CostLineDefault/GetCostLineDefaultByUser?employeeId=${userId}`)
      .subscribe((res: any) => {
        this.userDefaults = res.result;
      });
  }

  getServiceOrderStatuses() {
    return this.http.get(`${BASE_URL}status/GetStatusesForDDL?moduleName=ServiceOrder`);
  }

  getServiceOrderDepartments() {
    return this.http.get(`${BASE_URL}ServiceCentre/GetServiceCentres`);
  }

  getStatusesForDDL(module) {
    return this.http.get(`${BASE_URL}status/GetStatusesForDDL?moduleName=${module}`);
  }

  getServiceManagerCycleCountsForAlerts(id) {
    return this.http.get(`${BASE_URL}CycleCount/GetServiceManagerCycleCountsForAlerts?managerId=${id}`);
  }

  getTechnicianCycleCountReminder(id) {
    return this.http.get(`${BASE_URL}CycleCount/GetTechnicianCycleCountReminder?technicianId=${id}`);
  }

  getUserPreference(gridNames: string, userId: string) {
    return this.http.get(`${BASE_URL}Persistence/GetPersistence?employeId=${userId}&nameList=${gridNames}`);
  }

  getEquipmentHistory(serviceOrderId: string, item: string, serialNumber: any) {
    return this.http.get(`${BASE_URL}ServiceOrder/GetServiceOrderEquipmentHistory?serviceOrderId=${serviceOrderId}&item=${item}&serialNumber=${serialNumber}`);
  }

  saveUserPreference(data: PersistenceData) {
    this.http.post(`${BASE_URL}Persistence/UpdatePersistence`, data).subscribe();
  }

  resetUserPreference(data: PersistenceData): Observable<any> {
    return this.http.post(`${BASE_URL}Persistence/UpdatePersistence`, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getTimeDifferenceInHrs(dateToCompare: string | Date) {
    const currentTime = new Date().getTime();
    const closingTime = new Date(dateToCompare).getTime();
    return (currentTime - closingTime) / 1000 / 60 / 60; // milliseconds/1000 = seconds / 60 = minutes / 60 = hours
  }

  showNotification(type: SweetAlertIcon, message: string, position: SweetAlertPosition = 'center', timer: number = 3000) {
    Swal.fire({
      icon: type,
      html: message,
      toast: true,
      timer: timer,
      position,
      showConfirmButton: false,
      showCloseButton: true,
      // backdrop: false
    });
  }

  showLongNotification(type: SweetAlertIcon, message: string, position: SweetAlertPosition = 'center', timer: number = 5000, width:number = 480) {
    Swal.fire({
      icon: type,
      html: message,
      toast: true,
      timer: timer,
      position,
      showConfirmButton: false,
      showCloseButton: true,
      width
      // backdrop: false
    });
  }

  showDialogueWithNoTimer(type: SweetAlertIcon, message: string, position: SweetAlertPosition = 'center') {
    Swal.fire({
      icon: type,
      html: message,
      toast: false,
      position,
      showConfirmButton: false,
      showCloseButton: true,
      backdrop: true,
      allowOutsideClick: false,
      width: 500
    });
  }

  showErrorDialogueWithLink(title: string, message: string) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
      footer: '<a href="#">Try again?</a>'
    });

  }

  showConfirmation(message: string, confirmButtonText: string = 'Yes', denyButtonText: string = 'No', title: string = '') {
    return Swal.fire({
      title: title,
      html: message,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText,
      denyButtonText,
      confirmButtonColor: 'green'
    });
  }

  getLinkedOrder(itemId: string, warehouse?: string) {
    return this.http.get(`${BASE_URL}Inventory/GetInventoryItemLinks?item=${itemId}&warehouse=${warehouse}`)
  }

  getShippingMethodsForDDL(company: any) {
    return this.http.get(`${BASE_URL}ShippingMethod/GetShippingMethodsForDDL?company=${company}`);
  }

  getTechnicianAddresses(warehouseNumber: any) {
    return this.http.get(`${BASE_URL}Warehouse/GetTechnicianAddresses?warehouseNumber=${warehouseNumber}`);
  }

  getServiceItemsForDDL(company: any) {
    return this.http.get(`${BASE_URL}ServiceItem/GetServiceItemsForDDL?company=${company}`);
  }

  getTechniciansForManager() {
    return this.http.get(`${BASE_URL}Inventory/GetTechnicianListForManager`);
  }

  getEmployeeSearchView(payload) {
    return this.http.post(`${BASE_URL}ServiceEmployee/GetEmployeeSearchView`, payload);
  }

  getServicOrdersWithPublishErrors() {
    return this.http.get(`${BASE_URL}Dashboard/GetServiceOrdersWithPublishErrors`);
  }

  getServicOrdersUncosted() {
    return this.http.get(`${BASE_URL}Dashboard/GetServiceOrdersUncosted`);
  }

  getDataDiscrepancyLogData() {
    return this.http.get(`${BASE_URL}Dashboard/GetDataDiscrepancyLogData`);
  }

  showPermissionNotification() {
    this.showNotification('warning', 'You do not have permissions to perform this operation');
  }


  getDataForApi(url: string, type?: string, rowData: any = null) {
    if (type === 'post') {
      return this.http.post(`${BASE_URL}${url}`, rowData);
    } else if (type === 'get') {
      return this.http.get(`${BASE_URL}${url}`);
    }
  }

  callApi(url: string, type: string, rowData) {
    if (type === 'post') {
      return this.http.post(`${BASE_URL}${url}`, rowData);
    } else if (type === 'get') {
      return this.http.get(`${BASE_URL}${url}`);
    }
  }

  resetPersistence(grid) {
    this.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
      if (result.isConfirmed) {
        grid.search('');
        grid.clearFiltering();
        grid.clearSorting();
      }
      else {
        return
      }
    })
  }

  getItems(searchString: string = null, pageSize: number = 30, company, serviceItemGroup?) {
    // const columnName = isNaN(+searchString) ? 'itemDescription' : 'item';
    const columnName = 'item';
    const filterData = {
      requiresCounts: true,
      onPage: {
        pageIndex: 1,
        pageSize
      },
      skip: 0,
      parameters: [
        {
          "key": "company",
          "value": company
        }

      ],
      take: pageSize
    };

    if (serviceItemGroup) {
      filterData.parameters.push({
        "key": "serviceItemGroup",
        "value": serviceItemGroup
      });
    }

    if (searchString) {
      filterData['onWhere'] = {
        ignoreAccent: false,
        isComplex: true,
        condition: "and",
        predicates: [{
          condition: 'or',
          ignoreAccent: false,
          isComplex: false,
          field: columnName,
          operator: "contains",
          value: searchString,
          ignoreCase: true,
          oprtr: "contains"
        }]
      };
    }
    filterData['onSearch'] = filterData['onSearch'] ? filterData['onSearch'] : null;
    filterData['onSortBy'] = filterData['onSortBy'] ? filterData['onSortBy'] : null;
    return this.http.post(`${BASE_URL}ServiceItem/GetServiceItems`, filterData);
  }

  checkValidEmail(email) {
    const emailCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailCheck.test(String(email).toLowerCase());
  }

  getAlternateItemData(itemCode: any) {
    return this.http.get(`${BASE_URL}ServiceItem/GetServiceItemByItem?item=${itemCode}`);
  }
}
