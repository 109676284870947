
<div class="modal fade show" [ngClass]="{'expanded': isExpanded}" id="camera-modal" tabindex="-1"
     aria-labelledby="camera-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
  <div class="modal-dialog modal-lg">
    <app-loader *ngIf="showLoader"></app-loader>
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex w-100">
          <div class="mr-2 id">
            <h4 class="modal-title mb-0 item-id">Camera</h4>
          </div>
          <div class="btns-header ml-auto">
            <button class="header-icon close p-0 m-1" (click)="close()" aria-label="Close">
              <i class="fas fa-times" title="Close Popup"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body custom-scrollbar pt-3">
        <div class="video-container" *ngIf="!error">
          <video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" class="margin-auto" [width]="WIDTH" [height]="HEIGHT"
                 autoplay></video>
          <canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" class="margin-auto" [width]="WIDTH"
                  [height]="HEIGHT"></canvas>
        </div>

        <div class="snap-container">
          <button class="btn btn-primary" *ngIf="!isCaptured" (click)="capture()">Snap Photo</button>
          <button class="btn btn-danger" *ngIf="isCaptured" (click)="removeCurrent()">Take Another</button>
          <button class="btn btn-danger ml-2" (click)="stopStream()">Stop Stream</button>
          <div *ngIf="error" class="text-danger mt-4">
            {{ error }}. Please check your camera
          </div>
        </div>

        <ul class="list-unstyled">
          <!--<li  (click)="setPhoto(idx)">
            <img [src]="captures" height="50" />
          </li>-->
        </ul>
      </div>
      <div class="modal-footer">
        <!--<button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
        <button type="button" *ngIf="!isDraft" [disabled]="!isSigned" (click)="saveFile()" class="btn btn-sm btn-success mr-1" id="">Save</button>
        <button type="button" *ngIf="!isDraft" [disabled]="!isSigned" (click)="print()" class="btn btn-sm btn-success mr-1" id="">Print</button>-->
        <button type="button"  class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
        <button type="button" (click)="savePhoto()" class="btn btn-sm btn-success mr-1" id="">Save</button>
      </div>
    </div>
  </div>
</div>


