<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="core-return-order-modal"
    tabindex="-1"
    aria-labelledby="core-return-order-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header
                [id]="id"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="id ? id: category === 'Defective' ? 'Core Return Order' : 'Damaged Return Order'"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0" id="core-return-order-popup-body">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Technician' }}:<span class="required-asterik">*</span></label>
                                    <div class="col pt-2"> {{ data.technicianName }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Status' }}:</label>
                                    <div class="col pt-2"> {{ data.status }} </div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="id">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="data?.returnOrderId"
                                            [moduleId]="id"
                                            [module]="isCoreReturnOrder? returnOrderTypes.coreReturnOrder : returnOrderTypes.damagedReturnOrder"
                                            [comments]="data?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}"
                                            data-toggle="tab"
                                            href="#details-tab"
                                            title="Details"
                                        >Details  <i *ngIf="!validateDetails || !validateItems" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>
                                    <li class="nav-item" *ngIf="id">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === 'attachments'}"
                                            data-toggle="tab"
                                            href="#attachments-tab"
                                            title="Attachments"
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === 'history'}"
                                            data-toggle="tab"
                                            href="#history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div id="details-tab" class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}">
                                <div class="card">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-return-collapse-details">
                                        <a class="card-link flex-fill">General <i *ngIf="!validateDetails" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                    <div id="general-return-collapse-details" class="collapse show container-fluid mb-2">
                                        <app-core-return-order-popup-details-tab
                                            #detailsTab
                                            [status]="data?.status"
                                            [details]="detailsData"
                                            [shipmentMethods]="shipmentMethods"
                                            (tabUpdated)="detailsTabUpdated($event)">
                                        </app-core-return-order-popup-details-tab>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-return-collapse-items">
                                        <a class="card-link flex-fill">Items <i *ngIf="!validateItems" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                    <div id="general-return-collapse-items" class="collapse show">
                                        <app-core-return-order-popup-items-tab #itemsTab
                                            *ngIf="returnItemsReasons && returnItemsReasons.length && !isCoreReturnOrder"
                                            [status]="data?.status"
                                            [category]="category"
                                            [warehouse]="data?.warehouse"
                                            [items]="itemsData"
                                            [inventoryItems]="inventoryItems"
                                            [reasonsDDL]="returnItemsReasons"
                                            (toggleGenerateStatementButton)="toggleGenerateStatementButton($event)">
                                        </app-core-return-order-popup-items-tab>

                                        <app-core-return-order-popup-core-items-tab #coreItemsTab
                                        *ngIf="returnItemsReasons && returnItemsReasons.length && isCoreReturnOrder"
                                            [status]="data?.status"
                                            [category]="category"
                                            [warehouse]="data?.warehouse"
                                            [items]="itemsData"
                                            [reasonsDDL]="returnItemsReasons"
                                            (toggleGenerateStatementButton)="toggleGenerateStatementButton($event)">
                                        </app-core-return-order-popup-core-items-tab>
                                    </div>
                                </div>
                            </div>
                            <div id="attachments-tab" class="tab-pane" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab
                                    [moduleId]="data?.returnOrderId"
                                    [module]="isCoreReturnOrder? returnOrderTypes.coreReturnOrder : returnOrderTypes.damagedReturnOrder"
                                    [attachments]="attachmentsData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="history-tab" class="tab-pane" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [history]="data?.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" *ngIf="data.status === coreReturnOrderOpenStatus" id="" [disabled]="!isTechnician" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1"  *ngIf="data.status === coreReturnOrderOpenStatus" [disabled]="!isTechnician" (click)="saveOrder()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1"  *ngIf="data.status === coreReturnOrderOpenStatus" [disabled]="!isTechnician" (click)="saveOrder(true)">Save &amp; Close</button>
                <button type="button" class="btn btn-sm btn-primary mr-1" id="core-return-order-btn" [disabled]="generateStatmentBtnDisabled" (click)="generateStatement()">Generate Statement</button>
            </div>
        </div>
    </div>
</div>

<app-return-order-statement-viewer 
    *ngIf="showStatement && statmentUrl"
    [returnOrderId]="data?.returnOrderId"
    [module]="isCoreReturnOrder? returnOrderTypes.coreReturnOrder : returnOrderTypes.damagedReturnOrder"
    [url]="statmentUrl"
    [title]="statementPopupTitle"
    (onClose)="statementViewerClosed($event)"
></app-return-order-statement-viewer>