import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageElement } from 'src/app/modules/service-order/models/popup.model';
import { StatusDropdown } from 'src/app/modules/service-order/structure/structure';
import { ApiResponse, Companies, ModuleNames, PopupTabs, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { ReturnOrderTypesForDDL, parentStatuses, ReturnOrderServiceable, returnOrderStatuses, returnOrdersCategory } from '../../models/orders.model';
import { InventoryService } from '../../services/inventory.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-return-order-popup',
  templateUrl: './return-order-popup.component.html',
  styleUrls: ['./return-order-popup.component.scss', '../../../../../assets/scss/popup.scss']
})

export class ReturnOrderPopupComponent implements OnInit, OnDestroy {

  USER;
  private destroy$: Subject<null> = new Subject();

  @Input() tab: string = PopupTabs.details;
  @Input() id: string = null;
  @Input() showComments: boolean = false;
  @Input() data = null;
  @Input() itemsData = [];
  @Input() category: string = returnOrdersCategory.serviceable;
  @Input() itemsDDL: Object[] = [];
  @Input() inventoryItems = []; //All items will be fetched from the active inventory tab grid.

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onNeedRefresh = new EventEmitter();
  @Output() onOrderPlaced = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();

  loadedData = null;
  statusDropdown: PageElement = StatusDropdown;

  validateDetails: boolean = false;
  validateItems: boolean = false;
  showLoader: boolean = false;
  isExpanded: boolean = false;
  validationApplied: boolean = false;
  isManager: boolean = false;
  isCSA: boolean = false;
  createdDate: Date = new Date();
  shipmentMethods = [];
  categories = {};
  commentCount = 0;
  attachmentCount = 0;
  detailsData: Object;
  placeOrderBtnDisabled: boolean = true;

  detailsTab = PopupTabs.details;
  linksTab = PopupTabs.links;
  attachmentsTab = PopupTabs.attachments;
  historyTab = PopupTabs.history;

  @ViewChild('commentsButton') commentsButton: ElementRef;
  @ViewChild('commentsPopup') commentsPopup: ElementRef;
  @ViewChild('detailsTab') details;
  @ViewChild('itemsTab') items;
  @ViewChild('attachmentsTab') attachments;

  links = [];
  attachmentsData = [];
  returnItemsReasons = []
  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
  ) {
      this.USER = this.commonService.USER;
      this.isManager = this.USER.role === UserRoles.conwayServiceManager;
      this.isCSA = this.USER.role === UserRoles.csa;
  }

  ngOnInit(): void {
    this.inventoryService.popupLoader.pipe(
      takeUntil(this.destroy$),
    ).subscribe((showLoader: any) => {
        this.showLoader = showLoader;
    });
    this.route.paramMap.pipe(
      takeUntil(this.destroy$)).subscribe(params => {
      if (params && params.get('tab') && params.get('id') && params.get('showComments')) {
        this.id = params.get('id')
        this.showComments = params.get('showComments') == 'showComments' ? true : false
      }
    });
    if(!this.category){
      this.category = returnOrdersCategory.serviceable;
    }
    this.loadPage();
  }

  loadPage() {
    this.setPageData();
    this.setItemsTabData();
    this.setAttachmentsTabData();
    this.loadAPIs();
    this.toggleButtonDisable();
  }

  setPageData() {
    if ( !this.data ) {
      this.data = {
          returnOrderId: '',
          status: returnOrderStatuses.open,
          parentStatus: parentStatuses.open,
          company: this.USER.company,
          warehouse: this.USER.warehouse,
          orderCurrency: this.USER.orderCurrency,
          createdBy: this.data?.createdBy || this.USER.userId,
          technicianId: this.data?.technicianId || this.USER.userId,
          technicianName: this.data?.technicianName || this.USER.userName,
          lastUpdatedBy: this.data?.lastUpdatedBy || this.USER.userName,
          lastUpdatedByName: this.data?.lastUpdatedByName || this.USER.userName,
          itemList: [],
          comments: [],
          attachments: []
      };
    } else {
      this.commentCount = this.data.comments.length;
      this.attachmentCount = this.data.attachments.length;
    }
  }

  setDetailsTabData() {
    this.detailsData = {
        category: this.data?.category || this.category || null,
        shipmentOrderNumber: this.data?.shipmentOrderNumber || null,
        shipmentMethodCode: this.data?.shipmentMethodCode || this.getDefaultShipmentMethod('value'),
        shipmentMethodDescription: this.data?.shipmentMethodDescription || this.getDefaultShipmentMethod('description'),
        estimatedDeliveryDate: this.data?.estimatedDeliveryDate && new Date(this.data.estimatedDeliveryDate) || null,
        trackingId: this.data?.trackingId || null,
        warehouseOrderLN: this.data?.warehouseOrderLN || null,
        notes: this.data?.notes || null
    }
  }

  getDefaultShipmentMethod(key) {
    let shipmentMethod: any;
    if(this.commonService.USER.company === Companies.kingslynn){
      shipmentMethod = this.shipmentMethods.find( method => method.text === "UPS WORLD EXP 1-2 DAY 1030AM") || null;
    } else {
      shipmentMethod = this.shipmentMethods.find( method => method.text === 'UPS GROUND') || this.shipmentMethods[0];
    }
    return shipmentMethod[key];
  }

  setItemsTabData() {
    this.itemsData = this.data?.itemList && this.data?.itemList.length ? this.data.itemList : this.itemsData || [];

    this.itemsData?.length && this.itemsData?.map((item, index) => {
      return this.setItemsRow(item, index);
    });
  }

  setItemsRow(item, index) {
      item.returnOrderDetailId = item.returnOrderDetailId || '',
      item.lineNumber = item.lineNumber || index + 1;
      item.itemCode = item.itemCode || item.item || '';
      item.itemDescription = item.itemDescription || item.description || '';
      item.itemGroup = item.itemGroup || '';
      item.serviceItemGroup = item.serviceItemGroup || '';
      item.quantity = item.quantity || 1;
      item.reasonCode = item.reasonCode || '';
      item.createdBy = item.createdBy || '';
      item.technicianId = item.technicianId || '';
      item.technicianName = item.technicianName || '';
      item.lastUpdatedBy = item.lastUpdatedBy || '';
      item.lastUpdatedByName = item.lastUpdatedByName || '';
      item.isDeleted = item.isDeleted || false;
      return item;
  }

  setAttachmentsTabData() {
      this.attachmentsData = this.data?.attachments || [];
  }

  loadAPIs() {
    this.inventoryService.popupLoader.next(true);
    const promises = [
      this.inventoryService.getStatusesForDDL(ModuleNames.returnOrder),
      this.commonService.getShippingMethodsForDDL(this.commonService.USER.company),
      this.inventoryService.getInventory(this.category.toLowerCase(), this.commonService.USER.warehouse),
      this.inventoryService.getReasonOrExceptionCodesForDDL(ReturnOrderServiceable),
    ];
    // this.id && promises.push(this.commonService.getLinkedRecords(LinksEnum.returnOrder, this.id));

    forkJoin(promises).subscribe( (res: any) => {
        this.statusDropdown.dataSource = res[0].result;
        this.shipmentMethods = res[1].result;
        this.itemsDDL = res[2].result.map( item => {
          item.text = item.itemCode +' | ' + item.itemDescription;
          return item;
        });
        this.returnItemsReasons = res[3].result;
        // this.links = this.id ? res[4] : [];
        this.categories = ReturnOrderTypesForDDL;
        this.data.status = this.data && this.data.status ? this.data.status : returnOrderStatuses.open;
        this.data.parentStatus = this.data && this.data.parentStatus ? this.data.parentStatus : parentStatuses.open;
        this.setDetailsTabData();
        this.setData();
        this.loadedData = JSON.stringify(this.data);
        this.inventoryService.popupLoader.next(false);
    }, (error: HttpErrorResponse) => {
        this.inventoryService.popupLoader.next(false);
        throw error;
    });
  }

  setData(returnOrder?: boolean) {
      const status = !returnOrder ? this.data.status : returnOrderStatuses.submitted;
      this.data = {
          ...this.data,
          ...this.details?.getData() || this.detailsData,
          ...this.items?.getData() || { itemList: this.itemsData },
          status
      };
  }

  onCommentUpdate(data: any) {
    this.commentCount = data.filter(comment => !comment.isDeleted).length;
    this.onNeedRefresh.emit();
  }

  onAttachmentsUpdate(data: any) {
      this.data.attachments = data;
      this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  detailsTabUpdated(args) {
    this.data = {
      ...this.data,
      ...args
    }
  }

  isChanged() {
      return this.loadedData !== JSON.stringify(this.data);
  }

  validate() {
    this.validateDetails = this.details.validate();
    this.validateItems = this.items.validate();
    return this.validateDetails && this.validateItems;
  }

  getData(returnOrder?: boolean) {
    const status = !returnOrder ? this.data.status : returnOrderStatuses.submitted;
    return {
        ...this.data,
        ...this.details?.getData() || this.detailsData,
        ...this.items?.getData() || { itemList: this.itemsData },
        status
    };
  }

  save(close?: boolean, returnOrder?: boolean) {
    this.validationApplied = true;
    if ( this.validate()) {
      this.commonService.showConfirmation('Empty row(s) will not be saved ', 'Okay', 'Cancel').then(result => {
        if (result.isConfirmed) {
          this.inventoryService.popupLoader.next(true);
          this.saveAPI(close, returnOrder);
        }
      });
    } else {
      this.commonService.showNotification('error', 'Please provide all mandatory fields..');
    }
  }

  saveAPI(close?: boolean, returnOrder?: boolean){
    const data = this.getData(returnOrder);
    if (data.itemList && data.itemList.length) {
            this.inventoryService.saveReturnOrder(data)
            .subscribe((res: ApiResponse) => {
              if ( res.isSuccessful ) {
                if ( returnOrder ) {
                  this.commonService.showNotification('success', 'Return Order Placed!');
                } else {
                  if ( this.data.returnOrderId ) {
                    this.commonService.showNotification('success', 'Return Order Updated!');
                  } else {
                    this.commonService.showNotification('success', 'Return Order saved as draft!');
                  }
                }
                this.data = res.result;
                this.setAttachmentsTabData();
                this.itemsData = this.data.itemList;
                this.id = res.result.returnOrderId;
                this.loadedData = JSON.stringify(this.data);

                this.onSave.emit(res.result);
                if ( close ) {
                  this.close(true)
                }
                this.inventoryService.popupLoader.next(false);
              }
            }, (error: HttpErrorResponse) => {
                this.inventoryService.popupLoader.next(false);
                throw error;
            });
    } else {
      this.commonService.showNotification('error', 'Atleast one item needed in an Order!');
      this.inventoryService.popupLoader.next(false);
    }
  }

  toggleButtonDisable() {
    this.placeOrderBtnDisabled = this.data.status !== returnOrderStatuses.open;
    if ( !this.placeOrderBtnDisabled) {
      const index = this.findValidRow();
      this.placeOrderBtnDisabled = index < 0;
    }  
  }
  findValidRow(){
    return (this.data?.itemList as any).findIndex(element => ( element.reasonCode && element.quantity && element.itemCode) && !element.isDeleted);
  }

  close(forceClose?) {
      if ( !this.isChanged() || forceClose) {
          this.onClose.emit();
      } else {
          this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
          .then(result => {
              if ( result.isConfirmed ) {
                  this.onClose.emit();
              }
          })
      }
  }

  reset() {
    if ( this.isChanged() ) {
      this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
        .then(result => {
            if ( result.isConfirmed ) {
              this.data = JSON.parse(this.loadedData);
              this.itemsData = this.data.itemList;
              this.setDetailsTabData();
              this.loadPage();
            }
        })
    } else {
      this.commonService.showNotification('info','There is nothing to reset!');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onCategoryChanged(args) {
    if( args.isInteracted ) {
      this.category = args.value;
      this.inventoryItems = args.inventory;
      // this.inventoryService.getReasonOrExceptionCodesForDDL(this.returnOrderCategory).subscribe((res: any) => {
      //   this.returnItemsReasons = res.result;
      //   this.items.itemsGrid.getColumnByField('reasonCode').dataSource.dataSource.json = res.result;
      // });
      this.itemsData.map(item => {if(item.itemCode){item.isDeleted = true}});
      this.itemsData = [...this.itemsData];
      this.data.itemList = this.itemsData;
      this.toggleButtonDisable();
    }
  }
}
