import { Element } from "./main.model";

export enum Operators { 
    hasValue = 'hasValue', 
    doesntHaveValue = 'doesntHaveValue', 
    changed = 'changed', 
    changedFrom = 'changedFrom',
    changedTo = 'changedTo',
    equals = 'equals',
    notEqual = 'notEqual',
    greaterThan = 'greaterThan',
    lessThan = 'lessThan',
    greaterEqual = 'greaterEqual',
    lessEqual = 'lessEqual'
}

export const OperatorsList = [
    { text: 'Has value', value: Operators.hasValue },
    { text: 'Does not have value', value: Operators.doesntHaveValue },
    { text: 'Has Changed', value: Operators.changed },
    { text: 'Changed to', value: Operators.changedTo },
    { text: 'Changed from', value: Operators.changedFrom },
    { text: 'Equals to', value: Operators.equals },
    { text: 'Not equal to', value: Operators.notEqual },
    { text: 'Greater than', value: Operators.greaterThan },
    { text: 'Greater than or equals', value: Operators.greaterEqual },
    { text: 'Less than', value: Operators.lessThan },
    { text: 'Less than or equals', value: Operators.lessEqual }
];

export enum Actions {
    setValueOfField = 'setValueOfField',
    setPropertyOfField = 'setPropertyOfField',
    copyValueFrom = 'copyValueFrom',
    sendEmail = 'sendEmail',
    sendNotification = 'sendNotification',
    conditionalValidation = 'conditionalValidation'
}

export const ActionsList = [
    { text: 'Set value of field', value: Actions.setValueOfField },
    { text: 'Set property of field', value: Actions.setPropertyOfField },
    { text: 'Copy the value from', value: Actions.copyValueFrom },
    { text: 'Send email', value: Actions.sendEmail },
    { text: 'Send notification to', value: Actions.sendNotification },
    { text: 'Show Validation Message', value: Actions.conditionalValidation }
];

export interface Condition {
    field: Element;
    conditionId?: any;
    operator: Operators;
    fromValue: any;
    toValue: any; 
}

export interface Action {
    actionId: any;
    action: Actions;
    field: Element;
    fieldValue: any;
    fieldProperty?: any;
    toField: Element;
    toValue: any;
    formula?: string;
}

export interface Rule {
    ruleName: string;
    ruleId?: number;
    createdBy?: string;
    createdDate?: Date;
    conditions: Condition[],
    actions: Action[]
}
