import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddSignatureEventArgs, RemoveSignatureEventArgs, AjaxRequestSuccessEventArgs, PrintService } from '@syncfusion/ej2-angular-pdfviewer';
import {
    PdfViewer, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields
} from '@syncfusion/ej2-pdfviewer';
import { environment } from 'src/environments/environment';
import { Companies } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { returnOrderTypes, returnOrdersCategoryTypes } from 'src/app/modules/home/models/orders.model';

const BASE_URL = environment.apiUrl;
PdfViewer.Inject(
    Toolbar,
    Magnification,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    ThumbnailView,
    Print,
    TextSelection,
    TextSearch,
    Annotation,
    FormFields
);

@Component({
  selector: 'app-return-order-statement-viewer',
  templateUrl: './return-order-statement-viewer.component.html',
  styleUrls: ['./return-order-statement-viewer.component.scss'],
  providers: [PrintService]
})
export class ReturnOrderStatementViewerComponent implements OnInit {

  isExpanded: boolean = false;
  showLoader: boolean = false;
  showEmailPopup: boolean = false;

  viewer: PdfViewer;

  @Input() url = '';
  @Input() module = null;
  @Input() returnOrderId: string;
  @Output() onClose = new EventEmitter();
  emailSubject: string;
  employeeName: any;

  constructor(
    private commonService: CommonService
  ) {
    this.employeeName = this.commonService.USER.employeeName;
  }

  ngOnInit(): void {
    if(this.url){
      this.initializeViewer(this.url)
    } else {
      this.commonService.showNotification('error', 'An error occured please try again to see return order statement.')
      this.close();
    }
  }

  initializeViewer(url: string) {
    this.viewer = new PdfViewer();
    this.viewer.enableToolbar = false;
    this.viewer.enablePrint = true;
    this.viewer.printMode  = 'NewWindow';
    this.viewer.enableNavigationToolbar = false;
    this.viewer.hideSaveSignature = true;
    this.viewer.serviceUrl = BASE_URL + 'pdfviewer';
    this.viewer.appendTo('#pdfViewer');
    this.viewer.load(url, null);
}

  onExpandCollapse() {
    this.isExpanded = !this.isExpanded;
    setTimeout(() => {
        this.viewer.updateViewerContainer();
        if(this.isExpanded){
            this.viewer.height = '80vh';
        } else {
            this.viewer.height = '55vh';
        }
        this.viewer.updateViewerContainer();
    }, 100);
  }

  print() {
    this.viewer.print.print();
    this.viewer.saveAsBlob().then((res: any) => {});
  }

  sendEmail(){
    if(this.module === returnOrderTypes.coreReturnOrder){
      this.emailSubject = `Attachment  - Core Return order ${this.returnOrderId}`;
    } else if (this.module === returnOrderTypes.damagedReturnOrder){
      this.emailSubject = `Attachment  - Damaged Return order ${this.returnOrderId}`;
    } else {
      this.emailSubject = `Return order`;
    }
    this.showEmailPopup = true;
  }

  onEmailPopupClose(){
      this.showEmailPopup = false;
      this.emailSubject = `Return order`;
  }


  close() {
    this.viewer.destroy();
    this.viewer.formFieldCollection = this.viewer.formFieldCollections = null; // to handle tab key jumping to next input
    Array.from(document.querySelectorAll(`[id^="pdfViewer"]`))
    .forEach((el: HTMLElement) => { el.remove(); });
    this.onClose.emit();
  }
}
