<div 
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="assignment-details-modal" 
    tabindex="-1" 
    aria-labelledby="assignment-details-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <app-loader *ngIf="isLoading"></app-loader>
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Credit Card Details</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="cancel()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3 custom-scrollbar">  
                <div class="row">
                    <div class="col">
                        <label>Service Order Id:</label>
                        <p>{{transactionData?.serviceOrderId}}</p>
                    </div>
                    <div class="col">
                        <label>Company:</label>
                        <p>{{transactionData.company}}</p>
                    </div>
                    <div class="col">
                        <label>Amount:</label>
                        <p>{{transactionData.amount}}</p>
                    </div>
                    <div class="col">
                        <label>Currency:</label>
                        <p>{{transactionData.currencyCode}}</p>
                    </div>
                </div>
                <hr>
                <h4>Customer Info:</h4>
                <div class="row">
                    <div class="col">
                        <div class="field mb-3"> 
                            <label>First Name:</label>
                            <ejs-textbox
                                [placeholder]="'Enter first name...'"
                                (ngModel)="transactionData.deliveryFirstName"
                            ></ejs-textbox>
                        </div>
                    </div>
                    <div class="col">
                        <div class="field mb-3"> 
                            <label>Last Name:</label>
                            <ejs-textbox
                                [placeholder]="'Enter last name...'"
                                (ngModel)="transactionData.deliveryLastName"
                            ></ejs-textbox>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="transactionData?.serviceOrderId">
                    <form ngNoForm method="post" action={{environment?.barclaysFormURL}} id=form1 name=form1>
                        <!--Mandatory, Hex value of the Request-->
                        <!-- check before the payment: see Security: Check before the payment -->
                        <input type="text" name="SHASIGN" id="SHASIGN" value={{transactionInfo.hash}}>

                        <!-- general parameters: see Form parameters -->
                        <!--Mandatory, Input values i.e. Text field-->
                        <input type="text" name="AMOUNT" id="AMOUNT" value={{transactionInfo.amount}}>
                        <input type="text" name="ORDERID" id="ORDERID" value={{transactionInfo.serviceOrderId}}>
                        <input type="text" name="CN" id="CN" value={{transactionInfo.customerName}}>

                        <!--Mandatory, Values from a configuration file i.e. web.config-->
                        <input type="text" name="CURRENCY" id="CURRENCY" value={{transactionInfo.currency}}>
                        <input type="text" name="LANGUAGE" id="LANGUAGE" value={{transactionInfo.language}}>
                        <input type="text" name="PSPID" id="PSPID" value={{transactionInfo.pspID}}>
                        
                        <!--Optional, Values from a configuration file i.e. web.config-->
                        <!--(Absolute) URL of your home page. When the transaction has been processed, your customer is requested to return to this URL via a button "Back to merchant site".When you send the value "NONE", the button leading back to the merchantâ€™s site will be text.-->
                        
                        <input type="text" name="HOMEURL" id="HOMEURL" value={{transactionInfo.homeURL}}>
                        <input type="text" name="ACCEPTURL" id="ACCEPTURL" value={{transactionInfo.nxtURL}}>
                        <input type="text" name="CANCELURL" id="CANCELURL" value={{transactionInfo.urlList}}>
                        <input type="text" name="DECLINEURL" id="DECLINEURL" value={{transactionInfo.urlList}}>
                        <input type="text" name="EXCEPTIONURL" id="EXCEPTIONURL" value={{transactionInfo.urlList}}>
                        
                        <!-- Optional, "RES" for Authorization OR "SAL" for SALES -->
                        <input type="text" name="OPERATION" id="OPERATION" value={{transactionInfo.operation}}>

                        <!-- Optional Additional feedback parameters -->
                        <input type="text" name="COMPLUS" id="COMPLUS" value={{transactionInfo.complus}}>
                        <input type="text" name="PARAMPLUS" id="PARAMPLUS" value={{transactionInfo.paramplus}}>

                        <!-- Optional, Only display specific payment methods i.e. Visa, MasterCard-->
                        <input type="text" name="PMLIST" id="PMLIST" value={{transactionInfo.pmList}}>

                        <!-- Optional, display layout default is 0:Horizontally grouped logos with the group name on the left (default value) -->
                        <input type="text" name="PMLISTTYPE" id="PMLISTTYPE" value={{transactionInfo.pmListType}}>

                        <!-- Optional, Mode of Payment i.e. Credit Card-->
                        <!-- <input type="text" name="PM" value=""> -->

                        <!-- Optional, Only display specific payment method i.e. Visa-->
                        <!-- <input type="text" name="BRAND" value=""> -->
                        
                        <!-- Optional, Only display specific payment methods i.e. Display "MasterCard", don't display one of its sub-brands i.e. "Maestro"-->
                        <!-- <input type="text" name="EXCLPMLIST" value=""> -->
                        <!-- Optional, Need to explore this option  -->
                        <!-- <input type="text" name="CREDITDEBIT" value="D"> -->

                        <!-- SNA Logo -->
                        <input type="text" name="LOGO" id="LOGO" value={{transactionInfo.logo}}>
                        <input type="text" name="TITLE" id="TITLE" value={{transactionInfo.title}}>
                        <input type="text" name="WIN3DS" id="WIN3DS" value={{transactionInfo.win3ds}}>
                        <input type="hidden" name="TP" value="">
                        <input (click)='onClick()' type="submit" value="Submit" id="submit2" name="submit">
                    </form> 
                </div>
            </div>
            <div class="modal-footer">
                <span class="e-badge e-badge-primary e-badge-pill float-left p-1">{{status}}</span>
                <span class="error text-danger">{{errorMessage}}</span>
                <button type="button" class="btn btn-sm btn-danger close-modal mr-1" (click)="cancel()">Cancel</button>
                <button type="button" class="btn btn-sm btn-primary mr-1" id="" (click)="pay()">Pay</button>
            </div>
        </div>
    </div>
</div>