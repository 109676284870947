import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class MaintenanceDashboardService {

    configurationChange: Subject<any> = new Subject();
    popupLoader: Subject<any> = new Subject();
    updateIsEmailSent: Subject<any> = new Subject();
    updateIsEmailSentForImportGrid: Subject<any> = new Subject();
    rimsSupportEmail: string = 'rims.support@inavista.com';

    constructor(
        private http: HttpClient
    ) { }

    getServicOrdersWithPublishErrors() {
        return this.http.get(`${BASE_URL}Dashboard/GetServiceOrdersWithPublishErrors`);
    }

    getExportPublisErrors() {
        return this.http.get(`${BASE_URL}Dashboard/GetExportPublishErrors`);
    }

    republishExportError(data) {
        return this.http.post(`${BASE_URL}Dashboard/RepublishOrdersWithExportErrors`, data);
    }

    updateSOWithPublishError(data: any) {
        return this.http.post(`${BASE_URL}Dashboard/UpdateOrdersWithImportErrors`, data);
    }

    UpdateEmailSentForOrderWithError(data: any) {
        return this.http.post(`${BASE_URL}Dashboard/UpdateEmailSentForOrderWithError`, data);
    }
}
