import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl; 

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  popupLoader: Subject<any> = new Subject();
  statusUpdated: Subject<any> = new Subject();

  claimTotalBill$: Subject<any> = new Subject();
  rulesValid: boolean;
  updateRules$: Subject<any> = new Subject();

  constructor(private http: HttpClient) { }

  getClaims() {
    return this.http.get(`${BASE_URL}Claims/GetClaimsList`);
  }

  createClaimFromSO(soId) {
    return this.http.post(`${BASE_URL}ISCClaims/CreateClaimFromSO?serviceOrderId=${soId}`, null);
  }

  getPendingApprovalClaims(status = 'Submitted') {
    return this.http.get(`${BASE_URL}Claims/GetPendingApprovalClaims?status=${status}`);
  }

  getClaimsStatuses() {
    return this.http.get(`${BASE_URL}Claims/GetClaimStatuses`);
  }

  updateClaimStatus(params) {
    if(params.rejectionReason) {
      return this.http.post(`${BASE_URL}ISCClaims/UpdateClaimStatus?claimId=${params.claimId}&status=${params.status}&rejectionReason=${params.rejectionReason}`, params);
    } else {
      return this.http.post(`${BASE_URL}ISCClaims/UpdateClaimStatus?claimId=${params.claimId}&status=${params.status}`, params);
    }
  }

  upsertClaim(params) {
    return this.http.post(`${BASE_URL}ISCClaims/UpsertClaim`, params);
  }

  getClaimPopupDetails(serviceOrderId: string){
    return this.http.get(`${BASE_URL}Claims/GetClaimPopupDetails?serviceOrderId=${serviceOrderId}`);
  }
  
  getClaimItemsByServiceOrderId(serviceOrderId){
    return this.http.get(`${BASE_URL}Claims/GetServiceOrderTransactionsForClaimItems?serviceOrderId=${serviceOrderId}`);
  }

  getClaimItemsByClaimId(claimId) {
    return this.http.get(`${BASE_URL}api/GetClaimItemsByClaimId?claimId=${claimId}`);
  }

  getServiceOrdersForClaims(){
    return this.http.get(`${BASE_URL}ServiceOrder/GetServiceOrdersForClaims`);
  }

  getIscServiceOrdersForClaims(){
    return this.http.get(`${BASE_URL}ISCServiceOrder/GetISCServiceOrdersForClaims`);
  }

  getClaimByClaimId(claimId: string){
    return this.http.get(`${BASE_URL}ISCClaims/GetISCClaimById?claimId=${claimId}`);
  }

  getISCClaimByClaimId(claimId: string){
    return this.http.get(`${BASE_URL}ISCClaims/GetISCClaimById?claimId=${claimId}`);
  }

  getClaimDetailByServiceOrderId(soId: string){
    return this.http.get(`${BASE_URL}ISCClaims/GetClaimDetailByServiceOrderId?serviceOrderId=${soId}`);
  }

  saveClaim(body, url){
    return this.http.post(`${BASE_URL}${url}`, body);
  }

  checkClaimRulesByData(claimData) {
    return this.http.post(`${BASE_URL}Claims/CheckClaimRulesByData`, claimData);
  }

  // updateClaimStatusToInProgress(param) {
  //   return this.http.post(`${BASE_URL}ISCClaims/UpdateClaimStatus?claimId=${param.claimId}&status=${param.status}`, {});
  // }

  getReasonsForDDL(company: string, reasonType: string) {
    return this.http.get(`${BASE_URL}Reason/GetReasonsForDDL?company=${company}&reasonType=${reasonType}`);
  }

  getReviewersList() {
      return this.http.get(`${BASE_URL}ISCClaims/GetReviewersList`);
  }

  getISCPaymentMethods() {
      return this.http.get(`${BASE_URL}ISCClaims/GetISCPaymentMethods`);
  }

  getReferenceActivitiesByServiceOrderId(soId) {
    return this.http.get(`${BASE_URL}ISCServiceOrder/GetRefActivitiesOfISCSOForDDL?serviceOrderId=${soId}`)
  }  
}
