import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;
const IMAGE_URL = environment.imageUrl;
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  @Input() notes: string = null;
  @Input() showRte: boolean;
  @Input() label: string;

  private insertImageSettings: Object = {
    saveUrl: BASE_URL +'PdfViewer/SaveFile',
    removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
    path: IMAGE_URL,
  };

  @Output() notesChanged = new EventEmitter();
  @ViewChild('notesRte') notesRte;
  constructor() { }

  ngOnInit(): void {
  }

  blur(args) {
      this.notesRte.destroy();
      this.showRte = false;    
  }

  ngOnDestroy() {
    this.notes = null;
  }
}
