import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ApiResponse, gridNames, LinksEnum, paymentMethodType, UserRoles } from 'src/app/shared/models/app.model';
import { IscService } from '../../../services/isc.service';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { PurchaseOrderModel, PurchaseOrderStatus } from 'src/app/shared/models/purchaseOrder.model';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { InventoryService } from '../../../services/inventory.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-checkout-popup',
  templateUrl: './cart-checkout-popup.component.html',
  styleUrls: ['./cart-checkout-popup.component.scss']
})
export class CartCheckoutPopupComponent implements OnInit {

  purchaseOrderData: PurchaseOrderModel;

  isExpanded: boolean = true;
  @Output() onClose = new EventEmitter();
  @Output() onLinkIdClick = new EventEmitter();
  @Input() tab: string = 'details';
  @Input() box;
  @Input() showComments: boolean = false;
  /**Input from service order */
  @Input() serviceOrderId: string;
  @ViewChild('address') address: DropDownList;
  @ViewChild('shipmentMethodDDLRef') shipmentMethodDDL: DropDownList;


  @Input() recievedItems: any = [];
  @Input() singleRecievedItem: any;
  onsiteAddress: any;
  purchaseOrderId: any;
  taxRatePercentage: any;
  taxMessage;
  isIscSupervisor: boolean = false;

  constructor(private commonService: CommonService,
    private iscService: IscService,
    private inventoryService: InventoryService,
    private cdr: ChangeDetectorRef,
    private serviceOrderService: ServiceOrderService,
    private router: Router) { }

  columns;
  orderedGridcolumns;
  trackingUpdatesColumns;
  data: any;
  showLoader: boolean = false;
  gridHeight: number = window.innerHeight - 1600;
  trackingGridHeight: number = window.innerHeight - 1600;
  singleRowGridHeight: number = window.innerHeight - 1600;
  commentCount = 0;
  attachmentCount = 0;

  gridName = gridNames.POMultipleItemsCheckoutGrid;

  orderedItemsGridName = gridNames.orderedItemsGrid;
  trackingUpdateGridName = gridNames.trackingUpdatesGrid;
  mainGrid: any;
  allowRowEdit: boolean = false;

  showChasePaymentIframe: boolean = false;
  disablePaymentButton: boolean = true;

  userDetails;
  technicianAddresses: any;
  toSendItems = [];
  shipmentMethodsList: any = [];
  ddl2: any = [];
  showCartCheckoutPopup: boolean = false;
  multipleItemsDataSource: any = [];
  singleRowData: any = [];
  singleFlag: boolean = false;
  dataArray: any = [];
  poById: any;

  isIsc: boolean;
  isIscManager: boolean;
  isSM: boolean;
  isCSA: boolean;

  poRefFlag: boolean = false;

  totalNetPrice: any = 0;
  shippingCost: number = 0;
  tax: number = 0;
  shipmentDDLFlag: boolean = false;
  public paymentOptionsDDL = [
    {
      text: 'Credit Card',
      value: 'Credit Card'
    },
    {
      text: 'On Account',
      value: 'On Account'
    }
  ];
  @ViewChild('columnChooser') columnChooser;
  @ViewChild('addressesDropdown') addressesDropdown: DropDownList;
  public checkType: string = 'onSiteAdress';
  grid: GridComponent;
  orderedItemGrid: GridComponent;
  trackingGrid: GridComponent;

  @ViewChild('POCheckoutGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.grid = gridComponent;
    }
  }

  @ViewChild('OrderedItemsGrid') set orderedGridComponent(orderedGridComponent: GridComponent) {
    if (orderedGridComponent) {
      this.orderedItemGrid = orderedGridComponent;
    }
  }

  @ViewChild('TrackingUpdatesGrid') set trackingGridComponent(trackingGridComponent: GridComponent) {
    if (trackingGridComponent) {
      this.trackingGrid = trackingGridComponent;
    }
  }
  query = new Query().where('isDeleted', 'equal', false);
  toArray: any;
  showGrid: boolean = false;
  trackingTabDataSource: any;
  orderedTabDataSource: any
  links: any;
  popupTitle: string;
  onsiteFlag: boolean = false;
  deleteDisableFlag: boolean = false;
  historyData: any = []
  getPriceData: any;

  orderedTabItemStatus: any
  trackingTabItemStatus: any
  noOrderFlag: boolean = false;
  noTaxFlag: boolean = false;
  currency;
  shipmentargs: any;
  showCustomerAdvancedSearchPopup: boolean = false;


  ngOnInit(): void {
    this.showLoader = true;
    this.userDetails = this.commonService.USER;
    this.isIsc = this.commonService.USER.role === UserRoles.isc;
    this.isIscManager = this.commonService.USER.role === UserRoles.iscManager;
    this.isIscSupervisor = this.commonService.USER.role === UserRoles.IscSupervisor;
    this.isSM = this.commonService.USER.role === UserRoles.conwayServiceManager;
    this.isCSA = this.commonService.USER.role === UserRoles.csa;
    this.popupTitle = this.recievedItems[0]?.partsOrderId || 'Order (Checkout)';

    this.iscService.getCurrency(this.userDetails?.iscCode).subscribe((res: any) => {
      this.currency = res?.currency;
    })

    if (this.recievedItems.length === 1) {
      this.deleteDisableFlag = true;
    }
    this.setPurchaseOrderData();
    this.iscService.getTax(this.userDetails.employeeId).subscribe((res: ApiResponse) => {
      if (res?.isSuccessful) {
        this.taxRatePercentage = res?.result?.taxRatePercentage;
      }
      else {
        this.noTaxFlag = true;
        this.taxMessage = res.message;
        this.taxRatePercentage = 0.00;
      }

      if (this.recievedItems[0]?.comingFrom) {                //Case 1: coming from buy now
        this.fromBuyNow();
      } else if (this.recievedItems[0]?.assignmentId) {       //Case 2: coming from service order
        this.fromServiceOrder();
      } else if (this.recievedItems[0]?.partsOrderId) {       //Case 3: already created partsOrder id
        this.fromCreatedPurchaseOrder();    
      } else {                                                //Case 4: creation of purchase order
        this.creationMode();
      }

    })

    this.columns = this.getColumns();
    this.orderedGridcolumns = this.getOrderedItemGridColumns();
    this.trackingUpdatesColumns = this.getTrackingUpdatesColums();
  }

  onCustomerAdvancedSearchPopupClose(address: any) {
    this.showCustomerAdvancedSearchPopup = false;
    if (address) {
      this.checkType = 'userAddress';
      (this.addressesDropdown.dataSource as any).push(address);
      this.addressesDropdown.refresh();
      this.addressesDropdown.value = address.addressCode;
    }
  }

  fromBuyNow() {
    // "Case 1 : Buy now";
    this.purchaseOrderData['paymentMethod'] = paymentMethodType.onAccount;
    if (this.noTaxFlag) {
      this.commonService.showNotification('error', this.taxMessage);
    }
    const item = this.recievedItems[0]?.item;
    const quantity = this.recievedItems[0]?.orderQty;
    this.iscService.refreshPrice(item, quantity).subscribe((res: ApiResponse) => {

      const buyNowDiscount = res?.result?.result?.discountAmount?.discountAmountValue;
      const buyNowPrice = res?.result?.result?.bookPrice?.bookPriceValue;
      const buyNowGrossAmount = res?.result?.result?.grossAmount?.grossAmountValue;
      const buyNowNetAmount = res?.result?.result?.netAmount?.netAmountValue;
      const buyNowQuantity = res?.result?.result?.orderedQuantity?.orderedQuantityValue;

      this.recievedItems[0].quantity = buyNowQuantity;
      this.recievedItems[0].itemPrice = buyNowPrice;
      this.recievedItems[0].grossAmount = buyNowGrossAmount;
      this.recievedItems[0].itemDiscount = buyNowDiscount;
      this.recievedItems[0].netPrice = buyNowNetAmount;

      this.recievedItems[0].lineNumber = 1;
      this.totalNetPrice = this.recievedItems[0].netPrice;
      this.tax = this.totalNetPrice * (this.taxRatePercentage / 100);
      setTimeout(() => {
        this.getShipmentDDL();
        this.getTechnicianAddress();
        this.shipmentMethodDDL.value = this.purchaseOrderData?.shipmentMethodCode;
        this.purchaseOrderData.shipmentMethodDescription = this.shipmentMethodDDL?.text;
      }, 1000);
      this.showLoader = false;
      this.showGrid = true;
    })
  }

  fromServiceOrder() {
    // "Case 2 : from service order >> ", this.recievedItems[0]
    this.purchaseOrderData['paymentMethod'] = paymentMethodType.onAccount;
    if (this.noTaxFlag) {
      this.commonService.showNotification('error', this.taxMessage);
    }
    const data = {
      company: this.userDetails?.company,
      item: this.recievedItems[0]?.itemCode,
      unit: this.recievedItems[0]?.unit,
      quantity: this.recievedItems[0]?.qty,
      configuration: this.userDetails?.iscCode
    };
    this.getTechnicianAddress();
    const apis = [
      this.serviceOrderService.getPrice(data),
    ]
    this.serviceOrderId && apis.push(this.inventoryService.getIscOnsiteAddress(this.serviceOrderId))
    forkJoin(apis).subscribe((res: any) => {
      if (res[0]?.isSuccessful) {
        this.getPriceData = res[0].result
        this.recievedItems[0].item = this.recievedItems[0]?.itemCode;
        this.recievedItems[0].quantity = this.getPriceData?.orderedQuantity?.orderedQuantityValue;
        this.recievedItems[0].itemDiscount = this.getPriceData?.discountAmount?.discountAmountValue;
        this.recievedItems[0].grossAmount = this.getPriceData?.grossAmount?.grossAmountValue;
        this.recievedItems[0].itemPrice = this.getPriceData?.bookPrice?.bookPriceValue;
        this.recievedItems[0].netPrice = this.getPriceData?.netAmount?.netAmountValue;
        this.totalNetPrice = this.recievedItems[0].netPrice;
        this.tax = this.totalNetPrice * (this.taxRatePercentage / 100);
      }
      if (this.serviceOrderId && res[1]?.isSuccessful) {
        this.onsiteAddress = res[1].result;
      }
      this.recievedItems = this.recievedItems?.filter((item: any, index) => {
        item.lineNumber = index + 1;
        item.itemTotal = item.netPrice * item.quantity;
        if (item.lineNumber === this.recievedItems.length) {
          this.tax = this.totalNetPrice * (this.taxRatePercentage / 100)
        }
        return !item.isDeleted;
      });
      setTimeout(() => {
        const address = this.checkType === 'onSiteAdress' ? this.onsiteAddress : this.technicianAddresses[0];
        this.onsiteFlag = this.checkType === 'onSiteAdress';
        this.setAddress(address);
        this.getShipmentDDL();
      }, 1000);
      this.showLoader = false;
      this.showGrid = true;
    })
  }

  fromCreatedPurchaseOrder() {
    this.purchaseOrderId = this.recievedItems[0]?.partsOrderId;
    this.getPurchaseOrderById();
    this.showGrid = true;
  }

  creationMode() {
    // "Case 4 : creation mode >> ", this.recievedItems
    this.purchaseOrderData['paymentMethod'] = paymentMethodType.onAccount;
    if (this.noTaxFlag) {
      this.commonService.showNotification('error', this.taxMessage);
    }
    this.recievedItems = this.recievedItems?.filter((item: any, index) => {
      return !item.isDeleted;
    });

    this.recievedItems.forEach((item, index) => {
      item.lineNumber = index + 1;
      item.itemTotal = item.netPrice * item.quantity;
      this.totalNetPrice += item.netPrice;
      if (item.lineNumber === this.recievedItems.length) {
        this.tax = this.totalNetPrice * (this.taxRatePercentage / 100)
      }
    });
    setTimeout(() => {
      this.getShipmentDDL();
      this.getTechnicianAddress();
      this.shipmentMethodDDL.value = this.purchaseOrderData?.shipmentMethodCode;
      this.purchaseOrderData.shipmentMethodDescription = this.shipmentMethodDDL?.text;
    }, 1000);
    this.showLoader = false;
    this.showGrid = true;
  }

  getPurchaseOrderById() {
    this.showLoader = true;
    this.poRefFlag = true;
    forkJoin([
      this.iscService.getPurchaseOrderById(this.purchaseOrderId),
      this.commonService.getLinkedRecords(LinksEnum.purchaseOrder, this.purchaseOrderId)
    ]).subscribe((res: any) => {
      if (res[0].isSuccessful) {
        this.poById = res[0].result;
        this.iscService.getCurrency(this.poById?.soldToBP).subscribe((res: any) => {
          this.currency = res?.currency;
        })
        this.historyData = res[0]?.result?.history;
        this.links = res[1]?.result;
        this.orderedTabDataSource = res[0]?.result?.itemList;

        this.orderedTabDataSource.forEach((el: any) => {
          this.orderedTabItemStatus = el.status;
        });

        this.trackingTabDataSource = res[0]?.result?.trackingList
        this.trackingTabDataSource.forEach((el: any) => {
          this.trackingTabItemStatus = el.status;
        });

        this.purchaseOrderData = res[0]?.result;
        this.recievedItems = res[0]?.result?.itemList
        this.totalNetPrice = this.purchaseOrderData?.amount;
        this.tax = this.purchaseOrderData?.tax;
        this.commentCount = this.purchaseOrderData?.comments?.length || 0;
        this.attachmentCount = this.data?.attachments?.length || 0;

        this.shipmentMethodDDL.dataSource = [{
          text: this.purchaseOrderData.shipmentMethodDescription,
          value: this.purchaseOrderData?.shipmentMethodCode
        }]

        this.showLoader = false;
      } else {
        this.commonService.showNotification('error', res[0].message);
        this.showLoader = false;
      }
    }, error => {
      this.commonService.showNotification('error', error.message);
      this.showLoader = false;
    })
  }

  getShipmentDDL() {
    this.iscService.getShippingMethodDDL(this.totalNetPrice).subscribe((res: ApiResponse) => {
      if (res.isSuccessful) {
        this.shipmentDDLFlag = true;
        this.shipmentMethodsList = res?.result;
        if (this.purchaseOrderData?.partsOrderId) {
          setTimeout(() => {
            this.shipmentMethodDDL.value = this.purchaseOrderData?.shipmentMethodCode;
            this.purchaseOrderData.shipmentMethodDescription = this.shipmentMethodDDL?.text;
          }, 1000);
        }
      }
      else {
        this.commonService.showNotification('warning', res.message + ' for shipment method')
        this.shipmentMethodsList = [];
        this.showLoader = false;
      }
    })
  }

  getShipmentCost(shipmentMethod) {
    if (shipmentMethod) {
      this.iscService.getShippingCost(shipmentMethod.itemData.value, shipmentMethod.itemData.description, this.totalNetPrice).subscribe((res: any) => {
        this.shippingCost = res?.result?.shippingCost
        this.purchaseOrderData['shippingCost'] = this.shippingCost;
        this.cdr.detectChanges()
      })
    }
  }

  setGrid() {
    this.mainGrid = {
      toolbar: []
    };
  }

  reload() {
    this.getPurchaseOrderById();
  }

  setAddress(addr) {
    const { address, name: addressName, addressCode, city, state, stateProvince, zipCode, country } = addr;

    this.purchaseOrderData = {
        ...this.purchaseOrderData,
        address,
        addressName,
        addressCode,
        city,
        state: state ?? stateProvince,
        zipCode,
        country,
        telephone: this.onsiteAddress?.telephone, // Preserve the logic using onsiteAddress for the telephone field
    };
  }

  //-------------------------------------- columns data --------------------------------

  getColumns() {
    let columns = [
      // { field: "", type: 'checkbox', headerText: "", allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, width: 55, showInColumnChooser: false },
      { field: 'item', headerText: 'Item.', showInColumnChooser: false },
      { field: 'itemDescription', headerText: 'Description', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'quantity', headerText: 'Order Qty', type: 'number', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'itemPrice', headerText: 'Price (USD)', type: 'number', allowEditing: false, format: 'n2', textAlign: 'Left', width: 120, visible: true },
      { field: 'grossAmount', headerText: 'Gross Amount (USD)', type: 'number', allowEditing: false, format: 'n2', textAlign: 'Left', width: 120, visible: true },
      { field: 'itemDiscount', headerText: 'Discount (USD)', type: 'number', format: 'n2', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'netPrice', headerText: 'Net Amount (USD)', type: 'number', allowEditing: false, format: 'n2', textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 230, allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
        commands: [
          { title: 'Delete Item', buttonOption: { iconCss: 'fas fa-trash', cssClass: 'e-flat del-disable' }, }
        ]
      }
    ];
    return columns;
  }

  getOrderedItemGridColumns() {
    let orderedGridcolumns = [
      // { field: "", type: 'checkbox', headerText: "", allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, width: 55, showInColumnChooser: false },
      { field: 'item', headerText: 'Item.', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      { field: 'itemDescription', headerText: 'Description', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'quantity', headerText: 'Quantity', type: 'String', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'isDirectDelivery', headerText: 'Direct Delivery', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'orderDateLN', headerText: 'LN Order Date', type: 'datetime', format: { type: 'datetime', format: 'd MMM, y' }, allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'status', headerText: 'Status', type: 'String', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true }, {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 230, allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
        commands: [
          { title: 'Cancel', buttonOption: { iconCss: `fas fa-times-circle`, cssClass: `e-flat cancel-button` }, }
        ]
      }
    ];
    return orderedGridcolumns;
  }

  getTrackingUpdatesColums() {
    let trackingUpdatesColumns: any = [
      // { field: "", type: 'checkbox', headerText: "", allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, width: 55, showInColumnChooser: false },
      { field: 'item', headerText: 'Item.', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      { field: 'description', headerText: 'Description', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'shipQty', headerText: 'Quantity', type: 'String', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'status', headerText: 'Status', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'shipmentIdLN', headerText: 'LN Shipment Id', type: 'String', allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'shippedDateLN', headerText: 'LN Shipment Date', type: 'datetime', format: { type: 'datetime', format: 'd MMM, y' }, allowEditing: false, textAlign: 'Left', filter: { type: 'Menu' }, visible: true },
      { field: 'trackingId', headerText: 'Tracking Id', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
    ];
    if (this.isSM || this.isCSA) {
      trackingUpdatesColumns.push(
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 230, allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
          commands: [
            { title: 'Cancel', buttonOption: { iconCss: `fas fa-times-circle`, cssClass: `e-flat cancel-button` } }
          ]
        }
      );
    }
    return trackingUpdatesColumns;
  }

  // -------------------------------------- command clicks --------------------------------

  onCommandClick(args) {
    if (args.commandColumn.title === 'Delete Item') {
      this.showLoader = true;
      this.commonService.showConfirmation('Are you sure to delete this item?')
        .then(result => {
          if (result.isConfirmed) {
            args.rowData.isDeleted = true;
            args.rowData.isSelected = false;
            this.iscService.deleteItemFromCart(args.rowData).subscribe((res: ApiResponse) => {
              this.iscService.getUserCartItem().subscribe((res: ApiResponse) => {
                this.totalNetPrice = this.tax = 0;
                this.purchaseOrderData.itemList = res.result;
                this.purchaseOrderData.itemList.forEach((item: any, index) => {
                  item.lineNumber = index + 1;
                  item.itemTotal = item.netPrice * item.quantity;
                  this.totalNetPrice += item.netPrice * item.quantity;
                  if (item.lineNumber === this.purchaseOrderData.itemList.length) {
                    this.tax = this.totalNetPrice * (this.taxRatePercentage / 100);
                    this.shipmentMethodDDL.dataSource = [];
                    this.getShipmentDDL();
                    this.getShipmentCost(this.shipmentargs);
                  }
                })
                if (!this.purchaseOrderData.itemList?.length) {
                  this.noOrderFlag = true;
                  this.purchaseOrderData.shippingCost = 0;
                }
                this.cdr.detectChanges();
                this.showLoader = false;
              })
            })
            this.grid.refresh();
          }
          else {
            this.showLoader = false;
          }
        });
    }
  }

  onCommandColumnClickOrderedItems(args) {
    if (args.commandColumn.title === 'Cancel') {
      this.commonService.showConfirmation('Are you sure? Cancelling the order will cancel all the item lines and cannot be reverted!')
        .then(result => {
          this.showLoader = true;
          if (result.isConfirmed) {
            if (this.isSM || this.isCSA || this.isIscManager || this.isIscSupervisor) {
              args.rowData.status = 'Canceled';
              this.iscService.updatePurchaseOrder(this.purchaseOrderData).subscribe((res: ApiResponse) => {
                this.getPurchaseOrderById();
                this.orderedItemGrid.refresh();
                this.showLoader = false;
              })
            }
          }
          else {
            this.showLoader = false;
          }
        });
    }
  }

  //-------------------------------------- rowData bounds --------------------------------

  onRowDataBound(args) {
    const $row = args.row;
    if (this.deleteDisableFlag === true) {
      $row.getElementsByClassName('del-disable')[0]?.classList.add('disabled');
    }

    if (this.purchaseOrderData?.paymentStatus === 'Paid') {
      $row.getElementsByClassName('del-disable')[0]?.classList.add('disabled');
    }

    if (this.noOrderFlag === true) {
      $row.getElementsByClassName('del-disable')[0]?.classList.add('disabled');
    }
  }

  onRowDataBoundOrderedItems(args) {
    const $row = args.row;
    const status = args.data.status;

    if (status === 'Shipped') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
    }

    if ((this.isIsc || this.isIscManager || this.isIscSupervisor) && status === 'In Progress') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
    }

    if (status === 'Canceled') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('color-red');
    }
  }

  onRowDataBoundTracking(args) {
    const $row = args.row;
    const status = args.data.status;

    if (status === 'In Progress' || status === 'Shipped') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
    }

    if ((this.isIsc || this.isIscManager || this.isIscSupervisor) && status === 'In Progress') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
    }

    if (status === 'Canceled') {
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('disabled');
      $row.getElementsByClassName('cancel-button')[0]?.classList.add('color-red');
    }
  }

  onPaymentMethodChange(args) {
    this.purchaseOrderData.paymentMethod = args.value;
  }

  onAddressChange(args) {
    if (args?.itemData) {
      this.purchaseOrderData['address'] = args.itemData.address;
      this.purchaseOrderData['addressName'] = args.itemData.name;
      this.purchaseOrderData['addressCode'] = args.itemData.addressCode;
      this.purchaseOrderData['city'] = args.itemData.city;
      this.purchaseOrderData['state'] = args.itemData.stateProvince;
      this.purchaseOrderData['zipCode'] = args.itemData.zipCode;
      this.purchaseOrderData['country'] = args.itemData.country;
      this.purchaseOrderData['email'] = args.itemData.eMail;
      this.purchaseOrderData['telephone'] = this.onsiteAddress?.telephone; // why onsiteAddress???
    }
  }

  onShipmentMethodChange(args) {
    if(!this.purchaseOrderData?.partsOrderId){
      this.shipmentargs = args;
      this.getShipmentCost(args);
      this.purchaseOrderData['shipmentMethodCode'] = args.value;
      this.purchaseOrderData['shipmentMethodDescription'] = args.text;
      this.purchaseOrderData['amount'] = this.totalNetPrice;
      this.purchaseOrderData['tax'] = this.tax;
    }
  }

  getTechnicianAddress() {
    this.iscService.getTechnicianAddress(this.purchaseOrderData?.partsOrderId ? this.purchaseOrderData?.technicianId : this.userDetails?.employeeId)
      .subscribe((res: any) => {
        this.technicianAddresses = res?.result;
        let address;
        if (this.purchaseOrderData.partsOrderId) {
          address = this.technicianAddresses?.find(x => x.addressCode === this.purchaseOrderData.addressCode);
        } else {
          address = this.technicianAddresses[0];
        }
        if(address){
          this.purchaseOrderData.address = address?.address;
          this.purchaseOrderData.addressName = address?.name;
          this.purchaseOrderData.addressCode = address?.addressCode;
          this.purchaseOrderData.city = address?.city;
          this.purchaseOrderData.state = address?.stateProvince;
          this.purchaseOrderData.zipCode = address?.zipCode;
          this.purchaseOrderData.country = address?.country;
          this.purchaseOrderData.email = address?.eMail;
          this.purchaseOrderData.telephone = address?.telephone;
          this.showLoader = false;
        } else {
          this.getOnsiteAddress();
        }
      })
  }

  getOnsiteAddress() {
    this.inventoryService.getIscOnsiteAddress(this.purchaseOrderData.serviceOrderId ? this.purchaseOrderData.serviceOrderId : this.serviceOrderId)
      .subscribe((res: any) => {
        this.onsiteAddress = res?.result;
        this.addressesDropdown.dataSource = [res.result];
        this.addressesDropdown.value = this.onsiteAddress?.addressCode;

        this.purchaseOrderData.address = this.onsiteAddress?.address;
        this.purchaseOrderData.addressName = this.onsiteAddress?.name;
        this.purchaseOrderData.addressCode = this.onsiteAddress?.addressCode;
        this.purchaseOrderData.city = this.onsiteAddress?.city;
        this.purchaseOrderData.state = this.onsiteAddress?.stateProvince;
        this.purchaseOrderData.zipCode = this.onsiteAddress?.zipCode;
        this.purchaseOrderData.country = this.onsiteAddress?.country;
        this.purchaseOrderData.email = this.onsiteAddress?.eMail;
        this.purchaseOrderData.telephone = this.onsiteAddress?.telephone;
        this.showLoader = false;
      })
  }


  //------------------------------------- payment handling -------------------------------------

  ccPaymentCompleted(eventArgs) {
    this.showLoader = true;
    this.showChasePaymentIframe = false;
    this.cdr.detectChanges();
    this.showLoader = false;
    this.reload();
  }

  ccPaymentAuthcode(eventArgs) {
    this.disablePaymentButton = true;
  }

  ccPaymentCancel(eventArgs) {
    this.showChasePaymentIframe = false;
    this.showCartCheckoutPopup = false;
    this.onClose.emit();
    this.cdr.detectChanges();
    this.showLoader = false;
  }

  radioButtonChanged(args) {
    if (args.value === 'onSiteAdress') {            //option 1
      this.onsiteFlag = true;
      this.purchaseOrderData.address = this.onsiteAddress.address;
      this.purchaseOrderData.addressName = this.onsiteAddress.name;
      this.purchaseOrderData.addressCode = this.onsiteAddress.addressCode;
      this.purchaseOrderData.city = this.onsiteAddress.city;
      this.purchaseOrderData.state = this.onsiteAddress.stateProvince;
      this.purchaseOrderData.zipCode = this.onsiteAddress.zipCode;
      this.purchaseOrderData.country = this.onsiteAddress.country;
      this.purchaseOrderData.telephone = this.onsiteAddress.telephone;
    } else {
      this.onsiteFlag = false;                        //option 2
      const address = this.technicianAddresses[0];
      this.purchaseOrderData.address = address.address;
      this.purchaseOrderData.addressName = address.name;
      this.purchaseOrderData.addressCode = address.addressCode;
      this.purchaseOrderData.city = address.city;
      this.purchaseOrderData.state = address.stateProvince;
      this.purchaseOrderData.zipCode = address.zipCode;
      this.purchaseOrderData.country = address.country;
      this.purchaseOrderData.telephone = this.onsiteAddress.telephone;
    }
  }

  setPurchaseOrderData() {
    if (!this.purchaseOrderData) {
      this.purchaseOrderData = {
        partsOrderId: '',
        status: "Open",
        parentStatus: "Open",
        company: this.userDetails.company,
        technicianId: this.userDetails.employeeId,
        technicianName: this.userDetails.employeeName,
        warehouse: this.userDetails.warehouse,
        orderCurrency: this.userDetails.orderCurrency,
        createdBy: this.userDetails.employeeId,
        createdByName: this.userDetails.employeeName,
        lastUpdatedBy: this.userDetails.employeeName,
        lastUpdatedByName: this.userDetails.employeeName,
        serviceOrderId: "",
        priority: null,
        itemList: this.recievedItems,
        // createdDate: new Date(),
        estimatedDeliveryDate: null,
        warehouseOrderLN: null,
        addressType: "technician", //for now technician, needs to apply on change
        // phoneNumber: this.userDetails.telephone,
        notes: "",
        // addressCode: this.technicianAddresses.addressCode,
        // addressName: this.technicianAddresses.name,
        // address: this.technicianAddresses.address,
        // city: this.technicianAddresses.city,
        // state: this.technicianAddresses.state,
        // zipCode: this.technicianAddresses.zipcode,
        // country: this.technicianAddresses.country,
        // orderSubmissionDate: "2023-03-26T10:02:45.215Z",
        paymentStatus: 'Unpaid',
        shippingCost: this.shippingCost,
        tax: this.tax,
        amount: this.shippingCost + this.totalNetPrice,
        // cardType: "",
        // bankName: "",
        // checkAuthCode: "",
        comments: [],
        attachments: []
      };
    }
  }

  validate() {
    return this.purchaseOrderData?.shipmentMethodCode;
  }

  onAttachmentsUpdate(data: any) {
    this.data.attachments = data;
    this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
  }

  onCommentUpdate(data: any) {
    this.commentCount = data.filter(comment => !comment.isDeleted).length;
  }

  submitOnly() {
    if (this.purchaseOrderData.partsOrderId) {
      this.showLoader = true;
      this.purchaseOrderData.paymentStatus = "Paid";
      this.purchaseOrderData.status = "Submitted";
      this.purchaseOrderData['totalAmount'] = this.purchaseOrderData.amount + this.shippingCost + this.tax;
      if (this.serviceOrderId) {
        this.purchaseOrderData['serviceOrderId'] = this.serviceOrderId;
      }
      const items = this.purchaseOrderData.itemList;
      items.forEach(el => {
        el.status = 'Submitted'
      });
      this.iscService.updatePurchaseOrder(this.purchaseOrderData).subscribe((res: ApiResponse) => {
        if (res.isSuccessful) {
          this.getPurchaseOrderById();
          this.orderedItemGrid.refresh();
          this.commonService.showNotification('success', 'Purchase order "' + res.result.partsOrderId + '" updated!');
          this.showLoader = false;
        }
        else {
          this.commonService.showNotification('warning', 'Order not updated!');
        }
      })
    }
    else if (this.validate() && this.purchaseOrderData.itemList.length) {
      this.commonService.showConfirmation('Do you want to create order?')
        .then(result => {
          if (result.isConfirmed) {
            this.showLoader = true;
            this.purchaseOrderData.paymentStatus = "Paid";
            this.purchaseOrderData.status = "Submitted";
            this.purchaseOrderData['totalAmount'] = this.purchaseOrderData.amount + this.shippingCost + this.tax;
            if (this.serviceOrderId) {
              this.purchaseOrderData['serviceOrderId'] = this.serviceOrderId;
            }
            this.iscService.createIscPurchaseOrder(this.purchaseOrderData).subscribe((res: ApiResponse) => {
              if (res.isSuccessful) {
                this.commonService.showNotification('success', 'Purchase order "' + res.result.partsOrderId + '" created!');
                this.showLoader = false;
                this.onClose.emit();
              }
              else {
                this.commonService.showNotification('warning', 'Purchase Order is not created!');
                this.showLoader = false;
                this.onClose.emit();
              }
            })
          }
          else {
            this.commonService.showNotification('warning', 'Due to some error Purchase Order is not created!');
          }
        });
    }
    else {
      this.commonService.showNotification('error', 'Please fill the required fields');
    }
    
  }

  payAndSubmit() {
    if (this.validate() && this.purchaseOrderData.itemList.length) {
      if (this.purchaseOrderData.partsOrderId && this.purchaseOrderData.partsOrderId !== null) {
        this.purchaseOrderId = this.purchaseOrderData.partsOrderId
        this.data = {
          invoiceAmount: this.purchaseOrderData.amount + this.shippingCost + this.tax,
          customerCurrency: this.purchaseOrderData.orderCurrency,
          customerEmail: this.userDetails.email,
          address: this.purchaseOrderData.address,
          city: this.purchaseOrderData.city,
          state: this.purchaseOrderData.state,
          zipcode: this.purchaseOrderData.zipCode,
          customerPhoneNumber: this.purchaseOrderData.phoneNumber,
        }
        this.showChasePaymentIframe = true;
      }
      else {
        this.commonService.showConfirmation('Do you want to create order?').then(result => {
          if (result.isConfirmed) {
            this.showLoader = true;
            this.purchaseOrderData.status = "Payment Pending";
            if (this.serviceOrderId) {
              this.purchaseOrderData['serviceOrderId'] = this.serviceOrderId;
            }
            this.iscService.createIscPurchaseOrder(this.purchaseOrderData
            ).subscribe((res: ApiResponse) => {
              this.purchaseOrderId = res.result.partsOrderId
              this.data = {
                invoiceAmount: this.purchaseOrderData.amount + this.shippingCost + this.tax,
                customerCurrency: this.purchaseOrderData.orderCurrency,
                customerEmail: this.userDetails.email,
                address: this.purchaseOrderData.address,
                city: this.purchaseOrderData.city,
                state: this.purchaseOrderData.state,
                zipcode: this.purchaseOrderData.zipCode,
                customerPhoneNumber: this.purchaseOrderData.phoneNumber,
              }
              if (res.isSuccessful) {
                this.commonService.showNotification('success', 'Purchase order "' + res.result.partsOrderId + '" created!');
                this.showChasePaymentIframe = true;
              }
              else {
                this.commonService.showNotification('warning', 'Order not created');
              }
            })
            this.showLoader = false;
          }
          else {
            return;
          }
        });
      }
    }
    else {
      this.commonService.showNotification('error', 'Please fill all the fields');
    }
  }

  close() {
    if ([PurchaseOrderStatus.shipped, PurchaseOrderStatus.submitted].includes(this.purchaseOrderData.status)) {
      this.grid.refresh();
      this.onClose.emit();
      this.showLoader = false;
    } else {
      let confirmationMessage = 'Are you sure?\n This order details will be lost.';
      this.commonService.showConfirmation(confirmationMessage)
        .then(result => {
          if (result.isConfirmed) {
            if (this.recievedItems[0]?.comingFrom) {
              this.recievedItems = [];
            }
            this.grid.refresh();
            this.onClose.emit();
            this.showLoader = false;
          }
        });
    }
  }

  getStatusClass(status) {
    return this.commonService.getPurchaseOrdersClass(status);
  }

  redirectToUPS(args) {
    let url = `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${args}/`;
    window.open(url)
  }

}
