export const pageTypes = [
    { id: 'Master', name: 'page' },
    { id: 'Popup', name: 'popup' }
];

export const filterTypes = [
    { text: 'Menu', value: 'Menu' },
    { text: 'Excel', value: 'Excel' }
];

export enum columnFilterTypes {
    excel = 'Excel',
    menu = 'Menu'
}

export const actionItemButtonTypes = {
    popup: 'Popup',
    delete: 'Delete'
};

export const actionItemTabs = {
    details: 'Details',
    attachments: 'Attachments',
    history: 'History'
}

export const actionItemTypesDDL = [
    { text: 'Popup', value: 'Popup' },
    { text: 'Button', value: 'Button' },
    { text: 'Delete', value: 'Delete' }
];

export const actionItemTabsDDL = [
    { text: 'Details', value: 'Details'},
    { text: 'Attachments', value: 'Attachments'},
    { text: 'History', value: 'History'},
    { text: 'Email', value: 'Email'},
    { text: 'Priority', value: 'Priority'},
    { text: 'None', value: 'None'}
];

export const alignmentTypes = [
    { text: 'Left', value: 'Left' },
    { text: 'Center', value: 'Center' },
    { text: 'Right', value: 'Right' }
];

export const columnProperties = {
    headerText: null,
    allowFiltering: true,
    allowEditing: true,
    filterType: filterTypes[0].value,
    format: null,
    columnType: 'column',
    width: 0,
    type: 'string',
    visible: true,
    showInColumnChooser: true,
    textAlign: alignmentTypes[0].value
} 

export const actionIconList = [
    { iconCode: 'fa-comments', value: 'Comments' },
    { iconCode: 'fa-envelope', value: 'Email' },
    { iconCode: 'fa-exclamation-triangle', value: 'Priority' },
    { iconCode: 'fa-file-upload', value: 'Attachment' },
    { iconCode: 'fa-history', value: 'History' },
    { iconCode: 'fa-pencil-alt', value: 'Edit' },
    { iconCode: 'fa-trash', value: 'Delete' }
];