export const FontAwesomIconsList = [
    { iconCode: 'fas fa-list-ul' },
    { iconCode: 'fas fa-list-alt' },
    { iconCode: 'fas fa-pencil-alt' },
    { iconCode: 'fas fa-file-upload' },
    { iconCode: 'fas fa-bars' },
    { iconCode: 'fas fa-exchange-alt' },
    { iconCode: 'fas fa-tasks' },
    { iconCode: 'fas fas fa-balance-scale' },
    { iconCode: 'fas fa-th-list' },
    { iconCode: 'fas fa-sitemap' },
    { iconCode: 'fas fa-history' },
    { iconCode: 'fas fa-comment' },
    { iconCode: 'fas fa-trash' },
    { iconCode: 'fas fa-sitemap' },
    { iconCode: 'fas fa-bug' },
    { iconCode: 'fas fa-viruses' },
    { iconCode: 'fas fa-virus' },
    { iconCode: 'fas fa-virus-slash' },
    { iconCode: 'fas fa-spider' },
    { iconCode: 'fas fa-check-square' },
    { iconCode: 'fas fa-chart-bar' },
    { iconCode: 'fas fa-link' },
    { iconCode: 'fas fa-unlink' },
    { iconCode: 'fas fa-clipboard' },
    { iconCode: 'fas fa-clipboard-check' },
    { iconCode: 'fas fa-sticky-note' },
    { iconCode: 'fas fa-trophy' },
    { iconCode: 'fas fa-cog' },
    { iconCode: 'fas fa-exclamation' },
    { iconCode: 'fas fa-exclamation-circle' },
    { iconCode: 'fas fa-exclamation-triangle' },
    { iconCode: 'fas fa-globe' },
    { iconCode: 'fas fa-crop-alt' },
    { iconCode: 'fas fa-crop' },
    { iconCode: 'fas fa-credit-card' },
    { iconCode: 'fas fa-question-circle' },
    { iconCode: 'fas fa-envelope' },
    { iconCode: 'fas fa-phone' },
    { iconCode: 'fas fa-info' },
    { iconCode: 'fas fa-flask' },
    { iconCode: 'fas fa-key' },
    { iconCode: 'fas fa-plane' },
    { iconCode: 'fas fa-user-circle' },
    { iconCode: 'fas fa-code' },
    { iconCode: 'fas fa-stethoscope' }
];