<div class="tab-content-container mt-3 mb-2">
    <h4 class="border-bottom">Links</h4>
    <ejs-grid #linksTabGrid 
        id="linksTabGrid" 
        [dataSource]="links" 
        [allowPaging]="false" 
        [allowFiltering]="true"
        [allowSorting]="true" 
        [allowResizing]="true" 
        [allowExcelExport]="true"
        [editSettings]="{ allowEditing: false, allowAdding: true, allowDeleting: false }"
        [filterSettings]="{ type: 'Menu' }" 
        (toolbarClick)="toolbarClick($event, linksTabGrid)"
        (created)="onGridCreated()" 
        (actionComplete)="actionComplete($event)"
    ><e-columns>
            <!-- <e-column field='id' headerText='id' [width]='140' [isPrimaryKey]='true' [visible]='false' [showInColumnChooser]='false'></e-column> -->
            <e-column *ngFor="let column of gridColumns;" 
                [field]="column.field" [headerText]="column.headerText"
                [clipMode]="'EllipsisWithTooltip'" 
                [type]="column.type" 
                [edit]="column.edit" 
                [width]="column.width"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null" 
                [textAlign]="column.textAlign"
                [visible]="column.visible" 
                [isPrimaryKey]="column.isPrimaryKey || false"
                [validationRules]="column?.validationRules || null"
                [commands]="column?.commands ? column.commands : null" 
                [allowEditing]="column.allowEditing"
                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                <ng-template *ngIf="column.field === 'status'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                </ng-template>
                <ng-template *ngIf="column.field === 'orderId'" #template let-data>
                    <a href="javascript:" (click)="onLinkIdClick.emit(data)">{{data.orderId}}</a>
                </ng-template>
                <ng-template *ngIf="column.field === 'customer'" #template let-data>
                    <a href="javascript:" (click)="onLinkIdClick.emit(data)">{{data.customer}}</a>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>

    <ng-template #linksTabGridColumnChooser>
        <app-column-chooser
            [grid]="linksTabGrid"
            [columns]="gridColumns"
            [showColumnChooser]="showColumnChooser"
            (onClose)="showColumnChooser = false"
        ></app-column-chooser>
    </ng-template>
</div>

<app-parts-orders-popup 
    *ngIf="showPartsOrderPopup"
    [id]="partsOrderId"
    [itemsData]="itemsData"
    [serviceOrderId]="serviceOrderId"
    [data]="partsOrderData"
    tab="details"
    popupSource="ServiceOrder"
    [serviceOrderTechnician]="{technicianName: data?.technicianName, technicianId: data?.technicianId}"
    [showComments]="false"
    (onSave)="partsOrderClose()"
    (onClose)="partsOrderPopupClose()"
></app-parts-orders-popup>