<div class="tab-content-container mt-3 mb-2">
    <h4 class="border-bottom">History</h4>
    <ejs-grid #historyTabGrid
        [dataSource]="dataSource"
        [allowPaging]="false"
        [allowFiltering]="true"
        [allowSorting]="true"
        [allowResizing]="true"
        [allowReordering]="true"
        [showColumnChooser]="true"
        [allowExcelExport]="true"
        [filterSettings]="{ type: 'Menu' }"
        [columns]="gridColumns"
        (toolbarClick)="toolbarClick($event, historyTabGrid)"
        (actionComplete)="onGridActionComplete($event, historyTabGrid)"
        (created)="onGridCreated(historyTabGrid)">
    </ejs-grid>

    <app-replenishment-orders-popup *ngIf="true"></app-replenishment-orders-popup>

    <ng-template #historyTabGridColumnChooser>
        <app-column-chooser
            [grid]="historyTabGrid"
            [columns]="gridColumns"
            [showColumnChooser]="showColumnChooser"
            (onClose)="showColumnChooser = false"
        ></app-column-chooser>
    </ng-template>
</div>