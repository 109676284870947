<div 
    class="modal fade show" 
    id="cancellation-reason-popup" 
    tabindex="-1" 
    aria-labelledby="cancellation-reason-popup-Label" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Cancelation details</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body custom-scrollbar pt-3">  
                <div class="row">
                    <div class="col-12">
                        <div class="field mb-3"> 
                            <label>Reason:<span class="required-asterik">*</span></label>
                            <ejs-dropdownlist #reasonCodeDDL
                                [placeholder]="'Select cancelation reason'" 
                                [dataSource]="cancelReasonsList"
                                [fields]="{ text: 'description', value: 'value' }"
                                [filterType]="'contains'"
                                [(ngModel)]="reasonCode"
                                (focus)="reasonCodeDDL.showPopup()"
                                (change)='reasonText=$event.itemData.description;'
                            >
                            </ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field mb-2">
                            <label>Cancelation Date & Time: </label>
                            <p class="pt-2">{{ reasonDatetime | date:'medium' }}</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="field mb-3"> 
                            <label>Note:</label>
                            <ejs-textbox 
                                placeholder="Enter note for the cancelation..."
                                rows="2" 
                                [multiline]='true' 
                                [(ngModel)]="reasonNote"
                            ></ejs-textbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="" (click)="save()" [disabled]="!reasonCode || reasonCode===null">Confirm</button>
            </div>
        </div>
    </div>
</div>