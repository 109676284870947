<div 
    class="modal fade show" 
    *ngIf="showPopup"
    [ngClass]="{'expanded': isExpanded}"
    id="assignments-modal" 
    tabindex="-1" 
    aria-labelledby="assignments-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Assignments</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3">
                <form #assignmentForm="ngForm">
                    <div class="row">
                        <div class="col-3">
                            <div class="field mb-3 position-relative">
                                <label>Description:</label><span class="required-asterik">*</span>
                                <ejs-textbox 
                                    required
                                    name="description"
                                    #description="ngModel"
                                    [(ngModel)]="data.description"
                                    [placeholder]="'Enter description...'" 
                                ></ejs-textbox>
                                <div *ngIf="description.invalid && (description.touched) && description.errors.required" class="field-error">Description is required</div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3 position-relative">
                                <label>Reference Activity:</label><span class="required-asterik">*</span>
                                <ejs-dropdownlist 
                                    required
                                    name="referenceActivity"
                                    #referenceActivity="ngModel"
                                    [(ngModel)]="data.referenceActivityCode"
                                    [placeholder]="'Select Service Department'"
                                    [fields]="{text: 'text', value: 'value'}" 
                                    [dataSource]="refActivityDDL"
                                    [allowFiltering]="true"
                                    [filterType]="'contains'"
                                    [sortOrder]="'Ascending'"
                                    (change)="onReferenceActivityChange($event)"
                                ></ejs-dropdownlist>
                                <div *ngIf="referenceActivity.invalid && (referenceActivity.touched) && referenceActivity.errors.required" class="field-error">Reference activity is required</div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>Service Type:</label>
                                <ejs-textbox 
                                    [readonly]="true" 
                                    [value]="data.serviceTypeDescription"
                                ></ejs-textbox>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>Reported Problem:</label>
                                <ejs-dropdownlist
                                    name="reportedProblem"
                                    #reportedProblem="ngModel"
                                    [(ngModel)]="data.reportedProblem"
                                    [placeholder]="'Select Reported Problem'" 
                                    [fields]="{text: 'text', value: 'value'}"
                                    [dataSource]="reportedProblems"
                                    [allowFiltering]="true"
                                    [filterType]="'contains'"
                                    [sortOrder]="'Ascending'"
                                    (change)="data.reportedProblemDescription = $event.itemData.description"
                                ></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>Solution:</label>
                                <ejs-dropdownlist
                                    name="scode"
                                    #solution="ngModel"
                                    [(ngModel)]="data.solutionCode"
                                    [placeholder]="'Select Solution'" 
                                    [fields]="{text: 'text', value: 'value'}"
                                    [dataSource]="solutions"
                                    [allowFiltering]="true"
                                    [filterType]="'contains'"
                                    [sortOrder]="'Ascending'"
                                    (change)="data.solutionDescription = $event.itemData.description"
                                ></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>Priority:</label><span class="required-asterik">*</span>
                                <ejs-dropdownlist 
                                    required
                                    name="priority"
                                    #priority="ngModel"
                                    [(ngModel)]="data.priority"
                                    [fields]="{text: 'text', value: 'value'}"
                                    [placeholder]="'Select Priority'" 
                                    [dataSource]="priorities"
                                    [sortOrder]="'Ascending'"
                                    [allowFiltering]="true"
                                    [filterType]="'contains'"
                                ></ejs-dropdownlist>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>Start Time:</label>
                                <ejs-datetimepicker
                                    #startDatePicker
                                    name="startDate"
                                    #startDate="ngModel"
                                    [(ngModel)]="data.startDate"
                                    [placeholder]="'Select start time...'"
                                    [min]="minDate"
                                    (focus)="$event.model.show()"
                                    (change)="onStartDateChange($event)"
                                ></ejs-datetimepicker>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="field mb-3">
                                <label>End Time:</label>
                                <ejs-datetimepicker
                                    #endDatePicker
                                    name="endDate"
                                    #endDate="ngModel"
                                    [(ngModel)]="data.endDate"
                                    [placeholder]="'Select end time...'"
                                    [min]="minDate"
                                    (focus)="$event.model.show()"
                                    (change)="onEndDateChange($event)"
                                ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                </form>  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" id="" [disabled]="assignmentForm.invalid" (click)="checkRefActivity()">Save</button>
            </div>
        </div>
    </div>
</div>