<div
    class="modal fade show in" 
    [ngClass]="{'expanded': isExpanded}"
    id="shipment-adjustment-order-modal" 
    tabindex="-1" 
    aria-labelledby="shipment-adjustment-order-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-popup-header
                [id]="orderId"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data?.createdDate : null"
                [title]="orderId"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">Technician:</label>
                                    <div class="col pt-2">{{ data?.technicianName }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <div><label class="control-label col-md-auto mt-2"> Status: </label>{{ data?.status }}</div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="orderId">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': data?.commentCount}"></i> <span>{{data?.commentCount}}</span> Comment<span *ngIf="data?.commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="orderId"
                                            [moduleId]="orderId"
                                            [module]="moduleName"
                                            [comments]="data.comments || []"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}" 
                                            data-toggle="tab"
                                            href="#shipment-adjustment-order-popup-details-tab"
                                            title="Details"
                                            >Details 
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'exceptions'}" 
                                            data-toggle="tab"
                                            href="#shipment-adjustment-order-popup-exceptions-tab"
                                            title="Details"
                                            >Exceptions 
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'attachments'}" 
                                            data-toggle="tab"
                                            href="#shipment-adjustment-order-popup-attachments-tab"
                                            title="Attachments" 
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': data?.attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'history'}" 
                                            data-toggle="tab"
                                            href="#shipment-adjustment-order-popup-history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content flex-fill">
                            <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="shipment-adjustment-order-popup-details-tab">
                                <app-shipment-adjustment-order-popup-details-tab
                                    [data]="data"
                                ></app-shipment-adjustment-order-popup-details-tab>
                            </div>
                            <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'exceptions'}" id="shipment-adjustment-order-popup-exceptions-tab">
                                <app-shipment-adjustment-order-popup-exceptions-tab
                                    [data]="data"
                                ></app-shipment-adjustment-order-popup-exceptions-tab>
                            </div>
                            <div id="shipment-adjustment-order-popup-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab 
                                    [moduleId]="orderId"
                                    [module]="moduleName"
                                    [attachments]="data?.attachments"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="shipment-adjustment-order-popup-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [popup]="moduleName"
                                    [history]="data?.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <!-- <button type="button" class="btn btn-sm btn-info mr-1" (click)="reset()">Reset</button> -->
                <button *ngIf="!orderId" type="button" class="btn btn-sm btn-success mr-1" (click)="save(false)">Save</button>
                <button *ngIf="!orderId" type="button" class="btn btn-sm btn-success mr-1" (click)="save(true)">Save &amp; Close</button>
            </div>
        </div>
    </div>
</div>

