<div class="pt-1">
    <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">To<span class="required-asterik">*</span> :</label>
        <div class="col-sm-10 mt-1">
            <ejs-textbox
                [id]="'to-email'"
                [placeholder]="'Enter receiver(s) email...'"
                type="text"
                [(ngModel)]="receiversEmails"
                required
                (created)="checkReceiverEmail()"
                (blur)="checkReceiverEmail()"
                (input)="checkReceiverEmailInput($event)"
            ></ejs-textbox>
            <span class="e-error" *ngIf="validationApplied && !receiversEmails">* Receiver's email is required.</span>
            <span class="e-error" *ngIf="receiversEmails && !emailValid">* You entered an invalid email.</span>
        </div>
        <label for="staticEmail" class="col-sm-2 col-form-label">CC:</label>
        <div class="col-sm-10 mt-1">
            <ejs-textbox
                [id]="'cc-email'"
                [placeholder]="'Enter cc receiver(s) email...'"
                type="text"
                [(ngModel)]="ccReceiversEmails"
                (created)="checkCCEmail()"
                (blur)="checkCCEmail()"
                (input)="checkCCReceiverEmailInput($event)"
            ></ejs-textbox>
            <span class="e-error" *ngIf="ccReceiversEmails && !ccEmailValid">* You entered an invalid email.</span>
        </div>
        <label for="staticEmail" class="col-sm-2 col-form-label">Subject:</label>
        <div class="col-sm-10 mt-1">
            <ejs-textbox
                [id]="'email-subject'"
                [placeholder]="'Enter email subject...'"
                type="text"
                [(ngModel)]="emailSubject"
            ></ejs-textbox>
            <span class="e-error" *ngIf="emailSubject && !emailSubjectValid">* Email subject is required.</span>
        </div>
    </div>
</div>