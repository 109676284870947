import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Query } from '@syncfusion/ej2-data';
import { ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-shipment-details-tab',
  templateUrl: './shipment-details-tab.component.html',
  styleUrls: ['./shipment-details-tab.component.scss']
})
export class ShipmentDetailsTabComponent implements OnInit, OnDestroy {

  gridHeight: number;
  gridWidth: any = '100%';
  columns: Object[];

  @Input() priorities: Object[];
  @Input() shipmentMethods: Object[];
  @Input() technicianAddresses: Object[];
  @Input() shipmentOrderId: any;
  @Input() shipmentOrderData: any;
  @Input() details: any;
  @Input() items: any;
  @Input() status: 'string';

  @ViewChild('deliveryAck') deliveryAck: CheckBoxComponent;
  @ViewChild('shipmentOrderItemsGrid') itemsGrid: GridComponent;
  @ViewChild('shipmentOrderItemsGridColumnChooser') columnChooser;

  @Output() updateStatus = new EventEmitter()
  itemsQuery: any;
  showColumnChooser: boolean;
  isTechnician: boolean;
  orderId: any;
  partOrderData: any;
  showPopup: boolean = false;
  deliveryAcknowBtnDisabled: boolean = false

  constructor(
    private inventoryService: InventoryService,
    private commonService: CommonService
  ) { 
    this.deliveryAcknowBtnDisabled = this.commonService.roleClaims['Inv_Order_ReceiptGrid_Delivery_Acknowledged']?.disable;
  }

  ngOnInit(): void {
    this.isTechnician = this.commonService.USER.employeeId === this.shipmentOrderData.technicianId;
    this.gridHeight = document.getElementsByClassName('modal-body')[0].scrollHeight - 350;
    this.columns = this.getGridColumns();
  }

  getGridColumns() {
    return [
      { field: 'partsOrderRef', headerText: 'Part Order', type: 'string', textAlign: 'Left', width: 100 },
      { field: 'warehouseOrderLN', headerText: 'LN Warehouse Order No', type: 'string', textAlign: 'Left', width: 130, filter: { type: 'Menu', operator: 'contains'} },
      { field: 'warehouseOrderLNLine', headerText: 'LN WH Line#' , type: 'number',format: 'n', textAlign: 'Left', width: 130,  visible: false },
      { field: 'warehouseOrderLNSequence', headerText: 'LN WH Line Seq.', type: 'number',format: 'n', textAlign: 'Left', width: 130, visible: false },
      { field: 'serviceOrderId', headerText: 'Service Order No.', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: false },
      { field: 'serviceOrderIdLN', headerText: 'LN Service Order No.', type: 'string', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'itemCode', headerText: 'Item', type: 'string', textAlign: 'Left', width: 130, filter: { type: 'Excel', operator: 'contains'} },
      { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left' },
      { field: 'itemGroup', headerText: 'Item Group', type: 'string', textAlign: 'Left', filter: {type: 'CheckBox'}, width: 150, visible: false },
      { field: 'orderQty', headerText: 'Ord Qty', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      { field: 'quantityShippedInInventoryUnit', headerText: 'Ship Qty', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      // { field: 'shipmentLine', headerText: 'Sr. No.', visible: false },
      // // { field: 'lineNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, visible: false },
      // { field: 'msOrderLine', headerText: 'Ord Line#', type: 'number',format: 'n', textAlign: 'Left', width: 100, visible: false, },
      // { field: 'backOrder', headerText: 'Back Order', type: 'number', format: 'n', textAlign: 'right', width: 142, allowEditing: false, visible: false },
    ];
  }

  getData() {
    return this.details;
  }

  onGridCreated() {
    this.itemsQuery = new Query().where('isDeleted', 'notequal', true);
    this.itemsGrid.dataSource = this.items;
    this.itemsGrid.toolbar = [
        { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.itemsGrid.toolbar.findIndex(x => x === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.itemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    updateFilterIcon({requestType: 'filtering'}, this.itemsGrid);
  }

  toolbarClick(args: ClickEventArgs) {
    if (args.item.id === 'grid_excelexport') {
        const dataSource = getExcelDataSource(this.itemsGrid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Shipment Order ${this.shipmentOrderId ?? null } - Items (${getCurrentDate()}).xlsx`
        };
        this.itemsGrid.excelExport(excelExportProperties);
    } else if (args.item.id === 'grid_pdfexport') {
        this.itemsGrid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
        this.itemsGrid.clearFiltering();
        this.itemsGrid.search('');
    } else if (args.item.id === 'column-chooser') {
        this.showColumnChooser = !this.showColumnChooser;
    }
}

  
  changeStatus(args) {
    this.commonService.showConfirmation('Delivery Acknowledgement will not be reverted and any other changes will be saved! Do you want to proceed?').then(result => {
      if ( result.isConfirmed ) {
        this.updateStatus.emit(args);
      } else {
        this.deliveryAck.checked = false;
      }
    });
  }

  onPartOrderClick(data){
    this.orderId = data.partsOrderRef;
    // this.showLoader = true;
    this.inventoryService.getPartsOrderById(this.orderId)
    .subscribe((res: any) => {
        this.partOrderData = res.result;
        // this.showLoader = false;
        this.showPopup = true;
    }, (error: HttpErrorResponse) => {
        // this.showLoader = false;
        throw error;
    });
  }
  
  onClosePartsPopup() {
    this.showPopup = false;
    this.orderId = null;
    this.partOrderData = null;
  }

  partsOrderSaved(updatedData) {
    this.onClosePartsPopup();
  }
  ngOnDestroy() {
    this.details = null;
  }
}
