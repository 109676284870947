import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-barclay-success',
  template: `
    <div class="text-center pt-5">
        <img class="img" height="200px" width="200px" src="../../../../../assets/img/shared/check.png"/>
        <br><br>
        <h1>Transaction Successful</h1>
        <p>Transaction Id: {{transactionID}}</p>
        <p>Date: {{transactionDate}}</p>
        <p>Amount: {{transactionCurrency}} {{transactionAmount}}</p>

        <button class="btn btn-success btn-sm" (click)="printInvoice()">Print Invoice</button>
    </div>`
})
export class BarclaySuccessComponent implements OnInit {
    
    @Input() transactionID : string;
    @Input() transactionDate : Date;
    @Input() transactionAmount : string;
    @Input() transactionCurrency : string;

    @Output() close = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    printInvoice(){
        this.close.emit();
    }
}