import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sf-picklist',
    templateUrl: './sf-picklist.component.html',
    styleUrls: ['./sf-picklist.component.scss']
})

export class SfPicklistComponent implements OnInit { 
    // @Input() 
    constructor() {}
    ngOnInit() {}

}