<!-- <app-loader *ngIf="viewLoader"></app-loader> -->
<div class="control-section" style="overflow-y: hidden;">
    <div id="wrapper">
        <div class="col-lg-12 col-sm-12 col-md-12" id="sidebar-section">
            <div class="header-section dock-menu" id="header">
                <ul class="header-list">
                    <li id="hamburger" class="icon-menu icon list" (click)="sidebarMenuInstance.toggle()"></li>
                    <li id="snapon-logo" class="icon-menu icon list float-left">
                        <img src="../../../../../assets/img/shared/RIMS-logo.png" alt="Snapon Logo"/>
                    </li>
                    <li class="right-header list">
                        <div class="horizontal-menu">
                            <ejs-menu 
                                [items]='AccountMenuItem' 
                                cssClass='dock-menu'
                                (select)="onUserMenuClick($event)"
                            ></ejs-menu>
                        </div>
                    </li>

                </ul>
            </div>
            <div style="display: flex;">
                <ejs-sidebar #sidebarMenuInstance 
                    id="sidebar-menu" 
                    class="dock-menu" 
                    [enableDock]='true'
                    [enableGestures]="false"
                    width='220px' 
                    dockSize='50px' 
                    mediaQuery='(min-width: 600px)' 
                    target='.main-content' 
                    >
                    <div class="main-menu" class="treeViewClass">
                        <ejs-treeview
                        [fields]='menuItems'
                        [expandOn]="'Click'"
                        orientation='Vertical'
                        (nodeClicked)="onMenuItemSelect($event)"
                        >
                        </ejs-treeview>
                    </div>
                </ejs-sidebar>
                <div style="overflow: auto;">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- 
<div class="container-fluid">
    <div class="row">
        <nav class="col-sm-3 col-md-2 d-none d-sm-block bg-light sidebar">
            <div class="page-list">
                <ul class="nav nav-pills flex-column">
                    <li class="nav-item page-name">
                        <a class="nav-link" [routerLink]="['/home/dashboard']">Dashboard</a>
                    </li>
                    <li class="nav-item page-name" *ngFor="let page of pages" (click)="selectPage(page)">
                        <a class="nav-link" [ngClass]="{'active': selectedPage && selectedPage.id === page.id}"
                            href="javascript:void(0);">{{page.pageName}}</a>
                    </li>
                </ul>
            </div>
        </nav>
        <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-3 main">
            <div class="page-container w-100" *ngIf="selectedPage">
                <div style="min-height: 450px">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </main>
    </div>
</div> -->