import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { EditSettingsModel, ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { IInventoryGroupType, IReceiptsExceptionsTypes, shipmentOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ApiResponse, gridNames } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { CheckBox } from '@syncfusion/ej2-angular-buttons';
import { MouseEventArgs } from '@syncfusion/ej2-base';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TextBox } from '@syncfusion/ej2-inputs';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';

@Component({
  selector: 'app-shipped-exceptions',
  templateUrl: './shipped-exceptions.component.html',
  styleUrls: ['./shipped-exceptions.component.scss']
})
export class ShippedExceptionsComponent implements OnInit, OnChanges {

  @Input() exceptions : any = [];
  @Input() status : string = shipmentOrderStatuses.shipped;
  @Input() shipmentOrderId: any;
  @Input() shipmentOrderData: any;

  gridName: string = gridNames.receiptsPopupShippedExceptionsGrid;
  gridHeight: 240;
  allowEditing: boolean = true;
  toolbar: any = [];
  showGrid: boolean = false;
  shipmentOrderExceptionsGrid: GridComponent;
  exceptionsDDL: any = [];
  itemsList: any = [];
  public data?: object[];
  public editSettings?: EditSettingsModel;
  public orderForm?: FormGroup | any;

  @Output() onExceptionGridUpdated = new EventEmitter();

  @ViewChild('shipmentOrderExceptionsGridColumnChooser') exceptionsGridColumnChooser;
  gridColumns: any;
  exceptionType: HTMLInputElement;
  exceptionTypeObject: any;
  itemReturned: HTMLInputElement;
  itemReturnedObject: any;
  isTabValid: boolean = false;
  selectedRowId: any;
  itemCode: HTMLInputElement;
  itemCodeObject: any;
  onItemUpdate = new Subject<string>();
  selectItemCode: any;
  showItemAdvancedSearchPopup: boolean = false;
  itemChangedFromAdvanceSearch: boolean = false;
  itemDescription: HTMLInputElement;
  itemDescriptionObject: any;
  itemType: HTMLInputElement;
  itemTypeObject: any;
  serviceItemGroup: any;
  roleCanEdit: any;
  canEdit: any;

  @ViewChild('shipmentOrderExceptionsGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.shipmentOrderExceptionsGrid = gridComponent;
      this.getToolbar();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.shipmentOrderExceptionsGrid?.isEdit) {
      if (!this.eRef.nativeElement.contains(event.target)
      && !document.getElementById(gridNames.receiptsPopupShippedExceptionsGrid+'itemCode_popup')?.contains(event.target)
      && !this.itemChangedFromAdvanceSearch
      && !document.getElementById(gridNames.receiptsPopupShippedExceptionsGrid+'reasonDescription_popup')?.contains(event.target)) {
        this.shipmentOrderExceptionsGrid?.endEdit();
      }
    }
  }

  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private serviceOrderService: ServiceOrderService,
    private eRef: ElementRef,

  ) { }

  ngOnChanges(change) {
    if(change?.status){
      this.status === change.status.currentValue;
      this.getCanEditValue();
      // this.enableDisableButton();
    }

    if(change?.exceptions && this.status === shipmentOrderStatuses.deliveryAcknowledged){
      if(!this.checkEmptyRows() && this.canEdit){
          this.addEmptyRowsToItemsData();
      }
    }
  }

  ngOnInit(): void {
    this.inventoryService.receiptsPopupLoader.next(true);
    this.gridColumns = this.getColumns();
    this.getCanEditValue();
    this.exceptions.map((row, index) => {
      row.tempRowId = index;
      row.item = `${row.itemCode} | ${row.itemDescription}`
    });
    this.data = this.exceptions;
    this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' };
    this.getExceptionsDDL();
    this.getItemList();
    this.onItemUpdate.pipe(
          debounceTime(500),
          distinctUntilChanged()
      ).subscribe(value => {
          if (this.itemCodeObject) {
              this.itemCodeObject.showPopup();
              this.itemCodeObject.showSpinner();
          }
          this.getItemList({itemCode: value});
      });
  }

  getCanEditValue(){
    this.roleCanEdit = this.commonService.roleClaims['Inv_Order_Receipt_Popup_Receipt_Tab']?.edit
    this.canEdit = this.status === shipmentOrderStatuses.deliveryAcknowledged && this.roleCanEdit;
    this.allowEditing = this.canEdit;
  }
  
  getExceptionsDDL(){
    this.inventoryService.getReasonOrExceptionCodesForDDL('ShipmentOrder')
    .subscribe((res: any) => {
      if(res.isSuccessful){
        this.exceptionsDDL = res.result.filter(row => [IReceiptsExceptionsTypes.damaged, IReceiptsExceptionsTypes.unknown, IReceiptsExceptionsTypes.wrongpart].includes(row.text));
        // this.showLoader = false;
        if(this.canEdit){
          this.addEmptyRowsToItemsData();
        }
      } else {
        this.commonService.showNotification('error', res.message);
        // this.showLoader = false;
      }
    }, (error: HttpErrorResponse) => {
      // this.showLoader = false;
      throw error;
    });
  }

   addEmptyRowsToItemsData() {
    for (let i = 0; i < 2; i++) {
      this.exceptions?.push(this.setExceptionRow({}));
    }
    this.shipmentOrderExceptionsGrid?.refresh();
    setTimeout(() => {
      this.inventoryService.receiptsPopupLoader.next(false);
    }, 100);
  }

  checkEmptyRows(){
    const exceptionsDataSource = this.shipmentOrderExceptionsGrid.dataSource as any;
    const hasEmptyRow = exceptionsDataSource.some(row => !row.reasonCode && !row.itemCode && !row.quantity);
    return hasEmptyRow;
  }

  setExceptionRow(detail) {
    detail.tempRowId = this.commonService.getMaxTempRowId(this.shipmentOrderExceptionsGrid?.dataSource || []) + 1;
    detail.rowId = this.commonService.getMaxRowId(this.shipmentOrderExceptionsGrid?.dataSource || []) + 1;
    detail.shoExceptionId = 0,
    detail.shipmentOrderId = this.shipmentOrderId;
    detail.adjustmentOrderId = '';
    detail.adjustmentOrderLineId = null;
    detail.itemCode = '';
    detail.itemDescription = '';
    detail.itemGroup = '';
    detail.type = '';
    detail.quantity = null;
    detail.quantitySent = null;
    detail.quantityReceived = null;
    detail.reasonCode = null;
    detail.reasonDescription = null;
    detail.isDeleted = false;
    detail.isAdjustmentOrder = false;
    detail.isManual = false;
    detail.isRemovedFromExceptionList = false;
    detail.orderReceived = true;
    detail.exceptionStatus = '';
    detail.technicianId = '';
    detail.technicianName = '';
    detail.shipmentOrderLN = '';
    detail.status = '';
    detail.warehouseOrderLN = '';
    detail.adjustmentDate = null;
    detail.reasonForAdjustment = '';
    detail.warehouse = '';
    detail.inventoryDate = null;
    detail.fromLoc = '';
    detail.line = detail.rowId;
    detail.inventoryAdjustedStorageUnit = null;
    detail.inventoryAdjustedInventoryUnit = null;
    detail.varianceStorageUnit = null;
    detail.createdBy = '';
    detail.createdDate = null;
    detail.createdByName = '';
    detail.lastUpdatedBy = '';
    detail.lastUpdatedDate = null;
    detail.lastUpdatedByName = '';
    detail.isReturn = false;
    return detail;
  }

  getToolbar(){
    this.shipmentOrderExceptionsGrid.toolbar = [
      { text: '', id: 'exceptions-column-chooser', align: 'Left', prefixIcon: '', template: this.exceptionsGridColumnChooser, tooltipText: 'Show/Hide Columns' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.toolbar.findIndex(x => x === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    if(this.commonService.roleClaims['PO_Popup_ItemTabGrid_Toolbar_Add_Item']?.visible){
      this.shipmentOrderExceptionsGrid?.toolbar.push({ text: '', id: 'add-exception', align: 'Left', template: '', prefixIcon: 'e-add', tooltipText: 'Add Exception', cssClass: `grid-add-button ${this.canEdit?'':'disabled-grid-button'}` },)
    }
  }

  getColumns(){
    return [
      { field: 'tempRowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
      { field: 'reasonDescription', headerText: 'Exception', type: 'string', width: 150, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, edit: this.editExceptionTypes() },
      { field: 'itemCode', headerText: 'Item', type: 'string', width: 175, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, edit: this.editItem() },
      { field: 'itemDescription', headerText: 'Description', type: 'string', width: 195, textAlign: 'Left', edit: this.editItemDescription(), showInColumnChooser: false },
      { field: 'quantity', headerText: 'Quantity', type: 'number', textAlign: 'right', width: 150, editType: 'numericedit', allowEditing: true,
        edit: {
          params: {
            validateDecimalOnType: true,
            decimals: 0,
            min: 1,
            format: "N"
          }
        }
      },
      { field: 'type', headerText: 'Type', type: 'string', edit: this.editItemType()},
      { field: 'isReturn', headerText: 'Return', type: 'boolean', allowFiltering: false, width: 80, edit: this.editItemReturned(), showInColumnChooser: false},
      {
        field: 'Actions', headerText: '', headerTextAlign: 'Center', textAlign: 'Center', allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 110,
        commands: [
          { title: 'Delete Item', buttonOption: { iconCss: 'fas fa-trash', cssClass: `${this.status === shipmentOrderStatuses.deliveryAcknowledged? 'e-flat' : 'e-flat disabled-grid-button'}` } }
        ]
      }
    ];
  }

  getItemList(rowData?) {
    const value = rowData?.itemCode || '';
    let serviceItemGroup = '';
    this.commonService.getItems(value, 10, this.commonService.USER.company, serviceItemGroup)
    .subscribe((res: ApiResponse) => {
      if(res && res.result && res.result.length) {
          res.result.map((item, index) => {
            item.value =`${item.item}`
            item.text =`${item.item} | ${item.itemDescription}`
          });
          this.itemsList = res.result;
          this.itemCodeObject ? this.itemCodeObject.dataSource = res.result : null;
          // this.showGrid = true;
      } else {
          this.itemsList = [];
      }
    });
  }

  onToolbarClick(args, grid){
    if (args.item.id === "add-exception") {
      this.inventoryService.receiptsPopupLoader.next(true);
        if((this.shipmentOrderExceptionsGrid.dataSource as any).filter(row => !row.reasonCode && !row.itemCode && !row.quantity).length > 0){
          this.inventoryService.receiptsPopupLoader.next(false);
          this.commonService.showNotification('warning', 'There is already empty row present');
        }
        else{
          if ( this.shipmentOrderExceptionsGrid.isEdit ) {
            this.inventoryService.receiptsPopupLoader.next(false);
            this.commonService.showNotification('warning', 'There is a row in exception grid, which is already in edit mode!', 'center', 5000);
          } else {
            let newException = this.setExceptionRow({});
            (this.shipmentOrderExceptionsGrid.dataSource as any).push(newException);
            this.shipmentOrderExceptionsGrid.refresh();
            setTimeout(() => {
              const index = (this.shipmentOrderExceptionsGrid.dataSource as any).findIndex( x => x.tempRowId === newException.tempRowId);
              this.shipmentOrderExceptionsGrid.selectRow(index);
              this.shipmentOrderExceptionsGrid.startEdit();
              this.inventoryService.receiptsPopupLoader.next(false);
            }, 100);
          }
        }
    } else if (args.item.id === 'grid_excelexport') {
        const dataSource = getExcelDataSource(this.shipmentOrderExceptionsGrid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Shipment Order ${this.shipmentOrderId ?? null } - Exceptions (${getCurrentDate()}).xlsx`
        };
        this.shipmentOrderExceptionsGrid.excelExport(excelExportProperties);
    } else if (args.item.id === 'grid_pdfexport') {
        this.shipmentOrderExceptionsGrid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
        this.shipmentOrderExceptionsGrid.clearFiltering();
        this.shipmentOrderExceptionsGrid.search('');
    } else if (args.item.id === 'exceptions-column-chooser') {
        this.exceptionsGridColumnChooser = !this.exceptionsGridColumnChooser;
    }
  }
  
  onGridCreated(args, grid){

  }
  
  onGridActionComplete(args, grid){
    if ( args.requestType === 'beginEdit') {
        if ( args.rowData.reasonDescription === 'Unknown' ) {
          this.itemCodeObject.dataSource = [this.commonService.unknownPart];
        }
      } else if (args.requestType === 'save') {
        const index = (this.shipmentOrderExceptionsGrid.dataSource as any).findIndex(row => row.tempRowId === args.data.tempRowId );
        this.shipmentOrderExceptionsGrid.dataSource[index].reasonCode = this.exceptionsDDL.find( row => row.text === this.shipmentOrderExceptionsGrid.dataSource[index].reasonDescription).value;
        this.shipmentOrderExceptionsGrid.dataSource[index].serviceItemGroup = this.serviceItemGroup;
        const emptyRecordsList = (this.shipmentOrderExceptionsGrid.dataSource as any).filter((item, index) => !item.reasonDescription );
        if (emptyRecordsList?.length < 2) {
          this.addExceptionNewRow();
        setTimeout(() => {
          const firstEmptyRowIndex = (this.shipmentOrderExceptionsGrid.dataSource as any).findIndex((item, index) => emptyRecordsList.find( row => row.tempRowId).tempRowId === item.tempRowId );;
          this.shipmentOrderExceptionsGrid.selectRow(firstEmptyRowIndex);
          this.shipmentOrderExceptionsGrid.startEdit();
        }, 100);
      }
      updateFilterIcon({requestType: 'filtering'}, this.shipmentOrderExceptionsGrid);
    }
  }

  addExceptionNewRow() {
    let newException = this.setExceptionRow({});
    (this.shipmentOrderExceptionsGrid.dataSource as any).push(newException);
    this.shipmentOrderExceptionsGrid.refresh();
  }
  
  commandColumnClick(args, grid){
    if (args.commandColumn.title === 'Delete Item') {
      const row = (this.shipmentOrderExceptionsGrid?.dataSource as any).find(x => x.tempRowId === args.rowData.tempRowId);
      if(!row.shoExceptionId){
        const index = (this.shipmentOrderExceptionsGrid?.dataSource as any).findIndex(x => x.tempRowId === args.rowData.tempRowId);
        (this.shipmentOrderExceptionsGrid?.dataSource as any).splice(index, 1)
        this.shipmentOrderExceptionsGrid?.refresh();
      } else {
        this.commonService.showConfirmation('Are you sure? You want to delete this exception?').then(result => {
          if (result.isConfirmed) {
            row.tempRowId = args.rowData.tempRowId;
            row.isDeleted = true;
            this.shipmentOrderExceptionsGrid?.refresh();
          }
        })
      }
    }
  }
  
  onLoad(args, grid){
    this.shipmentOrderExceptionsGrid.element.addEventListener('mouseup', (e: MouseEventArgs) => {
      if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
        const cellIndex = +(e.target as HTMLElement).getAttribute('data-colindex');
        const clickedColumnName = this.shipmentOrderExceptionsGrid.getColumnByIndex(cellIndex).field;
        this.shipmentOrderExceptionsGrid.isEdit && this.shipmentOrderExceptionsGrid.endEdit();
        let rowIndex: number = parseInt((e.target as HTMLElement).getAttribute("Index"));
        this.shipmentOrderExceptionsGrid.selectRow(rowIndex);
        this.shipmentOrderExceptionsGrid.startEdit();
        clickedColumnName && document?.getElementById(gridNames.receiptsPopupShippedExceptionsGrid + clickedColumnName)?.focus();
      };
    });
  }
  
  onActionBegin(args, grid){

  }

  editExceptionTypes() {
      return {
          create: (args) => {
            this.exceptionType = document.createElement('input');
            return this.exceptionType;
          },
          read: () => {
              if (this.exceptionTypeObject) {
                  return this.exceptionTypeObject.value;
              }
          },
          destroy: () => {
            if ( this.exceptionTypeObject ) {
              this.exceptionTypeObject.destroy();
              this.exceptionTypeObject = null;
            }
          },
          write: (args) => {
              this.exceptionTypeObject = new DropDownList({
                  dataSource: this.exceptionsDDL.filter(row => [IReceiptsExceptionsTypes.damaged, IReceiptsExceptionsTypes.unknown, IReceiptsExceptionsTypes.wrongpart].includes(row.text)),
                  fields: { value: 'text', text: 'text' },
                  popupWidth: '150px',
                  value: args.rowData.reasonDescription,
                  change: event => {
                    // this.reasonCode = event.itemData.value;
                    // this.reasonDescription = event.itemData.text;
                    this.itemCodeObject.value = this.itemDescriptionObject.value = null;
                    this.itemReturnedObject.checked = true;
                    const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
                    if(event.itemData.text == IReceiptsExceptionsTypes.unknown){
                      this.itemCodeObject.dataSource = [this.commonService.unknownPart];
                      this.itemCodeObject.value = this.selectItemCode = this.commonService.unknownPart.value;
                      this.itemDescriptionObject.value = this.commonService.unknownPart.text;
                      this.itemCodeObject.enabled = false;
                      anchorElement.classList.add('d-none');
                    } else {
                      this.itemCodeObject.dataSource = this.itemsList;
                      this.itemCodeObject.value = null;
                      this.itemCodeObject.enabled = true;
                      anchorElement.classList.remove('d-none');
                    }
                  }
              });
              this.exceptionTypeObject.appendTo(this.exceptionType);
          }
      }
    }

  editItem() {
    return {
      create: args => {
        this.selectedRowId = args.data.tempRowId;
        this.itemCode = document.createElement('input');
        return this.itemCode;
      },
      read: () => {
        if (this.itemCodeObject) {
            return this.itemCodeObject.value;
        }
      },
      destroy: () => {
        this.itemCodeObject.destroy();
      },
      write: (args) => {
        this.itemCodeObject = new DropDownList({
            dataSource: this.itemsList,
            fields: { value: 'value', text: 'text' },
            value: args.rowData.itemCode,
            cssClass: 'generic-items-dropdown-list',
            // itemTemplate: '<span title="${text}">${text}</span>',
            allowFiltering: true,
            showClearButton: true,
            popupWidth: '350px',
            filterType: 'Contains',
            enabled: [IReceiptsExceptionsTypes.damaged, IReceiptsExceptionsTypes.wrongpart].includes(this.exceptionTypeObject.value),
            filtering: args => { this.onItemUpdate.next(args.text) },
            focus: event => {
              this.itemCodeObject.showPopup()
              if(args.rowData.reasonCode == this.exceptionsDDL.find(row => [IReceiptsExceptionsTypes.unknown].includes(row.text)).value){
                this.itemCodeObject.dataSource = [this.commonService.unknownPart];
              } else {
                const existingItems = [];
                (this.shipmentOrderExceptionsGrid?.dataSource as any)?.forEach(exception => {
                    if (exception.itemCode !== this.itemCodeObject?.value) {
                        existingItems.push(exception.itemCode);
                    }
                });
                this.itemCodeObject.dataSource = this.itemsList.filter(item => !existingItems.includes(item.value));
              }
            },
            blur: args => {
                if(this.itemCodeObject['isSelectCustom']){
                    this.itemCodeObject.value = null;
                }
            },
            created:() => {
              this.selectItemCode = args.rowData.itemCode;
            },
            change: event => {
                if ( event.value && event.item ) {
                  this.itemDescriptionObject.value = event.itemData?.itemDescription ? event.itemData.itemDescription : null;
                //   this.itemCodeObject.value = event.itemData?.item;
                  this.serviceItemGroup = event.itemData?.serviceItemGroup;
                  this.itemTypeObject.value = this.serviceItemGroup === this.commonService.coreItemServiceGroup ? IInventoryGroupType.core: IInventoryGroupType.standard;
                  let damagedExceptionCode = this.exceptionsDDL.find(row => [IReceiptsExceptionsTypes.damaged].includes(row.text));
                  if(this.exceptionTypeObject.value === damagedExceptionCode.text){
                    if(this.serviceItemGroup === this.commonService.coreItemServiceGroup){
                      this.itemReturnedObject.checked = true;
                    } else {
                      this.itemReturnedObject.checked = false;
                    }
                  }
                  
                //   this.itemGroup = event.itemData?.itemGroup;
                  this.itemChangedFromAdvanceSearch = false;
                //   this.exceptionQuantityObject.value = 1
                }
            }
        });
        this.itemCodeObject.appendTo(this.itemCode);
        this.addAdvancedSearchIcon(args.rowData.reasonDescription);
      }
    }
  }

  addAdvancedSearchIcon(reasonDescription) {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    itemAnchor.classList.add('d-none');
    if ([IReceiptsExceptionsTypes.damaged, IReceiptsExceptionsTypes.wrongpart].includes(reasonDescription)) {
      itemAnchor.classList.remove('d-none');
    }
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Advanced Search');
    itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.itemCode.parentElement.parentElement.append(itemAnchor);
  }

  showItemPopup() {
    this.showItemAdvancedSearchPopup = true;
    this.inventoryService.receiptsPopupLoader.next(true);
  }

  onItemAdvancedSearchPopupClose(data: any) {
    this.itemChangedFromAdvanceSearch = true;
  // // this.rowServiceItemGroup = data?.serviceItemGroup;
  if ( this.shipmentOrderExceptionsGrid.isEdit ) {
      if ( data ) {
          this.itemDescriptionObject.value = data?.itemDescription;
          this.itemCodeObject.dataSource = [{
              text: `${data?.item} | ${data?.itemDescription}`,
              value: data?.item,
              ...data
          }];
          this.itemCodeObject.value = data?.item;
      }
      setTimeout(() => {
          this.showItemAdvancedSearchPopup = false;
          this.inventoryService.receiptsPopupLoader.next(false);
      }, 100);
  } else {
      const rowIndex = this.shipmentOrderExceptionsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
      setTimeout(() => {
        this.shipmentOrderExceptionsGrid.selectRow(rowIndex);
          this.shipmentOrderExceptionsGrid.startEdit();
          if ( data ) {
            setTimeout(() => {
            this.itemDescriptionObject.value = data?.itemDescription;
            this.itemCodeObject.dataSource = [{
                text: `${data?.item} | ${data?.itemDescription}`,
                value: data?.item,
                ...data
              }];
              this.itemCodeObject.value = data?.item;
            }, 100);
          } else {
            this.showItemAdvancedSearchPopup = false;
            this.inventoryService.receiptsPopupLoader.next(false);
          }
      }, 100);
    }
  }

  editItemDescription() {
    return {
        create: () => {
            this.itemDescription = document.createElement('input');
            return this.itemDescription;
        },
        read: () => {
            if (this.itemDescriptionObject) {
                return this.itemDescriptionObject.value;
            }
        },
        destroy: () => {
            this.itemDescriptionObject.destroy();
            this.itemDescriptionObject = null;
        },
        write: (args) => {
            this.itemDescriptionObject = new TextBox({
                value: args.rowData.itemDescription,
                enabled: false,
                created:() => {
                  // this.selectItemDescription = args.rowData.itemDescription;
                },
            });
            this.itemDescriptionObject.appendTo(this.itemDescription);
        }
    }
  }

  editItemType() {
    return {
        create: () => {
            this.itemType = document.createElement('input');
            return this.itemType;
        },
        read: () => {
            if (this.itemTypeObject) {
                return this.itemTypeObject.value;
            }
        },
        destroy: () => {
            this.itemTypeObject.destroy();
            this.itemTypeObject = null;
        },
        write: (args) => {
            this.itemTypeObject = new TextBox({
                value: args.rowData.type,
                enabled: false,
            });
            this.itemTypeObject.appendTo(this.itemType);
        }
    }
  }

  editItemReturned() {
    return {
      create: () => {
          this.itemReturned = document.createElement('input');
          return this.itemReturned;
      },
      read: () => {
          if (this.itemReturnedObject) {
              return this.itemReturnedObject.checked;
          }
      },
      destroy: () => {
          this.itemReturnedObject.destroy();
          this.itemReturnedObject = null;
      },
      write: (args) => {
          this.itemReturnedObject = new CheckBox({
            checked: args.rowData.isReturn,
            disabled: true,
            value: args.rowData.isReturn
          });
          this.itemReturnedObject.appendTo(this.itemReturned);
      }
    }
  }

  getExceptionsData(){
    return {
      exceptionList:(this.shipmentOrderExceptionsGrid.dataSource as any).filter(row => {
        if(row.reasonCode && row.itemCode && row.quantity){
          row.warehouseOrderLN = row.warehouseOrderLN || this.itemsList[0].partsOrderRef;
          return row;
        }
      })
    }
  }

  onCheckboxClicked(event, data) {
    const rowIndex = this.exceptions.findIndex(col => col.tempRowId === data.tempRowId);
    let damagedExceptionCode = this.exceptionsDDL.find(row => [IReceiptsExceptionsTypes.damaged].includes(row.text));
    if(this.exceptions[rowIndex].reasonCode === damagedExceptionCode.value && this.exceptions[rowIndex].serviceItemGroup === this.commonService.coreItemServiceGroup && !event.checked){
      this.commonService.showNotification('warning', 'Core return items may only be discarded from your inventory when creating a damaged return order.');
    }
    (this.shipmentOrderExceptionsGrid.dataSource as any)[rowIndex]['isReturn'] = !data.isReturn;
    this.shipmentOrderExceptionsGrid.refresh();
  }

  validate(skipValidation){
    this.isTabValid = (this.shipmentOrderExceptionsGrid.dataSource as any)
                      .filter(row => 
                        (row.reasonCode && !row.itemCode) || 
                        (!row.reasonCode && row.itemCode) || 
                        (row.reasonCode && row.itemCode && row.quantity <= 0)
                      ).length <= 0;
    return this.isTabValid;
  }
}
