import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  @Output() onSelection = new EventEmitter<MenuEventArgs>();

  @Input() 
  items;

  @Input() 
  cssClass;

  @Input() 
  iconCss;

  constructor() { }

  ngOnInit(): void {
  }

}
