import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-po-response-failed',
  templateUrl: './po-response-failed.component.html',
  styleUrls: ['./po-response-failed.component.scss']
})
export class PoResponseFailedComponent implements OnInit {

  @Input() transactionErrorMessage = null;
  @Input() transactionErrorCode = null;
  @Input() transactionErrorSuggestion = null;

  @Output() retryPayment = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancel(){
    this.close.emit();
  }

  tryAgain(){
    this.retryPayment.emit();
  }

}
