<ejs-dashboardlayout 
    id='builder-layout{{layoutId}}' 
    class="builder-layout"
    #default_dashboard 
    [columns]="12" 
    [allowResizing]='true'
    [cellSpacing]="[15, 15]" 
    [cellAspectRatio]='2'
    (resizeStop)="onResize($event)"
    (change)="onLocationChange($event)"
>
    <e-panels>
        <e-panel 
            *ngFor="let item of pageStructure; let index = index;" 
            id="{{item.id}}"
            [sizeX]="item.sizeX" 
            [sizeY]="item.sizeY"
            [minSizeX]="item.minSizeX" 
            [minSizeY]="item.minSizeY" 
            [row]="item.row" 
            [col]="item.col"
        >
            <ng-template #content>
                <div class="field" [ngClass]="{'grid-field': item.type === 'grid'}" (click)="selectItem(item)">
                    <label>{{item.label}}</label>
                    <ejs-textbox 
                        *ngIf="item.type === 'textbox'" 
                        [placeholder]="item.placeholder"
                    ></ejs-textbox>
                    <ejs-numerictextbox *ngIf="item.type === 'number'" [placeholder]="item.placeholder" [min]="item.min"
                        [max]="item.max" [decimals]="item.decimals"></ejs-numerictextbox>
                    <ejs-datepicker *ngIf="item.type === 'datepicker'" [placeholder]="item.placeholder" [min]="item.min"
                        [max]="item.max"></ejs-datepicker>
                    <app-grid
                        *ngIf="item.type === 'grid'"
                        [gridUpdated]="showGrid"
                        [gridProps]="item"
                        [gridName]="gridName"
                        [preference]="preference"
                    ></app-grid>
                </div>
            </ng-template>
        </e-panel>
    </e-panels>
</ejs-dashboardlayout>