import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-barclay-failed',
  template: `
    <div class="text-center pt-5">
    <img class="img" height="200px" width="200px" src="../../../../../assets/img/shared/exclamation.png"/>
        <br><br>
        <h1>Transaction Failed</h1>
        <p>{{transactionErrorMessage}}</p>
        <p *ngIf="transactionErrorCode">Error code: {{transactionErrorCode}}</p>
        <p>Suggestion: {{transactionErrorSuggestion}}</p>

        <button type="button" class="btn btn-info btn-sm mr-2" (click)="cancel()">Change Payment Method</button>
        <button type="button" class="btn btn-success btn-sm" (click)="tryAgain()">Try Again</button>
    </div>`
})
export class BarclayFailedComponent implements OnInit {

    @Input() transactionErrorMessage = null;
    @Input() transactionErrorCode = null;
    @Input() transactionErrorSuggestion = null;

    @Output() retryPayment = new EventEmitter();
    @Output() close = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    cancel(){
        this.close.emit();
    }

    tryAgain(){
        this.retryPayment.emit();
    }
}