import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const BASE_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class IscService {

  constructor(private http: HttpClient) { }

  addToCart(payload) {
    // return this.http.post(`${BASE_URL}ISCPartsOrder/AddItemToCart`, payload);
    return this.http.post(`${BASE_URL}ISCPartsOrder/UpsertItemToCart`, payload);
    // click add to cart here, make loader true aqnd fale accordingly,  add item t cart api here
    // refresh the grid after each item added to the cart, update item added to the cart counter
  }

  deleteItemFromCart(item) {
    return this.http.post(`${BASE_URL}ISCPartsOrder/UpsertItemToCart`, item);
  }

  refreshPrice(item, quantity) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/RefreshItemPrice?item=${item}&orderQty=${quantity}&unit=ea`);
  }

  refreshItemDetails(item, quantity, unit) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/RefreshItemDetailsWebServicesCall?itemCode=${item}&quantity=${quantity}&unit=${unit}`);
  }


  getPrice(item, quantity, unit) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/RefreshItemPrice?item=${item}&orderQty=${quantity}&unit=${unit}`);
  }

  getUserCartItem() {
    return this.http.post(`${BASE_URL}ISCPartsOrder/GetUserCartItems`, {});
  }

  createIscPurchaseOrder(payload) {
    return this.http.post(`${BASE_URL}ISCPartsOrder/CreateISCPurchaseOrder`, payload);
  }

  getTechnicianAddress(warehouseId) {
    return this.http.get(`${BASE_URL}Warehouse/GetTechnicianAddresses?warehouseNumber=` + warehouseId);
  }

  getPurchaseOrders() {
    return this.http.get(`${BASE_URL}ISCPartsOrder/GetPurchaseOrders`);
  }

  updatePurchaseOrder(payload) {
    return this.http.post(`${BASE_URL}ISCPartsOrder/UpdatePurchaseOrder`, payload);
  }

  deletePurchaseOrder(payload) {
    return this.http.delete(`${BASE_URL}ISCPartsOrder/DeletePurchaseOrder?purchaseOrderId=${payload}`);
  }

  getPurchaseOrderDetails(poId) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/GetPurchaseOrderById?partsOrderId=` + poId);
  }

  getShippingMethodsForDDL(company) {
    return this.http.get(`${BASE_URL}ShippingMethod/GetShippingMethodsForDDL?company=` + company);
  }

  // new API for getting shipment methods dropdown list for purchase order creation
  getShippingMethodDDL(amount) {
    return this.http.get(`${BASE_URL}FreightCharges/GetShippingMethodsUsingSoldToBP?amount=${amount}`);
  }

  getPurchaseOrderById(poId) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/GetPurchaseOrderById?partsOrderId=` + poId);
  }

  // payment gateway

  authorizeCCPayment(payload) {
    return this.http.post(`${BASE_URL}CCPayment/AuthorizePurchaseOrderCCPayment`, payload);
  }

  // frieght charges

  getFrieghtCarges() {
    return this.http.get(`${BASE_URL}FreightCharges/GetFreightCharges`);
  }

  getFrieghtCargesById(fcId) {
    return this.http.get(`${BASE_URL}FreightCharges/GetFreightChargeById?freightChargeId=${fcId}`);
  }

  upsertFriehgtCharge(payload) {
    return this.http.post(`${BASE_URL}FreightCharges/UpsertFreightCharge`, payload);
  }

  deleteFriehgtCharge(payload) {
    return this.http.post(`${BASE_URL}FreightCharges/DeleteFreightCharge`, payload);
  }

  getShippingCost(code, desc, amount) {
    return this.http.get(`${BASE_URL}FreightCharges/GetShippingCost?carrierCode=${code}&carrierDesc=${desc}&amount=${amount}`);
  }

  processPurchasePayment(resp, poId) {
    return this.http.post(`${BASE_URL}CCPayment/ProcessPurchaseOrderCCResponse?responseUId=${resp}&purchaseOrderId=${poId}`, {});
  }

  getTax(empId) {
    return this.http.get(`${BASE_URL}ISCPartsOrder/GetTaxRateByISCEmployee?employeeId=${empId}`);
  }

  clearCart(empId) {
    return this.http.delete(`${BASE_URL}ISCPartsOrder/ClearMyCart?employeeId=${empId}`);
  }

  iscUserApproveReject(data) {
    return this.http.post(`${BASE_URL}UserProfile/ApproveISC?employeeId=${data.employeeId}&userApproval=${data.userApproval}`, null);
  }

  getCurrency(id) {
    return this.http.post(`${BASE_URL}SoldToBP/GetSoldToBPById?soldToBP=${id}`, null);
  }

  checkAvailability(items: any) {
    return this.http.post(`${BASE_URL}ISCPartsOrder/CheckAvailability`, items);
  }

}
