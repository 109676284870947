<app-barclay-success 
    *ngIf="transactionSuccessful"
    [transactionID]="transactionID"
    [transactionDate]="transactionDate"
    [transactionAmount]="transactionAmount"
    [transactionCurrency]="transactionCurrency"
    (close)="ccPaymentComplete()"
></app-barclay-success>

<app-barclay-failed 
    *ngIf="transactionFailed"
    [transactionErrorMessage]="transactionErrorMessage"
    [transactionErrorCode]="transactionErrorCode"
    [transactionErrorSuggestion]="transactionErrorSuggestion"
    (retryPayment)="retryPayment()"
    (close)="failedAndClose()"
></app-barclay-failed>