import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chase-response-success',
  templateUrl: './chase-response-success.component.html',
  styleUrls: ['./chase-response-success.component.scss']
})
export class ChaseResponseSuccessComponent implements OnInit {

  @Input() transactionID = null;
  @Input() transactionDate = null;
  @Input() transactionAmount = null;

  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  printInvoice(){
    this.close.emit();
  }

}
