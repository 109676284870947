import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CycleCountService } from './cycle-counts.service';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  providers: [CycleCountService]
})
export class CycleCountsModule { }
