<ejs-textbox 
    [autocomplete]='properties.autocomplete || null'
    [cssClass]='properties.cssClass || null'
    [enablePersistence]='properties.enablePersistence || false'
    [enableRtl]='properties.enableRtl || false'
    [enabled]='properties.enabled !== undefined ? properties.enabled : true'
    [htmlAttributes]='properties.htmlAttributes || null'
    [multiline]='properties.multiline || false'
    [placeholder]='properties.placeholder || null'
    [readonly]='properties.readonly || false'
    [showClearButton]='properties.showClearButton || false'
    [type]='properties.type || null'
    [value]='properties.value || null'

    (blur)='onBlur($event)' 
    (change)='onChange($event)' 
    (created)='onCreated($event)' 
    (destroyed)='onDestroyed($event)' 
    (focus)='onFocus($event)'
    (input)='onInput($event)'
></ejs-textbox>
