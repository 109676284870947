<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="ms-dynamic-modal"
    tabindex="-1"
    aria-labelledby="ms-dynamic-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-body">
                <iframe
                    *ngIf="showIframe"
                    [src]="iframeUrl"
                    width="100%"
                    height="100%"
                    style="visibility: visible; border: 1px solid transparent;"
                ></iframe>

                <app-chase-response-success
                    *ngIf="chasePaymentSuccessful"
                    [transactionID]="transactionID"
                    [transactionDate]="transactionDate"
                    [transactionAmount]="transactionAmount"
                    (close)="ccPaymentComplete($event)"
                ></app-chase-response-success>

                <app-chase-response-failed
                    *ngIf="chasePaymentFailed"
                    [transactionErrorMessage]="transactionErrorMessage"
                    [transactionErrorCode]="transactionErrorCode"
                    [transactionErrorSuggestion]="transactionErrorSuggestion"
                    (retryPayment)="retryPayment()"
                    (close)="failedAndClose()"
                ></app-chase-response-failed>

                <app-po-response-failed
                    *ngIf="chasePaymentFailedForPurchaseOrder"
                    [transactionErrorMessage]="transactionErrorMessage"
                    [transactionErrorCode]="transactionErrorCode"
                    [transactionErrorSuggestion]="transactionErrorSuggestion"
                    (retryPayment)="retryPayment()"
                    (close)="failedAndCloseForPurchaseOrder()"
                ></app-po-response-failed>

            </div>
        </div>
    </div>
</div>
