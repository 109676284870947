<div class="elements-container container-fluid p-0">
    <div class="row">
        <div class="col-4">
            <div class="field mb-3"> 
                <label>Problem Description:</label>
                <ejs-textbox 
                    class="border"
                    [placeholder]="'Enter Problem Description'" 
                    rows="6" 
                    [multiline]="true"
                    [readonly]="!isEditable || customerComplaintReadOnly"
                    [value]="data.customerComplaint"
                    (change)="data.customerComplaint = $event.value"
                ></ejs-textbox>
            </div>
        </div>
        <div class="col-4">
            <div class="field mb-3"> 
                <label>Diagnosis/Solution:</label><span *ngIf="data.status === serviceOrderStatuses.cancelled || isKingslynn" class="required-asterik">*</span>
                <ejs-textbox 
                    class="border"
                    #commentsToCustomer
                    [readonly]="!isEditable || commentsToCustomerReadOnly"
                    [placeholder]="'Enter Diagnosis/Solution...'" 
                    rows="6" 
                    [multiline]="true"
                    [value]="data.fstCommentsToCustomer"
                    (change)="fstCommentsToCustomerChange($event)"
                ></ejs-textbox>
            </div>
        </div>
        <div class="col-4" *ngIf="status === serviceOrderStatuses.cancelled">
            <div class="field mb-2"> 
                <label>Cancelation Note:</label>
                <div class="customer-address disabled-field border" style="min-height: 126px; padding: 4px 7px;">
                    <div *ngIf="data.reasonCode">{{data.reasonCode}} - {{data.reasonDescription}}</div>
                    <div *ngIf="data.cancellationDate">{{data.cancellationDate | date: 'MMM d, y hh:mm a'}}</div>
                    <div *ngIf="data.cancellationNotes">{{data.cancellationNotes}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
