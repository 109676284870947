<div
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="service-order-modal" 
    tabindex="-1" 
    aria-labelledby="service-order-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header 
                [id]="transferOrderId"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="transferOrderId ? 'Inventory Transfer Order No '+ transferOrderId : 'Inventory Transfer Order'"
                (onClose)="close()" 
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Technician' }}:<span class="required-asterik">*</span></label>
                                    <ejs-dropdownlist #requesterddl
                                        placeholder='Technician'
                                        [dataSource]='technicianList'
                                        [fields]="{text: 'technicianName', value: 'technicianId'}"
                                        [value]="data?.requester?.technicianId"
                                        [enabled]="false"
                                        (change)="onResourceChange($event)"
                                    ></ejs-dropdownlist>
                                    <i *ngIf="isCsa || isManager || isAdmin"
                                        (click)="showResourceSelectionPopup = true" 
                                        class="fas fa-user mt-2 ml-2" 
                                        style="color: #737373; cursor: pointer;"
                                        title="Select Requester"
                                    ></i>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2"> Status: </label>
                                    <ejs-dropdownlist #statusDDL
                                        id="statusddl"
                                        placeholder='Select Status'
                                        type='number' 
                                        format='n'
                                        [dataSource]='statusesDDL'
                                        [value]="data?.requester?.status"
                                        [enabled]="!statusDropdownReadOnly"
                                        (select)="statusChanged($event)"
                                    ></ejs-dropdownlist>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="transferOrderId">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="transferOrderId"
                                            [moduleId]="transferOrderId"
                                            module="TransferOrder"
                                            [comments]="data?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}" 
                                            data-toggle="tab"
                                            href="#inventory-transfer-details-tab"
                                            title="Details"
                                        >Details <i *ngIf="validationApplied && !validateDetails" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>
                                    <li class="nav-item" *ngIf="transferOrderId">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'attachments'}" 
                                            data-toggle="tab"
                                            href="#inventory-transfer-attachments-tab"
                                            title="Attachments" 
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'history'}" 
                                            data-toggle="tab"
                                            href="#inventory-transfer-history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="inventory-transfer-details-tab">
                                <div class="row">
                                    <div class="col">
                                        <div class="field mb-3 position-relative">
                                            <label>Ordering From: </label><span class="required-asterik">*</span>
                                            <ejs-textbox
                                                placeholder="Ordering From Tech"
                                                [value]="data?.responder?.technicianName"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>
                                        <div class="field mb-3 position-relative">
                                            <label>Quantity: </label><span class="required-asterik">*</span>
                                            <ejs-numerictextbox 
                                                #quantityField
                                                id="requested-quantity"
                                                required="true"
                                                name="quantity"
                                                placeholder="Quantity"
                                                [enabled]="data?.requester?.status === 'Open'"
                                                [showClearButton]="false"
                                                [showSpinButton]="false"
                                                [format]="'n'"
                                                [value]="data?.quantity"
                                                [min]="1"
                                                (keyup)="onKeyup($event)"
                                            ></ejs-numerictextbox>
                                            <div class="field-error" *ngIf="skipQuantityValidation && data?.quantity > onHandCount">{{data?.responder?.technicianName}} only has {{onHandCount}} on-hand.</div>
                                        </div>
                                        
                                        <div class="field mb-3 position-relative">
                                            <label class="mb-2">Service Order(s): </label>
                                            <i class="fas fa-search text-right ml-3 cursor-pointer" (click)="openServiceOrderSelectionPopup()" title="Search Service Orders"></i>
                                            <ejs-multiselect id='multiselectelement' 
                                                placeholder='Service Orders'
                                                mode="Box" 
                                                [dataSource]="data?.serviceOrderList" 
                                                [value]="data?.serviceOrderList"
                                                [enabled]="true" 
                                                [readonly]="false" 
                                                [showClearButton]="true"
                                                (change)="data.serviceOrderList = $event.value"
                                            ></ejs-multiselect>
                                        </div>
                                        
                                        <div class="field mb-3 position-relative">
                                            <label>Address: </label><span class="required-asterik">*</span>
                                            <ejs-dropdownlist #addressDDL
                                                id="addressddl"
                                                [dataSource]='techAddresses'
                                                [fields]="{text: 'address', value: 'address'}"
                                                [value]="data?.requester?.address"
                                                placeholder='Address'
                                                (change)="onAddressChange($event)"
                                            ></ejs-dropdownlist>
                                            
                                            <div class="customer-address disabled-field">
                                                <div *ngIf="data?.requester?.address">{{data?.requester?.address}}</div>
                                                <div *ngIf="data?.requester?.city">{{data?.requester?.city}}<span *ngIf="data?.requester?.state">, {{data?.requester?.state}}</span><span
                                                        *ngIf="data?.requester?.zipCode"> {{data?.requester?.zipCode}}</span></div>
                                                <div *ngIf="data?.requester?.country">{{data?.requester?.country}}</div>
                                                <div *ngIf="data?.requester?.phoneNumber">{{data.customerPhoneNumber}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Column seperator -->
                                    <div class="col">
                                        <div class="field mb-3 position-relative">
                                            <label>Item: </label><span class="required-asterik">*</span>
                                            <ejs-textbox
                                                placeholder="Item Code - Item Description"
                                                [value]="itemDescription"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>

                                        <div class="field mb-3 position-relative">
                                            <label>On Hand: </label>
                                            <ejs-textbox
                                                placeholder="Responder's On hand Quantity"
                                                [value]="onHandCount"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>

                                        <div class="field mb-3 position-relative">
                                            <label>Carrier: </label>
                                            <ejs-textbox
                                                placeholder="Carrier"
                                                [value]="data?.responder?.carrier"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>
                                        
                                        <div class="field mb-3 position-relative">
                                            <label>Tracking No.: </label>
                                            <ejs-textbox
                                                placeholder="Tracking Number"
                                                [value]="data?.responder?.trackingNumber"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>
                                        
                                        <div class="field mb-3 position-relative">
                                            <label>Date Shipped: </label>
                                            <ejs-datetimepicker
                                                placeholder="Estimated delivery"
                                                [openOnFocus]="true"
                                                [showClearButton]="true"
                                                [showTodayButton]="true"
                                                [enabled]="false"
                                                [value]="data?.responder?.dateShipped"
                                            ></ejs-datetimepicker>

                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="field mb-3">
                                            <app-notes
                                                [label]="'Notes'"
                                                [notes]="data?.notes"
                                                [showRte]="false"
                                                (notesChanged)="data.notes = $event"
                                            ></app-notes>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="inventory-transfer-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab 
                                    [moduleId]="data?.transferOrderId"
                                    module="TransferOrder"
                                    [attachments]="attachmentsData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="inventory-transfer-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [popup]="'Invetory Transfer Order'" 
                                    [history]="data?.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1" id="" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save(true)">Save &amp; Close</button>
                <!-- <button id="inventory-transfer-btn" type="button" class="btn btn-sm btn-primary mr-1" [disabled]="disablePlaceOrder" (click)="save(true, true)">Place Order</button> -->
            </div>
        </div>
    </div>
</div>

<app-resource-selection-popup 
    *ngIf="showResourceSelectionPopup"
    [responder]="data?.responder?.technicianId"
    (onClose)="onResourcePopupClose($event)"
></app-resource-selection-popup>

<app-link-service-orders-popup
    *ngIf="showServiceOrderSelectionPopup"
    [technicianId]="data?.requester?.technicianId"
    [selectedServiceOrderIDs]="data?.serviceOrderList"
    (onClose)="showServiceOrderSelectionPopup = false"
    (serviceOrderIdArray)="receiveServiceOrders($event)"
></app-link-service-orders-popup>
