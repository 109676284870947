<form #fieldsForm="ngForm" class="w-100" id="form-{{tabId}}-{{layoutId}}">
    <div class="container-fluid p-0" *ngIf="elements && elements.length">
        <div class="row">
            <ng-container *ngFor="let element of elements; let index = index;">
                <div class="w-100" *ngIf="index > 0 && element.row !== elements[index-1].row"></div>
                <div *ngIf="element.visible !== false" class="col-{{element.sizeX}}">
                    <div class="field position-relative">
                        <label 
                            *ngIf="element.showLabel" 
                            [class]="element.cssClass"
                            [ngClass]="{
                                'label-only': element.elementType === fieldTypes.label,
                                'label-top': element.position && element.position === positions.top,
                                'label-center': element.position && element.position === positions.center,
                                'label-bottom': element.position && element.position === positions.bottom
                            }"
                        >
                            {{element.elementLabel}} <span *ngIf="element.required && element.elementLabel" class="required-asterik">*</span>
                        </label>
                        <div *ngIf="element.elementType === fieldTypes.textbox">
                            <ejs-textbox
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [placeholder]="element.placeholder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [multiline]="element.multiline"
                                [width]="element.width"
                                [htmlAttributes]="module === 'Calibration' ? {name: element.elementKey, rows: element.rows} : {name: element.elementKey, minLength: element.min, maxLength: element.max, rows: element.rows}"
                                [showClearButton]="element.showClearButton"
                                (change)="onChange(element, $event); element.value = $event.value;"
                            ></ejs-textbox>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>
                        <div *ngIf="element.elementType === fieldTypes.numerictextbox">
                            <ejs-numerictextbox
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [placeholder]="element.placeholder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [format]="element.format"
                                [width]="element.width"
                                [min]="module === 'Calibration' ? null : element.min"
                                [max]="module === 'Calibration' ? null : element.max"
                                [decimals]="element?.decimals"
                                [step]="element.step"
                                [showSpinButton]="element.showSpinButton"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.value;"
                            ></ejs-numerictextbox>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>
                        <div *ngIf="element.elementType === fieldTypes.dropdownlist">
                            <ejs-dropdownlist
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [readonly]="element.readonly"
                                [dataSource]="element.dataSource"
                                [fields]="element.fields ? element.fields : {text: 'text', value: 'value'}"
                                [showClearButton]="element.showClearButton"
                                [fields]="element.fields"
                                [allowFiltering]="element.allowFiltering"
                                [placeholder]="element.placeholder"
                                [filterBarPlaceholder]="element.filterBarPlaceholder"
                                [sortOrder]="element.sortOrder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [popupWidth]="element.popupWidth"
                                [popupHeight]="element.popupHeight"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.itemData.value;"
                            ></ejs-dropdownlist>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>

                        <div *ngIf="element.elementType === fieldTypes.multiselect">
                            <ejs-multiselect
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [readonly]="element.readonly"
                                [dataSource]="element.dataSource"
                                [fields]="element.fields"
                                [showClearButton]="element.showClearButton"
                                [fields]="element.fields"
                                [allowFiltering]="element.allowFiltering"
                                [placeholder]="element.placeholder"
                                [filterBarPlaceholder]="element.filterBarPlaceholder"
                                [sortOrder]="element.sortOrder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [popupWidth]="element.popupWidth"
                                [popupHeight]="element.popupHeight"
                                [allowCustomValue]="element.allowCustomValue"
                                [closePopupOnSelect]="element.closePopupOnSelect"
                                [maximumSelectionLength]="element.maximumSelectionLength"
                                [openOnClick]="element.openOnClick"
                                [showDropdownIcon]="element.showDropdownIcon"
                                [showSelectAll]="element.showSelectAll"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.value;"
                            ></ejs-multiselect>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>
                        
                        <div *ngIf="element.elementType === fieldTypes.checkbox">
                            <ejs-checkbox
                                class="property-checkbox mt-1"
                                [id]="element.elementKey"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [readonly]="element.readonly"
                                [disabled]="!isEditable || element.disabled"
                                [label]="element.label"
                                [labelPosition]="element.labelPosition"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.checked"
                            ></ejs-checkbox>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                            <div *ngIf="element.elementType === fieldTypes.checkbox && element.cssClass" [class]="element.cssClass"></div>
                        </div>

                        <div *ngIf="element.elementType === fieldTypes.radiobutton" [class]="element.cssClass">
                            <div class="d-inline-block pr-2 mr-2">
                                <ejs-radiobutton
                                    class="mt-1 d-inline-block"
                                    [id]="element.elementKey"
                                    [required]="module === 'Calibration' ? false : element.required || false"
                                    [name]="element.elementKey"
                                    #field="ngModel"
                                    [(ngModel)]="element.value"
                                    [checked]="element.value === element.label ? true : false"
                                    [disabled]="!isEditable || element.disabled"
                                    [label]="element.label || 'First'"
                                    [labelPosition]="element.labelPosition"
                                    [readonly]="element.readonly"
                                    [value]="element.label"
                                    [htmlAttributes]="{name: element.elementKey}"
                                    (change)="onChange(element, $event); element.value = $event.value;"
                                ></ejs-radiobutton>
                                <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                            </div>
                            <div class="d-inline-block pl-2 pr-2">
                                <ejs-radiobutton
                                    class="mt-1 d-inline-block"
                                    [required]="module === 'Calibration' ? false : element.required || false"
                                    [name]="element.elementKey"
                                    #field="ngModel"
                                    [(ngModel)]="element.value"
                                    [checked]="element.value === element.label1 ? true : false"
                                    [disabled]="!isEditable || element.disabled"
                                    [label]="element.label1 || 'Second'"
                                    [value]="element.label1"
                                    [labelPosition]="element.labelPosition1"
                                    [readonly]="element.readonly"
                                    [htmlAttributes]="{name: element.elementKey}"
                                    (change)="onChange(element, $event); element.value = $event.value;"
                                ></ejs-radiobutton>
                                <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                            </div>
                            <div class="d-inline-block pl-2 pr-2" *ngIf="element.label2">
                                <ejs-radiobutton
                                    class="mt-1 d-inline-block"
                                    [required]="module === 'Calibration' ? false : element.required || false"
                                    [name]="element.elementKey"
                                    #field="ngModel"
                                    [(ngModel)]="element.value"
                                    [checked]="element.value === element.label2 ? true : false"
                                    [disabled]="!isEditable || element.disabled"
                                    [label]="element.label2"
                                    [value]="element.label2"
                                    [labelPosition]="element.labelPosition2"
                                    [readonly]="element.readonly"
                                    [htmlAttributes]="{name: element.elementKey}"
                                    (change)="onChange(element, $event); element.value = $event.value;"
                                ></ejs-radiobutton>
                                <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                            </div>
                        </div>
                        
                        <div *ngIf="element.elementType === fieldTypes.datepicker">
                            <ejs-datepicker
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [placeholder]="element.placeholder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [min]="element.minDate"
                                [max]="element.maxDate"
                                [format]="element.format"
                                [openOnFocus]="element.openOnFocus"
                                [showClearButton]="element.showClearButton"
                                [showTodayButton]="element.showTodayButton"
                                [start]="element.start || null"
                                [allowEdit]="element.allowEdit"
                                [width]="element.width"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.value;"
                            ></ejs-datepicker>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>
                        
                        <div *ngIf="element.elementType === fieldTypes.datetimepicker">
                            <ejs-datetimepicker
                                [id]="element.elementKey"
                                [class]="element.cssClass"
                                [required]="module === 'Calibration' ? false : element.required || false"
                                [name]="element.elementKey"
                                #field="ngModel"
                                [(ngModel)]="element.value"
                                [placeholder]="element.placeholder"
                                [readonly]="element.readonly"
                                [enabled]="isEditable && element.enabled"
                                [min]="element.minDate"
                                [max]="element.maxDate"
                                [openOnFocus]="element.openOnFocus"
                                [showClearButton]="element.showClearButton"
                                [showTodayButton]="element.showTodayButton"
                                [start]="element.start"
                                [allowEdit]="element.allowEdit"
                                [width]="element.width"
                                [htmlAttributes]="{name: element.elementKey}"
                                (change)="onChange(element, $event); element.value = $event.value;"
                            ></ejs-datetimepicker>
                            <div class="field-error" *ngIf="field.invalid && field.touched && field.errors.required">This field is required</div>
                        </div>
                        <div *ngIf="element.elementType === gridTypes.grid">
                            <app-syncfusion-grid
                                [gridProps]="element"
                            ></app-syncfusion-grid>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>