<div
    class="modal fade show in"
    [ngClass]="{'expanded': isExpanded}"
    id="checklist-modal"
    tabindex="-1"
    aria-labelledby="checklist-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
<app-loader *ngIf="showLoader"></app-loader>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <app-popup-header
                [isExpanded]="isExpanded"
                [title]="'Checklist'"
                (onClose)="close()"
                (onExpandCollapse)="expandCollapse($event)"
            ></app-popup-header>
            <div class="modal-body py-2">
                <p>Serialized Item Group - {{serializedItemGroupDescription}}</p>
                <div class="content mb-1">
                    <div class="size">
                        <ejs-dropdownlist
                            id='checklist-ddl'
                            [dataSource]='dropDownValues'
                            placeholder = 'Please select checklist'
                            (change)='onChecklistChange($event)'
                            [(value)]="selectedItem"
                            [enabled]="true"
                        ></ejs-dropdownlist>
                    </div>
                    <div class="right">
                        <button class="border color1" (click)="acceptAllClick($event)" ejs-button cssClass="e-link">Approve All</button>
                        <button class="border color2" (click)="rejectAllClick($event)" ejs-button cssClass="e-link">Reject All</button>
                    </div>
                </div>
                <ejs-grid
                    #checklistPopupGrid
                    [id]="checklistPopupGridId"
                    style="max-height: 860px;"
                    [dataSource]="gridData"
                    [allowFiltering]="true"
                    [allowSorting]="true"
                    [allowResizing]="true"
                    [allowReordering]="true"
                    [showColumnChooser]="true"
                    [allowExcelExport]="true"
                    [editSettings]="{ allowEditing: true }"
                    [toolbar]="checklistPopupGrid?.toolbar"
                    [rowHeight]="30"
                    [filterSettings]="{ type: 'Menu' }"
                    (toolbarClick)="toolbarClick($event, checklistPopupGrid)"
                    (created)="onGridCreated()"
                    (commandClick)="onCommandClick($event)"
                    (actionBegin)="onGridActionBegin($event)"
                    >
                    <e-columns>
                        <e-column
                            *ngFor="let column of gridColumns;"
                            [field]="column.field"
                            [isPrimaryKey]="column.isPrimaryKey || false"
                            [clipMode]="column.field === 'Actions' ? '' : 'EllipsisWithTooltip'"
                            [headerText]="column.headerText"
                            [type]="column.type"
                            [width]="column.width"
                            [format]="column.format ? column.format : null"
                            [filter]="column.filter ? column.filter : { type: 'Menu' }"
                            [textAlign]="column.textAlign"
                            [visible]="column.visible"
                            [edit]="column.edit"
                            [commands]="column?.commands ? column.commands : null"
                            [allowEditing]="column.allowEditing"
                            [allowSorting]="column.allowSorting"
                            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">

                            <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                <a href="javascript:" (click)="showComments = false; onServiceOrderClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                            </ng-template>

                            <ng-template *ngIf="column.field === 'status'" #template let-data>
                                <span class="grid-status-column {{data.isOverdue ? 'overdue' : getStatusClass(data.status)}}">
                                    <i class="fas fa-info mr-1" *ngIf="data.isOverdue"></i>
                                    {{data.status}}
                                </span>
                            </ng-template>

                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div class="modal-footer p-0">
                <button class="btn btn-sm btn-danger mr-2 comment-action-item" (click)="close()">Close</button>
                <button class="btn btn-sm btn-success mr-2 comment-action-item" (click)="saveChecklist(false, true)" [disabled]="disabledStatus">Save and Close</button>
                <button class="btn btn-sm btn-success comment-action-item" (click)="generate()" [disabled]="disabledStatus">Save & Generate</button>
            </div>
        </div>
    </div>
</div>


<ng-template #columnChooser>
    <app-column-chooser
        [columns]="checklistPopupGrid.columns"
        [grid]="checklistPopupGrid"
        [showColumnChooser]="showColumnChooser"
    ></app-column-chooser>
</ng-template>

<app-invoice-popup *ngIf="showInvoicePopup"
    [popupOpenedFrom]="InvoicePopupOpened.checklist"
    [serviceOrderData]="serviceOrderData"
    [checklistId]="hasChecklist"
    [checklistType]="selectedItem"
    [heading]="heading"
    [Object]="object"
    [checklistCertificate]="checklistCertificate"
    [invoice]="invoice"
    [isDraft]="false"
    [fieldRules]="fieldRules"
    [customerData]="customerData"
    [isSigned]="isSigned"
    (onSigned)="onInvoiceSigned($event)"
    (onClose)="onInvoicePopupClosed($event)"
    (certificateGenerate)="certificateGenerate.emit()"
></app-invoice-popup>
