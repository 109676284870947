<div class="card-header d-flex pl-0" id="headingTwo" data-toggle="collapse" href="#collapseTwo">
    <a class="card-link flex-fill">Exceptions</a>
    <i class="fas fa-chevron-up"></i>
</div>
<div id="collapseTwo" class="collapse show">
    <div class="card-body">
        <ejs-grid #shipmentOrderExceptionsGrid
            [id]="gridName"
            [dataSource]="exceptions"
            [height]="gridHeight"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [showColumnChooser]="true"
            [allowExcelExport]="true"
            [editSettings]="{ allowEditing: allowEditing, allowAdding: allowEditing, allowDeleting: allowEditing }"
            [filterSettings]="{ type: 'Menu' }"
            (toolbarClick)="onToolbarClick($event, shipmentOrderExceptionsGrid)"
            (created)="onGridCreated($event, shipmentOrderExceptionsGrid)"
            (actionComplete)="onGridActionComplete($event, shipmentOrderExceptionsGrid)"
            (actionBegin)="onActionBegin($event, shipmentOrderExceptionsGrid)"
            (commandClick)="commandColumnClick($event, shipmentOrderExceptionsGrid)"
            (load)="onLoad($event, shipmentOrderExceptionsGrid)"
            >
            <e-columns>
                <e-column 
                    *ngFor="let column of gridColumns;" 
                    [field]="column.field" 
                    [headerText]="column.headerText"
                    [clipMode]="'EllipsisWithTooltip'"
                    [isPrimaryKey]="column.isPrimaryKey || false"
                    [type]="column.type"
                    [edit]="column.edit"
                    [width]="column.width"
                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                    [format]="column.format ? column.format : null"
                    [headerTextAlign]="'left'" 
                    [textAlign]="column.textAlign" 
                    [visible]="column.visible"
                    [editType]="column.editType"
                    [showInColumnChooser]="column.showInColumnChooser ?? true"
                    [validationRules]="column?.validationRules || null"
                    [commands]="column?.commands ? column.commands : null"
                    [allowEditing]="column.allowEditing || false" 
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.field === 'isReturn'" #template let-data>
                        <ejs-checkbox #itemReturnedCB
                            [checked]="data.isReturn"
                            [disabled]="data.shoExceptionId || data.reasonDescription === 'Unknown'"
                            (change)="onCheckboxClicked($event, data)"
                        ></ejs-checkbox>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #shipmentOrderExceptionsGridColumnChooser>
    <app-column-chooser
        [grid]="shipmentOrderExceptionsGrid"
    ></app-column-chooser>
</ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [technicianId]="shipmentOrderData?.technicianId"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>

