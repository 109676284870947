import { Attachment } from '../../../shared/models/attachment.model';

export interface Invoice {
    _id?: string;
    technicianId: string;
    technicianName: string;
    paymentType: number | string | boolean;
    paymentTypeDescription: string;
    paymentAmount?: number;
    templateId?: any;
    templateDescription?: string;
    cardType?: string;
    status?: string;
    paymentAttachment?: Attachment;
    paymentAttachments?: Attachment[];
    lastUpdatedBy?: string;
    lastUpdatedByName?: string;
    checkAuthCode?: string;
    bankName?: string;
    createdBy?: string;
    createdByName?: string;
    createdDate?: Date | string;
    invoiceAttachment?: Attachment;
    invoiceId?: any;
    serviceOrderList: string[];
}

export enum PaymentTypes {
    cashOrMoneyOrder = '5',
    cheque = '10',
    creditCard = '15',
    purchaseOrder = '20',
    notApplicable = '25',
    notApplicableDescription = 'Not Applicable',
    invoice = '30',
    invoiceDescription = 'Invoice'
}

export const PaymentMethods = {
    cashOrMoneyOrder : {
        "paymentType": "5",
        "text": "CASH",
        "value": "CASH",
        "description": "Cash or Money Order",
        "valueList": null
    },
    cheque: {
        "paymentType": "10",
        "text": "CHECK",
        "value": "CHECK",
        "description": "Customer Check",
        "valueList": null
    },
    creditCard : {
        "paymentType": "15",
        "text": "CC",
        "value": "CC",
        "description": "Credit Card",
        "valueList": null
    },
    customerRefusedPayment : {
        "paymentType": "20",
        "text": "CRP",
        "value": "CRP",
        "description": "Customer Refused Payment",
        "valueList": null
    },
    purchaseOrder : {
        "paymentType": "20",
        "text": "PO",
        "value": "PO",
        "description": "Purchase Order - On Account",
        "valueList": null
    },
    notApplicable : {
        "paymentType": "25",
        "text": "NA",
        "value": "NA",
        "description": "Not Applicable",
        "valueList": null
    },
    invoice: {
        "paymentType": "30",
        "text": "NA",
        "value": "NA",
        "description": "Invoice",
        "valueList": null
    }
}

export const InvoiceStatuses = {
    created: "InvoiceCreated",
    pdfGenerated: "PdfGenerated",
    signed: "Signed"
}