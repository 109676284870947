import { ContextMenuItem, EditSettingsModel, FilterSettingsModel, GridLine, PageSettingsModel, SearchSettingsModel, SortSettingsModel, ToolbarItems } from "@syncfusion/ej2-angular-grids";
import { FieldTypes, LayoutSettings } from "./elements.model";

export enum GridTypes {
    grid = 'grid'
}

export interface GridElement {
    // general
    elementType: GridTypes;
    elementLabel?: string;
    elementId: number;
    elementKey: string;

    // layout related
    sizeX?: number;
    sizeY?: number;
    row?: number;
    col?: number;
    minSizeX?: number;
    minSizeY?: number;

    //grid
    allowExcelExport?: boolean;
    allowFiltering?: boolean;
    filterSettings?: FilterSettingsModel;
    allowPaging?: boolean;
    allowPdfExport?: boolean;
    allowReordering?: boolean;
    allowResizing?: boolean;
    allowSelection?: boolean;
    allowSorting?: boolean;
    showToolbar?: boolean;
    toolbarItems?: any[];
    columns: any[];
    pageSize: number;
    contextMenuItems?: ContextMenuItem[];
    dataSource?: any;
    editSettings?: EditSettingsModel;
    gridLines?: GridLine
    height?: string | number;
    pageSettings?: PageSettingsModel;
    query?: any;
    rowHeight?: number;
    selectedRowIndex?: number;
    searchSettings?: SearchSettingsModel;
    sortSettings?: SortSettingsModel;
    toolbar?: ToolbarItems[];
    width?: number | string;
    api?: string;
    allowActions?: boolean;
    actionItems?: any[];
}

export const GridProperties = [
    { key: 'elementId', label: 'Field Id:', placeholder: 'Enter field id...', type: FieldTypes.textbox },
    { key: 'elementLabel', label: 'Field Label:', placeholder: 'Enter field label...', type: FieldTypes.textbox, default: null },
    { key: 'elementKey', label: 'Field Key:', placeholder: 'Enter field key...', type: FieldTypes.textbox, default: null },
    { key: 'cssClass', label: 'Css Class:', type: FieldTypes.textbox, default: null },
    { key: 'rowHeight', label: 'Row Height:', type: FieldTypes.textbox, default: null },
    { key: 'height', label: 'Height:', type: FieldTypes.textbox, default: null },
    { key: 'width', label: 'Width:', type: FieldTypes.textbox, default: null },
    { key: 'visible', label: 'Visible?', type: FieldTypes.checkbox, default: true },
    { key: 'allowFiltering', label: 'Allow Filtering?', type: FieldTypes.checkbox, default: false },
    { key: 'allowPaging', label: 'Allow Paging?', type: FieldTypes.checkbox, default: false, id: 'grid-allow-pagination' },
    { key: 'pageSize', label: 'Page Size:', type: FieldTypes.numerictextbox, default: 20, min: 5 },
    { key: 'allowReordering', label: 'Allow Reordering?', type: FieldTypes.checkbox, default: false },
    { key: 'allowResizing', label: 'Allow Resizing?', type: FieldTypes.checkbox, default: false },
    { key: 'allowSelection', label: 'Allow Selection?', type: FieldTypes.checkbox, id: 'grid-allow-selection', default: false },
    { key: 'allowSorting', label: 'Allow Sorting?', type: FieldTypes.checkbox, default: false },
    { key: 'allowExcelExport', label: 'Allow Excel Export?', type: FieldTypes.checkbox, default: false },
    { key: 'allowPdfExport', label: 'Allow Pdf Export?', type: FieldTypes.checkbox, default: false },
    { key: 'gridLines', label: 'Show Grid Lines?', type: FieldTypes.checkbox, default: false },
    { key: 'allowActions', label: 'Show Actions Column?', type: FieldTypes.checkbox, id: 'grid-allow-actions', default: false },
    { key: 'showToolbar', label: 'Show Toolbar?', type: FieldTypes.checkbox, id: 'grid-show-toolbar', default: false },
    { key: 'api', label: 'Datasource API:', type: FieldTypes.dropdownlist, default: '', id: 'datasource-api', fields: { text: 'text', value: 'value' }, 
        dataSource: [
            { text: 'Pending Approval Exceptions List', value: 'ShipmentOrder/GetPendingApprovalExceptions?status=Pending Approval' }, 
            { text: 'Exceptions List', value: 'ShipmentOrder/GetAllShipmentExceptions' }, 
            { text: 'Adjustment Orders', value: 'ShipmentOrder/GetAdjustmentOrders'}
        ] 
    },
    { key: 'model', label: 'Data Model:', type: FieldTypes.textbox, default: '', id: 'grid-data-model' },
    ...LayoutSettings,
    // { key: 'contextMenuItems', label: 'Context Menu Items:', type: GridTypes.grid, col: '12', id: 'grid-context-menu-items', dataSource:[] },
]