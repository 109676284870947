import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { partsOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { CommonService } from 'src/app/shared/services/common.service';
import { Companies, UserRoles } from 'src/app/shared/models/app.model';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsTabComponent implements OnInit, OnDestroy {

  @Input() priorities: Object[];
  @Input() shipmentMethods: Object[];
  @Input() technicianAddresses: any;
  @Input() onsiteAddress: any = '';
  @Input() popupSource;
  @Input() details: any;
  @Input() status: string;
  @Input() isManager: boolean = false;

  @Output() tabUpdated = new EventEmitter();

  @ViewChild('address') address: DropDownList;
  @ViewChild('shipmentMethod') shipmentMethod;

  statuses = partsOrderStatuses;
  validationApplied: boolean = false;

  notesLabel: string = 'Notes';
  public checkType: string;
  isCSA: boolean = false;
  isIsc: boolean;
  isIscManager: boolean;
  isIscSupervisor: boolean;
  isKingslynn: boolean;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.isIsc = this.commonService.USER.role === UserRoles.isc;
    this.isIscManager = this.commonService.USER.role === UserRoles.iscManager;
    this.isIscSupervisor = this.commonService.USER.role === UserRoles.IscSupervisor;
    this.isCSA = this.commonService.USER.role === UserRoles.csa;
    this.isKingslynn = this.commonService.USER.company === Companies.kingslynn;
    if (this.popupSource === 'ServiceOrder') {
      this.checkType = this.isKingslynn ? 'onSite' : 'technician'; 
      setTimeout(() => {
        this.setAddress();
      }, 1000);
    }
    else {
      this.checkType = 'technician';
      this.setAddress();
    }
  }

  dateChanged(args) {
    this.details.estimatedDeliveryDate = args.itemData.value;
    this.tabUpdated.emit(this.details);
  }

  getData() {
    return this.details;
  }

  validate() {
    this.validationApplied = true;
    const validateAddress = this.isKingslynn ? true : this.address.value;
    if (this.shipmentMethod.value && validateAddress) {
      return true;
    } else {
      this.commonService.showNotification('error', 'Please provide all mandatory fields in details section..');
      return false;
    }
  }

  setAddress() {
    if (this.checkType === 'onSite') {
      this.address.text = this.onsiteAddress?.address;
      this.details.address = this.onsiteAddress?.address;
      this.details.addressName = this.onsiteAddress?.addressName || this.onsiteAddress?.name || '';
      this.details.addressCode = this.onsiteAddress?.addressCode || '';
      this.details.city = this.onsiteAddress?.city || '';
      this.details.state = this.onsiteAddress?.stateProvince || '';
      this.details.zipCode = this.onsiteAddress?.zipCode || '';
      this.details.country = this.onsiteAddress?.country || '';
    } else {
      const address = this.technicianAddresses[0];
      this.address.text = address?.address;
      this.address.value = address?.address;
      this.details.address = address?.address;
      this.details.addressName = address?.addressName || address?.name || '';
      this.details.addressCode = address?.addressCode;
      this.details.city = address?.city;
      this.details.state = address?.stateProvince;
      this.details.zipCode = address?.zipCode;
      this.details.country = address?.country;
    }
    this.address.enabled = this.checkType === 'technician'
    this.address.refresh();
    this.tabUpdated.emit(this.details);
  }

  radioButtonChanged(args) {
    if (args.value === 'onSite') {
      this.address.text = this.onsiteAddress?.address;
      this.details.address = this.onsiteAddress?.address;
      this.details.addressName = this.onsiteAddress?.name || this.onsiteAddress?.addressName || '';
      this.details.addressCode = this.onsiteAddress?.addressCode || '';
      this.details.city = this.onsiteAddress?.city || '';
      this.details.state = this.onsiteAddress?.stateProvince || '';
      this.details.zipCode = this.onsiteAddress?.zipCode || '';
      this.details.country = this.onsiteAddress?.country || '';
    } else {
      const address = this.technicianAddresses[0];
      this.address.text = address?.address;
      this.address.value = address?.address;
      this.details.address = address?.address;
      this.details.addressName = address?.addressName || address?.name || '';
      this.details.addressCode = address?.addressCode;
      this.details.city = address?.city;
      this.details.state = address?.stateProvince;
      this.details.zipCode = address?.zipCode;
      this.details.country = address?.country;
    }
    this.address.enabled = args.value === 'technician'
    this.address.refresh();
    this.tabUpdated.emit(this.details);
  }

  onAddressChange(args) {
    if (args.isInteracted) {
      this.details.address = args.itemData.address;
      this.details.addressName = args.itemData.addressName;
      this.details.addressCode = args.itemData.addressCode;
      this.details.city = args.itemData.city;
      this.details.state = args.itemData.stateProvince;
      this.details.zipCode = args.itemData.zipCode;
      this.details.country = args.itemData.country;
      this.tabUpdated.emit(this.details);
    }
  }


  ngOnDestroy() {
    this.details = null;
  }
}
