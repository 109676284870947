<!-- <div class="bg-red w-100 h-100"> Email popup</div> -->
<div
    class="modal fade show in"
    id="claims-modal"
    tabindex="-1"
    aria-labelledby="claims-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <app-loader *ngIf="showLoader"></app-loader>
    <!-- <div class="modal-dialog"> -->
    <form id="template_driven" #userForm="ngForm" class="modal-dialog" novalidate>
        <!-- <app-loader *ngIf="showLoader"></app-loader> -->
        <div class="modal-content" style="width: 800px;height: 650px;left: -30%;">
          <div class="modal-header">
            <div class="d-flex w-100">

                <div class="flex-fill" >
                  <h3 class="modal-title mb-0">
                    <i class="fas fa-envelope"></i> Send Email</h3>
                </div>

                <div class="btns-header">
                  <button
                      class="header-icon close p-0 m-0"
                      (click)="close()"
                      aria-label="Close"
                  ><i class="fas fa-times"
                        title="Close Popup"
                    ></i>
                  </button>
                  <!-- <button
                      class="close header-icon expand-modal p-0 m-0"
                      (click)="isExpanded=!isExpanded"
                  ><i class="fas ml-2 mr-2"
                      [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                      [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                    ></i>
                </button> -->
              </div>
            </div>
          </div>
            <div class="modal-body pt-0">
              <app-email-address-input #emailFormHeader
                [receiversEmails]="receiverEmail"
                [ccReceiversEmails]="ccEmail"
                [emailSubject]="emailSubject"
              ></app-email-address-input>
                <div class="row ">
                  <div class="col-sm-12">
                    <ejs-richtexteditor
                      id="emailbody"
                      name="emailbody"
                      #emailbody="ngModel"
                      [(ngModel)]="emailBody"
                    ></ejs-richtexteditor>
                  </div>
                </div>
            </div>
            <div class="modal-footer p-0">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-primary send-email" (click)="sendEmail()">Send Email</button>
            </div>
        </div>
    </form>
</div>
