import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommentDeleteModel, CommentUpdateModel } from '../models/comment.model';

const BASE_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private http: HttpClient) { }

  saveComment(data: CommentUpdateModel) {
    return this.http.put(`${BASE_URL}Comment/SaveComment`, data);
  }

  
  deleteComment(data: CommentDeleteModel) {
    return this.http.delete(`${BASE_URL}Comment/DeleteComment`, {body: data});
  }
  
  updateComment(data: CommentUpdateModel) {
    return this.http.put(`${BASE_URL}Comment/UpdateComment`, data);
  }

  saveClaimISCComment(data: CommentUpdateModel) {
    return this.http.put(`${BASE_URL}ISCClaims/SaveISCLineComment`, data);
  }

  saveClaimISCdeleteComment(data: CommentDeleteModel) {
    return this.http.delete(`${BASE_URL}ISCClaims/DeleteISCLineComment`, {body: data});
  }
  
  saveClaimISCupdateComment(data: CommentUpdateModel) {
    return this.http.put(`${BASE_URL}ISCClaims/UpdateISCLineComment`, data);
  }

  getCommentsByPrimaryId(primaryId: string, moduleName: string) {
    return this.http.get(`${BASE_URL}Comment/GetCommentsByPrimaryId?primaryId=${primaryId}&moduleName=${moduleName}`)
  }
}
