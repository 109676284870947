
export interface ClaimDetailsModel  {
  createdDate?: string, //"2022-07-17T10:45:38.986Z",
  createdBy?: string,
  lastUpdatedDate?: string, //"2022-07-17T10:45:38.986Z",
  lastUpdatedBy?: string,
  lastUpdatedByName?: string,
  id: 0,
  claimId: string,
  invoiceId: string,
  serviceDate: string,//"2022-07-17T10:45:38.986Z",
  customerPO: string,
  callType: string,
  configuration: string,
  model: string,
  serialNumber: string,
  installDate: string,//"2022-07-17T10:45:38.986Z", // date in a string as we get date toString
  contract: string,
  warranty: string,
  iscAddress: string,
  iscBusinessPartnerId: 0,
  lnId: string,
  serviceCenter: 0,
  customerAddress: string,
  customerName: string,
  payVia?: string,
}

export interface ClaimsModel {
  createdDate?: string,
  createdBy?: string,
  lastUpdatedDate?: string,
  lastUpdatedBy?: string,
  lastUpdatedByName?: string,
  claimId: string,
  serviceOrderId: string,
  technicianId: string,
  technicianName: string,
  model: string,
  customerDescription: string,
  status: string,
  isRuleChecked: boolean,
  claimDetails: ClaimDetailsModel,
  comments?: object[],
  history?: object[],
  attachment?: object[]
}

export interface ClaimModel {
    claimId?: string;
    submissionDate?: Date;
    serviceOrderId?: string;
    status?: string;
    invoiceId?: string;
    serviceDate?: Date;
    customerPO?: string;
    callGroup?: string;
    configuration?: string;
    customerDescription?: string;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    customerPhoneNumber?: string;
    customerEmail?: string;

    model?: string;
    serialNumber?: string;
    installDate?: Date;
    serviceContractCode?: string;
    contractDetails?: object;
    warrantyCode?: string;
    warrantyDescription?: object;
    contractWarrantyCoverageDescription?: object;
    
    ISCaddress?: string;
    ISCBPid?: number;
    LNid?: string;
    serviceCenter?: number;
    customerAddress?: string;
    customerName?: string;
    callType?: string;
    contract?: string;
    warranty?: string;

    iscAddress?: string;
    employeeName?: string;
    iscSoldToBP?: string;
    iscCity?: string;
    iscState?: string;
    iscCountry?: string;
    iscPhoneNumber?: string;
    iscEmail?: string;

    payVia?: any;
    claimDetails?: object;
}

export interface ClaimItemModel {

}

export const ISCUSersRoles = {
    ISCMAnager : "ISCManager"
}

export const ClaimStatuses = {
    open : 'Open',
    submitted : 'Submitted',
    inProgress : 'In Progress',
    awaitingInfo : 'Awaiting Information',
    pendingApproval : 'Pending Approval',
    approved : 'Approved',
    rejected : 'Rejected',
    flagged : 'Flagged',
    completed : 'Completed',
    paused: 'Paused'
}

export interface UpdateClaimsStatus {
  claimId: string;
  status: any;
  rejectionReason?: string | null;
}