import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { ApiResponse } from 'src/app/shared/models/app.model';
import { Attachment } from 'src/app/shared/models/attachment.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { environment } from 'src/environments/environment';
import { Invoice, PaymentMethods } from '../../models/payment-invoicing.model';
import { ServiceOrderService } from '../../services/service-order.service';
import { InvoicePopupOpenedFrom } from '../../models/service-order.model';

const token: string = localStorage.getItem('access_token');
const BASE_URL = environment.apiUrl;

@Component({
    selector: 'app-payment-type-popup',
    templateUrl: './payment-type-popup.component.html',
    styleUrls: ['./payment-type-popup.component.scss', '../../../../modules/service-order/components/popup-header/popup-header.component.scss'],
})
export class PaymentTypePopupComponent implements OnInit {

    USER;
    InvoicePopupOpenedFrom: any = InvoicePopupOpenedFrom;
    isExpanded: boolean = false;
    canGoNext: boolean = false;
    showLoader: boolean = false;
    showInvoicePopup: boolean = false;
    showCameraPopup: boolean = false;
    templateTypes = [];
    paymentTypes = PaymentMethods;
    invoiceReadOnly: boolean = false;

    @Input() data = null;
    @Input() invoice: Invoice = null;
    @Input() serviceOrderId = null;
    @Input() fieldRules: any;
    @Input() customerData: any;

    public dropElement: HTMLElement;
    public path: Object = {
        saveUrl: `${BASE_URL}BlobManager/UploadTempBlobAsync`,
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
    };

    @ViewChild('paymentAttachmentsUpload') public uploadObj: UploaderComponent;

    @Output() onClose = new EventEmitter();
    @Output() onNext = new EventEmitter();

    constructor(
        private commonService: CommonService,
        private serviceOrderService: ServiceOrderService
    ) {
        this.USER = commonService.USER;
        this.invoiceReadOnly = this.commonService.roleClaims['SO_Popup_Fields_invoice']?.readOnly
    }

    ngOnInit(): void {
        this.loadPage();
        if (this.invoice) {
            this.toggleNextButton();
            if (this.invoice.paymentAttachments && this.invoice.paymentAttachments.length) {
                this.invoice.paymentAttachments.map((attachment: Attachment) => {
                    attachment.fileType = attachment.fileType?.toLowerCase();
                });
            }
        }
        this.data = {
            paymentType: null
        };
        this.dropElement = document.getElementById('payment-attachment-droparea');
    }

    loadPage() {
        this.loadTemplateTypes();
    }

    loadTemplateTypes() {
        this.serviceOrderService.getTemplateTypes(this.invoice.serviceOrderList[0], this.USER.company)
            .subscribe((res: ApiResponse) => {
                if ( res.result ) {
                    this.templateTypes = res.result.filter( row => row.text !== 'Service Invoice - Billable - No Discount' && row.text !== 'Canada Service Invoice - Billable - NoDiscounts');
                    if(res.result.findIndex( row => row.text === 'Service Invoice - Billable - No Discount') > -1){
                        this.templateTypes.unshift(res.result.filter( row => row.text === 'Service Invoice - Billable - No Discount')[0])
                    } else if(res.result.findIndex( row => row.text === 'Canada Service Invoice - Billable - NoDiscounts') > -1){
                        this.templateTypes.unshift(res.result.filter( row => row.text === 'Canada Service Invoice - Billable - NoDiscounts')[0])
                    }
                    this.invoice.templateId = this.templateTypes[0]?.value;
                }
            }, (error: HttpErrorResponse) => {

            });
    }

    beforeFileUpload(args: any) {
        if (args.fileData.name.length > 250) {
            args.cancel = true;
        } else {
            args.currentRequest.setRequestHeader('Authorization',`Bearer ${token}`);
            this.showLoader = true;
        }
    }

    deleteAttachment(tempId: number) {
        if (this.invoiceReadOnly) {
            this.commonService.showPermissionNotification();
        } else {
            const index = this.invoice.paymentAttachments.findIndex(att => att.tempId === tempId);
            if (index > -1) {
                this.invoice.paymentAttachments.splice(index, 1);
            }
            this.toggleNextButton();
        }
    }

    onUploadSuccess(args: any) {
        this.showLoader = false;
        this.addNew(args)
    }

    addNew(args: any) {
        const file = args.file
        const response = JSON.parse(args.e.srcElement.responseText);
        if (!this.invoice.paymentAttachments) {
            this.invoice.paymentAttachments = [];
        }
        this.invoice.paymentAttachments.push({
            attachmentId: 0,
            fileName: file.name,
            tempId: this.invoice.paymentAttachments.length + 1,
            lastUpdated: file.rawFile.lastModifiedDate,
            size: this.getFileSize(file.size),
            fileSize: file.size,
            comment: 'New file',
            fileType: file.type.toLowerCase(),
            fileId: response.result.fileId,
            uri: response.result.uri,
            uploadedById: this.USER.userId,
            uploadedByName: this.USER.userName
        });
        this.toggleNextButton();
    }

    toggleNextButton() {
        if (
            ( this.invoice.paymentType == PaymentMethods.cashOrMoneyOrder.paymentType || this.invoice.paymentType == PaymentMethods.purchaseOrder.paymentType )
            || (this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.invoice.cardType)
            || (this.invoice.paymentType == PaymentMethods.cheque.paymentType && this.invoice.checkAuthCode && this.invoice.bankName )
            || (this.invoice.paymentType == PaymentMethods.notApplicable.paymentType)
            || (this.invoice.paymentType == PaymentMethods.invoice.paymentType)
        ) {
            this.canGoNext = true;
        } else {
            this.canGoNext = false;
        }
    }

    getFileSize(size: any) {
        return size > 1e+6 ? Math.round(size / 1e+6) + " MB" : Math.round(size / 1000) + " KB"
    }

    close() {
        this.onClose.emit();
    }

    next() {
        this.showLoader = true;
        this.serviceOrderService.updateInvoice(this.invoice)
        .subscribe((res: ApiResponse) => {
            if (res.isSuccessful) {
                this.onNext.emit(res.result);
            } else {
                this.commonService.showNotification('error', 'There was a problem updating invoice, please try again!');
            }
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.commonService.showNotification('error', 'There was a problem updating invoice, please try again!');
            this.showLoader = false;
        })
    }

    onCameraOpen() {
        if (this.invoiceReadOnly) {
            this.commonService.showPermissionNotification();
        } else {
            this.showCameraPopup = true;
        }
    }

    saveCameraCapture(data: any) {
        if ( data && data.imageName ) {
            if (!this.invoice.paymentAttachments) {
                this.invoice.paymentAttachments = [];
            }
            this.invoice.paymentAttachments.push({
                attachmentId: 0,
                fileName: data.imageName,
                tempId: this.invoice.paymentAttachments.length + 1,
                lastUpdated: new Date(),
                size: this.getFileSize(data.size),
                fileSize: data.size,
                comment: 'New file',
                fileType: 'jpg',
                fileId: data.imageName,
                uri: data.uri,
                uploadedById: this.USER.userId,
                uploadedByName: this.USER.userName
            });
            this.toggleNextButton();
            this.showCameraPopup = false;
        }
    }

    downloadFile(uri: string, fileName: string) {
        downloadResource(uri, fileName);
    }

}
