<form ngNoForm *ngIf="transactionInfo.hash" method="post" action={{environment?.barclaysFormURL}} id=form1 name=form1>
    <!--Mandatory, Hex value of the Request-->
    <!-- check before the payment: see Security: Check before the payment -->
    <input type="hidden" name="SHASIGN" id="SHASIGN" value={{transactionInfo.hash}}>

    <!-- general parameters: see Form parameters -->
    <!--Mandatory, Input values i.e. Text field-->
    <input type="hidden" name="AMOUNT" id="AMOUNT" value={{transactionInfo.amount}}>
    <input type="hidden" name="ORDERID" id="ORDERID" value={{transactionInfo.serviceOrderId}}>
    <input type="hidden" name="CN" id="CN" value={{transactionInfo.customerName}}>

    <!--Mandatory, Values from a configuration file i.e. web.config-->
    <input type="hidden" name="CURRENCY" id="CURRENCY" value={{transactionInfo.currency}}>
    <input type="hidden" name="LANGUAGE" id="LANGUAGE" value={{transactionInfo.language}}>
    <input type="hidden" name="PSPID" id="PSPID" value={{transactionInfo.pspID}}>
    
    <!--Optional, Values from a configuration file i.e. web.config-->
    <!--(Absolute) URL of your home page. When the transaction has been processed, your customer is requested to return to this URL via a button "Back to merchant site".When you send the value "NONE", the button leading back to the merchantâ€™s site will be text.-->
    
    <input type="hidden" name="HOMEURL" id="HOMEURL" value={{transactionInfo.homeURL}}>
    <input type="hidden" name="ACCEPTURL" id="ACCEPTURL" value={{transactionInfo.nxtURL}}>
    <input type="hidden" name="CANCELURL" id="CANCELURL" value={{transactionInfo.urlList}}>
    <input type="hidden" name="DECLINEURL" id="DECLINEURL" value={{transactionInfo.urlList}}>
    <input type="hidden" name="EXCEPTIONURL" id="EXCEPTIONURL" value={{transactionInfo.urlList}}>
    
    <!-- Optional, "RES" for Authorization OR "SAL" for SALES -->
    <input type="hidden" name="OPERATION" id="OPERATION" value={{transactionInfo.operation}}>

    <!-- Optional Additional feedback parameters -->
    <input type="hidden" name="COMPLUS" id="COMPLUS" value={{transactionInfo.complus}}>
    <input type="hidden" name="PARAMPLUS" id="PARAMPLUS" value={{transactionInfo.paramplus}}>

    <!-- Optional, Only display specific payment methods i.e. Visa, MasterCard-->
    <input type="hidden" name="PMLIST" id="PMLIST" value={{transactionInfo.pmList}}>

    <!-- Optional, display layout default is 0:Horizontally grouped logos with the group name on the left (default value) -->
    <input type="hidden" name="PMLISTTYPE" id="PMLISTTYPE" value={{transactionInfo.pmListType}}>

    <!-- Optional, Mode of Payment i.e. Credit Card-->
    <!-- <input type="hidden" name="PM" value=""> -->

    <!-- Optional, Only display specific payment method i.e. Visa-->
    <!-- <input type="hidden" name="BRAND" value=""> -->
    
    <!-- Optional, Only display specific payment methods i.e. Display "MasterCard", don't display one of its sub-brands i.e. "Maestro"-->
    <!-- <input type="hidden" name="EXCLPMLIST" value=""> -->
    <!-- Optional, Need to explore this option  -->
    <!-- <input type="hidden" name="CREDITDEBIT" value="D"> -->

    <!-- SNA Logo -->
    <input type="hidden" name="LOGO" id="LOGO" value={{transactionInfo.logo}}>
    <input type="hidden" name="TITLE" id="TITLE" value={{transactionInfo.title}}>
    <input type="hidden" name="WIN3DS" id="WIN3DS" value={{transactionInfo.win3ds}}>
    <input type="hidden" name="TP" value="">
    <input type="submit" value="Submit" id="barclay-form-submit" name="submit">
</form> 