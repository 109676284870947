
<div class="tab-content-container mt-3 mb-2">
    <ejs-grid #shipmentAdjustmentExceptionGrid
        *ngIf="showGrid"
        id="shipmentAdjustmentExceptionGrid"
        [dataSource]="data?.electronicAdjustmentOrderLines"
        [allowPaging]="false"
        [allowFiltering]="true"
        [allowSorting]="true"
        [allowResizing]="true"
        [allowReordering]="true"
        [showColumnChooser]="true"
        [allowExcelExport]="true"
        [filterSettings]="{ type: 'Menu' }"
        (created)="onGridCreated()"
        ><e-columns>
            <!-- (toolbarClick)="toolbarClick($event, shipmentAdjustmentExceptionGrid)"
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event, shipmentAdjustmentExceptionGrid)"
        (commandClick)="onCommandClick($event)"
        (dataBound)="dataBound($event)"
        (rowDataBound)="rowDataBound($event)" -->
            <!-- <e-column field='id' headerText='id' [width]='140' [isPrimaryKey]='true' [visible]='false' [showInColumnChooser]='false'></e-column> -->
            <e-column 
                *ngFor="let column of columns;" 
                [field]="column.field" 
                [headerText]="column.headerText"
                [clipMode]="'EllipsisWithTooltip'"
                [type]="column.type"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null"
                [visible]="column.visible"
                [width]="column.width"
                [showInColumnChooser]="column.showInColumnChooser ?? true"
                [textAlign]="column.textAlign">
                <ng-template *ngIf="column.field === 'reasonDescription'" #template let-data>
                    <span class="grid-status-column {{getStatusClass(data.reasonDescription)}}">{{data.reasonDescription}}</span>
                </ng-template>
                <ng-template *ngIf="column.field === 'shipmentOrderId'" #template let-data>
                    <a href="javascript:" (click)="openShipmentOrderPopup(data.shipmentOrderId)">{{data.shipmentOrderId}}</a>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>
</div>

<ng-template #shipmentAdjustmentExceptionGridColumnChooser>
    <app-column-chooser
        [grid]="shipmentAdjustmentExceptionGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-linked-popup-opener
    *ngIf="linkedPopupData"
    [data]="linkedPopupData"
    (onPopupClose)="linkedPopupData = null;"
></app-linked-popup-opener>
