import { Rule } from "./rule.model";
import { FontAwesomIconsList } from "./icons.model";
import { 
    Positions,
    FieldTypes, 
    LabelProperties, 
    TabProperties, 
    SectionProperties, 
    TextboxProperties, 
    CheckboxProperties, 
    RadioProperties, 
    NumericTextboxProperties, 
    DropdownListProperties, 
    MultiSelectProperties, 
    DatePickerProperties, 
    DateTimePickerProperties 
} from "./elements.model";
import { GridProperties, GridElement, GridTypes } from "./grid.model";

export { 
    FieldTypes, 
    Positions, 
    GridElement,
    GridTypes
};

export enum TextboxTypes { 
    text = 'text', 
    email = 'email',
    password = 'password'
}

export enum TypesOfElements { 
    tab = 'tab',
    section = 'section',
    element = 'element'
}

export enum SortTypes {
    None = 'None',
    Ascending = 'Ascending',
    Descending = 'Descending'
}

export enum ElementTypes {
    section = 'section',
    tab = 'tab',
    element = 'element',
    calibrationPopup = 'calibrationPopup'
};

export enum BuilderPageTypes {
    'Master' = 'Master',
    'Popup' = 'Popup',
    'Grid' = 'Grid',
    'NormalPopup' = 'NormalPopup'
};

export enum BuilderCalibrationTypes {
    'GenericCalibration' = 'Calibration_BSI',
    'GasCalibration' = 'Calibration_CA1',
    'ACMaintenance' = 'Calibration_CA2',
    'SmokeCalibration' = 'Calibration_CA3',
    'MOTPlayDetector' = 'Calibration_CA4',
    'BrakeTesterCalibration' = 'Calibration_CA5',
    'LiftLevel' = 'Calibration_CA6',
    'BeamsetterCalibration' = 'Calibration_CA7',
    'WheelAlignerCal' = 'Calibration_CA8',
    'WheelBalancer' = 'Calibration_CA9'
}

export enum BuilderModuleNames {
    'dashboard' = 'dashboard',
    'serviceOrder' = 'serviceOrder',
    'inventory' = 'inventory',
    'orders' = 'orders',
    'calibration' = 'calibration',
    'refrenceEquipment' = 'refrenceEquipment',
    'claims' = 'claims',
    'master' = 'master',
    'books'  = 'books'
};

export enum BuilderOrderTypes {
    'PartsOrders' = 'PartOrder',
    'ReturnOrders' = 'Returnrder',
    'ShipmentOrders' = 'ShipmentOrder',
    'AdjustmentOrders' = 'AdjustmentOrder'
}

export const ModuleNames = [
    { text: 'Dashboard', value: BuilderModuleNames.dashboard },
    { text: 'Service Order', value: BuilderModuleNames.serviceOrder },
    { text: 'Inventory', value: BuilderModuleNames.inventory },
    { text: 'Orders', value: BuilderModuleNames.orders },
    { text: 'Calibrations', value: BuilderModuleNames.calibration },
    { text: 'Ref. Equipment', value: BuilderModuleNames.refrenceEquipment },
    { text: 'Claims', value: BuilderModuleNames.claims },
    { text: 'Master Data', value: BuilderModuleNames.master },
    { text: 'Price Books', value: BuilderModuleNames.books}
];

export enum ModulesList {
    'InventoryAdjustmentOrder' = 'InventoryAdjustmentOrder'
}

export const CalibrationTypes = [
    { text: 'Generic Calibration', value: BuilderCalibrationTypes.GenericCalibration },
    { text: 'Gas Calibration', value: BuilderCalibrationTypes.GasCalibration },
    { text: 'Smoke Calibration', value: BuilderCalibrationTypes.SmokeCalibration },
    { text: 'MOT Play Detector Calibration', value: BuilderCalibrationTypes.MOTPlayDetector },
    { text: 'Brake Tester Calibration', value: BuilderCalibrationTypes.BrakeTesterCalibration },
    { text: 'Lift Load Test', value: BuilderCalibrationTypes.LiftLevel },
    { text: 'Beam Setter Calibration', value: BuilderCalibrationTypes.BeamsetterCalibration },
    { text: 'Level Standing Area', value: BuilderCalibrationTypes.WheelAlignerCal }
];

export const OrderTypes = [
    { text: 'Parts Orders', value: BuilderOrderTypes.PartsOrders },
    { text: 'Return Orders', value: BuilderOrderTypes.ReturnOrders },
    { text: 'Shipment Orders', value: BuilderOrderTypes.ShipmentOrders },
    { text: 'Adjustment Orders', value: BuilderOrderTypes.AdjustmentOrders }
];

export const SubModuleNames = {
    CalibrationTypes: CalibrationTypes,
    OrderTypes: OrderTypes
}

export const PageTypes = [
    { text: 'Edit Popup', value: 'Popup' },
    { text: 'Normal Popup', value: 'NormalPopup' },
    { text: 'Grid', value: 'Grid' }
];

export const getCalibrationName = (value: string) => {
    return CalibrationTypes.find(calibration => calibration.value === value).text;
}

export enum TabTypes {
    custom = 'custom', // custom tabs created by the builder
    attachments = 'attachments',
    history = 'history'
};

export const IconList = FontAwesomIconsList;

export interface DropdownlistFields {
    text: any;
    value: any
}
export interface Element {
    elementType: FieldTypes;
    elementId: number;
    elementKey: string;
    type?: TextboxTypes;
    elementLabel: string;
    placeholder?: string;
    filterBarPlaceholder?: string; // for dropdowns
    min?: any;
    max?: any;
    minDate?: Date;
    maxDate?: Date;
    cssClass?: string;
    readonly?: boolean;
    enabled?: boolean;
    width?: number | string;
    popupHeight?: number | string;
    popupWidth?: number | string;
    showClearButton?: boolean;
    showSpinButton?: boolean; // for numerictextbox
    multiline?: boolean; // for textbox
    sortOrder?: SortTypes;
    text?: any;
    value?: any;
    visible?: boolean;

    showLabel: boolean;
    
    // datepickers    
    allowEdit?: boolean;
    format?: string;
    showTodayButton?: boolean;

    fields?: DropdownlistFields;
    decimals?: number;
    dataSource?: any;
    api?: string;
    allowFiltering?: boolean;
    required?: boolean;

    // layout related
    sizeX?: number;
    sizeY?: number;
    row?: number;
    col?: number;
    minSizeX?: number;
    minSizeY?: number;

    // multiselect dropdown
    allowCustomValue?: boolean;
    closePopupOnSelect?: boolean;
    maximumSelectionLength?: number;
    openOnClick?: boolean;
    showDropdownIcon?: boolean;
    showSelectAll?: boolean;
}

export interface Section {
    sectionId: number;
    elementType: ElementTypes;
    isFull?: boolean;
    isValid?: boolean;
    sectionKey: string;
    sectionTitle: string;
    elements: Element[]
}

export interface CalibrationPage {
    _id?: string;
    elementType: ElementTypes;
    isPopup?: boolean;
    pageName: string;
    pageType: BuilderPageTypes;
    module?: string;
    subModule?: string;
    elements: string;
}

export interface Tab {
    tabId: number;
    elementType: ElementTypes;
    tabType: TabTypes;
    isActive: boolean;
    isValid?: boolean;
    tabTitle: string;
    tabKey: string;
    tabIcon?: string;
    sections: Section[]
};

export interface MSPopup {
    _id?: string;
    pageId: number;
    pageName: string;
    pageType: string;
    module?: string;
    subModule?: string;
    isPopup: boolean;
    apis?: {
        get: string;
        post: string;
        put: string;
        delete: string;
    };
    elements: {
        parentElements: {
            title: Element;
            resource: Element;
        };
        tabs: Tab[];
    };
    rules: Rule[];
    createdBy?: string;
    createdDate?: Date;
    lastUpdatedBy?: string;
    lastUpdatedDate?: Date;
};

export interface GridPage {
    _id?: string;
    pageId: number;
    pageName: string;
    module?: string;
    subModule?: string;
    pageType: BuilderPageTypes.Grid;
    isPopup: boolean;
    elements: any[];
    createdBy?: string;
    createdDate?: Date;
    lastUpdatedBy?: string;
    lastUpdatedDate?: Date;
};

export const ElementProperties = {
    [FieldTypes.label]: LabelProperties,
    [ElementTypes.tab]: TabProperties,
    [ElementTypes.section]: SectionProperties,
    [FieldTypes.textbox]: TextboxProperties,
    [FieldTypes.checkbox]: CheckboxProperties,
    [FieldTypes.radiobutton]: RadioProperties,
    [FieldTypes.numerictextbox]: NumericTextboxProperties,
    [FieldTypes.dropdownlist]: DropdownListProperties,
    [FieldTypes.multiselect]: MultiSelectProperties,
    [FieldTypes.datepicker]: DatePickerProperties,
    [FieldTypes.datetimepicker]: DateTimePickerProperties,
    [GridTypes.grid]: GridProperties
}
