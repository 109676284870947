import { formatDate } from '@angular/common';

export const dateFormat = 'd MMM, y';
export const dateTimeFormat = 'd MMM, y hh:mm';

export enum dateFormats {
    DMY = 'dd/MM/yyyy',
    MDY = 'MM/dd/yyyy'
}

export const getCurrentDate = () => {
    return formatDate(new Date(), dateFormat, 'en-US');
}
export const getUTCDate = (date?: any) => {
    date = date ? new Date(date) : new Date() 
    return date.toISOString();
}
export const getWeekStartAndEndDates = () => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const first = today.getDate() - today.getDay();
    const weekStart = first + 1;
    const weekEnd = weekStart + 6;
    const startDate = new Date(today.setDate(weekStart));
    const endDate = new Date(today.setDate(weekEnd));
    let firstday = new Date(startDate.setHours(0,0,0)).toUTCString();
    let lastday = new Date(endDate.setHours(23,59,59)).toUTCString();
    return { StartDate: firstday, EndDate: lastday};
}
export const getNextWeekStartAndEndDates = () => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const first = today.getDate() - today.getDay();
    const nextWeekStart = first + 8;
    const nextWeekEnd = nextWeekStart + 6;
    const startDate = new Date(today.setDate(nextWeekStart));
    const endDate = new Date(today.setDate(nextWeekEnd));
    let firstday = new Date(startDate.setHours(0,0,0)).toUTCString();
    let lastday = new Date(endDate.setHours(23,59,59)).toUTCString();
    return { StartDate: firstday, EndDate: lastday};
}
export const getNextWeekTwoStartAndEndDates = () => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const first = today.getDate() - today.getDay();
    const nextTwoWeekStart = first + 8;
    const nextTwoWeekEnd = nextTwoWeekStart + 13;
    const startDate = new Date(today.setDate(nextTwoWeekStart));
    const endDate = new Date(today.setDate(nextTwoWeekEnd));
    let firstday = new Date(startDate.setHours(0,0,0)).toUTCString();
    let lastday = new Date(endDate.setHours(23,59,59)).toUTCString();
    return { StartDate: firstday, EndDate: lastday};
}

export const getMonthStartAndEndDates = () => {
    const today = new Date();
    today.setHours(0,0,0,0);
    const year = today.getFullYear(), month = today.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    let firstDate = new Date(firstDay.setHours(0,0,0)).toUTCString();
    let lastDate = new Date(lastDay.setHours(23,59,59)).toUTCString();
    return { StartDate: firstDate, EndDate: lastDate };
}

export const calculateMinMaxDates = (number: number) => {
    if ( number !== undefined && number !== null ) {
        const today = new Date();
        today.setHours(0,0,0,0);
        today.setDate(today.getDate() + number);
        return new Date(today);
    } else {
        return null;
    }
}