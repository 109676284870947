import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chase-response-failed',
  templateUrl: './chase-response-failed.component.html',
  styleUrls: ['./chase-response-failed.component.scss']
})
export class ChaseResponseFailedComponent implements OnInit {

  @Input() transactionErrorMessage = null;
  @Input() transactionErrorCode = null;
  @Input() transactionErrorSuggestion = null;

  @Output() retryPayment = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancel(){
    this.close.emit();
  }

  tryAgain(){
    this.retryPayment.emit();
  }
}
