import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-cancel-status-popup',
  templateUrl: './cancel-status-popup.component.html',
  styleUrls: ['./cancel-status-popup.component.scss']
})
export class CancelStatusPopupComponent implements OnInit {

  @Input() cancelReasonsList = [];

  @Output() onClose = new EventEmitter();

  isExpanded: boolean = false;
  showLoader: boolean = false;
  reasonCode: string = null;
  reasonText: string = null;
  reasonDatetime = new Date();
  reasonNote: string = null;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  save() {
    this.commonService.showConfirmation('Are you sure? Service order cancelation can not be reverted.')
    .then( result => {
        if ( result.isConfirmed ) {
          this.onClose.emit({isConfirmed: true, cancellationCode: this.reasonCode, cancellationText: this.reasonText, cancellationDate: this.reasonDatetime, cancellationNote: this.reasonNote});
        } else {
          this.reasonCode = null;
          this.reasonDatetime = new Date();
          this.reasonNote = null;
          this.onClose.emit({isConfirmed: false});
        }
    });
  }

  close() {
    this.onClose.emit({isConfirmed: false});
  }

}