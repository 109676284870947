<div class="text-center pt-5">
  <img class="img" height="200px" width="200px" src="../../../../../assets/img/shared/check.png"/>
  <br><br>
  <h1>Transaction Successful</h1>
  <p>Transaction Id: {{transactionID}}</p>
  <p>Date: {{transactionDate | date: 'MMM d, y hh:mm a'}}</p>
  <p>Amount: {{transactionAmount}}</p>

  <button class="btn btn-success btn-sm"  (click)="printInvoice()">Print Invoice</button>
</div>
