import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ServiceOrderService } from '../../services/service-order.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements AfterViewInit {
    WIDTH = 480;
    HEIGHT = 360;
    stream = null;
    @ViewChild("video")
    public video: ElementRef;
    isExpanded: boolean = false;
    showLoader: boolean = false;
    @ViewChild("canvas")
    public canvas: ElementRef;

    captures: string[] = [];
    error: any;
    isCaptured: boolean;

    @Output() onSave = new EventEmitter();
    @Output() onClose = new EventEmitter();


    constructor(
        private serviceOrderService: ServiceOrderService
    ) { }

    async ngAfterViewInit() {
        await this.setupDevices();
    }

    async setupDevices() {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            try {
                this.stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                });
                if (this.stream) {
                    this.video.nativeElement.srcObject = this.stream;
                    this.video.nativeElement.play();
                    this.error = null;
                } else {
                    this.error = "You have no output video device";
                }
            } catch (e) {
                this.error = e;
            }
        }
    }

    capture() {
        this.drawImageToCanvas(this.video.nativeElement);
        this.captures = this.canvas.nativeElement.toDataURL("image/png");
        this.isCaptured = true;

    }
    savePhoto() {
        let fileToUpload = this.canvas.nativeElement.toDataURL();
        let imageData = {
            Imagebase64: fileToUpload,
            ImageName: Date.now()
        };
        this.showLoader = true;
        this.serviceOrderService.saveImage(imageData)
        .subscribe((res: any) => {
            this.canvas.nativeElement.toBlob( (blob) => {
                this.onSave.emit({uri: res.ImageUrl, base64Image: fileToUpload, size: blob.size, imageName: imageData.ImageName});
                this.showLoader = false;
            });
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }
    
    removeCurrent() {
        this.isCaptured = false;
    }

    setPhoto(idx: number) {
        this.isCaptured = true;
        const image = new Image();
        image.src = this.captures[idx];
        this.drawImageToCanvas(image);
    }

    drawImageToCanvas(image: any) {
        this.canvas.nativeElement
        .getContext("2d")
        .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    }

    async stopStream() {
        if (this.stream) {
            this.video.nativeElement.pause();
            this.stream = null;
        }
    }

    close() {
        this.onClose.emit();
    }
}
