<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="return-order-modal"
    tabindex="-1"
    aria-labelledby="return-order-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header
                [id]="id"
                [isExpanded]="isExpanded"
                [createdDate]="data ? data.createdDate : null"
                [title]="id ? id: 'Return Order'"
                (onClose)="close()"
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0" id="return-order-popup-body">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col resource-wrapper">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Technician' }}:<span class="required-asterik">*</span></label>
                                    <div class="col pt-2"> {{ data.technicianName }}</div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2">{{ 'Status' }}:</label>
                                    <div class="col pt-2"> {{ data.status }} </div>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="id">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="id"
                                            [moduleId]="id"
                                            module="ReturnOrder"
                                            [comments]="data?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === detailsTab}"
                                            data-toggle="tab"
                                            href="#return-order-details-tab"
                                            title="Details"
                                        >Details  <i *ngIf="validationApplied && !validateDetails" class="fas fa-exclamation-circle validation-exclamation"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === linksTab, 'color-red': data?.openLinkCount, 'color-green': data?.linkCount && data?.openLinkCount === 0}"
                                            data-toggle="tab"
                                            href="#return-order-links-tab"
                                            title="Links"
                                            aria-expanded="true"
                                        ><i class="fas fa-link"></i></a>
                                    </li>
                                    <li class="nav-item" *ngIf="id">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === attachmentsTab}"
                                            data-toggle="tab"
                                            href="#return-order-attachments-tab"
                                            title="Attachments"
                                            aria-expanded="true"
                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            [ngClass]="{'active': tab === historyTab}"
                                            data-toggle="tab"
                                            href="#history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div id="return-order-details-tab" class="tab-pane mt-3" [ngClass]="{'active show': tab === detailsTab}">
                              <div class="card">
                                <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-return-collapse-details">
                                    <a class="card-link flex-fill">General</a>
                                    <i class="fas fa-chevron-up"></i>
                                </div>
                                <div id="general-return-collapse-details" class="collapse show container-fluid mb-2">
                                    <app-return-order-popup-details-tab #detailsTab 
                                        *ngIf="shipmentMethods"
                                        [status]="data?.status"
                                        [details]="detailsData"
                                        [shipmentMethods]="shipmentMethods"
                                        [categories]="categories"
                                        (tabUpdated)="detailsTabUpdated($event)"
                                        (categoryChanged)="onCategoryChanged($event)">
                                    </app-return-order-popup-details-tab>
                               </div>
                              </div>
                                <div class="card">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#general-return-collapse-items">
                                        <a class="card-link flex-fill">Items</a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                <div id="general-return-collapse-items" class="collapse show">
                                    <app-return-order-items-tab #itemsTab
                                        *ngIf="returnItemsReasons && returnItemsReasons.length"
                                        [status]="data?.status"
                                        [category]="category"
                                        [warehouse]="data.warehouse"
                                        [technicianId]="data?.technicianId"
                                        [items]="itemsData"
                                        [inventoryItems]="inventoryItems"
                                        [reasonsDDL]="returnItemsReasons">
                                    </app-return-order-items-tab>
                                </div>
                            </div>
                            </div>
                            <div id="return-order-links-tab" class="tab-pane" [ngClass]="{'active show': tab === linksTab}">
                                <app-links-tab-shared #linksTab
                                    [links]="links"
                                    (onLinkIdClick)="onLinkIdClick.emit($event)"
                                ></app-links-tab-shared>
                            </div>
                            <div id="return-order-attachments-tab" class="tab-pane" [ngClass]="{'active show': tab === attachmentsTab}">
                                <app-attachments-tab
                                    [moduleId]="data?.returnOrderId"
                                    module="ReturnOrder"
                                    [attachments]="attachmentsData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="history-tab" class="tab-pane" [ngClass]="{'active show': tab === historyTab}">
                                <app-history-tab-shared
                                    [popup]="'Return Order'"
                                    [history]="data?.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1 reset" id="" [disabled]="isManager" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1" [disabled]="isManager" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1" [disabled]="isManager" (click)="save(true)">Save &amp; Close</button>
                <button id="return-order-btn" type="button" class="btn btn-sm btn-primary mr-1" [disabled]="isManager || isCSA || placeOrderBtnDisabled" (click)="save(true, true)">Return Order</button>
            </div>
        </div>
    </div>
</div>
