<div class="card-header d-flex pl-0" id="headingOne" data-toggle="collapse" href="#collapseOne">
    <a class="card-link flex-fill">Shipped Items</a>
    <i class="fas fa-chevron-up"></i>
</div>

<div id="collapseOne" class="collapse show">
    <div class="card-body">
        <ejs-grid #shipmentOrderReceiptsGrid
            [id]="gridName"
            [dataSource]="receipts"
            [height]="gridHeight"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [showColumnChooser]="true"
            [allowExcelExport]="true"
            [editSettings]="{ allowEditing: allowEditing, allowAdding: false, allowDeleting: false }"
            [filterSettings]="{ type: 'Menu' }"
            (queryCellInfo)="queryCellInfo($event, shipmentOrderReceiptsGrid)"
            (toolbarClick)="onToolbarClick($event, shipmentOrderReceiptsGrid)"
            (created)="onGridCreated($event, shipmentOrderReceiptsGrid)"
            (actionComplete)="onActionComplete($event, shipmentOrderReceiptsGrid)"
            (load)="onGridLoad($event, shipmentOrderReceiptsGrid)"
            >
            <!-- (actionBegin)="onReceiptsGridActionBegin($event)" -->
            <e-columns>
                <e-column 
                    *ngFor="let column of gridColumns;" 
                    [field]="column.field" 
                    [headerText]="column.headerText"
                    [clipMode]="'EllipsisWithTooltip'"
                    [type]="column.type"
                    [edit]="column.edit"
                    [width]="column.width"
                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                    [format]="column.format ? column.format : null"
                    [headerTextAlign]="'left'" 
                    [textAlign]="column.textAlign" 
                    [visible]="column.visible"
                    [showInColumnChooser]="column.showInColumnChooser ?? true"
                    [validationRules]="column?.validationRules || null"
                    [commands]="column?.commands ? column.commands : null"
                    [allowEditing]="column.allowEditing || false" 
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true"
                ></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>


<ng-template #shipmentOrderReceiptsGridColumnChooser>
    <app-column-chooser
        [grid]="shipmentOrderReceiptsGrid"
    ></app-column-chooser>
</ng-template>