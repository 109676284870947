import { Component, Input } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
    selector: 'app-column-chooser',
    templateUrl: './column-chooser.component.html',
    styleUrls: ['./column-chooser.component.scss']
})
export class ColumnChooserComponent {

    @Input() grid: GridComponent;

    onClick() {
        this.grid.columnChooserModule.openColumnChooser(0, 40)
    }
}
