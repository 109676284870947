import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class CycleCountService {

    popupLoader: Subject<any> = new Subject();
    receiptsPopupLoader: Subject<any> = new Subject();
    getData: Subject<any> = new Subject();

    constructor(private http: HttpClient) { }

    getStatusesForDDL(moduleName: any) { // 'PartsOrder' as parameter
        return this.http.get(`${BASE_URL}status/GetStatusesForDDL?moduleName=${moduleName}`);
    }

    updateCycleCount(data: any) {
        return this.http.post(`${BASE_URL}CycleCount/UpdateCycleCount`, data);
    }

    deleteCycleCount(id){
        return this.http.delete(`${BASE_URL}CycleCount/DeleteCycleCount?cycleCountId=${id}`);
    }

    updateCycleCountStartDate(data: any) {
        return this.http.post(`${BASE_URL}CycleCount/UpdateCycleCountStartDate`, data);
    }

    updateCycleCountStatus(data: any) {
        return this.http.post(`${BASE_URL}CycleCount/UpdateCycleCountStatus`, data);
    }

    createManyCycleCounts(cycleCountList) {
        return this.http.post(`${BASE_URL}CycleCount/BulkCreateCycleCount`, cycleCountList);
    }

    importCycleCountDetail(formData) {
        return this.http.post(`${BASE_URL}CycleCount/ImportCycleCountDetail`, formData);
    }

    uploadCycleCountDetail(id,data) {
        return this.http.post(`${BASE_URL}CycleCount/SaveCycleCountDetail?cycleCountId=${id}`, data);
    }

    getCycleCountById(id) {
        return this.http.get(`${BASE_URL}CycleCount/GetCyclecountById?cycleCountId=${id}`);
    }

    checkIfCycleCountNoExists(id) {
        return this.http.get(`${BASE_URL}CycleCount/CheckIfCycleCountNoExists?cycleCountNo=${id}`);
    }
}
