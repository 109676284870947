<div
    class="modal fade show"
    [ngClass]="{'expanded': isExpanded}"
    id="item-advance-search-modal"
    tabindex="-1"
    aria-labelledby="item-advance-search-modalLabel"
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Items</h4>
                        <!-- <h4 class="modal-title mb-0 item-id">{{ gridType === 'config' ? 'Configurations' : 'Customers' }}</h4> -->
                    </div>
                    <div class="btns-header ml-auto">
                        <button
                            class="header-icon close p-0 m-0"
                            (click)="close()"
                            aria-label="Close"
                        >
                            <i
                                class="fas fa-times"
                                title="Close Popup"
                            ></i>
                        </button>
                        <button
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i
                                class="fas ml-2 mr-2"
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body pt-3">
                <div class="row">
                    <div class="col">
                        <ejs-grid
                            *ngIf="mainGrid"
                            #itemsAdvancedSearchGrid
                            [height]="gridHeight"
                            [query]="query"
                            [allowPaging]="true"
                            [pageSettings]="pageSettings"
                            [dataSource]="data"
                            [allowFiltering]="true"
                            [allowResizing]="true"
                            [allowReordering]="true"
                            [allowSorting]="true"
                            [toolbar]="mainGrid?.toolbar"
                            [sortSettings]="{columns: [{field: 'onHand', direction: 'Desending'}] }"
                            [filterSettings]="{ type: 'Menu' }"
                            [allowSelection]='true'
                            (toolbarClick)="toolbarClick($event, itemsAdvancedSearchGrid)"
                            (commandClick)="onCommandClick($event)"
                            (rowSelected)='rowSelected($event)'
                            (created)="onGridCreated($event)"
                            (actionBegin)="onGridActionBegin($event)"
                            (actionComplete)="onGridActionComplete($event, itemsAdvancedSearchGrid)"
                            >
                            <e-columns>
                                <e-column
                                    *ngFor="let column of mainGrid.columns;"
                                    [field]="column.field"
                                    [headerText]="column.headerText"
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [format]="column.format ? column.format : null"
                                    [filterType]="'contains'"
                                    [textAlign]="column.textAlign"
                                    [visible]="column.visible"
                                    [commands]="column?.commands ? column.commands : null"
                                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                    [allowEditing]="column.allowEditing"
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                    <ng-template *ngIf="column.field === 'item'" #template let-data>
                                        <a href="javascript:" (click)="onCommandClick({commandColumn: {title: 'Proceed'}, rowData: data})">{{data.item}}</a>
                                    </ng-template>
                                    <ng-template *ngIf="column.field === 'status'" #template let-data>
                                        <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                                    </ng-template>
                                    <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                        <a href="javascript:" (click)="onServiceOrderClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #onHandCheckbox>
  <ejs-checkbox
    class="property-checkbox mt-1"
    *ngIf="![UserRoles.isc, UserRoles.iscManager, UserRoles.IscSupervisor].includes(USER.role)"
    [checked]="isOnHandChecked"
    [label]="'On Hand'"
    (change)="onHoldCheckboxChange($event)"
  ></ejs-checkbox>
</ng-template>
