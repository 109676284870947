import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateTimePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { forkJoin } from 'rxjs';
import { Companies } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { ExecutionTabService } from '../../services/execution-tab.service';
import { ServiceOrderService } from '../../services/service-order.service';

@Component({
    selector: 'app-isc-assignments-popup',
    templateUrl: './isc-assignments-popup.component.html',
    styleUrls: ['./isc-assignments-popup.component.scss']
})
export class IscAssignmentsPopupComponent implements OnInit {

    showLoader: boolean = false;
    isExpanded: boolean = false;
    showPopup: boolean = false;
    prevRefActivity: string = '';
    
    @Input() assignments;
    @Input() assignmentDetails;
    @Input() referenceActivities;
    @Input() reportedProblems;
    @Input() solutions;
    @Input() priorities;

    minDate = new Date();
    refActivityDDL: any;

    @Input() data = null;
    @Input() equipmentInfo = null;
    @Input() soCreatedDate = null;

    @ViewChild('assignmentForm') assignmentForm: NgForm;
    @ViewChild('startDatePicker') startDatePicker: DateTimePickerComponent;
    @ViewChild('endDatePicker') endDatePicker: DateTimePickerComponent;

    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();
    USER: any;

    constructor(
        private executionTabService: ExecutionTabService,
        private commonService: CommonService,
        private serviceOrderService: ServiceOrderService
    ) {
        this.USER = this.commonService.USER;
    }

    ngOnInit(): void {
        this.showPopup = true;
        this.data = JSON.parse(JSON.stringify(this.data));
        this.updateRefActivityDDL();
        this.prevRefActivity = this.data.referenceActivityDescription;
        this.equipmentInfo.warrantyDescriptionObj = [
            {
                "costType": "labor",
                "percentageCoverage": 50,
                "effectiveDate": "2020-10-10T00:00:00Z",
                "expiryDate": "2022-10-09T00:00:00Z"
            },
            {
                "costType": "travel",
                "percentageCoverage": 100,
                "effectiveDate": "2020-11-10T00:00:00Z",
                "expiryDate": "2021-11-09T00:00:00Z"
            },
            {
                "costType": "material",
                "percentageCoverage": 50,
                "effectiveDate": "2020-12-10T00:00:00Z",
                "expiryDate": "2021-12-09T00:00:00Z"
            }
        ];
        if ( !this.data ) {
            this.data = {
                description: null,
                priority: null,
                referenceActivityDescription: null,
                referenceActivityCode: null,
                serviceTypeCode: null,
                serviceTypeDescription: null,
                reportedProblem: null,
                reportedProblemDescription: null,
                solutionCode: null,
                solutionDescription: null,
                startDate: null,
                endDate: null
            };
        }
    }

    // loadDropdowns() {
    //     this.serviceOrderService.popupLoader.next(true);
    //     forkJoin([
    //         this.executionTabService.getReferenceActivities(this.USER.company, this.data.callGroup),
    //         this.executionTabService.getReportedProblems(this.USER.company),
    //         this.executionTabService.getSolutions(this.USER.company),
    //         this.serviceOrderService.getPriorities(this.USER.company)
    //     ]).subscribe( (res: any) => {
    //         this.referenceActivities = this.getDropdownMappedData(res[0].result);
    //         this.reportedProblems = this.getDropdownMappedData(res[1].result);
    //         this.solutions = res[2].result;
    //         this.priorities = this.getDropdownMappedData(res[3].result);
    //         this.data.priority = this.data.priority ? this.data.priority : this.priorities.find( priority => priority.text === 'Normal').value || this.priorities.find( p => p.text === 'Low').value;
    //         this.showPopup = true;
    //         this.serviceOrderService.popupLoader.next(false);
    //     }, (error: HttpErrorResponse) => {
    //         this.serviceOrderService.popupLoader.next(false);
    //     })
    // }
    
    updateRefActivityDDL() {
        const existingRefActivities = [];
        this.assignments.forEach(assignment => {
            if (assignment.referenceActivityCode !== this.data.referenceActivityCode) {
                existingRefActivities.push(assignment.referenceActivityCode);
            }
        });
        this.refActivityDDL = this.updateRefActivityDS(existingRefActivities);
        
        if ( this.USER.company === Companies.kingslynn ) {
            this.refActivityDDL = this.refActivityDDL.filter(ref => ref.category === 'Billable Service');
        }
    }

    updateRefActivityDS(existingRefActivities) {
        return this.referenceActivities.filter(activity => {
            if (activity.description === 'Contract Service' && this.equipmentInfo) {
                if (this.equipmentInfo?.serviceContractCode && this.equipmentInfo.serviceContractDescriptionObj.length) {
                    const index = this.equipmentInfo.serviceContractDescriptionObj.findIndex(contract => new Date(contract.expiryDate) > new Date(this.soCreatedDate));
                    return index > -1 ? !existingRefActivities.includes(activity.value) : false;
                } else {
                    return false;
                }
            } else if (activity.description === 'Warranty Service' && this.equipmentInfo) {
                if (this.equipmentInfo.warrantyCode && this.equipmentInfo.warrantyDescriptionObj.length) {
                    const index = this.equipmentInfo.warrantyDescriptionObj.findIndex(warranty => new Date(warranty.expiryDate) > new Date(this.soCreatedDate));
                    return index > -1 ? !existingRefActivities.includes(activity.value) : false;
                } else {
                    return false;
                }
            } else {
                return !existingRefActivities.includes(activity.value);
            }
        });
    }

    getDropdownMappedData(data: any) {
        data.map( row => {
            row.text = row.description;
        })
        return data;
    }

    onReferenceActivityChange(args: any) {
        this.data.referenceActivityDescription = args.itemData.description;
        this.data.serviceTypeDescription = args.itemData.serviceTypeDescription;
        this.data.serviceTypeCode = args.itemData.serviceTypeCode;
    }

    onStartDateChange(args: any) {
        this.data.startTime = args.value;
        this.endDatePicker.min = args.value;
    }

    onEndDateChange(args: any) {
        this.data.endTime = args.value;
        this.startDatePicker.max = args.value;
    }

    close() {
        if ( this.assignmentForm.touched ) {
            this.commonService.showConfirmation('Are you sure? All the changes will be lost and cannot be undone!')
            .then( (res) => {
                if ( res.isConfirmed ) {
                    this.equipmentInfo = null;
                    this.onClose.emit();
                }
            });
        } else {
            this.equipmentInfo = null;
            this.refActivityDDL = null;
            this.onClose.emit();
        }
    }

    checkRefActivity() {
        if ( this.data.referenceActivityDescription !== this.prevRefActivity && this.hasTransactions()) {
            this.commonService.showConfirmation('Are you sure? The transactions added for this assignment will be deleted.')
            .then( (res) => {
                if ( res.isConfirmed ) {
                    this.saveAssignment(true);
                } 
            });
        } else {
            this.saveAssignment();
        }
    }

    hasTransactions() {
        const index = this.assignmentDetails.findIndex( ad => ad.assignmentId === this.data.tempId);
        return index > -1;
    }

    saveAssignment(deleteTransactions?: boolean) {
        if (!this.data.referenceActivityDescription) {
            const activity = this.referenceActivities.find( x => x.value === this.data.referenceActivityCode)
            this.data.referenceActivityDescription = activity.description;
            this.data.serviceTypeDescription = activity.serviceTypeDescription;
            this.data.serviceTypeCode = activity.serviceTypeCode;
        }
        this.onSave.emit({data: this.data, deleteTransactions});
        this.equipmentInfo = null;
        this.refActivityDDL = null;
        this.onClose.emit();
    }
}
