import { Injectable } from '@angular/core';
import { Observable, interval, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { HomeService } from 'src/app/modules/home/services/home.service';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService {

  private tokenExpiryIntervalDurationSubject = new Subject<number>(); // Subject for updating interval duration
  tokenExpiryIntervalDuration$: Observable<number> = this.tokenExpiryIntervalDurationSubject.asObservable(); // Observable for interval duration
  private refreshInterval$: Observable<number>;
  tokenThrashhold = 300000;

  constructor(
    private homeService: HomeService
  ) {
    this.refreshInterval$ = interval(this.tokenThrashhold);
    this.refreshInterval$ = this.tokenExpiryIntervalDuration$.pipe(
      distinctUntilChanged(), 
      switchMap(duration => interval(duration))
    );
  }

  calculateIntervalDuration(){
    const expirationTime = new Date(parseInt(localStorage.getItem('token_expiry'))).getTime();
    const currentTime = new Date().getTime();
    if(currentTime <= expirationTime){
      this.updateIntervalDuration(expirationTime - (currentTime + this.tokenThrashhold));  
    }
  }

  // Method to update the interval duration
  updateIntervalDuration(duration: number): void {
    this.tokenExpiryIntervalDurationSubject.next(duration);
  }

  startTokenRefresh(): Observable<unknown> {
    return this.refreshInterval$.pipe(
      switchMap(() => this.homeService.renewToken())
    );
  }
}
