<!-- <div class="bg-red w-100 h-100"> Email popup</div> -->
<div
    class="modal fade show in" 
    [ngClass]="{'expanded': isExpanded}"
    id="pdfviewer-modal" 
    tabindex="-1" 
    aria-labelledby="pdfviewer-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <!-- <app-loader *ngIf="showLoader"></app-loader> -->
        <div class="modal-content">
            <div class="modal-header py-1">
                <div class="d-flex w-100">
            
                    <div class="flex-fill" >
                    <h3 class="modal-title">{{ title }}</h3>
                    </div>

                    <div class="btns-header">
                    <button 
                        class="header-icon close p-0 m-0" 
                        (click)="close()" 
                        aria-label="Close"
                    ><i class="fas fa-times" 
                            title="Close Popup"
                        ></i>
                    </button>
                    <button 
                        class="close header-icon expand-modal p-0 m-0"
                        (click)="onExpandBtnClick()"
                    ><i class="fas ml-2 mr-2" 
                        [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                        [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                        ></i>
                    </button>
                </div>
                </div>
            </div>
            <div class="modal-body pdf-scroll-bar pt-3">
                <div id="pdfViewer" class="w-100"></div>
            </div>
            <div class="modal-footer p-0">
                <button type="button" class="btn btn-sm btn-danger close-modal" *ngIf="isAlreadySigned || title != calibration" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="showPopupForEmail()">Send Email</button>
                <button type="button" class="btn btn-sm btn-primary save" *ngIf="allowSignature && title != calibration" (click)="save()" [disabled]="!isSigned">Save</button>
                <button type="button" class="btn btn-sm btn-primary save" *ngIf="allowSignature && title == calibration && !isAlreadySigned" (click)="save(true)" [disabled]="!isSigned">Save & Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="print()" >Print</button>
            </div>
        </div>
    </div>
</div>

<app-email-popup *ngIf="showEmailPopup"
    [receiverEmail]="customerData?.email"
    [emailSubject]="emailSubject"
    [attachment]="attachment"
    [isCalibrationCertificate]="isCalibrationCertificate"
    [isEstimateInvoice]="isEstimateInvoice"
    [urlLink]="link"
    [serviceOrderId]="serviceOrderId"
    [serviceOrderIdLN]="serviceOrderIdLN"
    (onClose)="onEmailPopupClose()"
></app-email-popup>
    