import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiResponse, gridNames, PersistenceData, GridPersistRequests } from 'src/app/shared/models/app.model';
import { GridComponent, EditService, ExcelExportProperties, CommandClickEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { CustomAdaptor, getExcelDataSource, gridActionBeginForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { CycleCountService } from '../cycle-counts.service';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-cycle-counts-add-popup',
  templateUrl: './cycle-counts-add-popup.component.html',
  styleUrls: ['./cycle-counts-add-popup.component.scss']
})
export class CycleCountAddPopupComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  @Output() refreshMainGrid = new EventEmitter();

  showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountAddPopupGrid;
  columns: any = [];
  warehouseData: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 280;
  showPopup: boolean =false;
  object;
  query: Query;
  public pageSettings: Object
  rowCycleCountData = null
  selectedTab: string = 'details';
  startDateElement: HTMLInputElement;
  startDateObject: DatePicker;
  showAddPopup: boolean = false;
  isExpanded = true

  public selectionOptions: SelectionSettingsModel;

  @ViewChild('cycleCountAddPopupGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  USER: any;
  preference;
  showWareHouseGrid: boolean = false;

  constructor(
    private commonService : CommonService,
    private cycleCountService : CycleCountService,
  ) {
    this.USER = this.commonService.USER;
  }

  ngOnInit(): void {
    this.selectionOptions = { type: 'Multiple', persistSelection: false, checkboxOnly: true };
    this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
    this.grid = null;
    this.getColumns();
    this.getData();
    this.commonService.getUserPreference(this.gridName, this.USER.employeeId).subscribe((res: ApiResponse) => {
      if (res && res.result && res.result.length && res.result[0].value) {
        this.preference = res.result[0].value;
        localStorage.setItem(`grid${this.gridName}`, this.preference);
      } else {
          localStorage.removeItem(`grid${this.gridName}`);
      }
      this.showWareHouseGrid = true;
      this.showLoader = false;
    }, error => {
      localStorage.removeItem(`grid${this.gridName}`);
      this.showLoader = false;
    });
    this.showLoader = false;
  }

  getData() {
    let value =null;
    this.commonService.getItems(value, 30, this.USER.company)
    .subscribe((res: ApiResponse) => {
      this.warehouseData = new DataManager({
        url: `${BASE_URL}CycleCount/GetWarehouseDataForCycleCountCreation`,
        adaptor: new CustomAdaptor()
    });
      this.query= new Query().addParams('company', this.USER.company );
    });
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { type:'checkbox', width: 20 },
        { field: 'warehouse', headerText: 'Warehouse', type: 'String', textAlign: 'Left', width: 100, isPrimaryKey: true },
        { field: 'employeeName', headerText: 'Technician Name', type: 'String', allowEditing: false, textAlign: 'Left', width: 100, filter: { type: 'Excel' }, visible: true },
        { field: 'serviceDepartment', headerText: 'Service Department', type: 'String', textAlign: 'Left', width: 100 },
        { field: 'lastCycleCount', headerText: 'Last Cycle Count Date', type: 'date', format: { type: 'date', format: 'd MMM, y' }, textAlign: 'Left', width: 100 , },
      ]};
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    updateFilterIcon(args, grid);
    if (GridPersistRequests.includes(args.requestType)) {
      this.savePersistance(grid);
    }
  }

  setGridToolbar() {
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        // { id: 'reset-persistence',align: 'Right', prefixIcon: 'fas fa-sync', cssClass: '', tooltipText: 'Reset Persistence' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'excel-export') {
        const dataSource = getExcelDataSource(this.grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Technicians (${getCurrentDate()}).xlsx`
        };
        this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    } else if (args.item.id === 'clear-filter') {
        this.grid.clearFiltering();
        this.grid.search('');
    }  else if (args.item.id === 'refresh') {
      this.getData();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountAddPopupGrid, value: '' }).subscribe((response) => {
            grid.search('');
            grid.clearFiltering();
            grid.clearSorting();
            this.showLoader = false;
          });
        }
        else{
          this.showLoader = false;
          return
        }
      });
    }
  }

  onCreate() {
    const payload = this.grid.getSelectedRecords();
    this.commonService.showConfirmation(`Do you want to create cycle count of all selected technicians?`, 'Yes', 'No', 'Create Cycle Counts?')
    .then( result => {
      if ( result.isConfirmed ) {
              this.showLoader = true;
              this.cycleCountService.createManyCycleCounts(payload).subscribe((response: any) => {
                this.commonService.showNotification('success', 'Cycle counts created successfully', 'center', 10000);
                this.onClose.emit();
                this.refreshMainGrid.emit()
                this.showLoader = false
              }, (error: HttpErrorResponse) => {
                this.showLoader = false;
                this.commonService.showNotification('error', 'Unable to create cycle counts');
              })
          }
      });
  }

  savePersistance(grid) {
    const persistenceData: PersistenceData = {
        userId: this.USER.userId,
        name: gridNames.cycleCountAddPopupGrid,
        value: grid.getPersistData()
    }
    this.commonService.saveUserPreference(persistenceData);
    localStorage.setItem(`grid${gridNames.cycleCountAddPopupGrid}`, grid.getPersistData());
  }

  expand() {
    this.isExpanded = !this.isExpanded;
    if(this.isExpanded) {
      this.gridHeight = window.innerHeight - 280;
    } else {
      this.gridHeight =  window.innerHeight - 420;
    }
  }

  close(){
    this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
    .then(result => {
        if (result.isConfirmed) {
            this.onClose.emit();
        }
    })
  }

}
