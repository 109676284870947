<ejs-dialog 
    #confirmDialog 
    [buttons]='confirmDlgButtons' 
    [visible]='true' 
    [header]='confirmHeader' 
    [animationSettings]='animationSettings' 
    [showCloseIcon]='showCloseIcon' 
    (close)="onClose($event)" 
    [target]='target'
    [width]='confirmWidth'
>
    <ng-template #content>
        <span>Are you sure you want to permanently delete these items ?</span>
    </ng-template>
</ejs-dialog>
