import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import {
  PdfViewer, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, AddSignatureEventArgs, RemoveSignatureEventArgs
} from '@syncfusion/ej2-pdfviewer';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';
import { ElementRef, Renderer2 } from '@angular/core';

const BASE_URL = environment.apiUrl;
PdfViewer.Inject(
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields
);

@Component({
  selector: 'app-pdf-viwer',
  templateUrl: './pdf-viwer.component.html',
  styleUrls: ['./pdf-viwer.component.scss']
})
export class PdfViwerComponent implements OnInit {

  isExpanded: boolean = false;
  signatureCount: number = 0;
  viewer: PdfViewer;
  @Input() title: string;
  @Input() allowSignature: boolean = false;
  @Input() certificateUrl: string;
  @Input() attachment: string;
  @Input() isCalibrationCertificate: boolean = false;
  @Input() isEstimateInvoice: boolean = false;
  @Input() customerData: any;
  @Input() serviceOrderId: any;
  @Input() serviceOrderIdLN: any;
  @Input() isAlreadySigned: boolean = false;
  isSigned: boolean = false;

  @Output() onClose = new EventEmitter();
  showEmailPopup: boolean;
  link: any;
  emailSubject: string;
  showEmailButton:boolean = false;

  calibration = "Calibration Certificate"

  constructor(
    private commonService : CommonService, private el: ElementRef, private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.initializeViewer();
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (this.isExpanded) {
  //     const modalHeight = this.el.nativeElement.querySelector('#pdfviewer-modal').offsetHeight - 130;
  //     this.renderer.setStyle(this.el.nativeElement.querySelector('#pdfViewer'), 'height', `${modalHeight}px`);
  //   } else {
  //     this.renderer.setStyle(this.el.nativeElement.querySelector('#pdfViewer'), 'height', '300px');
  //   }
  // }

  onExpandBtnClick() {
    setTimeout(() => {
      this.viewer.updateViewerContainer()
    }, 50);
    this.isExpanded=!this.isExpanded;

    if (this.isExpanded) {
      const modalHeight = this.el.nativeElement.querySelector('#pdfviewer-modal').offsetHeight - 130;
      this.renderer.setStyle(this.el.nativeElement.querySelector('#pdfViewer'), 'height', `${modalHeight}px`);
    } else {
      this.renderer.setStyle(this.el.nativeElement.querySelector('#pdfViewer'), 'height', '300px');
    }
  }

  initializeViewer() {
    this.viewer = new PdfViewer();
    this.viewer.enableToolbar = false;
    this.viewer.enablePrint = true;
    this.viewer.printMode  = 'NewWindow';
    this.viewer.enableNavigationToolbar = false;
    this.viewer.serviceUrl = BASE_URL + 'pdfviewer';
    this.viewer.addSignature = this.addSignature.bind(this);
    this.viewer.removeSignature = this.removeSignature.bind(this);
    this.viewer.appendTo('#pdfViewer');
    this.viewer.load(this.certificateUrl, null);
  }

  addSignature(args: AddSignatureEventArgs) {
    if (args.name === 'addSignature') {
        this.signatureCount += 1;
        if (this.signatureCount === 2) {
          this.isSigned = true;
        }
    }
  }

  removeSignature(args: RemoveSignatureEventArgs) {
      if (args.name === 'removeSignature') {
          this.signatureCount -= 1;
          if (this.signatureCount < 2) {
              this.isSigned = false;
          }
      }
  }

  showPopupForEmail(){
    this.showEmailPopup = true;
    this.link = this.certificateUrl;
    if(this.isCalibrationCertificate){
      this.emailSubject = `Calibration Certificate of Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId}`;
    } else if(this.isEstimateInvoice) {
      this.emailSubject = `Estimate Invoice of Service Order ${this.serviceOrderIdLN ? this.serviceOrderIdLN : this.serviceOrderId}`;
    }
  }

  print() {
    this.viewer.print.print();
    this.viewer.saveAsBlob().then((res: any) => {});
  }

  save(needToClose?){
    this.viewer.saveAsBlob().then((res: any) => {
      if(needToClose) this.close();
      this.commonService.showNotification('success', "File Saved");
    });
  }

  onEmailPopupClose(){
    this.link = null;
    this.emailSubject = null;
    this.showEmailPopup = false;
  }

  close(){
    this.viewer.destroy();
    this.viewer.formFieldCollection = this.viewer.formFieldCollections = null; // to handle tab key jumping to next input
    Array.from(document.querySelectorAll(`[id^="pdfViewer"]`))
    .forEach((el: HTMLElement) => { el.remove(); });
    this.onClose.emit(this.certificateUrl);
  }


}
