import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;

@Injectable()
export class MasterDataService {

    constructor(private http: HttpClient) { }

    get(api: string) {
        const filters = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize: 10
            },
            parameters: [],
            skip: 0,
            take: 10
        };
        return this.http.post(BASE_URL + api, filters);
    }

}
