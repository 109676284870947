import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransferOrderTypes } from 'src/app/modules/home/models/orders.model';

@Component({
  selector: 'app-transfer-order-popup-opener',
  templateUrl: './transfer-order-popup-opener.component.html',
  styleUrls: ['./transfer-order-popup-opener.component.scss']
})
export class TransferOrderPopupOpenerComponent implements OnInit {

  @Input() data: any = null;
  @Output() onClose = new EventEmitter();

  transferOrderTypes = TransferOrderTypes;

  constructor() { }

  ngOnInit(): void {
    this.data.args.transferOrderId = this.data.id;
    if(!this.data.args.inventoryCategory){
      this.data.args.inventoryCategory = this.data.args.quantity > 0 ? TransferOrderTypes.requester : TransferOrderTypes.responder;
    }
  }

  onSave(){
    this.data = null
    this.onClose.emit(null);
  }

  onPopupClose(){
    this.data = null
    this.onClose.emit(null);
  }

}
