import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { OrderTypes } from 'src/app/shared/models/app.model';
import { ShipmentAdjustmentOrder } from 'src/app/shared/models/shipment-adjustment-order.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { updateFilterIcon } from 'src/app/shared/utils/grid-functions';

@Component({
  selector: 'app-shipment-adjustment-order-popup-exceptions-tab',
  templateUrl: './shipment-adjustment-order-popup-exceptions-tab.component.html',
  styleUrls: ['./shipment-adjustment-order-popup-exceptions-tab.component.scss']
})
export class ShipmentAdjustmentOrderPopupExceptionsTabComponent implements OnInit {

  @Input() data: ShipmentAdjustmentOrder;

  showColumnChooser: boolean;
  columns = [];

  @ViewChild('shipmentAdjustmentExceptionGridColumnChooser') columnChooser;
  @ViewChild('shipmentAdjustmentExceptionGrid') shipmentAdjustmentExceptionGrid: GridComponent;
  showGrid: boolean = false;
  linkedPopupData: { id: any; type: OrderTypes; };

  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.columns = this.getGridColumns();
    setTimeout(() => {
      this.showGrid = true;
    }, 500);
  }

  getGridColumns() {
    const columns: any = [
        { field: 'shoExceptionId', headerText: 'shoExceptionId', isPrimaryKey: true, visible: false, showInColumnChooser: false },
        { field: 'line', headerText: 'Order Line Id', type: 'number', format: 'n', width: 100, visible: false },
        { field: 'shipmentOrderId', headerText: 'Shipment Order Id'},
        { field: 'itemCode', headerText: 'Item' },
        { field: 'itemDescription', headerText: 'Item Description' },
        { field: 'serviceItemGroup', headerText: 'Service Item Group', visible: false, filter: { type: 'Excel' } },
        { field: 'quantity', headerText: 'Quantity', type: 'number', format: 'n', width: 100 },
        { field: 'reasonDescription', headerText: 'Reason' },
        { field: 'createdDate', type: 'dateTime', headerText: 'Created Date', format: { type: 'dateTime', format: 'd MMM, y hh:mm a' }, width: 220, visible: false },
        { field: "createdBy", headerText: "Created By", visible: false },
    ];
    return columns;
  };

  getStatusClass(status: string) {
    return this.commonService.getStatusClass(status);
  }

  onGridCreated() {
    this.shipmentAdjustmentExceptionGrid.dataSource = this.data.electronicAdjustmentOrderLines;
    
    this.shipmentAdjustmentExceptionGrid.toolbar = [
        { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.shipmentAdjustmentExceptionGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        this.shipmentAdjustmentExceptionGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
    updateFilterIcon({ requestType: 'filtering' }, this.shipmentAdjustmentExceptionGrid);
  }

  openShipmentOrderPopup(shipmentOrderId){
    this.linkedPopupData = {
        id: shipmentOrderId,
        type: OrderTypes.shipmentOrder
    };
  }
}
