<div class="elements-container container-fluid p-0">
    <div class="row">
        <div class="col-3">
            <div class="field mb-3 no-border">
                <label>:</label><span class="required-asterik">*</span>
                <ejs-dropdownlist [placeholder]="''" [dataSource]=""></ejs-dropdownlist>
                <ejs-textbox [placeholder]="''"></ejs-textbox>
            </div>
        </div>
        <div 
            *ngFor="let element of elements"
            class="col-{{element.sizeX}}"
        >
            <div class="field mb-3 no-border" [ngClass]="{'grid-field': element.type === 'grid'}">
                <label>{{element.label}}:</label><span *ngIf="element.required" class="required-asterik">*</span>
                
                <ejs-textbox 
                    *ngIf="element.type === elementTypes.text" 
                    [placeholder]="element.placeholder"
                    [rows]="element.rows"
                    [multiline]="element.rows ? true : false"
                ></ejs-textbox>
                
                <ejs-numerictextbox 
                    *ngIf="element.type === elementTypes.number" 
                    [placeholder]="element.placeholder" 
                    [min]="element.min"
                    [max]="element.max" 
                    [decimals]="element.decimals"
                ></ejs-numerictextbox>
                
                <ejs-datepicker 
                    *ngIf="element.type === elementTypes.date" 
                    [placeholder]="element.placeholder" 
                    [min]="element.min"
                    [max]="element.max"
                ></ejs-datepicker>
                
                <ejs-datetimepicker 
                    *ngIf="element.type === elementTypes.datetime" 
                    [placeholder]="element.placeholder" 
                    [min]="element.min"
                    [max]="element.max"
                ></ejs-datetimepicker>

                <ejs-dropdownlist 
                    *ngIf="element.type === elementTypes.dropdown" 
                    [placeholder]="element.placeholder"
                    [dataSource]="element.dataSource"
                ></ejs-dropdownlist>
                
                <app-grid
                    *ngIf="element.type === elementTypes.grid"
                    [gridUpdated]="showGrid"
                    [gridProps]="element"
                ></app-grid>
            </div>
        </div>
    </div>
</div>
