<app-loader *ngIf="showLoader"></app-loader>
<div id="accordion">
    <div class="card">
        <app-shipped-items
            #receiptsSection
            [receipts]="receipts"
            [status]="status"
            (onShippedItemsUpdated)="onShippedItemsUpdated($event)"
        ></app-shipped-items>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col pt-2">
            <div class="row">
                <div class="col-10 text-right"><h5><strong>Quantity Shipped from LN: </strong></h5></div>
                <div class="col-2">{{shipmentOrderData.totQtyFromLN}}</div>
            </div>
        </div>
        <div class="col pt-2">
            <div class="row">
                <div class="col-10 text-right"><h5><strong>Total Serviceable Received: </strong></h5></div>
                <div class="col-2">{{shipmentOrderData.totServiceableReceived}}</div>
            </div>
        </div>
        <div class="col pt-2">
            <div class="row">
                <div class="col text-right"><h5><strong>Miscellaneous: </strong></h5></div>
                <div class="col-2">{{shipmentOrderData.totMisc}}</div>
            </div>
        </div>
    </div>
    <div class="card">
        <app-shipped-exceptions
            #exceptionsSection
            [status]="status"
            [shipmentOrderData]="shipmentOrderData"
            [exceptions]="exceptions"
        ></app-shipped-exceptions>
    </div>
  </div>