import { Component, ElementRef, EventEmitter, HostListener, Input, Output, OnInit, ViewChild } from '@angular/core';
import { EditService, GridComponent } from '@syncfusion/ej2-angular-grids';

import { Subject } from 'rxjs';
import { UserRoles, gridNames } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { IReceiptsExceptionsTypes, shipmentOrderStatuses } from '../../../models/orders.model';
import { InventoryService } from '../../../services/inventory.service';

@Component({
  selector: 'app-shipment-receipts-tab',
  templateUrl: './shipment-receipts-tab.component.html',
  styleUrls: ['./shipment-receipts-tab.component.scss'],
  providers: [EditService]
})
export class ShipmentReceiptsTabComponent implements OnInit {

  gridHeight: number;
  gridWidth: any = '100%';
  receiptsGridColumns: Object[];
  exceptionsGridColumns: Object[];
  showReceiptsGridColumnChooser: boolean = false;
  showExceptionsGridColumnChooser: boolean = false;
  receiptsGrid: GridComponent;
  exceptionsGrid: GridComponent;
  showGrid: boolean = false;
  totalServiceableReceived: number = 0;
  miscellaneousItem: number = 0;

  @Input() shipmentOrderData: any;
  @Input() receipts: any = [];
  @Input() exceptions: any = [];
  @Input() status: any;
  @Input() shipmentOrderId: any;

  @Output() onUpdate = new EventEmitter();

  exceptionsDDL: any = [];
  quantity: any;
  quantityObject: any;
  exceptionTypes: any = [];
  exceptionType: HTMLInputElement;
  exceptionTypeObject: any;
  defaultExceptionType: string = IReceiptsExceptionsTypes.damaged;
  itemDescription: HTMLInputElement;
  itemDescriptionObject: any;
  selectedRowId: any;
  itemCode: HTMLInputElement;
  itemCodeObject: any;
  USER: any;
  itemsList: any;
  showItemAdvancedSearchPopup: boolean = false;
  onItemUpdate = new Subject<string>();
  itemChangedFromAdvanceSearch: boolean = false;
  skipBeginEditEvent: boolean = false;
  selectedExceptionType: any;
  exceptionsReasonCode: string;
  receiptsGridSelectedRowId: any;
  exceptionsGridSelectedRowId: any;
  itemReturned: HTMLInputElement;
  itemReturnedObject: any;
  itemData: any;
  exceptionQuantity: any;
  exceptionQuantityObject: any;
  selectItemCode: any;
  selectItemDescription: any;
  gridButtonClass: string = '';
  isShipmentTechnician: boolean;
  canEdit: boolean = false;
  isKLManager: boolean = false;
  roleCanEdit: boolean = true;

  @ViewChild('shipmentOrderReceiptsGridColumnChooser') receiptsGridColumnChooser;
  @ViewChild('shipmentOrderExceptionsGridColumnChooser') exceptionsGridColumnChooser;
  
  @ViewChild('receiptsSection') receiptsSection;
  @ViewChild('exceptionsSection') exceptionsSection;

  itemsQuery: any;
  showLoader: boolean = false;
  isTabValid: any = true;
  itemGroup: any;
  serviceItemGroup: any;
  itemTypes: any = {
    core : 'Core Item',
    standard: 'Standard Item'
  };
  itemTypeObject: any;
  itemType: HTMLInputElement;
  reasonCode: any;
  reasonDescription: any;

  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService,
    private eRef: ElementRef
  ) {
    this.USER = this.commonService.USER;
    this.isKLManager = this.USER.role === UserRoles.klServiceManager;
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.receiptsGrid?.isEdit) {
      if (!this.eRef.nativeElement.contains(event.target) ) {
        this.receiptsGrid?.endEdit();
      }
    } else if (this.exceptionsGrid?.isEdit) {
      if (!this.eRef.nativeElement.contains(event.target)
      && !document.getElementById(gridNames.shipmentOrderExceptionsGrid+'itemCode_popup')?.contains(event.target)
      && !document.getElementById(gridNames.shipmentOrderExceptionsGrid+'reasonDescription_popup')?.contains(event.target)) {
        this.exceptionsGrid?.endEdit();
      }
    }
  }

  ngOnChanges(change) {
    if(change?.status){
      this.status === change.status.currentValue;
      this.getCanEditValue();
      this.enableDisableButton();
    }
  }


  ngOnInit(): void {
    this.inventoryService.receiptsPopupLoader.next(false);
    this.isShipmentTechnician = this.USER.employeeId === this.shipmentOrderData.technicianId;
    this.shipmentOrderData.totMisc = this.shipmentOrderData.totMisc??0;
  }

  getCanEditValue(){
    this.roleCanEdit = this.commonService.roleClaims['Inv_Order_Receipt_Popup_Receipt_Tab']?.edit
    this.canEdit = this.status === shipmentOrderStatuses.deliveryAcknowledged && (this.isShipmentTechnician || this.roleCanEdit);
  }
  
  enableDisableButton(){
    if (this.canEdit) {
      document.getElementById(this.exceptionsGrid?.element.id)?.getElementsByClassName('grid-add-button')[0]?.classList.remove('disabled-grid-button');
    } else {
      document.getElementById(this.exceptionsGrid?.element.id)?.getElementsByClassName('grid-add-button')[0]?.classList.add('disabled-grid-button');
    }
  }

  onShippedItemsUpdated(shippedItemsGridData){
    this.shipmentOrderData.totServiceableReceived = 0;
    let overShippedMisc = 0;
    let exceptionsMisc = 0;
    shippedItemsGridData.forEach((rowData) => {
      this.shipmentOrderData.totServiceableReceived += rowData.quantityServiceable;
      overShippedMisc += rowData.overShipment;
      exceptionsMisc += rowData.shortShipment;
    });
    this.updateMiscCount(overShippedMisc, exceptionsMisc);
  }

  updateMiscCount(overShippedMisc, exceptionsMisc){
    this.shipmentOrderData.totMisc = 0;
  setTimeout(() => {
      this.shipmentOrderData.totMisc = (overShippedMisc + exceptionsMisc);
    }, 100);
  }

  getItemsData(){
    return this.receiptsSection.getItemsData();
  }

  getExceptionsData(){
    return this.exceptionsSection.getExceptionsData();
  }

  validate(skipValidation) {
    return this.exceptionsSection.validate(skipValidation);
  }
}
