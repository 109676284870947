import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangedEventArgs, FocusInEventArgs, FocusOutEventArgs, InputEventArgs, TextBox } from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'sf-textbox',
  templateUrl: './sf-textbox.component.html',
  styleUrls: ['./sf-textbox.component.scss']
})
export class SfTextboxComponent implements OnInit {
  
  @Input() properties: TextBox;
  @Output() blur: EventEmitter<FocusOutEventArgs> = new EventEmitter<FocusOutEventArgs>();
  @Output() change: EventEmitter<ChangedEventArgs> = new EventEmitter<ChangedEventArgs>();
  @Output() created: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() destroyed: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() focus: EventEmitter<FocusInEventArgs> = new EventEmitter<Object>();
  @Output() input: EventEmitter<InputEventArgs> = new EventEmitter<InputEventArgs>();

  constructor() {}

  ngOnInit(): void {}

  onBlur(args) { this.blur.emit(args); }
  onChange(args) { this.change.emit(args); }
  onCreated(args) { this.created.emit(args); }
  onDestroyed(args) { this.destroyed.emit(args); }
  onFocus(args) { this.focus.emit(args); }
  onInput(args) { this.input.emit(args); }

  addAtributes() {}
  addIcon() {}
  destroy() {}
  focusIn() {}
  focusOut() {}
  getPersistData() {}
  removeAttributes(attributes: string) {}
}
