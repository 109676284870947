<div class="card" *ngIf="gsData">
  <div class="card-header d-flex pl-0" data-toggle="collapse" href="#collapseOne">
      <a class="card-link flex-fill">General <i
              *ngIf="validationApplied && !validateGeneralSectionData()"
              class="fas fa-exclamation-circle validation-exclamation"></i></a>
      <i class="fas fa-chevron-up"></i>
  </div>
  <div id="collapseOne" class="collapse show">
      <div class="card-body pb-0">
          <div class="row">
              <div class="col">
                  <div class="elements-container container-fluid p-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="field mb-3 border-bottom">
                                <label>Service Department:</label>
                                <div>{{gsData.serviceDepartmentDescription}}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="field mb-2 position-relative">
                                        <label>Customer:</label><span class="required-asterik">*</span>
                                        <ejs-dropdownlist #configurationsDDL
                                            [placeholder]="'Select Customer'"
                                            [dataSource]="configurations"
                                            [allowFiltering]="true"
                                            [readonly]="!isEditable || customerCodeReadOnly"
                                            [enabled]="USER.role !== userRoles.iscManager && USER.role !== userRoles.IscSupervisor && USER.role !== userRoles.isc && gsData.offlineOrder && (!hasTransaction ? !(!isEditable || customerCodeReadOnly) : false)"
                                            filterType="contains"
                                            sortOrder="Ascending"
                                            [showClearButton]="false"
                                            [fields]="{text: 'text', value: 'value'}"
                                            [value]="gsData.configuration"
                                            (focus)="configurationsDDL.showPopup()"
                                            (filtering)="onConfigurationFiltering($event)"
                                            (change)="onConfigurationChange($event)"
                                          ></ejs-dropdownlist>
                                        <div class="field-error" *ngIf="validationApplied && !gsData.configuration">Customer is required
                                        </div>
                                        <a class="advanced-search-button" href="javascript:"
                                            *ngIf="gsData.offlineOrder && (!hasTransaction ? isEditable && !customerCodeReadOnly : false)"
                                            (click)="showAdvancedSearch = true">Advanced Search
                                        </a>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="col-12" *ngIf="gsData.customerDescription">
                                    <div class="field mb-2">
                                        <label>Address:</label>
                                        <div class="customer-address disabled-field">
                                            <div>{{gsData.customerDescription}}</div>
                                            <div *ngIf="gsData.address">{{gsData.address}}</div>
                                            <div *ngIf="gsData.city">{{gsData.city}}<span *ngIf="gsData.state">, {{gsData.state}}</span><span
                                                    *ngIf="gsData.zipCode"> {{gsData.zipCode}}</span></div>
                                            <div *ngIf="gsData.country">{{gsData.country}}</div>
                                            <div *ngIf="gsData.customerPhoneNumber">{{gsData.customerPhoneNumber}}</div>
                                            <div *ngIf="gsData.customerEmail">{{gsData.customerEmail}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="gsData.termsOfPayment">
                                    <div class="field mb-2">
                                        <label>Payment Terms: </label>
                                        <div class="disabled-field">{{gsData.termsOfPayment}} {{gsData.termCodeDescription ? ' - ' + gsData.termCodeDescription : ''}}</div>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="gsData.businessPartnerStatus">
                                    <div class="field mb-2">
                                        <label>Business Partner Status: </label> <br>
                                        <div
                                            class="bp-status grid-status-column {{gsData.businessPartnerStatus == 1 ? 'blue' : gsData.businessPartnerStatus == 2 ? 'green' : gsData.businessPartnerStatus == 3 ? 'red' : ''}}">
                                            {{gsData.businessPartnerStatus == 1 ? 'Potential' : gsData.businessPartnerStatus == 2 ? 'Active'
                                            : gsData.businessPartnerStatus == 3 ? 'Inactive' : ''}}</div>
                                    </div>
                                </div>

                                <div class="col-12" *ngIf="gsData.signal">
                                    <div class="field mb-2">
                                        <label>Signal: </label>
                                        <div class="disabled-field">{{gsData.signal}}</div>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="gsData.activityText">
                                    <div class="field mb-2">
                                        <label>Activity Text:</label>
                                        <div class="customer-address disabled-field">
                                            <div>{{gsData.activityText}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="field mb-2 position-relative">
                                        <label>LN Order No.:</label>
                                        <ejs-textbox
                                            placeholder='LN Order No.'
                                            [readonly]="true"
                                            [enabled]="false"
                                            [value]="gsData.serviceOrderIdLN"
                                        ></ejs-textbox>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="field mb-2 position-relative">
                                        <label>LN Order Date:</label>
                                        <p class="pt-2">{{ gsData.orderDateLN | date : 'd MMM, y'}}</p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="field mb-2 position-relative">
                                        <label>Call Group:</label><span class="required-asterik">*</span>
                                        <ejs-dropdownlist #callgroupCombo [placeholder]="'Select Call group'" [dataSource]="callgroups"
                                            [allowFiltering]="true" [filterType]="'contains'" [sortOrder]="'Ascending'"
                                            [enabled]="gsData?.offlineOrder"
                                            [readonly]="!isEditable || callGroupReadOnly"
                                            (change)="gsData.callGroup = $event?.itemData?.value"
                                            [fields]="{text: 'text', value: 'value'}"
                                            [value]="gsData?.callGroup"
                                            ></ejs-dropdownlist>
                                        <div class="field-error" *ngIf="validationApplied && !gsData.callGroup">Call group value is
                                            required.</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="field mb-2">
                                        <label>Priority:</label>
                                        <ejs-dropdownlist [placeholder]="'Select Priority'" [dataSource]="priorities"
                                            [allowFiltering]="true" [filterType]="'contains'" [sortOrder]="'Ascending'"
                                            [readonly]="!isEditable || priorityReadOnly"
                                            [fields]="{text: 'text', value: 'value'}"
                                            [value]="gsData.priority"
                                            (change)="gsData.priority = $event.itemData.value; gsData.priorityDescription = $event.itemData.text"
                                        ></ejs-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="field mb-2">
                                        <label>Reference:</label>
                                        <ejs-textbox 
                                            [placeholder]="'Enter reference...'" 
                                            [value]="gsData.reference"
                                            [readonly]="!isEditable || referenceReadOnly"
                                            (input)="refrenceChanged($event)"
                                        ></ejs-textbox>
                                        <div class="field-error" *ngIf="!refrenceInputLengthValidation">Max. limit for refrence input is 30 characters.</div>
                                    </div>
                                </div>
                                <div class="col-12"
                                    *ngIf="status === serviceOrderStatuses.paused || status === serviceOrderStatuses.cancelled">
                                    <div class="field mb-3 position-relative">
                                        <label>Reason:</label><span
                                            *ngIf="status === serviceOrderStatuses.paused || status === serviceOrderStatuses.cancelled"
                                            class="required-asterik">*</span>
                                        <ejs-dropdownlist #reasonsDDL
                                            [placeholder]="'Select reason for pause'"
                                            [dataSource]="status === serviceOrderStatuses.cancelled ? reasonsForCancellation : reasonsForPause"
                                            [allowFiltering]="true"
                                            [filterType]="'contains'"
                                            [sortOrder]="'Ascending'"
                                            [readonly]="status === serviceOrderStatuses.paused || status === serviceOrderStatuses.cancelled ? false : !isEditable || reasonCodeReadOnly"
                                            [fields]="{text: 'description', value: 'value'}"
                                            [value]="gsData.reasonCode"
                                            (change)="reasonChange($event)"
                                        ></ejs-dropdownlist>
                                        <div class="field-error" *ngIf="validationApplied && !reasonsDDL.value">Reason is required, when status is Paused.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12">
                                    <div class="field mb-2">
                                        <label>Start/ETA Date:</label>
                                        <ejs-datetimepicker class="ms-datepicker"
                                            format="d MMM, y h:mm a"
                                            [readonly]="id ? !isEditable || startDateReadOnly : true"
                                            [min]="today" [value]="gsData.startDate || null"
                                            [showClearButton]="false"
                                            [allowEdit]="false"
                                            (focus)="onStartDateFocus($event)"
                                            (created)="onStartDateCreated($event)"
                                            (change)="onStartDateChange($event)"
                                        ></ejs-datetimepicker>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="field mb-2"
                                                title="{{hasCheckedInServiceOrder ? 'Another service order is already checked in' : ''}}">
                                                <label>Check-in:</label>
                                                <ejs-datetimepicker [readonly]="true" [showClearButton]="false"
                                                    format="d MMM, y h:mm a"
                                                    [value]="gsData.checkInDate || null" [allowEdit]="false"
                                                    (change)="setCheckinDate($event)"></ejs-datetimepicker>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="field mb-2"
                                                title="{{hasCheckedInServiceOrder ? 'Another service order is already checked in' : ''}}">
                                                <label>Check-out:</label>
                                                <ejs-datetimepicker [readonly]="true" [showClearButton]="false"
                                                    format="d MMM, y h:mm a"
                                                    [value]="gsData.checkOutDate || null" [allowEdit]="false"
                                                    (change)="gsData.checkOutDate = $event.value; checkoutTimeChange($event)">
                                                </ejs-datetimepicker>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="field mb-2">
                                                <label>Elapsed Time:</label>
                                                <p>{{formatedElapsedTime}}</p>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="field mb-2 position-relative">
                                                <label>NTE:</label>
                                                <ejs-numerictextbox [min]="1" [validateDecimalOnType]='true' [decimals]="2" [value]="gsData.nteAmount || null" [showSpinButton]="false"
                                                    [enabled]=" gsData.offlineOrder && (!id || gsData.status === 'Open')" (change)="nteChangeTrigger($event)">
                                                </ejs-numerictextbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-auto"
                                    *ngIf="canCheckin">
                                    <div class="field button-field mb-2">
                                        <button class="checkin-button btn btn-default"
                                            [ngClass]="{'color-green': status === serviceOrderStatuses.inProgress}"
                                            (click)="toggleCheckin()"><i class="fas fa-street-view"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <app-config-advanced-search
                    *ngIf="showAdvancedSearch"
                    [serviceDepartment]="gsData.serviceDepartment"
                    (onClose)="onCloseSearchForCustomer($event)"
                ></app-config-advanced-search>
              </div>
          </div>
      </div>
  </div>
</div>
  <div class="card">
    <div class="card-header d-flex pl-0" data-toggle="collapse"
        href="#collapseEquipmentInfo">
        <a class="card-link flex-fill">Equipment Information <i
                *ngIf="validationApplied && !validateEquipmentSectionData()"
                class="fas fa-exclamation-circle validation-exclamation"></i></a>
        <i class="fas fa-chevron-up"></i>
    </div>
    <div id="collapseEquipmentInfo" class="collapse show">
        <div class="card-body pb-0">
            <div class="row">
                <div class="col">
                    <div class="elements-container container-fluid p-0">
                      <div class="row">
                          <div class="col-4">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="field mb-3 position-relative">
                                          <label>Model:</label><span class="required-asterik">*</span>
                                          <ejs-dropdownlist #objectsDDL
                                              [placeholder]="'Select Object'"
                                              [dataSource]="objects"
                                              [allowFiltering]="true"
                                              [filterType]="'contains'"
                                              [fields]="{text: 'text', value: 'value'}"
                                              [value]="eqData.itemCode"
                                              [enabled]="isModelEnabled"
                                              (change)="onObjectChange($event)"
                                          ></ejs-dropdownlist>
                                          <div class="field-error" *ngIf="validationApplied && !eqData.itemCode">Model is required</div>
                                      </div>
                                  </div>
                                  <div class="col-12">
                                      <div class="field mb-3 position-relative">
                                          <label>Serial Number:</label><span *ngIf="!skipValidationOnSerialForKL" class="required-asterik">*</span>
                                          <ejs-dropdownlist #serialNumberSearch
                                              [id]="'serial-number-search'"
                                              [placeholder]="'Select Serial Number'"
                                              [dataSource]="serialNumbers"
                                              [allowFiltering]="true"
                                              [filterType]="'contains'"
                                              [fields]="{text: 'text', value: 'value'}"
                                              [value]="eqData.serialNumber"
                                              [enabled]="isSerialNumberEnabled"
                                              (focus)="serialNumberSearch.showPopup()"
                                              (created)="onSerialNumberCreated()"
                                              (filtering)="onSerialNumberFiltering($event)"
                                              (change)="onSerialNumberChange($event)"
                                          ></ejs-dropdownlist>
                                          <div class="field-error" *ngIf="validationApplied && !eqData.serialNumber && !skipValidationOnSerialForKL">Serial number is required</div>
                                          <a class="advanced-search-button" href="javascript:" *ngIf="eqData?.offlineOrder && !hasTransaction ? isEditable && !customerCodeReadOnly : false" (click)="showAdvancedSearchForSerialNumbers = true">Advanced Search</a>
                                      </div>
                                  </div>
                                  <div class="col-12">
                                      <div class="field mb-3 position-relative">
                                          <label>Equipment Status:</label>
                                          <ejs-dropdownlist [placeholder]="'Select equipment status'"
                                              [dataSource]="[{text: 'OPERATIONAL', value: 'OP'}, {text: 'DOWN ', value: 'DN'}, {text: 'MANAGER EXPEDITE', value: 'ME'}]"
                                              [allowFiltering]="true"
                                              [filterType]="'contains'"
                                              [sortOrder]="'Ascending'"
                                              [fields]="{text: 'text', value: 'value'}"
                                              [enabled]="!hasTransaction ? !(!isEditable || serialNumberReadOnly) : false"
                                              [value]="eqData.equipmentStatus"
                                              (change)="eqData.equipmentStatus = $event.value; eqData.equipmentStatusDescription = $event.itemData.text"
                                          ></ejs-dropdownlist>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-4">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="field mb-3">
                                          <label>Category:</label>
                                          <p class="pt-2" [ngStyle]="{'color':!eqData.itemCode ? '#c1bebe' : '#000000'}">{{ !eqData.itemCode ? 'No Object Selected' : eqData.serializedItemGroupDescription }}</p>
                                      </div>
                                  </div>
                                  <div class="col-12">
                                      <div class="field mb-3">
                                          <label>Install Date:</label>
                                          <p class="pt-2" [ngStyle]="{'color':!eqData.serialNumber ? '#c1bebe' : '#000000'}">{{ !eqData.serialNumber ?  'No Serial Number Selected' : eqData.installationDate | date : 'd MMM, y'}}</p>
                                      </div>
                                  </div>
                                  <!-- <div class="col-12">
                                      <div class="field mb-3">
                                          <label>Calibration Due Date:</label>
                                          <p class="pt-2">{{ eqData.calibrationNextCertificateDate | date : 'd MMM, y'}}</p>
                                      </div>
                                  </div> -->

                              </div>
                          </div>
                          <div class="col-4">
                              <div class="row">
                                  <div class="col-12">
                                      <div class="field mb-3">
                                          <label>Warranty:</label>
                                          <p class="pt-2">{{ eqData.warrantyDescription }}</p>
                                          <ng-container *ngIf="eqData.serialNumber else noSerialForWarranty">
                                            <div *ngFor="let desc of warrantyInfo" class="text-white {{ desc.class }} pl-2 bg-coverage-gradient">
                                                <div *ngIf="desc.costType === 'No coverage'">
                                                    <span>{{desc.costType}}</span>
                                                </div>
                                                <div *ngIf="desc.costType !== 'No coverage'">
                                                    <span class="warranty-cost-type">  {{ desc.costType }}</span> :
                                                    <span class="warranty-percentage-coverage"> {{ desc.percentageCoverage }}% </span> -
                                                    <span class="warranty-effective-date">{{ desc.effectiveDate | date: 'd MMM, y' }} </span>
                                                    <span class="warranty-expiry-date"> to {{ desc.expiryDate | date: 'd MMM, y' }} </span>
                                                </div>
                                            </div>
                                          </ng-container>
                                          <ng-template #noSerialForWarranty>
                                            <p style="color: #c1bebe"> No Serial Number Selected!</p>
                                          </ng-template>
                                      </div>
                                  </div>
                                  <div class="col-12">
                                      <div class="field mb-3">
                                          <label>Contract:</label>
                                          <p class="pt-2">{{ eqData.serviceContractCode }}</p>
                                          <ng-container *ngIf="eqData.serialNumber else noSerialForContract">
                                            <div class="text-white {{ desc.class }} pl-2 bg-coverage-gradient" *ngFor="let desc of contractInfo">
                                                <div *ngIf="desc.costType === 'No coverage'">
                                                    <span>{{desc.costType}}</span>
                                                </div>
                                                <div *ngIf="desc.costType !== 'No coverage'">
                                                    <span class="contract-cost-type"> {{ desc.costType }} :</span>
                                                    <span class="contract-percentage-coverage" *ngIf="desc.costType !== 'No coverage'">{{ desc.percentageCoverage }}% </span> -
                                                    <span class="contract-effective-date" *ngIf="desc.costType !== 'No coverage'"> {{ desc.effectiveDate | date : 'd MMM, y'}} </span>
                                                    <span class="contract-expiry-date" *ngIf="desc.costType !== 'No coverage'"> to {{ desc.expiryDate | date : 'd MMM, y'}} </span>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-template #noSerialForContract>
                                            <p style="color: #c1bebe"> No Serial Number Selected!</p>
                                          </ng-template>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <app-config-advanced-search-for-serial-number
                      *ngIf="showAdvancedSearchForSerialNumbers"
                      [serialNumbers]="serialNumbersForAdvanceSearch"
                      [serviceDepartment]="serviceDepartment"
                      [offlineOrder]="gsData.offlineOrder"
                      (onClose)="onCloseSearchForSerialNumbers($event)"
                  ></app-config-advanced-search-for-serial-number>

                </div>
            </div>
        </div>
    </div>
  </div>
<div class="card" *ngIf="notesData">
  <div class="card-header d-flex pl-0" data-toggle="collapse"
      href="#collapseNotesComments">
      <a class="card-link flex-fill">Notes/Comments <i
              *ngIf="validationApplied && !validateCommentsNotesData()"
              class="fas fa-exclamation-circle validation-exclamation"></i></a>
      <i class="fas fa-chevron-up"></i>
  </div>
  <div id="collapseNotesComments" class="collapse show">
      <div class="card-body pb-0">
          <div class="row">
              <div class="col">
                  <div class="elements-container container-fluid p-0">
                    <div class="row">
                        <div class="col-4">
                            <div class="field mb-3">
                                <label>Problem Description:</label>
                                <ejs-textbox
                                    class="border"
                                    [placeholder]="'Enter Problem Description'"
                                    rows="6"
                                    [multiline]="true"
                                    [readonly]="!isEditable || customerComplaintReadOnly"
                                    [value]="notesData.customerComplaint"
                                    (change)="notesData.customerComplaint = $event.value"
                                ></ejs-textbox>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="field mb-3">
                                <label>Diagnosis/Solution:</label><span *ngIf="status === serviceOrderStatuses.cancelled || paymentButtonClicked && !this.notesData.fstCommentsToCustomer" class="required-asterik">*</span>
                                <ejs-textbox
                                    class="border"
                                    #commentsToCustomer
                                    [readonly]="!isEditable || fstCommentsToCustomerReadOnly"
                                    [placeholder]="'Enter Diagnosis/Solution...'"
                                    rows="6"
                                    [multiline]="true"
                                    [value]="notesData.fstCommentsToCustomer"
                                    (change)="notesData.fstCommentsToCustomer = $event.value"
                                ></ejs-textbox>
                            </div>
                        </div>
                        <div class="col-4" *ngIf="status === serviceOrderStatuses.cancelled">
                            <div class="field mb-2">
                                <label>Cancelation Note:</label>
                                <div class="customer-address disabled-field border" style="min-height: 126px; padding: 4px 7px;">
                                    <div *ngIf="notesData.reasonCode">{{notesData.reasonCode}} - {{notesData.reasonDescription}}</div>
                                    <div *ngIf="notesData.cancellationDate">{{notesData.cancellationDate | date: 'MMM d, y hh:mm a'}}</div>
                                    <div *ngIf="notesData.cancellationNotes">{{notesData.cancellationNotes}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              </div>
          </div>
      </div>
  </div>
</div>
