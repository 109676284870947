import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }


  getOpenItemsForTechnician(techinichianId: any) {
    return this.http.get(`${BASE_URL}UserProfile/GetOpenItemsForTechnician?technicianId=` + techinichianId);
  }

  clearUserProfileImages(employeeId: any, imageType: string) {
    return this.http.get(`${BASE_URL}UserProfile/ClearUserProfileImages?employeeId=${employeeId}&moduleName=${imageType}`);
  }

  upsertUserProfileSignature(sign: { signature: string }) {
    return this.http.post(`${BASE_URL}UserProfile/UpsertUserProfileSignature`, sign);
  }
  enableDisableUser(payload: any) {
    return this.http.post(`${BASE_URL}UserProfile/EnableDisableUser`, payload);
  }
  updateUserSSO(payload: any) {
    return this.http.post(`${BASE_URL}UserProfile/UpdateUserSSO`, payload);
  }
  getRoles(searchKey: string) {
    let payload = {
      "requiresCounts": true,
      "onWhere": null,
      "onSearch": {
        "fieldNames": [
            "userType"
        ],
        "operator": "wildcard",
        "searchKey": searchKey,
        "ignoreCase": true,
        "ignoreAccent": false,
        "oprtr": "wildcard"
    },
      "onPage": {
        "pageIndex": 1,
        "pageSize": 0
      },
      "parameters": [],
      "skip": 0,
      "take": 0
    }
    return this.http.post(`${BASE_URL}UserProfile/GetRoles`, payload);
  }
  upsertUserProfileInfo(data: any) {
    return this.http.post(`${BASE_URL}UserProfile/UpsertUserProfileInfo`, data);
  }
  upsertUserProfileAddress(data: any) {
    return this.http.post(`${BASE_URL}UserProfile/UpsertUserProfileAddress`, data);
  }
  upsertUserProfileNotificationPref(data: any) {
    return this.http.post(`${BASE_URL}UserProfile/UpsertUserProfileNotificationPref`, data);
  }
  resetUserById(employeeId: string) {
    return this.http.get(`${BASE_URL}UserProfile/ResetUserById?employeeId=` + employeeId);
  }
  getAllShipmentExceptionsForUser(employeeId: string) {
    return this.http.get(`${BASE_URL}ShipmentOrder/GetAllShipmentExceptionsForUser?employee=` + employeeId);
  }
  getUserProfileById(employeeId: string) {
    return this.http.get(`${BASE_URL}UserProfile/GetUserProfileById?employeeId=` + employeeId);
  }
  sendMissingSignatureEmail(payload: any) {
    return this.http.post(`${BASE_URL}UserProfile/SendMissingSignatureEmail`, payload );
  }
  getEmployeesWithoutSignature() {
    return this.http.get(`${BASE_URL}UserProfile/GetEmployeesWithoutSignature`);
  }

  //  the API to fetch Users when creating a new user in rims only
  getServiceEmpsNotInRIMS(userType: string) {
    return this.http.get(`${BASE_URL}UserProfile/GetServiceEmpsNotInRIMS?UserType=`+userType);
  }
  getRIMSModuleTaskList() {
    return this.http.get(`${BASE_URL}UserProfile/GetRIMSModuleTaskList`);
  }

  getNotificationDuration() {
    return this.http.get(`${BASE_URL}UserProfile/GetNotificationDuration`);
  }

  clearPersistenceForId(employeeId: string) {
    return this.http.get(`${BASE_URL}UserProfile/ClearPersistenceForId?employeeId=` + employeeId);
  }

}
