<div class="text-center pt-5">
    <img class="img" height="200px" width="200px" src="../../../../../assets/img/shared/exclamation.png"/>
    <br><br>
    <h1>Transaction Failed</h1>
    <p>{{transactionErrorMessage}}</p>
    <p>Error code: {{transactionErrorCode}}</p>
    <p>Suggestion: {{transactionErrorSuggestion}}</p>

    <button type="button" class="btn btn-info btn-sm mr-2"  (click)="cancel()">Change Payment Method</button>
    <button type="button" class="btn btn-success btn-sm"  (click)="tryAgain()">Try Again</button>
</div>