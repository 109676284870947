<div
    class="modal fade show" 
    [ngClass]="{'expanded': !isExpanded}"
    id="service-order-modal" 
    tabindex="-1" 
    aria-labelledby="service-order-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-xl">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <app-popup-header 
                [id]="cycleCountData?.cycleCountId"
                [isExpanded]="isExpanded"
                [createdDate]="cycleCountData ? cycleCountData.createdDate : null"
                [title]="cycleCountData?.cycleCountId"
                (onClose)="close()" 
                (onExpandCollapse)="isExpanded = $event"
            ></app-popup-header>
            <div class="modal-body pt-0">
                <div class="row header-section">
                    <div class="col-md-7">
                        <div class="row header-left-section">
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2"> Warehouse: </label>
                                    <ejs-textbox
                                        [value]="cycleCountData?.warehouse"
                                        [enabled]="false"
                                    ></ejs-textbox>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row field no-border">
                                    <label class="control-label col-md-auto mt-2"> Status: </label>
                                    <ejs-dropdownlist #statusDDL
                                        id="statusddl"
                                        placeholder='Select Status'
                                        [dataSource]='statusesDDL'
                                        [value]="cycleCountData?.status"
                                        [enabled]="!statusDropdownReadOnly"
                                        (select)="onStatusChange($event)"
                                    ></ejs-dropdownlist>
                                </div>
                            </div>
                            <div class="col-auto" *ngIf="cycleCountData?.cycleCountId">
                                <div class="comments-section position-relative">
                                    <a class="comments-opener" href="javascript:;" #commentsButton (click)="showComments = !showComments"><i class="fas fa-comment mr-2" [ngClass]="{'color-orange': commentCount}"></i> <span>{{commentCount}}</span> Comment<span *ngIf="commentCount>1">s</span></a>
                                    <div class="comments-container position-absolute" #commentsPopup *ngIf="showComments">
                                        <app-comments
                                            [enableAddNew]="showComments"
                                            [serviceOrderId]="cycleCountData?.cycleCountId"
                                            [moduleId]="cycleCountData?.cycleCountId"
                                            module="CycleCount"
                                            [comments]="cycleCountData?.comments"
                                            (commentUpdated)="onCommentUpdate($event)"
                                            (onClose)="showComments = false;"
                                        ></app-comments>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col tabbable-line pt-1">
                                <ul class="nav nav-tabs float-right" id="tabs">
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link position-relative"
                                            [ngClass]="{'active': tab === 'details'}" 
                                            data-toggle="tab"
                                            href="#cycle-count-details-tab"
                                            title="Details"
                                        >Details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            #costAnalysis
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'costAnalysis'}" 
                                            data-toggle="tab"
                                            href="#cycle-count-costAnalysis-tab"
                                            title="Cost Analysis" 
                                            aria-expanded="true"
                                            (click)="appCostAnalysisGrid.refreshOnce()"
                                        ><i class="fas fa-table"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'attachments'}" 
                                            data-toggle="tab"
                                            href="#cycle-count-attachments-tab"
                                            title="Attachments" 
                                            aria-expanded="true"

                                        ><i class="fas fa-file-upload" [ngClass]="{'color-green': attachmentCount}"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a 
                                            class="nav-link" 
                                            [ngClass]="{'active': tab === 'history'}" 
                                            data-toggle="tab"
                                            href="#cycle-count-history-tab"
                                            title="History"
                                        ><i class="fas fa-history"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content custom-scrollbar flex-fill">
                            <div class="tab-pane mt-3" [ngClass]="{'active show': tab === 'details'}" id="cycle-count-details-tab">
                                <div class="px-4">
                                    <div class="row gx-5">
                                        <div class="field mb-3 col-3 position-relative">
                                            <label>Start/ETA Date: </label><span class="required-asterik">*</span>
                                            <ejs-datetimepicker #cycleCountStartDate
                                                placeholder="Start/ETA Date"
                                                [openOnFocus]="true"
                                                [showClearButton]="true"
                                                [showTodayButton]="true"
                                                [value]="cycleCountData?.startDate"
                                                [min]="currentDate"
                                                (change)="onStartDateChange($event)"
                                                [enabled]="canEditStartDate && (cycleCountData?.status == 'Open' || cycleCountData?.status == 'Planning'  || cycleCountData?.status == 'Scheduled')"
                                            ></ejs-datetimepicker>
                                        </div>
                                        <div class="field mb-3 col-3 position-relative">
                                            <label>Duration: </label>
                                            <ejs-textbox
                                                placeholder="Duration"
                                                [value]="cycleCountDuration"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>
                                        <div class="field mb-3 col-3 position-relative">
                                            <label>End Date: </label>
                                            <ejs-datetimepicker
                                                placeholder="End Date"
                                                [openOnFocus]="true"
                                                [showClearButton]="true"
                                                [showTodayButton]="true"
                                                [enabled]="false"
                                                [value]="cycleCountData?.endDate"
                                            ></ejs-datetimepicker>
                                        </div>
                                        <div class="field mb-3 col-3 position-relative">
                                            <label>Cycle Count No:</label>
                                            <ejs-textbox
                                                placeholder="Cycle Count No:"
                                                [value]="cycleCountData?.cycleCountNumber"
                                                (change)="cycleCountData.cycleCountNumber = $event.value;"
                                                [enabled]="false"
                                            ></ejs-textbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" style="height: 100px;">
                                    <div class="card-header d-flex pl-0" data-toggle="collapse" href="#app-cycle-collapse-open-exception">
                                        <a class="card-link flex-fill">Open Exception
                                            <ng-container *ngIf="exceptionGridExist">
                                                <i *ngIf="!exceptionExist" class="fas fa-check-circle" style="margin-left: 5px; color: #4be331;"></i>
                                                <i *ngIf="exceptionExist" class="fas fa-exclamation-circle" style="font-size: 15px; margin-left: 5px; color: #e33140;"></i>
                                            </ng-container>
                                        </a>
                                        <i class="fas fa-chevron-up"></i>
                                    </div>
                                  <div id="app-cycle-collapse-open-exception" class="collapse show">
                                        <app-cycle-counts-open-exception
                                            [openExceptionList]="openExceptions"
                                            (adjustmentOrderPopupClosed)="reloadPopupData()"
                                        ></app-cycle-counts-open-exception>
                                  </div>
                                </div>
                            </div>
                            <div id="cycle-count-costAnalysis-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'costAnalysis'}">
                                <div class="card">
                                    <app-cycle-counts-cost-analysis #appCostAnalysisGrid
                                        [costAnalysisList]="cycleCountData?.itemList"
                                        [warehouseId]="cycleCountData?.warehouse"
                                        [status]="cycleCountData?.status"
                                        [cycleCountId]="cycleCountData?.cycleCountId"
                                        (reloadPopupData)="reloadPopupData()"
                                        (updatedcostAnalysisList)="updatedcostAnalysisList = $event"
                                        (isFileUploaded)="isFileUploaded = $event"
                                        (totalExtCost)="totalExtCost = $event"
                                        (totalCostDifference)="totalCostDifference = $event"
                                        > 
                                    </app-cycle-counts-cost-analysis>
                                </div>
                            </div>
                            <div id="cycle-count-attachments-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'attachments'}">
                                <app-attachments-tab #attachmentsTab
                                    [moduleId]="cycleCountId"
                                    module="CycleCount"
                                    [attachments]="attachmentsData"
                                    (attachmentsUpdated)="onAttachmentsUpdate($event)"
                                ></app-attachments-tab>
                            </div>
                            <div id="cycle-count-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                                <app-history-tab-shared
                                    [popup]="'Cycle count history'" 
                                    [history]="cycleCountData?.history"
                                ></app-history-tab-shared>
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-info mr-1" id="" (click)="reset()">Reset</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save()">Save</button>
                <button type="button" class="btn btn-sm btn-success mr-1" (click)="save(true)">Save &amp; Close</button>
                <!-- <button id="cycle-count-btn" type="button" class="btn btn-sm btn-primary mr-1" [disabled]="disablePlaceOrder" (click)="save(true, true)">Place Order</button> -->
            </div>
        </div>
    </div>
</div>

<app-cycle-counts-resource-selection-popup 
    *ngIf="showResourceSelectionPopup"
    [employeeId]="technicianId"
    (onClose)="onResourcePopupClose($event)"
></app-cycle-counts-resource-selection-popup>

<app-link-service-orders-popup
    *ngIf="showServiceOrderSelectionPopup"
    [technicianId]="data?.requester?.technicianId"
    [selectedServiceOrderIDs]="data?.serviceOrderList"
    (onClose)="showServiceOrderSelectionPopup = false"
    (serviceOrderIdArray)="receiveServiceOrders($event)"
></app-link-service-orders-popup>
